/* Mixin de ejemplo para botón con animación, se manda a llamar @include btn-black; donde necesites usar el mixin, para no repetir código*/

@mixin btn-red {
    height: 40px;
    border: solid 1px $p-red;
    @include SourceSansRegular;
    font-size: 20px;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $p-red;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
    border-radius: 0;
    transition: 0.5s;
    @media screen and (min-width: $bp-large) {
        height: 45px;
        font-size: 22px;
    }
    &:hover {
        background: $p-red;
        color: $white;
    }
}

@mixin input-styles {
    @include SourceSansRegular;
    font-size: 16px;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $p-gray;

    ::placeholder {
        color: $p-gray;
    }

    &:focus {
        box-shadow: none;
        outline: none;
    }
}
