/* Mixin de ejemplo para fuentes, se manda a llamar @include regular; donde necesites usar el mixin, para no repetir código
@mixin regular {
  font-family: 'Open Sans';
  font-weight: normal;
  font-style: normal;
}*/

@mixin SourceSansRegular {
  font-family: 'Source Sans Pro';
  font-weight: 500;
  font-style: normal;
}

@mixin SourceSansBold {
  font-family: 'Source Sans Pro';
  font-weight: bold;
  font-style: normal;
}

@mixin PlayfairDisplayRegular {
  font-family: 'Playfair Display';
  font-weight: 500;
  font-style: normal;
}

@mixin PlayfairDisplayBlack {
  font-family: 'Playfair Display';
  font-weight: 800;
  font-style: normal;
}

@mixin PlayfairDisplayBold {
  font-family: 'Playfair Display';
  font-weight: 800;
  font-style: normal;
}