.sales-order-view {
    .page-wrapper {
        .page-main {
            .columns {
                .column {
                    .items {
                        border-bottom: none;
                        .nav {
                            strong,
                            a {
                                @include PlayfairDisplayBlack;
                                font-size: 24px;
                                color: $p-black;
                            }
                        }
                    }
                    .order-details-items {
                        .order-title {
                            strong {
                                @include SourceSansRegular;
                                font-size: 18px;
                                color: $p-black;
                            }
                        }
                        .table-wrapper {
                            .data {
                                thead {
                                    tr {
                                        th {
                                            @include SourceSansBold;
                                            font-size: 16px;
                                            color: $p-black;
                                            border-bottom: 1px solid #979797;
                                        }
                                    }
                                }
                                tbody {
                                    tr {
                                        td {
                                            color: $p-black;
                                            @include SourceSansRegular;
                                            font-size: 16px;
                                            font-weight: 500;
                                            &::before {
                                                color: $p-black;
                                                @include SourceSansBold;
                                                font-size: 16px;
                                            }
                                        }
                                        .col.price,
                                        .col.subtotal {
                                            display: flex;
                                            align-items: center;
                                            @media screen and (min-width: $bp-medium) {
                                                display: table-cell;
                                            }
                                            .price-excluding-tax {
                                                font-size: 16px;
                                            }
                                        }
                                    }
                                }
                                tfoot {
                                    tr {
                                        .mark {
                                            @include SourceSansRegular;
                                            font-size: 20px;
                                            color: $p-black;
                                        }
                                        .amount {
                                            .price {
                                                @include SourceSansBold;
                                                font-size: 20px;
                                                color: $p-black;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .block-order-details-view {
                        .block-content {
                            .box {
                                .box-content {
                                    @include SourceSansRegular;
                                    font-size: 16px;
                                    color: $p-black;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Shipment

.sales-order-invoice,
.sales-order-shipment,
.sales-order-creditmemo {
    .page-main {
        .items {
            border-bottom: none;
            .nav {
                strong,
                a {
                    @include PlayfairDisplayBlack;
                    font-size: 24px;
                    color: $p-black;
                }
            }
        }
        .order-details-items {
            .actions-toolbar {
                .action.print {
                    @include SourceSansRegular;
                    color: $p-red;
                    font-size: 16px;
                    text-decoration: underline;
                }
            }
            .order-title {
                display: flex;
                flex-direction: column;
                @media screen and (min-width: $bp-medium) {
                    margin-bottom: 10px;
                }
                @media screen and (min-width: $bp-large) {
                    margin-bottom: 20px;
                }
                strong {
                    @include SourceSansRegular;
                    font-size: 24px;
                    color: $p-black;
                    @media screen and (min-width: $bp-large) {
                        margin-right: 25px;
                    }
                }
                .action {
                    @include SourceSansRegular;
                    color: $p-red;
                    font-size: 16px;
                    text-decoration: underline;
                }
            }
            .table-wrapper {
                .data {
                    thead {
                        tr {
                            th {
                                @include SourceSansBold;
                                font-size: 16px;
                                color: $p-black;
                                border-bottom: 1px solid #979797;
                            }
                        }
                    }
                    tbody {
                        tr {
                            td {
                                color: $p-black;
                                @include SourceSansRegular;
                                font-size: 16px;
                                font-weight: 500;
                                &::before {
                                    color: $p-black;
                                    @include SourceSansBold;
                                    font-size: 16px;
                                }
                            }
                            .col.price,
                            .col.subtotal {
                                display: flex;
                                align-items: center;
                                @media screen and (min-width: $bp-medium) {
                                    display: table-cell;
                                }
                                .price-excluding-tax {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                    tfoot {
                        tr {
                            .mark {
                                @include SourceSansRegular;
                                font-size: 20px;
                                color: $p-black;
                            }
                            .amount {
                                .price {
                                    @include SourceSansBold;
                                    font-size: 20px;
                                    color: $p-black;
                                }
                            }
                        }
                    }
                }
            }
        }
        .block-order-details-view {
            .block-content {
                .box {
                    .box-content {
                        @include SourceSansRegular;
                        font-size: 16px;
                        color: $p-black;
                    }
                }
            }
        }
    }
}

//Store Credit

.aw_store_credit-info-index {
    .page-main {
        .block.store-credit {
            margin-bottom: 0;
            @media screen and (min-width: $bp-large) {
                margin-bottom: 20px;
            }
            .block-content {
                .base {
                    @include SourceSansRegular;
                    font-size: 24px;
                    color: $p-black;
                }
            }
        }
        .table-wrapper {
            .data {
                thead {
                    tr {
                        th {
                            @include SourceSansBold;
                            font-size: 16px;
                            color: $p-black;
                            border-bottom: 1px solid #979797;
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            color: $p-black;
                            @include SourceSansRegular;
                            font-size: 16px;
                            font-weight: 500;
                            &::before {
                                color: $p-black;
                                @include SourceSansBold;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
        .secondary {
            .action {
                span {
                    @include SourceSansRegular;
                    color: $p-red;
                    font-size: 16px;
                    text-decoration: underline;
                }
            }
        }
    }
}
