//GLOBAL

.page-footer {
    .footer.content {
        margin: 0 auto;
        padding: 0;
        border: 0;
        max-width: 100%;
        .block.newsletter {
            padding: 10px 20px 20px 20px;
            margin-bottom: 0;
            background: $p-red;
            width: auto;
            min-width: 100%;
            max-width: 100%;
            @media screen and (min-width: $bp-medium) and (max-width: $bp-large) {
                padding: 10px 35px 20px 35px;
            }
            @media screen and (min-width: $bp-medium) {
                display: flex;
                align-items: center;
                width: auto;
                justify-content: space-around;
                height: auto;
                flex-wrap: wrap;
                float: none;
            }
            @media screen and (min-width: $bp-large) {
                justify-content: space-evenly;
                padding: 0;
                height: auto;
                flex-wrap: nowrap;
            }
            .info-dscto {
                padding: 20px 0;
                text-align: center;
                @media screen and (min-width: $bp-large) {
                    width: 100%;
                    text-align: right;
                    padding: 20px 50px 20px 0;
                }
                .text {
                    color: $white;
                    font-size: 16px;
                    @include SourceSansRegular;
                    margin: 0;
                    @media screen and (min-width: $bp-medium) {
                        font-size: 20px;
                    }
                }
                .welcome {
                    text-transform: uppercase;
                }
            }
            .info {
                .text {
                    font-size: 14px;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;

                    padding-bottom: 10px;
                    width: 80%;
                    @media screen and (min-width: $bp-medium) and (max-width: $bp-large) {
                        padding: 0;
                        width: 100%;
                    }
                    @media screen and (min-width: $bp-large) {
                        width: 100%;
                        padding: 0;
                        font-size: 22px;
                    }
                }
            }
            .content {
                @media screen and (min-width: $bp-medium) {
                    width: 100%;
                }
                @media screen and (min-width: $bp-large) {
                    margin: 30px 0;
                }
                .form.subscribe {
                    display: flex;
                    flex-wrap: wrap;
                    @media screen and (min-width: $bp-medium) {
                        display: flex;
                        width: 100%;
                        margin: 0;
                        align-items: flex-start;
                        flex-wrap: nowrap;
                    }
                    .newsletter {
                        max-width: 100%;
                        width: 100%;
                        @media screen and (min-width: $bp-medium) {
                            margin-right: 0;
                        }
                        @media screen and (min-width: $bp-large) {
                            margin-right: 0;
                        }
                        .control {
                            &:before {
                                display: none;
                            }
                            input {
                                padding: 0 10px;
                                height: 45px;
                                border: 0;
                                border-radius: 0;
                                width: 100%;
                                font-size: 20px;
                                font-stretch: normal;
                                line-height: normal;
                                letter-spacing: normal;
                                text-align: center;
                                margin: 0;
                                @include SourceSansRegular;

                                background-clip: initial;
                                @media screen and (min-width: $bp-medium) {
                                    width: 100%;
                                }
                                @media screen and (min-width: $bp-large) {
                                    width: 100%;
                                }
                            }
                            ::placeholder {
                                opacity: 1;
                                color: #4a4a4a;
                                @include SourceSansRegular;
                                @media screen and (min-width: $bp-large) {
                                    font-size: 20px;
                                    text-align: left;
                                }
                            }
                        }
                    }
                    .actions {
                        display: block;
                        width: 100%;
                        .action.subscribe {
                            width: 100%;
                            border-radius: 0;
                            margin-top: 10px;
                            background-color: $p-black;
                            border: none;
                            margin-left: 0;
                            height: 45px;
                            position: relative;
                            @media screen and (min-width: $bp-medium) {
                                margin-top: 0;
                            }
                            @media screen and (min-width: $bp-large) {
                                width: 150px;
                                text-align: center;
                                font-size: 20px;
                                padding: 0;
                                transition: 0.5s;
                            }
                            span {
                                color: $white;
                                @include SourceSansRegular;
                                font-size: 20px;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
        .links-container {
            padding: 30px;
            background: $p-black;
            @media screen and (min-width: $bp-medium) {
                padding: 30px 20px 0 30px;
            }
            @media screen and (min-width: $bp-large) {
                padding: 30px 70px 15px;
            }
            .sections {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                margin-left: 0;
                @media screen and (min-width: $bp-medium) and (max-width: $bp-large) {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: auto auto;
                    align-items: flex-start;
                    margin-bottom: 20px;
                }
                @media screen and (min-width: $bp-large) {
                    align-items: flex-start;
                    justify-content: space-between;
                    flex-direction: row;
                }
                .col1 {
                    @media screen and (min-width: $bp-medium) and (max-width: $bp-large) {
                        grid-area: 1 / 1 / 2 / 2;
                    }
                }
                .col2 {
                    @media screen and (min-width: $bp-medium) and (max-width: $bp-large) {
                        grid-area: 1 / 2 / 2 / 3;
                    }
                }
                .column {
                    margin: 0;
                    width: 65%;
                    @media screen and (min-width: $bp-medium) {
                        width: 31%;
                    }
                    @media screen and (min-width: $bp-large) {
                        width: 21%;
                    }
                    .list {
                        list-style: disc;
                        padding: 0;
                        margin: 10px 0 0;
                        .item {
                            font-size: 18px;
                            margin-bottom: 5px;
                            font-stretch: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: left;
                            color: $white;
                            padding-left: 10px;
                            @media screen and (min-width: $bp-medium) {
                                font-size: 12px;
                            }
                            @media screen and (min-width: $bp-large) {
                                margin-bottom: 0;
                                line-height: 1.33;
                            }
                            a {
                                color: $white;
                                @include SourceSansRegular;
                                font-size: 18px;
                            }
                        }
                    }
                }
                .info-footer {
                    padding: 0;
                    margin: 20px 0;
                    width: 100%;
                    @media screen and (min-width: $bp-medium) and (max-width: $bp-large) {
                        grid-area: 2 / 1 / 3 / 3;
                    }
                    @media screen and (min-width: $bp-large) {
                        width: 35%;
                        margin: 0;
                    }
                    @media screen and (min-width: $bp-xlarge) {
                        width: 45%;
                    }
                    @media screen and (min-width: $bp-xxlarge) {
                        width: 41%;
                    }
                    @media screen and (min-width: 1600px) {
                        width: 35%;
                    }
                    .list {
                        @media screen and (min-width: $bp-medium) {
                            width: 100%;
                        }
                        .item {
                            list-style: none;
                            .container {
                                @media screen and (min-width: $bp-medium) {
                                    display: grid;
                                    grid-template-columns: 1fr 1fr;
                                    grid-template-rows: auto auto auto;
                                    grid-gap: 0px 25px;
                                }
                                .text-container {
                                    display: flex;
                                    flex-direction: column;
                                    margin-bottom: 10px;
                                    @include SourceSansRegular;
                                    .first {
                                        font-weight: bold;
                                    }
                                    .text {
                                        font-size: 18px;
                                        font-stretch: normal;
                                        line-height: 1.58;
                                        letter-spacing: normal;
                                        text-align: left;
                                        margin: 0;
                                        @media screen and (min-width: $bp-large) {
                                            font-size: 16px;
                                        }
                                    }
                                    .spacing {
                                        margin: 10px 0;
                                    }
                                }
                                .email {
                                    @media screen and (min-width: $bp-medium) {
                                        grid-area: 3 / 1 / 4 / 3;
                                    }
                                    a {
                                        font-size: 18px;
                                        text-align: left;
                                        text-decoration: underline;
                                    }
                                }
                                .col1 {
                                    @media screen and (min-width: $bp-medium) {
                                        grid-area: 1 / 1 / 2 / 2;
                                    }
                                }
                                .col2 {
                                    @media screen and (min-width: $bp-medium) {
                                        grid-area: 1 / 2 / 2 / 3;
                                    }
                                }
                                .col3 {
                                    @media screen and (min-width: $bp-medium) {
                                        grid-area: 2 / 1 / 3 / 2;
                                    }
                                }
                                .col4 {
                                    @media screen and (min-width: $bp-medium) {
                                        grid-area: 2 / 2 / 3 / 3;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .info {
                @media screen and (min-width: $bp-medium) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    width: 100%;
                }
                @media screen and (min-width: $bp-large) {
                    display: grid;
                    grid-template-columns: 1fr 2fr 1fr;
                    grid-template-rows: 1fr;
                    gap: 1px 1px;
                }
                .logos {
                    @media screen and (min-width: $bp-medium) {
                        width: 100%;
                    }
                    @media screen and (min-width: $bp-large) {
                        width: auto;
                        margin: 0;
                    }
                    .subtitle {
                        font-size: 18px;
                        font-stretch: normal;
                        line-height: 1.58;
                        letter-spacing: normal;
                        text-align: center;
                        margin: 0;
                        @media screen and (min-width: $bp-large) {
                            text-align: left;
                        }
                    }
                    .list {
                        display: flex;
                        margin: 10px 0 30px;
                        align-items: center;
                        justify-content: space-evenly;
                        flex-wrap: wrap;
                        @media screen and (min-width: $bp-medium) {
                            justify-content: center;
                            margin: 0;
                        }
                        @media screen and (min-width: $bp-large) {
                            justify-content: inherit;
                            a,
                            .icon {
                                margin: 0 20px 0 0;
                            }
                        }
                        a {
                            @media screen and (min-width: $bp-medium) {
                                margin: 0 10px;
                            }
                            @media screen and (min-width: $bp-large) {
                                margin: 0;
                            }
                        }
                        i {
                            @media screen and (min-width: $bp-medium) {
                                margin: 0 5px;
                            }
                        }
                    }
                    &:last-child {
                        .list {
                            margin-bottom: 0;
                            @media screen and (min-width: $bp-large) {
                                margin-top: 1em;
                            }
                            .icon {
                                margin-bottom: 20px;
                                @media screen and (min-width: $bp-large) {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    &:nth-child(2) {
                        @media screen and (min-width: $bp-large) {
                            justify-content: center;
                        }
                        .list {
                            width: 100%;
                            margin: auto;
                            justify-content: center;
                            @media screen and (min-width: $bp-medium) {
                                margin: 15px 0 0;
                                width: 100%;
                            }
                            .icon {
                                padding: 0 5px;
                                @media screen and (min-width: $bp-medium) {
                                    padding: 0;
                                }
                            }
                            .icon-icon-mc,
                            .icon-icon-visa,
                            .icon-icon-amex,
                            .icon-icon-conekta {
                                padding-bottom: 25px;
                                @media screen and (min-width: $bp-medium) {
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }
                }
                .logos.methods {
                    @media screen and (min-width: $bp-medium) {
                        display: flex;
                    }
                    .col1 {
                        @media screen and (min-width: $bp-medium) {
                            justify-content: flex-end;
                        }
                    }
                    .col2 {
                        @media screen and (min-width: $bp-medium) {
                            justify-content: flex-start;
                        }
                    }
                }
            }
        }
        .copyright {
            margin: 0;
            color: $white;
            background: $p-black;
            text-align: center;
            padding: 0 20px 20px 20px;
            @include SourceSansRegular;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.44;
            letter-spacing: normal;
            @media screen and (min-width: $bp-medium) {
                padding: 10px 0 30px;
            }
            span {
                display: flex;
                width: 70%;
                margin: auto;
                @media screen and (min-width: $bp-medium) {
                    justify-content: center;
                    width: 100%;
                }
            }
        }
    }
}

.onepage-index-index {
    .page-footer {
        .footer.content {
            .copyright {
                padding: 30px 0;
            }
        }
    }
}
