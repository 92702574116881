.blog-post-view {
    .page-wrapper {
        .page-header {
            background-color: $p-black;
        }

        .sections {
            .section-items {
                .section-item-content {
                    .page-main {
                        .rootmenu {
                            @media screen and (min-width: $bp-large) {
                                background-color: $p-black;
                            }
                        }
                    }
                }
            }
        }

        .page-main {
            padding-top: 0;

            .image-view {
                display: none;
            }

            .columns {
                @media screen and (min-width: $bp-large) {
                    padding: 20px 40px 0;
                }
                .column {
                    .blog__post-view {
                        display: flex;
                        flex-direction: column;
                        @media screen and (min-width: $bp-medium) {
                            padding-right: 30px;
                        }
                        @media screen and (min-width: $bp-large) {
                            padding-right: 45px;
                        }

                        .page-title-wrapper {
                            display: block;

                            .page-title {
                                text-align: center;

                                span {
                                    @include PlayfairDisplayBold;
                                    font-size: 40px;
                                    line-height: 24px;

                                    &::before,
                                    &::after {
                                        content: "~";
                                    }
                                }
                            }
                        }

                        .blog__post-featured-image {
                            order: -1;
                            @media screen and (min-width: $bp-medium) {
                                padding: 0 0 20px;
                            }
                        }

                        .content-cms-block {
                            background: #ebebeb;
                            padding: 30px 25px 50px;
                            margin-bottom: 30px;
                            @media screen and (min-width: $bp-large) {
                            }
                            h1 {
                                @include PlayfairDisplayBold;
                                font-size: 25px;
                                line-height: 26px;
                                color: $p-black;
                            }
                            h3 {
                                @include SourceSansBold;
                                font-size: 20px;
                                line-height: 26px;
                                color: $p-black;
                            }
                            p {
                                @include SourceSansRegular;
                                font-size: 16px;
                                line-height: 26px;
                                color: $p-black;
                            }
                            iframe {
                                max-width: 100%;
                            }
                            ul {
                                li {
                                    @include SourceSansRegular;
                                    font-size: 16px;
                                    line-height: 26px;
                                    color: $p-black;
                                }
                            }
                            ol {
                                li {
                                    @include SourceSansRegular;
                                    font-size: 16px;
                                    line-height: 26px;
                                    color: $p-black;
                                }
                            }
                        }

                        .blog__post-meta {
                            width: 100%;
                            align-items: flex-start;

                            @media screen and (min-width: $bp-large) {
                                display: grid;
                                grid-template-columns: 1fr auto;
                                grid-template-rows: auto;
                            }

                            .meta-container {
                                display: flex;
                                flex-direction: column;
                                margin-bottom: 15px;

                                @media screen and (min-width: $bp-large) {
                                    grid-area: 1/1/2;
                                    display: grid;
                                    grid-template-columns: auto auto;
                                    justify-content: center;
                                    padding-left: 10%;
                                }

                                .blog__post-meta-author {
                                    @media screen and (min-width: $bp-large) {
                                        margin-top: 20px;
                                    }
                                }
                            }

                            .socials {
                                display: flex;
                                @media screen and (min-width: $bp-large) {
                                    grid-area: 1/2/1;
                                }
                                .addthis_sharing_toolbox {
                                    @media screen and (min-width: $bp-large) {
                                        margin-right: 0;
                                        margin-left: 0;
                                    }
                                }
                            }
                        }

                        .blog__post-footer {
                            display: flex;

                            @media screen and (min-width: $bp-large) {
                                margin-bottom: 10px;
                            }

                            .blog__post-footer-categories {
                                @include SourceSansRegular;
                                font-size: 14px;
                                color: #a3a3a3;
                                margin-left: 0;

                                a {
                                    @include SourceSansRegular;
                                    font-size: 14px;
                                    color: #a3a3a3;
                                }
                            }
                        }

                        .blog__post-view-related-products {
                            .block-title {
                                @include PlayfairDisplayBold;
                                font-size: 25px;
                                line-height: 26px;
                                color: $p-black;
                                margin-bottom: 15px;
                            }

                            ol {
                                .products {
                                    margin: 0;

                                    .uk-child-width-1-2 > * {
                                        @media screen and (max-width: 630px) {
                                            width: 50%;
                                        }
                                    }

                                    .uk-grid > * {
                                        padding-left: 30px;
                                    }

                                    .uk-grid {
                                        margin-left: -30px;
                                    }

                                    .products.list {
                                        .item {
                                            margin-left: 0;

                                            .product-item-info {
                                                width: 100%;

                                                .discount-flag {
                                                    width: 45px;
                                                    height: 45px;
                                                    margin: 10px;
                                                    border-radius: 3em;
                                                    overflow: hidden;
                                                    position: absolute;
                                                    z-index: 2;
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    background: $hover;
                                                    transform: rotate(-25deg);
                                                    font-size: 18px;
                                                    color: $white;
                                                    @include PlayfairDisplayBold;
                                                    left: 25px;

                                                    @media screen and (min-width: $bp-medium) {
                                                        width: 60px;
                                                        height: 60px;
                                                    }

                                                    .discount {
                                                        margin-top: -5px;

                                                        @media screen and (min-width: $bp-medium) {
                                                            margin-top: -6px;
                                                            font-size: 24px;
                                                        }

                                                        @media screen and (min-width: $bp-large) {
                                                            margin-top: -10px;
                                                        }
                                                    }

                                                    .percent {
                                                        font-size: 10.5px;
                                                        padding-top: 5px;

                                                        @media screen and (min-width: $bp-medium) {
                                                            font-size: 14px;
                                                        }
                                                    }
                                                }

                                                .product-item-photo {
                                                    text-align: center;
                                                    display: block;
                                                }

                                                .product {
                                                    .product {
                                                        text-align: center;
                                                        font-size: 0;

                                                        .product-item-link {
                                                            margin-top: 10px;
                                                            font-size: 16px;
                                                            @include SourceSansRegular;
                                                            color: $p-black;
                                                            height: 45px;
                                                            overflow: hidden;
                                                            display: block;
                                                            text-align: center;

                                                            @media screen and (min-width: $bp-medium) {
                                                                margin-top: 30px;
                                                                font-size: 20px;
                                                                height: 55px;
                                                            }

                                                            @media screen and (min-width: $bp-large) {
                                                                margin-top: 20px;
                                                            }
                                                        }
                                                    }

                                                    .price-box {
                                                        text-align: center;
                                                        margin-bottom: 10px;
                                                        height: 64px;

                                                        .text-old-price {
                                                            display: none;
                                                        }

                                                        .normal-price {
                                                            .text-special-price {
                                                                display: none;
                                                            }
                                                        }

                                                        .old-price {
                                                            .text-old-price {
                                                                display: none;
                                                            }

                                                            .price-container {
                                                                font-size: 18px;

                                                                @media screen and (min-width: $bp-medium) {
                                                                    font-size: 24px;
                                                                }

                                                                .price-wrapper {
                                                                    .price {
                                                                        @include SourceSansRegular;
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        .price-container {
                                                            .price-wrapper {
                                                                .price {
                                                                    @include SourceSansBold;
                                                                    font-size: 18px;
                                                                    color: $p-black;

                                                                    @media screen and (min-width: $bp-medium) {
                                                                        font-size: 24px;
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        .special-price {
                                                            .text-special-price {
                                                                display: none;
                                                            }
                                                        }
                                                    }

                                                    .field {
                                                        display: none;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .uk-icon {
                                        background: url(../images/icons/icon-arrow-izq-oscura.svg);
                                        content: "";
                                        width: 50px;
                                        height: 55px;
                                        background-repeat: no-repeat;
                                        padding: 0;
                                        margin: 0;
                                        background-position: center;
                                        background-color: rgba(
                                            255,
                                            255,
                                            255,
                                            0.25
                                        );
                                        border-radius: 0;

                                        &:hover {
                                            background: url(../images/icons/icon-arrow.svg);
                                            content: "";
                                            width: 50px;
                                            height: 55px;
                                            background-repeat: no-repeat;
                                            padding: 0;
                                            margin: 0;
                                            background-position: center;
                                            background-color: $white;
                                            border-radius: 0;
                                        }

                                        svg {
                                            display: none;
                                        }
                                    }

                                    .uk-position-center-right {
                                        transform: rotate(-180deg);
                                        top: 42%;
                                        right: -15px;

                                        @media screen and (min-width: $bp-large) {
                                            top: 40%;
                                        }

                                        @media screen and (min-width: $bp-xlarge) {
                                            top: 45%;
                                        }

                                        @media screen and (min-width: $bp-xxlarge) {
                                            top: 50%;
                                        }

                                        &:hover {
                                            transform: rotate(0deg);
                                        }
                                    }

                                    .uk-position-center-left {
                                        transform: translateY(0);
                                        top: 42%;
                                        left: -15px;

                                        @media screen and (min-width: $bp-large) {
                                            top: 40%;
                                        }

                                        @media screen and (min-width: $bp-xlarge) {
                                            top: 45%;
                                        }

                                        @media screen and (min-width: $bp-xxlarge) {
                                            top: 50%;
                                        }

                                        &:hover {
                                            transform: rotate(-180deg);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
