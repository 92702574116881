.page-with-filter {
    .page-wrapper {
        .page-header {
            background-color: transparent;
            @media screen and (min-width: $bp-large) {
                background-color: transparent;
            }

            .header {
                .header-right {
                    .mobile-right-menu {
                        #right-menu {
                            background-color: rgba(0, 0, 0, 0.75);
                        }
                    }
                }
            }
        }

        .sections {
            .section-items {
                .section-item-content {
                    .page-main {
                        .rootmenu {
                            background-color: rgba(0, 0, 0, 0.75) !important;
                        }
                    }
                }
            }
        }

        .page-main {
            .category-view {
                .category-image {
                    position: relative;
                    height: 223px;
                    overflow: hidden;
                    margin-top: -70px;
                    display: flex;
                    justify-content: center;
                    @media screen and (min-width: $bp-large) {
                        margin-top: -120px;
                        height: auto;
                    }
                    @media screen and (min-width: $bp-xlarge) {
                        margin-top: -170px;
                    }

                    h1 {
                        position: absolute;
                        left: 0;
                        top: 0;
                        color: white;
                        right: 0;
                        text-align: center;
                        font-size: 40px;
                        @include PlayfairDisplayBold;
                        margin: 0;
                        bottom: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-shadow: 0 0 5px rgba(0, 0, 0, 0.75);

                        &::before {
                            content: "~ ";
                        }

                        &::after {
                            content: " ~";
                        }

                        @media screen and (max-width: $bp-large) {
                            padding: 0 5vw;
                            margin-top: 5vh;
                        }

                        @media screen and (min-width: $bp-large) {
                            top: 30%;
                            font-size: 50px;
                        }
                    }

                    .image {
                        width: auto;
                        object-fit: contain;
                        height: 100%;
                        max-width: initial;
                        @media screen and (min-width: $bp-large) {
                            width: 100%;
                        }
                    }
                }
            }

            .columns {
                padding: 0 15px;
                @media screen and (min-width: $bp-large) {
                    padding: 0 20px;
                }

                .column {
                    padding-bottom: 30px;
                    @media screen and (min-width: $bp-medium) {
                        width: 80%;
                    }

                    .toolbar {
                        margin-bottom: 0;
                        @media screen and (min-width: $bp-medium) {
                            justify-content: flex-end;
                            align-items: center;
                            display: flex;
                        }

                        .toolbar-amount {
                            @include SourceSansRegular;
                            font-size: 16px;
                            color: $p-black;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                        }

                        .toolbar-sorter {
                            .sorter-label {
                                @include SourceSansRegular;
                                font-size: 16px;
                                color: $p-black;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: normal;
                                letter-spacing: normal;
                            }

                            .sorter-options {
                                border: 1px solid $p-gray;

                                .sorter-label {
                                    @include SourceSansRegular;
                                    font-size: 16px;
                                    color: $p-black;
                                    font-stretch: normal;
                                    font-style: normal;
                                    line-height: normal;
                                    letter-spacing: normal;
                                }
                            }
                        }

                        .field {
                            .label,
                            .control,
                            .limiter-text {
                                @include SourceSansRegular;
                                font-size: 16px;
                                color: $p-black;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: normal;
                                letter-spacing: normal;
                            }

                            .control {
                                .limiter-options {
                                    border: 1px solid $p-gray;

                                    option:focus {
                                        background-color: $p-red !important;
                                    }
                                }
                            }
                        }

                        .pages {
                            @media screen and (min-width: $bp-medium) {
                                margin-right: 15%;
                            }
                            @media screen and (min-width: $bp-large) {
                                margin-right: 30%;
                            }

                            .items {
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .item {
                                    display: flex;

                                    .page {
                                        @include SourceSansRegular;
                                        font-size: 16px;
                                        color: $p-black;
                                    }
                                }

                                .current {
                                    .page {
                                        background-color: $p-red;
                                        padding: 0;
                                        width: 25px;
                                        height: 25px;
                                        line-height: 25px;
                                        color: $white;
                                    }
                                }

                                .pages-item-next,
                                .pages-item-previous {
                                    .action {
                                        background: url(../images/icons/icon-right.svg);
                                        content: "";
                                        width: 25px;
                                        height: 25px;
                                        background-repeat: no-repeat;
                                        padding: 0;
                                        margin: 0;
                                        background-position: center;
                                        border: none;

                                        &::before {
                                            display: none;
                                        }
                                    }
                                }

                                .pages-item-previous {
                                    transform: rotate(-180deg);
                                }
                            }
                        }
                    }

                    .products {
                        margin: 0;

                        .products {
                            .item {
                                margin-bottom: 0;

                                .product-item-info {
                                    position: relative;

                                    .discount-flag {
                                        width: 45px;
                                        height: 45px;
                                        margin: 10px;
                                        border-radius: 3em;
                                        overflow: hidden;
                                        position: absolute;
                                        z-index: 2;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        background: $hover;
                                        transform: rotate(-25deg);
                                        font-size: 18px;
                                        color: $white;
                                        @include PlayfairDisplayBold;
                                        left: -10px;
                                        top: 18px;
                                        @media screen and (min-width: $bp-medium) {
                                            width: 60px;
                                            height: 60px;
                                        }
                                        @media screen and (min-width: $bp-large) {
                                            top: 26px;
                                        }
                                    }

                                    .product-reviews-summary {
                                        display: flex;
                                        justify-content: center;
                                        margin-bottom: 0;
                                        margin-top: 0;
                                        height: 28px;

                                        .rating-summary {
                                            margin: 0;
                                            left: 0;

                                            .rating-result {
                                                width: 125px;
                                                margin: 0;

                                                &::before {
                                                    content: "";
                                                    width: 100%;
                                                    background-image: url("../images/empty-stars-small.svg");
                                                    background-repeat: no-repeat;
                                                }

                                                span {
                                                    &::before {
                                                        position: sticky;
                                                        content: "";
                                                        background-image: url("../images/filled-stars-small.svg");
                                                        background-repeat: no-repeat;
                                                    }
                                                }
                                            }
                                        }

                                        .reviews-actions {
                                            display: none;
                                        }
                                    }

                                    .product-item-details {
                                        height: 100%;

                                        .product {
                                            font-size: 0;
                                            text-align: center;
                                            height: 45px;
                                            overflow: hidden;
                                            margin-bottom: 0;
                                            margin-top: 0;
                                            @media screen and (min-width: $bp-large) {
                                                height: 60px;
                                            }

                                            .product-item-link {
                                                font-size: 16px;
                                                @include SourceSansRegular;
                                                color: $p-black;
                                                display: -webkit-box;
                                                line-height: normal;
                                                -webkit-line-clamp: 2;
                                                -webkit-box-orient: vertical;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                @media screen and (min-width: $bp-medium) {
                                                    font-size: 16px;
                                                }
                                                @media screen and (min-width: $bp-large) {
                                                    font-size: 20px;
                                                }
                                            }
                                        }

                                        .price-box {
                                            height: 45px;
                                            line-height: 20px;
                                            text-align: center;
                                            margin-bottom: 10px;
                                            margin-top: 5px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            flex-direction: column;
                                            @media screen and (min-width: $bp-medium) {
                                                line-height: 26px;
                                                height: 55px;
                                            }

                                            .text-old-price {
                                                display: none;
                                            }

                                            .price-container {
                                                .price-wrapper {
                                                    .price {
                                                        @include SourceSansBold;
                                                        font-size: 18px;
                                                        color: $p-black;
                                                        @media screen and (min-width: $bp-medium) {
                                                            font-size: 20px;
                                                        }
                                                        @media screen and (min-width: $bp-large) {
                                                            font-size: 24px;
                                                        }
                                                    }
                                                }
                                            }

                                            .normal-price {
                                                .text-special-price {
                                                    display: none;
                                                }

                                                .price-container {
                                                    .price-label {
                                                        display: none;
                                                    }

                                                    .price-wrapper {
                                                        .price {
                                                            @include SourceSansBold;
                                                            font-size: 18px;
                                                            color: $p-black;
                                                            @media screen and (min-width: $bp-medium) {
                                                                font-size: 20px;
                                                            }
                                                            @media screen and (min-width: $bp-large) {
                                                                font-size: 24px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .old-price {
                                                .text {
                                                    display: none;
                                                }

                                                .text-old-price {
                                                    display: none;
                                                }

                                                .price-container {
                                                    .price-wrapper {
                                                        .price {
                                                            @include SourceSansRegular;
                                                            font-size: 18px;
                                                            color: $p-black;
                                                            @media screen and (min-width: $bp-medium) {
                                                                font-size: 20px;
                                                            }
                                                            @media screen and (min-width: $bp-large) {
                                                                font-size: 24px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .special-price {
                                                .text-special-price {
                                                    display: none;
                                                }
                                            }
                                        }

                                        .swatch-attribute {
                                            .swatch-attribute-options {
                                                display: flex;
                                                justify-content: center;
                                                flex-wrap: wrap;

                                                .swatch-option {
                                                    font-size: 16px;
                                                    color: $p-black;
                                                    outline: none;
                                                    overflow: visible;
                                                    border: 1px solid $p-gray;
                                                    line-height: initial;
                                                    height: auto;
                                                    width: auto;
                                                    box-shadow: none;
                                                    background-color: $white;
                                                    padding: 5px 10px;
                                                    margin-bottom: 10px;

                                                    &.selected {
                                                        @include SourceSansBold;
                                                        color: $white;
                                                        border: solid 1px
                                                            #93002a;
                                                        background-color: #93002a !important;
                                                    }
                                                }
                                            }
                                        }

                                        .product-item-inner {
                                            display: block;

                                            .product {
                                                height: auto;
                                                margin-bottom: 0;

                                                .actions-primary {
                                                    display: flex;
                                                    .stock.unavailable {
                                                        height: 40px;
                                                        display: flex;
                                                        justify-content: center;
                                                        align-items: center;
                                                        padding: 7px 10px;
                                                        font-size: 16px;
                                                        @include SourceSansRegular;
                                                        border: 1px solid $p-red;
                                                        width: 100%;
                                                        @media screen and (min-width: $bp-large) {
                                                            font-size: 22px;
                                                            height: 48px;
                                                        }
                                                        span {
                                                            text-transform: uppercase;
                                                            color: $p-red;
                                                        }
                                                    }

                                                    form {
                                                        width: 100%;

                                                        .action {
                                                            width: 100%;
                                                            @include btn-red;
                                                            font-size: 16px;
                                                            padding: 7px 8px;

                                                            span {
                                                                text-transform: uppercase;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    div:nth-child(2) {
                        display: block;

                        .pages {
                            display: none;
                        }

                        .limiter {
                            display: none;
                        }

                        .toolbar-sorter {
                            flex-direction: row;
                            justify-content: center;
                        }
                    }

                    div:nth-child(4) {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        margin-top: 20px;
                        @media screen and (min-width: $bp-medium) {
                            flex-direction: row;
                        }

                        .field {
                            display: flex;
                            align-items: center;
                        }

                        .toolbar-sorter {
                            display: none;
                        }
                    }
                }

                .sidebar {
                    @media screen and (min-width: $bp-medium) {
                        width: 20%;
                    }

                    .block {
                        .block-title {
                            strong {
                                @include SourceSansRegular;
                                font-size: 20px;
                                color: $p-black;
                                display: block;
                                text-align: center;
                                padding: 10px;
                                border: 1px solid $p-gray;
                            }
                        }

                        .block-content {
                            border: 1px solid $p-gray;
                            border-top: 0;
                            border-bottom: none;

                            .block-subtitle {
                                @include SourceSansRegular;
                                font-size: 20px;
                                color: $p-black;
                            }

                            .filter-current {
                                .items {
                                    display: none;
                                }

                                .filter-current-subtitle {
                                    border-top: 0;
                                    border-left: 0;
                                    border-right: 0;

                                    &:after {
                                        content: "+";
                                    }
                                }

                                + .filter-actions {
                                    display: none;
                                }

                                &.active {
                                    .items {
                                        display: flex;
                                    }

                                    .filter-current-subtitle {
                                        border-bottom: 0;

                                        &:after {
                                            content: "";
                                            border-top: 1px solid #4a4a4a;
                                            display: block;
                                            width: 12px;
                                        }
                                    }

                                    + .filter-actions {
                                        display: block;
                                        border-bottom: 1px solid $p-gray;
                                        padding-bottom: 15px;
                                        margin: 0;
                                    }
                                }
                            }

                            .filter-current {
                                .filter-current-subtitle {
                                    @include SourceSansRegular;
                                    font-size: 16px;
                                    color: $p-black;
                                    border-bottom: 1px solid $p-gray;
                                    margin: 0;
                                    padding: 10px;
                                    text-align: left;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                }

                                .items {
                                    padding: 0 10px;
                                    flex-wrap: wrap;

                                    .item {
                                        background: $p-active-filter;
                                        padding: 5px;

                                        .filter-container {
                                            display: flex;
                                            align-items: center;
                                        }

                                        .filter-label,
                                        .filter-value {
                                            @include SourceSansBold;
                                            font-size: 14px;
                                            font-stretch: normal;
                                            line-height: normal;
                                            letter-spacing: normal;
                                            color: $p-black;
                                            margin-right: 5px;
                                        }

                                        .filter-label {
                                            font-weight: normal;
                                        }

                                        .action {
                                            display: flex;
                                        }
                                    }
                                }
                            }

                            .filter-actions {
                                padding: 0 10px;

                                .filter-clear {
                                    @include btn-red;
                                    width: 100%;
                                    height: 30px !important;
                                    font-size: 16px !important;

                                    span {
                                        text-transform: uppercase;
                                    }

                                    &:hover {
                                        text-decoration: none;
                                        background: $p-black;
                                        border: 1px solid $p-black;
                                    }
                                }
                            }

                            .filter-options {
                                .filter-options-item {
                                    .filter-options-title {
                                        @include SourceSansRegular;
                                        font-size: 16px;
                                        color: $p-black;
                                        border-bottom: 1px solid $p-gray;
                                        margin: 0;
                                        padding: 10px;
                                        text-align: left;
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        border-bottom: 1px solid $p-gray;

                                        &:after {
                                            content: "";
                                            width: 16px;
                                            height: 16px;
                                            background: url("../images/catalog/more.svg")
                                                no-repeat;
                                            display: block;
                                        }
                                    }

                                    &.active {
                                        .filter-options-title {
                                            border-bottom: 0;
                                            border-bottom: none;

                                            &:after {
                                                content: "";
                                                background: url("../images/catalog/less.svg")
                                                    no-repeat;
                                                background-position: center;
                                                display: block;
                                                width: 16px;
                                            }
                                        }

                                        .filter-options-content {
                                            border-bottom: 1px solid $p-gray;
                                            padding-bottom: 20px;
                                            max-height: 200px;
                                            overflow: auto;
                                        }
                                    }
                                }

                                .filter-options-content {
                                    padding: 0 10px;
                                    margin: 0;

                                    .price-form {
                                        display: flex;
                                        justify-content: space-between;
                                        flex-wrap: wrap;
                                        align-items: flex-end;

                                        span {
                                            width: 10px;
                                            height: 2px;
                                            background: $p-gray;
                                            position: relative;
                                            top: 10px;
                                        }

                                        .input-form {
                                            width: 45%;

                                            label {
                                                display: block;
                                                text-align: left;
                                                font-size: 12px;
                                                @include SourceSansRegular;
                                                color: $p-black;
                                                @media screen and (min-width: $bp-large) {
                                                    font-size: 14px;
                                                }
                                            }

                                            input {
                                                border: 1px solid $p-black;
                                                @include input-styles;
                                                font-size: 12px !important;
                                                color: $p-black !important;
                                                @media screen and (min-width: $bp-large) {
                                                    font-size: 14px !important;
                                                }

                                                &::placeholder {
                                                    color: $p-black;
                                                }
                                            }
                                        }

                                        .action-button {
                                            margin-top: 10px;
                                            @include btn-red;
                                            width: 100%;
                                            height: 30px !important;
                                            font-size: 16px !important;
                                            text-transform: uppercase;
                                            border: 1px solid $p-black !important;
                                            color: $white !important;
                                            background-color: $p-black !important;

                                            &:hover {
                                                text-decoration: none;
                                                background: $white !important;
                                                border: 1px solid $p-black;
                                                color: $p-black !important;
                                            }
                                        }
                                    }

                                    .swatch-attribute {
                                        .swatch-attribute-options {
                                            a {
                                                @include SourceSansRegular;
                                                font-size: 14px;
                                                color: $p-black;
                                                display: flex;
                                                align-items: center;
                                                min-height: 18px;
                                                background: url("../images/catalog/unchecked.svg")
                                                    no-repeat;
                                                background-position: left center;
                                                background-color: transparent !important;

                                                .swatch-option {
                                                    @include SourceSansRegular;
                                                    font-size: 14px;
                                                    color: $p-black;
                                                    border: 0;
                                                    background: none;
                                                    padding: 0 0 0 24px;

                                                    &:hover {
                                                        outline: none;
                                                    }

                                                    &.text.selected {
                                                        background-color: transparent !important;
                                                    }

                                                    &.selected {
                                                        outline: none;
                                                    }
                                                }

                                                &:hover {
                                                    background: url("../images/catalog/checked.svg")
                                                        no-repeat;
                                                }

                                                &.mana-selected {
                                                    background: url("../images/catalog/checked.svg")
                                                        no-repeat;

                                                    &:hover {
                                                        background: url("../images/catalog/unchecked.svg")
                                                            no-repeat;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .items {
                                        .item {
                                            a {
                                                @include SourceSansRegular;
                                                font-size: 14px;
                                                color: $p-black;
                                                display: flex;
                                                align-items: center;
                                                min-height: 18px;
                                                background: url("../images/catalog/unchecked.svg")
                                                    no-repeat;
                                                background-position: left center;
                                                background-color: transparent !important;
                                                padding-left: 24px;

                                                span {
                                                    @include SourceSansRegular;
                                                    font-size: 14px;
                                                    color: $p-black;
                                                }

                                                &:hover {
                                                    background: url("../images/catalog/checked.svg")
                                                        no-repeat;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//Related & Crosssell products carousel

.block.related,
.block.crosssell,
.block.upsell {
    margin-bottom: 0;
    padding: 0 15px;

    .block-title {
        text-align: center;
        margin-bottom: 20px;

        strong {
            @include PlayfairDisplayBlack;
            font-size: 24px;
            color: $p-black;
        }
    }

    .block-content {
        .block-actions {
            display: none;
        }

        .wrapper {
            margin: 0 auto;
            width: 100%;

            .uk-slider-items {
                /*         display: flex;
                justify-content: center; */

                .item {
                    .product-item-info {
                        width: 100%;

                        .discount-flag {
                            width: 45px;
                            height: 45px;
                            margin: 10px;
                            border-radius: 3em;
                            overflow: hidden;
                            position: absolute;
                            z-index: 2;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: $hover;
                            transform: rotate(-25deg);
                            font-size: 18px;
                            color: $white;
                            @include PlayfairDisplayBold;
                            left: 25px;
                            @media screen and (min-width: $bp-medium) {
                                width: 60px;
                                height: 60px;
                            }

                            .discount {
                                margin-top: -5px;
                                @media screen and (min-width: $bp-medium) {
                                    margin-top: -6px;
                                    font-size: 24px;
                                }
                                @media screen and (min-width: $bp-large) {
                                    margin-top: -10px;
                                }
                            }

                            .percent {
                                font-size: 10.5px;
                                padding-top: 5px;
                                @media screen and (min-width: $bp-medium) {
                                    font-size: 14px;
                                }
                            }
                        }

                        .product-item-photo {
                            text-align: center;
                            display: block;
                        }

                        .product {
                            .product {
                                text-align: center;
                                font-size: 0;

                                .product-item-link {
                                    margin-top: 10px;
                                    font-size: 16px;
                                    @include SourceSansRegular;
                                    color: $p-black;
                                    height: 45px;
                                    overflow: hidden;
                                    display: block;
                                    text-align: center;
                                    @media screen and (min-width: $bp-medium) {
                                        margin-top: 30px;
                                        font-size: 20px;
                                        height: 55px;
                                    }
                                    @media screen and (min-width: $bp-large) {
                                        margin-top: 20px;
                                    }
                                }
                            }

                            .price-box {
                                text-align: center;
                                margin-bottom: 10px;
                                height: 64px;

                                .text-old-price {
                                    display: none;
                                }

                                .normal-price {
                                    .text-special-price {
                                        display: none;
                                    }
                                }

                                .old-price {
                                    .text-old-price {
                                        display: none;
                                    }

                                    .price-container {
                                        font-size: 18px;
                                        @media screen and (min-width: $bp-medium) {
                                            font-size: 24px;
                                        }

                                        .price-wrapper {
                                            .price {
                                                @include SourceSansRegular;
                                            }
                                        }
                                    }
                                }

                                .price-container {
                                    .price-wrapper {
                                        .price {
                                            @include SourceSansBold;
                                            font-size: 18px;
                                            color: $p-black;
                                            @media screen and (min-width: $bp-medium) {
                                                font-size: 24px;
                                            }
                                        }
                                    }
                                }

                                .special-price {
                                    .text-special-price {
                                        display: none;
                                    }
                                }
                            }

                            .field {
                                display: none;
                            }

                            .product-item-actions {
                                height: auto;
                                margin-bottom: 0;

                                .actions-primary {
                                    width: 100%;
                                    .stock.unavailable {
                                        height: 45px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        padding: 7px 10px;
                                        font-size: 16px;
                                        @include SourceSansRegular;
                                        border: 1px solid $p-red;
                                        @media screen and (min-width: $bp-medium) {
                                            font-size: 20px;
                                        }
                                        span {
                                            text-transform: uppercase;
                                            color: $p-red;
                                        }
                                    }

                                    .action {
                                        @include btn-red;
                                        border-radius: 0;
                                        padding: 0;
                                        width: 100%;
                                        height: 45px;
                                        font-size: 16px;
                                        padding: 7px 10px;
                                        @media screen and (min-width: $bp-medium) {
                                            font-size: 20px !important;
                                        }

                                        span {
                                            @include SourceSansRegular;
                                            text-transform: uppercase;
                                        }
                                    }
                                }

                                .secondary-addto-links {
                                    display: none;
                                }
                            }
                            .product-reviews-summary {
                                display: flex;
                                justify-content: center;
                                margin: 0;

                                .rating-summary {
                                    margin: 0;
                                    height: 30px;
                                    left: 0;

                                    .rating-result {
                                        width: 125px;
                                        margin: 0;

                                        &::before {
                                            content: "";
                                            width: 100%;
                                            background-image: url("../images/empty-stars-small.svg");
                                            background-repeat: no-repeat;
                                        }

                                        span {
                                            &::before {
                                                position: sticky;
                                                content: "";
                                                background-image: url("../images/filled-stars-small.svg");
                                                background-repeat: no-repeat;
                                            }
                                        }
                                    }
                                }

                                .reviews-actions {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
