.block-search {
    @media screen and (min-width: $bp-large) {
        position: absolute !important;
        top: 0;
        width: 350px !important;
        left: 0 !important;
    }

    .minisearch {
        .label {
            &.active {
                &:before {
                    display: none;
                }

                .icon-icon-close {
                    display: block;
                }

                .icon-icon-search {
                    display: none;
                }
            }

            &:before {
                display: none;
            }

            .icon-icon-close {
                display: none;
            }

            .icon-icon-search {
                display: block;
            }
        }

        .control {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border: none;
            width: 100%;
            position: absolute;
            top: 100%;
            left: -999999999px;
            right: 0;
            margin: 0 auto;
            background-color: rgba(0, 0, 0, 0.5);
            padding: 20px;
            @media screen and (min-width: $bp-large) {
                left: 0;
                background: none;
            }

            .input-container {
                width: 100%;
                height: 45px;
                @include input-styles;
                @media screen and (min-width: $bp-large) {
                    ::placeholder {
                        color: $white;
                    }
                }
            }

            input {
                height: 45px;
                background: $white;
                border: 0;
                left: 0;
                top: 0;
                margin: 0;
                z-index: 9;
                color: $p-black;
                @include SourceSansRegular;
                font-size: 16px;
                @media screen and (min-width: $bp-large) {
                    background: rgba(255, 255, 255, 0.15);
                    border: 1px solid $white;
                    color: $white;
                }

                &:focus {
                    color: $p-black;
                    @media screen and (min-width: $bp-large) {
                        border: 1px solid $white;
                        background: $white;
                        box-shadow: none;
                    }
                }
            }

            .actions {
                position: absolute;
                z-index: 2;
                right: 0;
                height: 45px;
                top: 0;

                .action {
                    border: 0;
                    background: none;
                    border-radius: 0;
                    padding: 0;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    opacity: 1;
                    &::before {
                        display: none;
                    }

                    .search-icon {
                        &:before {
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            font-size: 35px;
                            line-height: 33px;
                            color: $p-gray;
                            content: "\e615";
                            font-family: "icons-blank-theme";
                            vertical-align: middle;
                            display: inline-block;
                            font-weight: normal;
                            overflow: hidden;
                            speak: none;
                            text-align: center;
                        }
                    }
                    > span {
                        border: 0;
                        clip: initial;
                        height: auto;
                        margin: 0;
                        overflow: hidden;
                        padding: 0;
                        position: initial;
                        width: auto;
                        &.icon-icon-search-black {
                            display: none;
                        }
                    }
                }
            }
        }

        &.active {
            .control {
                left: 0;

                .actions {
                    .action {
                        display: block !important;
                        > span {
                            &.icon-icon-search {
                                display: none;
                            }
                            &.icon-icon-search-black {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
    .minisearch.active {
        .field {
            .control {
                .uk-position-relative {
                    .actions {
                        button {
                            .icon-icon-search-black {
                                display: block !important;
                                position: relative;
                            }
                            .icon-icon-search {
                                display: none !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
