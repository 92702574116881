.account {
    .page-wrapper {
        .page-main {
            padding: 20px;

            .page-title-wrapper {
                display: none;
            }
            .columns {
                .column {
                    padding: 20px 15px;
                    @media screen and (min-width: $bp-medium) {
                        padding: 0;
                    }
                    .block.block-dashboard-orders {
                        .block-content {
                            .table-wrapper {
                                .data {
                                    thead {
                                        tr {
                                            th {
                                                @include SourceSansBold;
                                                font-size: 18px;
                                                color: $p-black;
                                            }
                                        }
                                    }
                                    tbody {
                                        tr {
                                            td {
                                                @include SourceSansRegular;
                                                font-size: 16px;
                                                color: $p-black;
                                                &::before {
                                                    @include SourceSansBold;
                                                    font-size: 16px;
                                                }
                                            }
                                            .actions {
                                                .action {
                                                    span {
                                                        color: $p-red;
                                                        text-decoration: underline;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .block.block-order-details-comments {
                        margin-bottom: 20px;
                        .block-content {
                            .order-comments {
                                .comment-date,
                                .comment-content {
                                    @include SourceSansRegular;
                                    font-size: 16px;
                                    color: $p-black;
                                    line-height: normal;
                                }
                            }
                        }
                    }
                    .order-details-items.ordered {
                        .order-title {
                            display: flex;
                            flex-direction: column;
                            @media screen and (min-width: $bp-medium) {
                                flex-direction: row;
                                align-items: center;
                            }
                            strong {
                                @media screen and (min-width: $bp-medium) {
                                    margin: 0;
                                    padding-right: 10px;
                                }
                            }
                            .action.track {
                                span {
                                    font-size: 16px;
                                    color: $p-red;
                                    text-decoration: underline;
                                    @include SourceSansRegular;
                                }
                            }
                        }
                    }
                    .block-dashboard-addresses {
                        .block-title {
                            .action {
                                span {
                                    font-size: 16px;
                                    color: $p-red;
                                    text-decoration: underline;
                                    @include SourceSansRegular;
                                }
                            }
                        }
                    }
                    .block-reviews-dashboard {
                        .block-title {
                            .action {
                                span {
                                    font-size: 16px;
                                    color: $p-red;
                                    text-decoration: underline;
                                    @include SourceSansRegular;
                                }
                            }
                        }
                        .block-content {
                            .items {
                                .item {
                                    .product-name {
                                        a {
                                            @include SourceSansRegular;
                                            font-size: 18px;
                                            color: $p-black;
                                        }
                                    }
                                    .rating-summary {
                                        .label {
                                            span {
                                                @include SourceSansRegular;
                                                font-size: 16px;
                                                color: $p-black;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .block-addresses-list {
                        @media screen and (min-width: $bp-medium) {
                            overflow: auto;
                        }
                        .block-content {
                            .table-wrapper {
                                .data {
                                    thead {
                                        tr {
                                            th {
                                                @media screen and (min-width: $bp-medium) {
                                                    font-size: 16px;
                                                    color: $p-black;
                                                    @include SourceSansBold;
                                                }
                                            }
                                        }
                                    }
                                    tbody {
                                        tr {
                                            td {
                                                font-size: 16px;
                                                color: $p-black;
                                                @include SourceSansRegular;
                                                &::before {
                                                    @include SourceSansBold;
                                                }
                                            }
                                            .actions {
                                                .action {
                                                    span {
                                                        font-size: 16px;
                                                        color: $p-red;
                                                        text-decoration: underline;
                                                        @include SourceSansRegular;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .empty {
                                @include SourceSansRegular;
                                font-size: 16px;
                                color: $p-black;
                            }
                        }
                    }

                    .form {
                        max-width: 1000px;

                        .fieldset {
                            margin-bottom: 20px;
                        }

                        .legend {
                            margin-left: 0;
                            display: block;
                            width: 100%;
                            margin-bottom: 15px !important;
                        }

                        .upload-required {
                            @include SourceSansRegular;
                            font-size: 16px;
                            color: $p-black;
                        }

                        .field {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            @media screen and (min-width: $bp-medium) {
                                width: 30%;
                            }

                            .label {
                                width: fit-content;
                                @include SourceSansRegular;
                                font-size: 16px;
                                font-stretch: normal;
                                line-height: normal;
                                letter-spacing: normal;
                                color: $p-black;
                            }

                            .control {
                                width: 100%;

                                input {
                                    @include input-styles;
                                    height: 45px;
                                    border: solid 1px $p-gray;
                                }
                            }

                            &.field-name-firstname,
                            &.field-name-lastname,
                            &.field.email,
                            &.field.field-custom-rfc,
                            &.field.field-custom-company_name,
                            &.field.password {
                                display: inline-flex;
                                width: 100%;
                                margin-bottom: 0;
                                @media screen and (min-width: $bp-medium) {
                                    width: 45%;
                                    margin-right: 20px;
                                }
                            }

                            &.choice {
                                flex-direction: inherit;
                                align-items: center;
                                margin-bottom: 10px;
                                @media screen and (min-width: $bp-medium) {
                                    width: 45%;
                                }

                                &:before {
                                    padding: 0;
                                    width: auto;
                                }
                                [type="checkbox"]:not(:checked),
                                [type="checkbox"]:checked {
                                    left: -9999px;
                                    position: absolute;
                                    top: +20px;
                                    visibility: hidden;
                                }

                                [type="checkbox"]:not(:checked) + label,
                                [type="checkbox"]:checked + label {
                                    position: relative;
                                    padding-left: 1.4em;
                                    cursor: pointer;
                                }

                                /* checkbox aspect */
                                [type="checkbox"]:not(:checked) + label:before,
                                [type="checkbox"]:checked + label:before {
                                    content: "";
                                    position: absolute;
                                    left: 0px;
                                    top: 3px;
                                    width: 12px;
                                    height: 12px;
                                    border: 2px solid $p-gray;
                                    background: rgba(255, 255, 255, 0.15);
                                }

                                [type="checkbox"]:checked + label:before {
                                    background: $p-gray;
                                }

                                /* checked mark aspect */
                                [type="checkbox"]:not(:checked) + label:after,
                                [type="checkbox"]:checked + label:after {
                                    content: "";
                                    left: 3px;
                                    top: 5px;
                                    border: 2px solid $white;
                                    background: transparent;
                                    opacity: 0;
                                    transform: rotate(-45deg);
                                    position: absolute;
                                    width: 9px;
                                    height: 5px;
                                    font-size: 1.4em;
                                    line-height: 0.8;
                                    transition: all 0.2s;
                                }

                                /* checked mark aspect changes */
                                [type="checkbox"]:not(:checked) + label:after {
                                    opacity: 0;
                                    transform: scale(0);
                                    transform: rotate(-45deg);
                                }

                                [type="checkbox"]:checked + label:after {
                                    opacity: 1;
                                    border-top: none;
                                    border-right: none;
                                }
                            }
                        }
                    }

                    .documents-container {
                        max-width: 930px;
                    }

                    .js-upload {
                        flex-direction: column;
                        border: 2px dashed $p-gray;
                        @media screen and (min-width: $bp-large) {
                            flex-direction: initial;
                        }

                        img {
                            width: 60px;
                            height: 60px;
                            object-fit: contain;
                        }

                        .info-container {
                            display: flex;
                            flex-direction: column;
                            @media screen and (min-width: $bp-large) {
                                display: block;
                            }
                            .uk-form-custom {
                                float: left;
                            }
                            .example-document {
                                position: relative;
                                &:hover {
                                    .tooltip {
                                        display: block;
                                        position: absolute;
                                        top: -130px;
                                        left: 0;
                                        background: white;
                                        padding: 20px;
                                        box-shadow: 5px 5px 10px 0
                                            rgba(0, 0, 0, 0.75);
                                        border: solid 1px #707070;
                                        border-radius: 10px;
                                        z-index: 1;
                                        min-width: 140px;
                                        min-height: 140px;
                                        display: flex;
                                        align-items: center;
                                        @media screen and (min-width: 424px) {
                                            top: -175px;
                                        }
                                        @media screen and (min-width: $bp-medium) {
                                            top: -190px;
                                            right: 0;
                                            left: auto;
                                            max-width: 280px;
                                            max-height: 335px;
                                        }
                                        @media screen and (min-width: $bp-large) {
                                            top: -110px;
                                            left: 120px;
                                            min-width: 240px;
                                            min-height: 280px;
                                            max-width: 100%;
                                            max-height: 100%;
                                        }
                                        img {
                                            width: 100%;
                                            height: 100%;
                                        }
                                        &::after,
                                        &::before {
                                            right: 100%;
                                            top: 50%;
                                            border: solid transparent;
                                            content: "";
                                            height: 0;
                                            width: 0;
                                            position: absolute;
                                            pointer-events: none;
                                        }
                                        &::after {
                                            border-right-color: $white;
                                            border-width: 30px;
                                            margin-top: -30px;
                                        }
                                        &::before {
                                            border-right-color: #707070;
                                            border-width: 32px;
                                            margin-top: -32px;
                                        }
                                    }
                                }
                                .tooltip {
                                    display: none;
                                }
                                .example-link {
                                    @include SourceSansRegular;
                                    font-size: 16px;
                                    font-stretch: normal;
                                    line-height: normal;
                                    letter-spacing: normal;
                                    color: $p-red;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }

                    .actions-toolbar {
                        max-width: 920px;
                        margin-left: 0;
                        .primary {
                            .action {
                                @include btn-red;
                            }
                        }
                    }

                    .block {
                        .block-title {
                            strong,
                            a {
                                @include PlayfairDisplayBlack;
                                font-size: 24px;
                                color: $p-black;
                            }
                        }

                        .block-content {
                            .box {
                                .box-title {
                                    span {
                                        @include SourceSansBold;
                                        font-size: 16px;
                                        color: $p-black;
                                    }
                                }

                                .box-content {
                                    p,
                                    address {
                                        @include SourceSansRegular;
                                        font-size: 16px;
                                        color: $p-black;
                                    }
                                }

                                .box-actions {
                                    a {
                                        @include SourceSansRegular;
                                        font-size: 16px;
                                        color: $p-red;
                                        text-decoration: underline;
                                    }

                                    .change-password {
                                        margin-left: 10px;
                                    }
                                }
                            }
                        }

                        .alert-container {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 100%;
                            padding: 20px;
                            background-color: rgba(147, 0, 42, 0.2);
                            border: 1px solid $p-red;
                            @media screen and (min-width: $bp-medium) {
                                height: 60px;
                                padding: 0 20px;
                            }
                            @media screen and (min-width: $bp-large) {
                                padding: 0;
                            }

                            .img {
                                width: 38px;
                                margin-right: 20px;
                                margin-right: 20px;
                            }

                            .text {
                                @include SourceSansRegular;
                                font-size: 16px;
                                color: $p-black;

                                strong {
                                    @include SourceSansBold;
                                    font-size: 16px;
                                    color: $p-black;
                                }

                                a {
                                    color: $p-red;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }

                    .message {
                        color: $p-black;
                        background-color: rgba(147, 0, 42, 0.2);
                        text-align: center;
                        border: 1px solid $p-red;
                        @media screen and (min-width: $bp-medium) {
                            padding: 20px;
                        }
                        @media screen and (min-width: $bp-large) {
                            width: 50%;
                            margin: 10px auto 10px 15%;
                        }

                        span {
                            @include SourceSansRegular;

                            &::before {
                                position: relative;
                                top: 5px;
                            }
                        }
                    }

                    .actions-toolbar {
                        .secondary {
                            span {
                                font-size: 16px;
                                color: $p-red;
                                text-decoration: underline;
                            }
                        }
                    }
                }

                .form-address-edit,
                .form {
                    .fieldset {
                        .legend {
                            span {
                                @include PlayfairDisplayBlack;
                                font-size: 24px;
                                color: $p-black;
                            }
                        }

                        .field {
                            .label {
                                span {
                                    @include SourceSansBold;
                                    font-size: 16px;
                                    color: $p-black;
                                }
                            }
                        }
                    }

                    .actions-toolbar {
                        .primary {
                            .action {
                                @include btn-red;
                                padding: 7px 15px;
                            }
                        }

                        .secondary {
                            .action {
                                span {
                                    font-size: 16px;
                                    color: $p-red;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }

                    .password-info {
                        p {
                            @include SourceSansRegular;
                            font-size: 16px;
                            color: $p-black;

                            a {
                                font-size: 16px;
                                color: $p-red;
                                text-decoration: underline;
                            }
                        }
                    }
                }

                .toolbar {
                    .pager {
                        .toolbar-amount {
                            margin-bottom: 10px;
                            padding-top: 5px;
                            @media screen and (min-width: $bp-large) {
                                padding-top: 7px;
                            }

                            .toolbar-number {
                                @include SourceSansRegular;
                                font-size: 16px;
                                color: $p-black;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: normal;
                                letter-spacing: normal;
                            }
                        }

                        .limiter {
                            .limiter-label,
                            .limiter-text {
                                @include SourceSansRegular;
                                font-size: 16px;
                                color: $p-black;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: normal;
                                letter-spacing: normal;
                            }

                            .limiter-options {
                                border: 1px solid $p-gray;
                            }
                        }
                    }
                }

                .form-wishlist-items {
                    .products-grid {
                        .product-items {
                            .product-item {
                                .product-item-info {
                                    .product-item-name {
                                        text-align: center;

                                        .product-item-link {
                                            margin-top: 10px;
                                            font-size: 16px;
                                            @include SourceSansRegular;
                                            color: $p-black;
                                            @media screen and (min-width: $bp-medium) {
                                                margin-top: 35px;
                                                font-size: 20px;
                                                height: 50px;
                                            }
                                            @media screen and (min-width: $bp-large) {
                                                margin-top: 20px;
                                            }
                                        }
                                    }

                                    .price-box {
                                        height: 45px;
                                        line-height: 20px;
                                        text-align: center;
                                        margin-bottom: 10px;
                                        @media screen and (min-width: $bp-medium) {
                                            line-height: 26px;
                                            height: 55px;
                                        }

                                        .price-as-configured {
                                            .price-container {
                                                .price-wrapper {
                                                    .price {
                                                        @include SourceSansBold;
                                                        font-size: 18px;
                                                        color: $p-black;
                                                        @media screen and (min-width: $bp-medium) {
                                                            font-size: 24px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .product-item-inner {
                                        text-align: center;

                                        .field {
                                            .control {
                                                .product-item-comment {
                                                    border: 1px solid $p-gray;
                                                    @include SourceSansRegular;
                                                    font-size: 16px;
                                                }
                                            }
                                        }

                                        .box-tocart {
                                            display: flex;
                                            justify-content: center;
                                            float: none;
                                            margin: 0;

                                            .fieldset {
                                                .field {
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;

                                                    .label {
                                                        margin-right: 5px;
                                                        @media screen and (min-width: $bp-medium) {
                                                            margin-right: 0;
                                                        }

                                                        span {
                                                            @include SourceSansBold;
                                                            font-size: 16px;
                                                            color: $p-black;
                                                        }
                                                    }

                                                    .control {
                                                        width: auto;

                                                        .input-text {
                                                            border: 1px solid
                                                                $p-gray;
                                                        }
                                                    }
                                                }

                                                .product-item-actions {
                                                    .actions-primary {
                                                        .action {
                                                            @include btn-red;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .product-item-actions {
                                            display: flex;
                                            justify-content: center;
                                            float: none;

                                            a {
                                                span {
                                                    font-size: 16px;
                                                    color: $p-red;
                                                    text-decoration: underline;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .actions-toolbar {
                        .primary {
                            display: flex;
                            flex-wrap: wrap;

                            button {
                                @include btn-red;
                            }
                        }

                        .secondary {
                            a {
                                span {
                                    font-size: 16px;
                                    color: $p-red;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }

                .sidebar {
                    .block {
                        &.block-collapsible-nav {
                            position: initial !important;
                            .block-collapsible-nav-title {
                                display: none;
                            }
                            .block-collapsible-nav-content {
                                display: block;
                            }
                        }
                        .content {
                            padding-top: 0;
                            background-color: $white;
                            border: 1px solid $p-gray;
                            padding-bottom: 0;

                            .nav {
                                .nav {
                                    a {
                                        @include SourceSansRegular;
                                        font-size: 16px;
                                        color: $p-black;
                                        text-transform: uppercase;
                                        padding: 10px 10px 10px 20px;
                                        border-left: none;

                                        &:hover {
                                            background-color: $p-red;
                                            color: $white;
                                            strong {
                                                color: $white;
                                            }
                                        }
                                        strong {
                                            padding-left: 0;
                                        }
                                    }

                                    strong {
                                        @include SourceSansRegular;
                                        font-size: 16px;
                                        color: $white;
                                        text-transform: uppercase;
                                        padding: 10px 10px 10px 20px;
                                    }

                                    .delimiter {
                                        display: none;
                                    }
                                }

                                .current {
                                    strong {
                                        border-color: inherit;
                                        border-left: none;
                                        @include SourceSansBold;
                                        color: $p-red;
                                    }
                                }
                            }
                        }
                    }
                }
                .sidebar-additional {
                    .block {
                        .block-title {
                            strong {
                                @include PlayfairDisplayBlack;
                                font-size: 24px;
                                color: $p-black;
                                @media screen and (min-width: $bp-medium) {
                                    font-size: 20px;
                                }
                                @media screen and (min-width: $bp-large) {
                                    font-size: 24px;
                                }
                            }
                        }
                        .block-content {
                            .form {
                                #cart-sidebar-reorder {
                                    .product-item {
                                        .product-item-name {
                                            .product-item-link {
                                                span {
                                                    @include SourceSansRegular;
                                                    font-size: 16px;
                                                    color: $p-black;
                                                }
                                            }
                                        }
                                    }
                                }
                                .actions-toolbar {
                                    margin: 15px auto;
                                    @media screen and (min-width: $bp-medium) {
                                        display: flex;
                                        justify-content: center;
                                        flex-direction: column;
                                        align-items: center;
                                    }
                                    .primary {
                                        .action {
                                            margin: 0;
                                            span {
                                                @media screen and (min-width: $bp-medium) {
                                                    font-size: 16px;
                                                }
                                                @media screen and (min-width: $bp-large) {
                                                    font-size: 20x;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.documents-container-modal {
    max-width: 680px;
    left: 0 !important;
    right: 0;
    width: 90%;
    margin: 20px auto;
    @media screen and (min-width: $bp-medium) {
        margin: 0 auto;
        width: 100%;
    }

    .action-close {
        &:before {
            background: url(../images/icons/icon-cerrar.svg);
            content: "";
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .modal-inner-wrap {
        width: 100%;
        border-radius: 5px;

        .modal-header {
            padding-top: 0;
        }

        .documents-modal {
            padding: 0;
            @media screen and (min-width: $bp-medium) {
                padding: 30px 30px;
            }

            > .uk-flex {
                flex-direction: column;
                @media screen and (min-width: $bp-medium) {
                    flex-direction: initial;
                }
            }
            .uk-flex {
                @media screen and (min-width: $bp-medium) {
                    display: grid;
                    grid-template-columns: auto auto;
                    grid-template-rows: auto auto;
                    grid-gap: 0 10px;
                }
                .title {
                    @include PlayfairDisplayBlack;
                    font-size: 24px;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: $p-black;
                    @media screen and (min-width: $bp-medium) {
                        grid-area: 1 / 1 / 2 / 3;
                    }
                }
                .container {
                    @media screen and (min-width: $bp-medium) {
                        grid-area: 2 / 1 / 3 / 2;
                    }
                }
                .text {
                    @media screen and (min-width: $bp-medium) {
                        grid-area: 2 / 2 / 3 / 3;
                    }
                }
            }

            .content {
                .info,
                li {
                    @include SourceSansRegular;
                    font-size: 16px;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: $p-black;
                }

                li {
                    margin-bottom: 3px;
                    list-style: disc;
                    text-align: left;
                }
            }

            .container {
                width: 100%;
                max-width: 100%;
                text-align: center;
                margin-bottom: 20px;
                @media screen and (min-width: $bp-medium) {
                    max-width: 120px;
                    margin-top: 60px;
                    margin-bottom: 0;
                }
            }

            .text {
                width: 100%;
            }

            .uk-button-secondary {
                @include btn-red;
                width: 100%;
                background: $p-black;
                border: 1px solid $p-black;
                color: $white;
                font-size: 15px;
                @media screen and (min-width: $bp-medium) {
                    width: 70%;
                    font-size: 20px;
                }

                &:hover {
                    background: $p-black;
                    color: $white;
                }
            }
        }
    }
}

.wishlist-index-index {
    .page-wrapper {
        .page-main {
            .columns {
                .column {
                    .page-title-wrapper {
                        display: block;
                        .block-title {
                            .page-title {
                                margin-top: 0;
                                margin-bottom: 15px;
                                .base {
                                    @include PlayfairDisplayBlack;
                                    font-size: 24px;
                                    color: $p-black;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
