.quotation-index-index {
    .page-wrapper {
        .page-main {
            .columns {
                .column {
                    div {
                        .title {
                            h1 {
                                @include PlayfairDisplayBlack;
                                font-size: 24px;
                                color: $p-black;
                                margin-bottom: 20px;
                            }
                        }
                        .data {
                            thead {
                                @media screen and (min-width: $bp-medium) {
                                    border-top: 1px solid #979797;
                                }
                                tr {
                                    th {
                                        @include SourceSansBold;
                                        font-size: 16px;
                                        color: $p-black;
                                        border-bottom: 1px solid #979797;
                                    }
                                }
                            }
                            tbody {
                                tr {
                                    border-bottom: 1px solid #979797;
                                    td {
                                        display: block;
                                        color: $p-black;
                                        @include SourceSansRegular;
                                        font-size: 16px;
                                        @media screen and (min-width: $bp-medium) {
                                            display: table-cell;
                                        }
                                        &::before {
                                            padding-right: 10px;
                                            content: attr(data-th) ": ";
                                            display: inline-block;
                                            color: $p-black;
                                            @include SourceSansBold;
                                            font-size: 16px;
                                            @media screen and (min-width: $bp-medium) {
                                                display: none;
                                            }
                                        }
                                    }
                                    .actions {
                                        a {
                                            color: $p-red;
                                            @include SourceSansRegular;
                                            font-size: 16px;
                                            text-decoration: underline;
                                        }
                                        .action.delete {
                                            &::before,
                                            &::after {
                                                content: "|";
                                                color: $p-black;
                                                margin: 0 5px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    div
                        .table:not(.cart):not(.totals):not(.table-comparison)
                        > thead
                        > tr
                        > th {
                        @media screen and (max-width: $bp-small) {
                            display: none;
                        }
                    }
                    div
                        .table:not(.cart):not(.totals):not(.table-comparison)
                        > tbody {
                        @media screen and (max-width: $bp-small) {
                            display: block;
                        }
                    }
                    div
                        .table:not(.cart):not(.totals):not(.table-comparison)
                        > tbody
                        > tr {
                        @media screen and (max-width: $bp-small) {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
