.quotation-index-detail {
    .page-wrapper {
        .page-main {
            padding: 0 15px;
            .page.messages {
                .messages {
                    .message {
                        padding: 20px;
                        display: flex;
                        justify-content: center;
                        border: solid 1px #f5a623;
                        background-color: rgba(245, 166, 35, 0.2);
                        div {
                            @include SourceSansRegular;
                            font-size: 16px;
                            color: $p-black;
                            display: flex;
                            align-items: center;
                            &::before {
                                position: relative;
                                text-align: center;
                                width: 70px;
                                height: 35px;
                                margin-right: 0;
                                background-image: url("../images/customer/dashboard/alert.svg");
                                background-size: 38px 35px;
                                content: "";
                                background-repeat: no-repeat;
                                top: 5px;
                                @media screen and (min-width: $bp-medium) {
                                    width: 38px;
                                    margin-right: 30px;
                                }
                            }
                        }
                    }
                }
            }
            .columns {
                @media screen and (min-width: $bp-medium) {
                    padding-top: 25px;
                }
                .column {
                    display: block;
                    margin: auto;
                    .page-title-wrapper {
                        display: block;
                        .block-title {
                            .page-title {
                                text-align: left;
                                margin-top: 20px;
                                @media screen and (min-width: $bp-medium) {
                                    margin-top: 0;
                                    margin-bottom: 20px;
                                    display: flex;
                                }
                                span {
                                    @include PlayfairDisplayBlack;
                                    font-size: 24px;
                                    color: $p-black;
                                }
                            }
                        }
                    }
                    .table-quotation {
                        border-collapse: collapse;
                        thead {
                            @media screen and (max-width: 767px) {
                                display: none;
                            }
                            tr {
                                border: none;
                                border-bottom: 1px solid $p-red;
                                th {
                                    @include SourceSansBold;
                                    font-size: 20px;
                                    color: $p-black;
                                    border: none;
                                    text-align: center;
                                }
                                th:nth-child(1),
                                th:nth-child(2),
                                th:nth-child(4),
                                th:nth-child(5) {
                                    width: 2%;
                                }
                                th:nth-child(3) {
                                    text-align: left;
                                    width: 30%;
                                }
                            }
                        }
                        tbody {
                            @media screen and (max-width: 767px) {
                                display: flex;
                                flex-direction: column;
                            }
                            .details-container {
                                border: none;
                                border-bottom: 1px solid $p-red;
                                @media screen and (max-width: 767px) {
                                    display: grid;
                                    grid-template-columns: 1fr 1fr;
                                    grid-template-rows: 1fr 1fr 1fr 1fr;
                                    gap: 0px 0px;
                                    padding: 20px 0;
                                }
                                .img {
                                    @media screen and (max-width: 767px) {
                                        grid-area: 1 / 1 / 5 / 2;
                                        justify-content: center;
                                        align-items: center;
                                        display: flex;
                                        width: auto;
                                    }
                                }
                                .qty {
                                    @media screen and (max-width: 767px) {
                                        grid-area: 1 / 2 / 2 / 3;
                                    }
                                }
                                .product-name {
                                    @media screen and (max-width: 767px) {
                                        grid-area: 2 / 2 / 3 / 3;
                                    }
                                }
                                .price {
                                    @media screen and (max-width: 767px) {
                                        grid-area: 3 / 2 / 4 / 3;
                                    }
                                }
                                .subtotal {
                                    @media screen and (max-width: 767px) {
                                        grid-area: 4 / 2 / 5 / 3;
                                    }
                                }
                                .qty,
                                .product-name,
                                .price,
                                .subtotal {
                                    @media screen and (max-width: 767px) {
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: center;
                                        flex-wrap: wrap;
                                        padding: 0;
                                        &::before {
                                            padding-right: 10px;
                                            content: attr(data-th) ": ";
                                            display: inline-block;
                                            color: $p-black;
                                            @include SourceSansBold;
                                            font-size: 16px;
                                        }
                                    }
                                }
                                td {
                                    @include SourceSansRegular;
                                    font-size: 16px;
                                    color: $p-black;
                                    text-align: center !important;
                                    border: none !important;
                                    @media screen and (min-width: $bp-medium) {
                                        font-size: 18px;
                                    }
                                    img {
                                        height: 80px;
                                        width: 80px;
                                        min-width: 80px;
                                    }
                                    a {
                                        @include SourceSansRegular;
                                        font-size: 16px;
                                        color: $p-black;
                                        @media screen and (min-width: $bp-medium) {
                                            font-size: 18px;
                                        }
                                    }
                                }
                                td:nth-child(1) {
                                    text-align: left !important;
                                }
                                td:nth-child(3) {
                                    text-align: left !important;
                                }
                                td:nth-child(4),
                                td:nth-child(5) {
                                    @include SourceSansBold;
                                }
                                td:nth-child(4) {
                                    color: $p-red;
                                }
                            }
                        }
                        tfoot {
                            .borderless {
                                .space {
                                    @media screen and (max-width: 767px) {
                                        display: none;
                                    }
                                }
                                .remove-padding {
                                    .remove-padding {
                                        @media screen and (max-width: 767px) {
                                            padding: 10px;
                                        }
                                        @media screen and (min-width: $bp-medium) {
                                            border-collapse: collapse;
                                        }
                                        tbody {
                                            tr {
                                                @media screen and (max-width: 767px) {
                                                    justify-content: space-between;
                                                    width: 100%;
                                                    display: flex;
                                                    min-width: 100%;
                                                    border: none;
                                                }
                                                @media screen and (min-width: $bp-medium) {
                                                    justify-content: center;
                                                    border: none;
                                                    border-bottom: 1px solid
                                                        $p-red;
                                                }
                                                td {
                                                    @include SourceSansBold;
                                                    font-size: 16px;
                                                    color: $p-black;
                                                    padding: 20px 0;
                                                    border: none !important;
                                                    justify-content: center;
                                                    @media screen and (max-width: 767px) {
                                                        width: 100%;
                                                        padding: 10px 0 0;
                                                        justify-content: flex-end;
                                                    }
                                                    span {
                                                        margin: 0 !important;
                                                    }
                                                }
                                                td:nth-child(1) {
                                                    @media screen and (max-width: 767px) {
                                                        text-align: left !important;
                                                    }
                                                }
                                                .td-space {
                                                    @media screen and (min-width: $bp-large) {
                                                        padding-left: 25px;
                                                    }
                                                    @media screen and (min-width: 1920px) {
                                                        padding-left: 35px;
                                                    }
                                                }
                                                .space-td {
                                                    @media screen and (min-width: $bp-large) {
                                                        padding-left: 5px;
                                                        justify-content: end;
                                                    }
                                                    @media screen and (min-width: 1920px) {
                                                        padding-left: 25px;
                                                    }
                                                }
                                            }
                                            tr:nth-child(4) {
                                                td {
                                                    justify-content: center;
                                                    @media screen and (max-width: 767px) {
                                                        justify-content: flex-end;
                                                    }
                                                    color: $p-red;
                                                    span {
                                                        color: $p-red;
                                                    }
                                                }
                                                .space-td {
                                                    @media screen and (min-width: $bp-large) {
                                                        justify-content: flex-start;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .back-btn-quote {
                        left: 0;
                        @include SourceSansRegular;
                        font-size: 20px;
                        color: $white;
                        display: flex;
                        border: 1px solid $p-black;
                        border-radius: 0;
                        padding: 10px 20px;
                        width: 100px;
                        justify-content: center;
                        margin-left: auto;
                        text-decoration: none;
                        background-color: $p-black;
                        &:hover {
                            background-color: $white;
                            color: $p-black;
                        }
                    }
                }
            }
        }
    }
}
