.wishlist-index-index {
  .page-wrapper {
    .page-main {
      .columns {
        .column {
          .form-wishlist-items {
            .products-grid {
              .product-items {
                .product-item {
                  .product-reviews-summary {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    margin: 0;
                    .rating-summary {
                      margin: 0;
                      height: 30px;
                      left: 0;
                      .rating-result {
                        width: 125px;
                        margin: 0;
                        &::before {
                          content: '';
                          width: 100%;
                          background-image: url('../images/empty-stars-small.svg');
                          background-repeat: no-repeat; 
                        }
      
                        span {
                          &::before {
                            position: sticky;
                            content: '';
                            background-image: url('../images/filled-stars-small.svg');
                            background-repeat: no-repeat;
                          }
                        }
                      }
                    }
                    .reviews-actions {
                      display: flex;
                      align-items: center;
                      margin-bottom: 15px;
      
                      .action {
                        @include SourceSansRegular;
                        font-size: 16px;
                        color: $p-red;
                        text-decoration: underline;
                        margin-right: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}