.customer-address-form {
    .page-main {
        .form-address-edit {
            .fieldset {
                .field {
                    .control {
                        @media screen and (min-width: $bp-large) {
                            width: 30%;
                        }
                    }
                }
                .billing,
                .shipping {
                    .label {
                        display: block;
                        span {
                            @include SourceSansRegular;
                            font-size: 16px;
                            color: $white;
                            @media screen and (min-width: $bp-large) {
                                color: $p-black;
                            }
                        }
                    }

                    [type="checkbox"]:not(:checked),
                    [type="checkbox"]:checked {
                        left: -9999px;
                        position: absolute;
                        top: +20px;
                        visibility: hidden;
                    }

                    [type="checkbox"]:not(:checked) + label,
                    [type="checkbox"]:checked + label {
                        position: relative;
                        padding-left: 1.2em;
                        cursor: pointer;
                    }

                    /* checkbox aspect */
                    [type="checkbox"]:not(:checked) + label:before,
                    [type="checkbox"]:checked + label:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 10px;
                        width: 12px;
                        height: 12px;
                        border: 2px solid $p-gray;
                        background: rgba(255, 255, 255, 0.15);
                        @media screen and (min-width: 424px) {
                            left: 0;
                        }
                        @media screen and (min-width: $bp-medium) {
                            left: 20%;
                        }
                        @media screen and (min-width: $bp-large) {
                            border: 2px solid $p-gray;
                            top: 10px;
                        }
                        @media screen and (min-width: $bp-xlarge) {
                            left: 23%;
                        }
                    }

                    [type="checkbox"]:checked + label:before {
                        background: $p-red;
                    }

                    /* checked mark aspect */
                    [type="checkbox"]:not(:checked) + label:after,
                    [type="checkbox"]:checked + label:after {
                        content: "";
                        left: 3px;
                        top: 13px;
                        border: 2px solid $white;
                        background: transparent;
                        opacity: 0;
                        transform: rotate(-45deg);
                        position: absolute;
                        width: 8px;
                        height: 4px;
                        font-size: 1.4em;
                        line-height: 0.8;
                        transition: all 0.2s;
                        @media screen and (min-width: 424px) {
                            left: 3px;
                            top: 13px;
                        }
                        @media screen and (min-width: $bp-medium) {
                            top: 14px;
                            height: 3px;
                            left: 116px;
                        }
                        @media screen and (min-width: $bp-large) {
                            border: 2px solid $white;
                        }
                        @media screen and (min-width: $bp-xlarge) {
                            left: 23.4%;
                        }
                        @media screen and (min-width: $bp-xxlarge) {
                            left: 23.3%;
                        }
                        @media screen and (min-width: 1920px) {
                            left: 23.2%;
                        }
                    }

                    /* checked mark aspect changes */
                    [type="checkbox"]:not(:checked) + label:after {
                        opacity: 0;
                        transform: scale(0);
                        transform: rotate(-45deg);
                    }

                    [type="checkbox"]:checked + label:after {
                        opacity: 1;
                        border-top: none;
                        border-right: none;
                    }
                }
            }
        }
    }
}
