.quotation-index-resume {
    .page-wrapper {
        .page-main {
            .page-title-wrapper {
                .block-title {
                    text-align: center;
                    margin: 20px 0;
                    @media screen and (min-width: $bp-medium) {
                        margin: 20px 0 0;
                    }
                    .page-title {
                        @include PlayfairDisplayBlack;
                        font-size: 24px;
                        color: $p-black;
                        margin: 0;
                        @media screen and (min-width: $bp-large) {
                            font-size: 30px;
                        }
                    }
                }
            }
            .columns {
                .column {
                    width: 100%;
                    padding: 0 10px;
                    .cart-container {
                        margin-bottom: 30px;
                        @media screen and (min-width: $bp-medium) {
                            display: flex;
                            flex-direction: row-reverse;
                        }
                        .cart-summary {
                            text-align: center;
                            margin-bottom: 20px;
                            padding-bottom: 10px;
                            background-color: #f2f2f2;
                            @media screen and (min-width: $bp-medium) {
                                width: auto;
                                top: 0 !important;
                            }
                            @media screen and (min-width: $bp-large) {
                                width: 25%;
                                height: 100%;
                                padding-bottom: 20px;
                                margin-left: 40px;
                            }
                            .summary {
                                @include PlayfairDisplayBlack;
                                font-size: 24px;
                                color: $p-black;
                                @media screen and (min-width: $bp-medium) {
                                    margin-top: 20px;
                                }
                            }
                            #block-shipping {
                                display: none;
                            }
                            .cart-totals {
                                border-top: none;
                                .table-wrapper {
                                    .data {
                                        tbody {
                                            .totals {
                                                .mark {
                                                    @include SourceSansRegular;
                                                    font-size: 18px;
                                                    color: $p-gray;
                                                    line-height: 1.44;
                                                    padding: 0;
                                                }
                                                .amount {
                                                    padding: 0;
                                                    .price {
                                                        @include SourceSansBold;
                                                        font-size: 18px;
                                                        color: $p-gray;
                                                        line-height: 1.44;
                                                    }
                                                }
                                            }
                                            .grand {
                                                border-top: 1px solid $p-red;
                                                .mark {
                                                    font-size: 24px;
                                                    padding-top: 20px;
                                                    @media screen and (min-width: $bp-medium) {
                                                        font-size: 18px;
                                                    }
                                                    @media screen and (min-width: $bp-large) {
                                                        font-size: 24px;
                                                    }
                                                }
                                                .amount {
                                                    padding-top: 20px;
                                                    @media screen and (min-width: $bp-medium) {
                                                        padding-bottom: 0;
                                                    }
                                                    .price {
                                                        font-size: 24px;
                                                        @media screen and (min-width: $bp-medium) {
                                                            font-size: 20px;
                                                        }
                                                        @media screen and (min-width: $bp-large) {
                                                            font-size: 24px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .checkout {
                                margin: 20px 0;
                                .item {
                                    .quotation {
                                        &:hover {
                                            text-decoration: none;
                                        }
                                        .action {
                                            height: 45px;
                                            background-color: $p-red;
                                            border-radius: 0;
                                            border: none;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            line-height: 1px;
                                            width: 100%;
                                            @media screen and (min-width: $bp-medium) {
                                                height: 100%;
                                                line-height: 16px;
                                            }
                                            @media screen and (min-width: $bp-large) {
                                                height: 45px;
                                            }
                                            span {
                                                @include SourceSansRegular;
                                                font-size: 20px;
                                                color: $white;
                                                text-transform: uppercase;
                                                @media screen and (min-width: $bp-medium) {
                                                    font-size: 16px;
                                                }
                                                @media screen and (min-width: $bp-large) {
                                                    font-size: 20px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .quotation-comments {
                                label {
                                    display: flex;
                                    margin-bottom: 30px;
                                    strong {
                                        @include SourceSansRegular;
                                        font-size: 16px;
                                        color: $p-black;
                                        margin-left: 20px;
                                    }
                                }

                                [type="checkbox"]:not(:checked),
                                [type="checkbox"]:checked {
                                    left: -9999px;
                                    position: absolute;
                                    top: +20px;
                                    visibility: hidden;
                                }

                                [type="checkbox"]:not(:checked) + label,
                                [type="checkbox"]:checked + label {
                                    position: relative;
                                    padding-left: 0;
                                    cursor: pointer;
                                }

                                /* checkbox aspect */
                                [type="checkbox"]:not(:checked) + label:before,
                                [type="checkbox"]:checked + label:before {
                                    content: "";
                                    position: absolute;
                                    left: 0;
                                    top: 5px;
                                    width: 12px;
                                    height: 12px;
                                    border: 2px solid $p-gray;
                                    background: rgba(255, 255, 255, 0.15);
                                    border-radius: 0;
                                    @media screen and (min-width: $bp-large) {
                                        top: 5px;
                                    }
                                }

                                [type="checkbox"]:checked + label:before {
                                    background: $p-gray;
                                }

                                /* checked mark aspect */
                                [type="checkbox"]:not(:checked) + label:after,
                                [type="checkbox"]:checked + label:after {
                                    content: "";
                                    left: 3px;
                                    top: 7px;
                                    border: 2px solid $white;
                                    background: transparent;
                                    opacity: 0;
                                    transform: rotate(-45deg);
                                    position: absolute;
                                    width: 9px;
                                    height: 5px;
                                    font-size: 1.4em;
                                    line-height: 0.8;
                                    transition: all 0.2s;
                                    @media screen and (min-width: $bp-medium) {
                                        top: 10px;
                                        height: 3px;
                                    }
                                    @media screen and (min-width: $bp-large) {
                                        border: 2px solid $white;
                                        top: 10px;
                                    }
                                }

                                /* checked mark aspect changes */
                                [type="checkbox"]:not(:checked) + label:after {
                                    opacity: 0;
                                    transform: scale(0);
                                    transform: rotate(-45deg);
                                }

                                [type="checkbox"]:checked + label:after {
                                    opacity: 1;
                                    border-top: none;
                                    border-right: none;
                                }
                                #quotation-comments {
                                    border: 1px solid $p-gray;
                                    &::placeholder {
                                        @include SourceSansRegular;
                                        font-size: 16px;
                                        color: $p-gray;
                                    }
                                }
                            }
                        }
                        .form {
                            @media screen and (min-width: $bp-medium) {
                                width: auto;
                                margin-right: 5px;
                            }
                            @media screen and (min-width: $bp-large) {
                                width: 72%;
                                margin-right: 0;
                            }
                            .cart {
                                .cart.items {
                                    display: flex;
                                    flex-wrap: wrap;
                                    @media screen and (min-width: $bp-medium) {
                                        display: table;
                                    }
                                    thead {
                                        tr {
                                            .item {
                                                display: none;
                                                @media screen and (min-width: $bp-medium) {
                                                    display: table-cell;
                                                }
                                            }
                                            .col {
                                                @media screen and (min-width: $bp-medium) {
                                                    font-size: 18px;
                                                    @include PlayfairDisplayBlack;
                                                    color: $p-black;
                                                    text-align: center;
                                                }
                                                @media screen and (min-width: $bp-large) {
                                                    font-size: 24px;
                                                }
                                            }
                                        }
                                    }
                                    .cart {
                                        border-top: 1px solid $p-gray;
                                        border-left: 1px solid $p-gray;
                                        border-right: 1px solid $p-gray;
                                        @media screen and (min-width: $bp-medium) {
                                            border-top: 1px solid $p-red;
                                            border-bottom: 1px solid $p-red;
                                            border-left: none;
                                            border-right: none;
                                        }
                                        .item-info {
                                            @media screen and (min-width: $bp-medium) {
                                                padding: 20px;
                                            }
                                            .price {
                                                .price-excluding-tax {
                                                    font-size: 0;
                                                    .cart-price {
                                                        p {
                                                            margin: 0 0 5px;
                                                            color: $p-black !important;
                                                            .price {
                                                                @include SourceSansRegular;
                                                                font-size: 18px;
                                                                color: $p-black;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .item {
                                                display: grid;
                                                grid-template-columns: 40% 58%;
                                                padding: 10px;
                                                align-items: flex-start;
                                                grid-column-gap: 10px;
                                                @media screen and (min-width: $bp-medium) {
                                                    margin: 20px;
                                                    padding: 0;
                                                    display: flex;
                                                    width: 100%;
                                                }
                                                @media screen and (min-width: $bp-large) {
                                                    align-items: center;
                                                }
                                                .product-item-photo {
                                                    position: relative;
                                                    top: 0;
                                                    max-width: 100%;
                                                    @media screen and (min-width: $bp-medium) {
                                                        width: 120px;
                                                        max-width: 120px;
                                                        max-height: 120px;
                                                    }
                                                    @media screen and (min-width: $bp-large) {
                                                        width: 174px;
                                                        max-width: 174px;
                                                        max-height: 174px;
                                                    }
                                                    .product-image-container {
                                                        width: 100% !important;
                                                        @media screen and (min-width: $bp-medium) {
                                                            width: 120px !important;
                                                        }
                                                        @media screen and (min-width: $bp-large) {
                                                            width: 174px !important;
                                                        }
                                                        .product-image-wrapper {
                                                            .product-image-photo {
                                                                @media screen and (min-width: $bp-medium) {
                                                                    width: 120px;
                                                                    height: 120px;
                                                                    object-fit: cover;
                                                                }
                                                                @media screen and (min-width: $bp-large) {
                                                                    width: 174px;
                                                                    height: 174px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                .product-item-details {
                                                    line-height: 1.6rem;
                                                    @media screen and (min-width: $bp-medium) {
                                                        display: flex;
                                                        justify-content: flex-end;
                                                        position: relative;
                                                        left: -10px;
                                                        flex-direction: column;
                                                    }
                                                    .product-item-name {
                                                        a {
                                                            @include SourceSansRegular;
                                                            font-size: 18px;
                                                            color: $p-black;
                                                        }
                                                    }
                                                }
                                            }
                                            .price,
                                            .qty,
                                            .subtotal {
                                                padding-top: 0;
                                                padding-bottom: 0;
                                                width: 26%;
                                                @media screen and (min-width: $bp-medium) {
                                                    position: relative;
                                                    vertical-align: bottom;
                                                    padding-bottom: 20px;
                                                    text-align: center;
                                                }
                                                @media screen and (min-width: $bp-large) {
                                                    width: 16%;
                                                    vertical-align: middle;
                                                }
                                                &::before {
                                                    @include SourceSansRegular;
                                                    font-size: 16px;
                                                    color: $p-black;
                                                    text-transform: uppercase;
                                                    padding-bottom: 20px;
                                                }
                                            }
                                            .price,
                                            .subtotal {
                                                .price-excluding-tax {
                                                    .cart-price {
                                                        .price {
                                                            @include SourceSansBold;
                                                            font-size: 18px;
                                                            color: $p-red;
                                                            @media screen and (min-width: $bp-large) {
                                                                padding-bottom: 0;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .qty {
                                                @media screen and (min-width: $bp-large) {
                                                    width: 12%;
                                                }
                                                .field {
                                                    border: 1px solid $p-gray;
                                                    width: 100%;
                                                    @media screen and (min-width: $bp-medium) {
                                                        padding-bottom: 0;
                                                    }
                                                    @media screen and (min-width: $bp-large) {
                                                        margin: auto;
                                                        width: 70%;
                                                    }
                                                    @media screen and (min-width: 1920px) {
                                                        width: 50%;
                                                    }
                                                    .control {
                                                        width: 100%;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        @media screen and (min-width: $bp-medium) {
                                                            padding-bottom: 0;
                                                        }
                                                        button {
                                                            border: none;
                                                            background-color: transparent;
                                                            padding: 0;
                                                            display: flex;
                                                        }
                                                        .input-text {
                                                            @include SourceSansRegular;
                                                            font-size: 16px;
                                                            color: $p-black;
                                                            border: none;
                                                            padding: 0;
                                                            width: 26%;
                                                            margin: 0 2px;
                                                            min-width: 25px;
                                                        }
                                                    }
                                                }
                                            }
                                            .subtotal {
                                                .flex-actions {
                                                    display: none;
                                                    @media screen and (min-width: $bp-medium) {
                                                        display: block;
                                                    }
                                                    @media screen and (min-width: $bp-large) {
                                                        display: none;
                                                    }
                                                    .actions-toolbar {
                                                        position: absolute;
                                                        top: 20px;
                                                        left: 0;
                                                        right: 0;
                                                        margin-left: 0;
                                                        .action {
                                                            border: none;
                                                            background: transparent;
                                                            padding-top: 0;
                                                            padding: 0;
                                                            margin: 0;
                                                        }
                                                    }
                                                }
                                                .price-excluding-tax {
                                                    .cart-price {
                                                        .price {
                                                            color: $p-black;
                                                            @media screen and (min-width: $bp-large) {
                                                                padding-bottom: 0;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .item-actions {
                                                box-sizing: border-box;
                                                display: block;
                                                float: left;
                                                text-align: center;
                                                width: 21%;
                                                padding-top: 0;
                                                padding-bottom: 0;
                                                @media screen and (min-width: $bp-medium) {
                                                    display: none;
                                                }
                                                @media screen and (min-width: $bp-large) {
                                                    display: table-cell;
                                                    float: none;
                                                    vertical-align: middle;
                                                    width: 12%;
                                                    padding-bottom: 20px;
                                                }
                                                .actions-toolbar {
                                                    display: flex;
                                                    flex-direction: column-reverse;
                                                    @media screen and (min-width: $bp-large) {
                                                        flex-direction: row-reverse;
                                                        justify-content: center;
                                                    }
                                                    .action {
                                                        border: none;
                                                        background: transparent;
                                                        padding-top: 0;
                                                        @media screen and (min-width: $bp-large) {
                                                            margin: 0;
                                                            padding: 0 10px;
                                                            display: flex;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .cart:last-child {
                                        border-bottom: 1px solid $p-gray;
                                    }
                                }
                            }
                            .main {
                                display: flex;
                                justify-content: center;
                                @media screen and (min-width: $bp-medium) {
                                    justify-content: flex-end;
                                }
                                .continue {
                                    &::before {
                                        display: none;
                                    }
                                    display: flex;
                                    background: $white;
                                    border: 1px solid $p-black;
                                    border-radius: 0;
                                    padding: 10px 20px;
                                    span {
                                        @include SourceSansRegular;
                                        font-size: 20px;
                                        text-transform: uppercase;
                                        color: $p-black;
                                    }
                                }
                                .clear,
                                .update {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
