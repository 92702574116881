.checkout-cart-index {
    .page-wrapper {
        .page-main {
            .columns {
                .column {
                    .cart-container {
                        @media screen and (min-width: $bp-medium) {
                            display: flex;
                            flex-direction: row-reverse;
                            margin: 10px;
                        }
                        .cart-summary {
                            text-align: center;
                            margin-bottom: 20px;
                            padding-bottom: 0;
                            @media screen and (min-width: $bp-medium) {
                                width: auto;
                                top: 0 !important;
                            }
                            @media screen and (min-width: $bp-large) {
                                width: 25%;
                                height: 100%;
                                padding-bottom: 20px;
                                margin-left: 20px;
                            }
                            .summary {
                                @include PlayfairDisplayBlack;
                                font-size: 24px;
                                color: $p-black;
                                @media screen and (min-width: $bp-medium) {
                                    margin-top: 20px;
                                }
                            }
                            #block-shipping {
                                display: none;
                            }
                            .block.discount {
                                padding: 10px 20px 20px;
                                border: solid 1px $p-gray;
                                @media screen and (min-width: $bp-medium) {
                                    padding: 10px 10px 10px;
                                }
                                @media screen and (min-width: $bp-large) {
                                    padding: 10px 20px;
                                }
                                .title {
                                    text-align: left;
                                    border-top: none;
                                    @media screen and (min-width: $bp-medium) {
                                        display: flex;
                                    }
                                    strong {
                                        @include SourceSansRegular;
                                        font-size: 16px;
                                        color: $p-gray;
                                        @media screen and (min-width: $bp-medium) {
                                            margin: 0;
                                        }
                                    }
                                    &::after {
                                        content: url(../images/checkout/mas-opciones.svg);
                                        right: 0;
                                        top: 0;
                                        @media screen and (min-width: $bp-large) {
                                            top: -6px;
                                        }
                                    }
                                }
                                .content {
                                    form {
                                        .fieldset {
                                            margin: 0;
                                            margin-top: 20px;
                                            .field {
                                                margin-bottom: 15px;
                                                .control {
                                                    .input-text {
                                                        height: 45px;
                                                        border: 1px solid
                                                            $p-gray;
                                                        &::placeholder {
                                                            @include SourceSansRegular;
                                                            font-size: 16px;
                                                            color: $p-gray;
                                                        }
                                                    }
                                                }
                                            }
                                            .actions-toolbar {
                                                .primary {
                                                    @media screen and (min-width: $bp-medium) {
                                                        width: 100%;
                                                    }
                                                    .action {
                                                        height: 45px;
                                                        background-color: $p-black;
                                                        color: $white;
                                                        transition: 0.5s;
                                                        &:hover {
                                                            background-color: $white;
                                                            color: $p-black;
                                                            border: 1px solid
                                                                $p-black;
                                                        }
                                                        @media screen and (min-width: $bp-medium) {
                                                            line-height: normal;
                                                            display: flex;
                                                            align-items: center;
                                                            margin-right: 0;
                                                            width: 100%;
                                                            justify-content: center;
                                                        }
                                                        span {
                                                            @include SourceSansRegular;
                                                            font-size: 20px;
                                                            text-transform: uppercase;
                                                            @media screen and (min-width: $bp-medium) {
                                                                font-size: 14px;
                                                            }
                                                            @media screen and (min-width: $bp-large) {
                                                                font-size: 18px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .block.discount.active {
                                .title {
                                    &::after {
                                        content: url(../images/checkout/menos-opciones.svg);
                                        right: 0;
                                        top: -9px;
                                        @media screen and (min-width: $bp-large) {
                                            top: -15px;
                                        }
                                    }
                                }
                            }
                            .cart-totals {
                                .table-wrapper {
                                    .data {
                                        tbody {
                                            .totals {
                                                .mark {
                                                    @include SourceSansRegular;
                                                    font-size: 18px;
                                                    color: $p-gray;
                                                    line-height: 1.44;
                                                    padding: 0;
                                                }
                                                .amount {
                                                    padding: 0;
                                                    .price {
                                                        @include SourceSansBold;
                                                        font-size: 18px;
                                                        color: $p-gray;
                                                        line-height: 1.44;
                                                    }
                                                }
                                            }
                                            .shipping {
                                                display: none;
                                            }
                                            .grand {
                                                border-top: 1px solid $p-red;
                                                .mark {
                                                    font-size: 24px;
                                                    padding-top: 20px;
                                                    @media screen and (min-width: $bp-medium) {
                                                        font-size: 18px;
                                                    }
                                                    @media screen and (min-width: $bp-large) {
                                                        font-size: 24px;
                                                    }
                                                }
                                                .amount {
                                                    padding-top: 20px;
                                                    @media screen and (min-width: $bp-medium) {
                                                        padding-bottom: 0;
                                                    }
                                                    .price {
                                                        font-size: 24px;
                                                        @media screen and (min-width: $bp-medium) {
                                                            font-size: 20px;
                                                        }
                                                        @media screen and (min-width: $bp-large) {
                                                            font-size: 24px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .checkout {
                                .item {
                                    aplazo-placement {
                                        font-family: "Playfair Display";
                                    }
                                    .action {
                                        height: 45px;
                                        background-color: $p-red;
                                        border-radius: 0;
                                        border: none;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        line-height: 1px;
                                        color: $white;
                                        transition: 0.5s;
                                        &:hover {
                                            background-color: $white;
                                            color: $p-red;
                                            border: 1px solid $p-red;
                                            text-decoration: none;
                                        }
                                        @media screen and (min-width: $bp-medium) {
                                            height: 100%;
                                            line-height: 16px;
                                        }
                                        @media screen and (min-width: $bp-large) {
                                            height: 45px;
                                        }
                                        span {
                                            @include SourceSansRegular;
                                            font-size: 20px;
                                            text-transform: uppercase;
                                            @media screen and (min-width: $bp-medium) {
                                                font-size: 16px;
                                            }
                                            @media screen and (min-width: $bp-large) {
                                                font-size: 20px;
                                            }
                                        }
                                    }
                                    .multicheckout {
                                        line-height: normal;
                                        height: 100%;
                                        padding: 10px;
                                        &::hover {
                                            text-decoration: none;
                                        }
                                    }
                                }
                            }
                        }
                        .form {
                            @media screen and (min-width: $bp-medium) {
                                width: auto;
                                margin-right: 10px;
                            }
                            @media screen and (min-width: $bp-large) {
                                width: 75%;
                                margin-right: 0;
                            }
                            .cart {
                                .cart.items {
                                    display: flex;
                                    flex-wrap: wrap;
                                    @media screen and (min-width: $bp-medium) {
                                        display: table;
                                    }
                                    thead {
                                        tr {
                                            .item {
                                                display: none;
                                                @media screen and (min-width: $bp-medium) {
                                                    display: table-cell;
                                                }
                                            }
                                            .col {
                                                @media screen and (min-width: $bp-medium) {
                                                    font-size: 18px;
                                                    @include PlayfairDisplayBlack;
                                                    color: $p-black;
                                                    text-align: center;
                                                }
                                                @media screen and (min-width: $bp-large) {
                                                    font-size: 24px;
                                                }
                                            }
                                        }
                                    }
                                    .cart {
                                        border-top: 1px solid $p-gray;
                                        border-left: 1px solid $p-gray;
                                        border-right: 1px solid $p-gray;
                                        margin: 0 15px;
                                        &:last-child {
                                            border-bottom: 1px solid $p-gray;
                                        }
                                        @media screen and (min-width: $bp-medium) {
                                            border-top: 1px solid $p-red;
                                            border-bottom: 1px solid $p-red !important;
                                            border-left: none;
                                            border-right: none;
                                        }
                                        .item-info {
                                            @media screen and (min-width: $bp-medium) {
                                                padding: 20px;
                                            }
                                            .price {
                                                .old-price {
                                                    color: $p-black;
                                                    .price {
                                                        color: $p-black;
                                                        @include SourceSansRegular;
                                                        font-size: 20px;
                                                    }
                                                }
                                                .price-excluding-tax {
                                                    font-size: 0;
                                                    .cart-price {
                                                        p {
                                                            margin: 0 0 5px;
                                                            color: $p-black !important;
                                                            .price {
                                                                @include SourceSansRegular;
                                                                font-size: 18px;
                                                                color: $p-black;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .item {
                                                display: grid;
                                                grid-template-columns: 40% 58%;
                                                padding: 10px;
                                                align-items: flex-start;
                                                grid-column-gap: 10px;
                                                @media screen and (min-width: $bp-medium) {
                                                    margin: 20px;
                                                    padding: 0;
                                                    display: flex;
                                                    width: 100%;
                                                }
                                                @media screen and (min-width: $bp-large) {
                                                    align-items: center;
                                                }
                                                .product-item-photo {
                                                    position: relative;
                                                    top: 0;
                                                    max-width: 100%;
                                                    @media screen and (min-width: $bp-medium) {
                                                        width: 120px;
                                                        max-width: 120px;
                                                        max-height: 120px;
                                                    }
                                                    @media screen and (min-width: $bp-large) {
                                                        width: 174px;
                                                        max-width: 174px;
                                                        max-height: 174px;
                                                    }
                                                    .product-image-container {
                                                        width: 100% !important;
                                                        @media screen and (min-width: $bp-medium) {
                                                            width: 120px !important;
                                                        }
                                                        @media screen and (min-width: $bp-large) {
                                                            width: 174px !important;
                                                        }
                                                        .product-image-wrapper {
                                                            .product-image-photo {
                                                                @media screen and (min-width: $bp-medium) {
                                                                    width: 120px;
                                                                    height: 120px;
                                                                    object-fit: cover;
                                                                }
                                                                @media screen and (min-width: $bp-large) {
                                                                    width: 174px;
                                                                    height: 174px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                .product-item-details {
                                                    line-height: 1.6rem;
                                                    @media screen and (min-width: $bp-medium) {
                                                        display: flex;
                                                        justify-content: flex-end;
                                                        position: relative;
                                                        left: -10px;
                                                        flex-direction: column;
                                                    }
                                                    .cart.item {
                                                        display: flex;
                                                        div {
                                                            &:before {
                                                                margin: 0;
                                                                position: static;
                                                            }
                                                        }
                                                    }
                                                    .product-item-name {
                                                        a {
                                                            @include SourceSansRegular;
                                                            font-size: 18px;
                                                            color: $p-black;
                                                        }
                                                    }
                                                }
                                            }
                                            .price,
                                            .qty,
                                            .subtotal {
                                                padding-top: 0;
                                                padding-bottom: 0;
                                                width: 26%;
                                                @media screen and (min-width: $bp-medium) {
                                                    position: relative;
                                                    vertical-align: bottom;
                                                    padding-bottom: 20px;
                                                    text-align: center;
                                                }
                                                @media screen and (min-width: $bp-large) {
                                                    width: 16%;
                                                    vertical-align: middle;
                                                }
                                                &::before {
                                                    @include SourceSansRegular;
                                                    font-size: 16px;
                                                    color: $p-black;
                                                    text-transform: uppercase;
                                                    padding-bottom: 20px;
                                                }
                                            }
                                            .price,
                                            .subtotal {
                                                .price-excluding-tax {
                                                    .cart-price {
                                                        .price {
                                                            @include SourceSansBold;
                                                            font-size: 18px;
                                                            color: $p-red;
                                                            @media screen and (min-width: $bp-large) {
                                                                padding-bottom: 0;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .qty {
                                                @media screen and (min-width: $bp-large) {
                                                    width: 12%;
                                                }
                                                .field {
                                                    border: 1px solid $p-gray;
                                                    width: 100%;
                                                    @media screen and (min-width: $bp-medium) {
                                                        padding-bottom: 0;
                                                    }
                                                    @media screen and (min-width: $bp-large) {
                                                        width: 70%;
                                                        margin: auto;
                                                    }
                                                    .control {
                                                        width: 100%;
                                                        @media screen and (min-width: $bp-medium) {
                                                            padding-bottom: 0;
                                                        }
                                                        label {
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;
                                                            button {
                                                                border: none;
                                                                background-color: transparent;
                                                                padding: 0;
                                                                display: flex;
                                                            }
                                                            .input-text {
                                                                @include SourceSansRegular;
                                                                font-size: 16px;
                                                                color: $p-black;
                                                                border: none;
                                                                padding: 0;
                                                                width: 26%;
                                                                margin: 0 2px;
                                                                min-width: 25px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .subtotal {
                                                .flex-actions {
                                                    display: none;
                                                    @media screen and (min-width: $bp-medium) {
                                                        display: block;
                                                    }
                                                    @media screen and (min-width: $bp-large) {
                                                        display: none;
                                                    }
                                                    .actions-toolbar {
                                                        position: absolute;
                                                        top: 20px;
                                                        left: 0;
                                                        right: 0;
                                                        margin-left: 0;
                                                        .action {
                                                            border: none;
                                                            background: transparent;
                                                            padding-top: 0;
                                                            padding: 0;
                                                            margin: 0;
                                                        }
                                                        .use-ajax.action.towishlist.action-towishlist {
                                                            display: none;
                                                        }
                                                    }
                                                }
                                                .price-excluding-tax {
                                                    .cart-price {
                                                        .price {
                                                            color: $p-black;
                                                            @media screen and (min-width: $bp-large) {
                                                                padding-bottom: 0;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .item-actions {
                                                box-sizing: border-box;
                                                display: block;
                                                float: left;
                                                text-align: center;
                                                width: 21%;
                                                padding-top: 0;
                                                padding-bottom: 0;
                                                @media screen and (min-width: $bp-medium) {
                                                    display: none;
                                                }
                                                @media screen and (min-width: $bp-large) {
                                                    display: table-cell;
                                                    float: none;
                                                    vertical-align: middle;
                                                    width: 12%;
                                                    padding-bottom: 20px;
                                                }
                                                .actions-toolbar {
                                                    display: flex;
                                                    flex-direction: column-reverse;
                                                    @media screen and (min-width: $bp-large) {
                                                        flex-direction: row;
                                                        justify-content: center;
                                                    }
                                                    .action {
                                                        border: none;
                                                        background: transparent;
                                                        padding-top: 0;
                                                        @media screen and (min-width: $bp-large) {
                                                            margin: 0;
                                                            padding: 0 10px;
                                                            display: flex;
                                                        }
                                                    }
                                                    .use-ajax.action.towishlist.action-towishlist {
                                                        display: none;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .main {
                                display: flex;
                                justify-content: center;
                                flex-direction: column;
                                @media screen and (min-width: $bp-medium) {
                                    justify-content: flex-end;
                                    flex-direction: row;
                                    margin: 10px;
                                }
                                .continue,
                                .clear,
                                .update {
                                    &::before {
                                        display: none;
                                    }
                                    display: flex;
                                    background: $white;
                                    border: 1px solid $p-black;
                                    border-radius: 0;
                                    padding: 10px 20px;
                                    color: $p-black;
                                    transition: 0.5s;
                                    &:hover {
                                        background-color: $p-black;
                                        color: $white;
                                    }
                                    span {
                                        @include SourceSansRegular;
                                        font-size: 20px;
                                        text-transform: uppercase;
                                        text-align: center;
                                        @media screen and (min-width: $bp-medium) {
                                            font-size: 16px;
                                        }
                                        @media screen and (min-width: $bp-large) {
                                            font-size: 20px;
                                        }
                                    }
                                }
                                .clear,
                                .update {
                                    @media screen and (min-width: $bp-medium) {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                    .steps {
                        border: 1px solid $p-red;
                        margin: 0 15px;
                        @media screen and (min-width: $bp-large) {
                            margin: 30px 10px 0;
                        }
                        .uk-slider-items {
                            .container {
                                display: grid;
                                grid-template-columns: 25% 75%;
                                align-items: center;
                                margin-top: 20px;
                                padding: 0 15px;
                                &::after,
                                &::before {
                                    display: none;
                                }
                                @media screen and (min-width: $bp-medium) {
                                    margin-bottom: 30px;
                                }
                                @media screen and (min-width: $bp-large) {
                                    margin-bottom: 40px;
                                    margin-top: 40px;
                                    grid-template-columns: 25% 75%;
                                }

                                .img {
                                    margin-right: 20px;
                                    text-align: center;
                                }

                                .text-container {
                                    .text {
                                        margin: 0;
                                        color: $p-red;
                                        @include SourceSansRegular;
                                    }

                                    .title {
                                        font-size: 22px;
                                        @media screen and (min-width: $bp-medium) {
                                            text-transform: initial;
                                        }
                                    }

                                    .subtitle {
                                        font-size: 18px;
                                        color: $p-red;
                                        @media screen and (min-width: $bp-large) {
                                            font-size: 16px;
                                        }
                                    }
                                }
                            }
                        }
                        .uk-slider-nav {
                            justify-content: center;
                            li {
                                margin-bottom: 20px;
                                a {
                                    background-color: #c8c8c8;
                                    border: 1px solid #c8c8c8;
                                }
                            }
                            .uk-active {
                                a {
                                    background-color: $p-red;
                                    border: 1px solid $p-red;
                                }
                            }
                        }
                    }
                    .block {
                        margin-top: 20px;
                        @media screen and (min-width: $bp-large) {
                            margin-top: 30px;
                        }
                        .block-title {
                            text-align: center;
                            @media screen and (min-width: $bp-large) {
                                margin-bottom: 20px;
                            }
                            strong {
                                @include PlayfairDisplayBlack;
                                font-size: 24px;
                                color: $p-black;
                                @media screen and (min-width: $bp-large) {
                                    font-size: 30px;
                                }
                            }
                        }
                        .block-content {
                            .uk-slider {
                                margin-top: 20px;
                                margin-bottom: 0;
                                .products {
                                    @media screen and (min-width: $bp-large) {
                                        justify-content: center;
                                    }
                                    .item {
                                        display: flex;
                                        justify-content: center;
                                        margin-bottom: 0;
                                        @media screen and (min-width: $bp-large) {
                                            width: calc((100% - 4%) / 5);
                                        }
                                        .product-item-info {
                                            .photo {
                                                .product-image-container {
                                                    .product-image-wrapper {
                                                        padding-bottom: 100% !important;
                                                        .product-image-photo {
                                                            margin-top: 0;
                                                        }
                                                    }
                                                }
                                            }
                                            .details {
                                                .product {
                                                    font-size: 0;
                                                    height: 65px;
                                                    overflow: hidden;
                                                    text-align: center;
                                                    @media screen and (min-width: $bp-large) {
                                                        height: 55px;
                                                    }
                                                    a {
                                                        @include SourceSansRegular;
                                                        font-size: 16px;
                                                        color: $p-black;
                                                        @media screen and (min-width: $bp-large) {
                                                            font-size: 20px;
                                                        }
                                                    }
                                                }
                                                .price-box {
                                                    text-align: center;
                                                    margin-bottom: 10px;
                                                    .normal-price {
                                                        .price-container {
                                                            .price-wrapper {
                                                                .price {
                                                                    @include SourceSansBold;
                                                                    font-size: 18px;
                                                                    color: $p-black;
                                                                    @media screen and (min-width: $bp-large) {
                                                                        font-size: 20px;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    .price-container {
                                                        .price-wrapper {
                                                            .price {
                                                                @include SourceSansBold;
                                                                font-size: 18px;
                                                                color: $p-black;
                                                                @media screen and (min-width: $bp-large) {
                                                                    font-size: 20px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                .actions {
                                                    height: auto;
                                                    .actions-primary {
                                                        width: 100%;
                                                        .action {
                                                            @include btn-red;
                                                            font-size: 16px;
                                                            padding: 7px 5px;
                                                            width: 100%;
                                                            span {
                                                                text-transform: uppercase;
                                                                font-size: 16px;
                                                            }
                                                        }
                                                    }
                                                    .secondary-addto-links {
                                                        display: none;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .arrows {
                                    .uk-icon {
                                        background: url(../images/icons/icon-arrow-izq-oscura.svg);
                                        content: "";
                                        width: 50px;
                                        height: 55px;
                                        background-repeat: no-repeat;
                                        padding: 0;
                                        margin: 0;
                                        background-position: center;
                                        background-color: rgba(
                                            255,
                                            255,
                                            255,
                                            0.25
                                        );
                                        border-radius: 0;
                                        &:hover {
                                            background: url(../images/icons/icon-arrow.svg);
                                            content: "";
                                            width: 50px;
                                            height: 55px;
                                            background-repeat: no-repeat;
                                            padding: 0;
                                            margin: 0;
                                            background-position: center;
                                            background-color: $white;
                                            border-radius: 0;
                                        }
                                        svg {
                                            display: none;
                                        }
                                    }
                                    .uk-position-center-right {
                                        transform: rotate(-180deg);
                                        top: 42%;
                                        right: -15px;
                                        @media screen and (min-width: $bp-large) {
                                            top: 40%;
                                        }
                                        @media screen and (min-width: $bp-xlarge) {
                                            top: 45%;
                                        }
                                        @media screen and (min-width: $bp-xxlarge) {
                                            top: 50%;
                                        }
                                        &:hover {
                                            transform: rotate(0deg);
                                        }
                                    }

                                    .uk-position-center-left {
                                        transform: translateY(0);
                                        top: 42%;
                                        left: -15px;
                                        @media screen and (min-width: $bp-large) {
                                            top: 40%;
                                        }
                                        @media screen and (min-width: $bp-xlarge) {
                                            top: 45%;
                                        }
                                        @media screen and (min-width: $bp-xxlarge) {
                                            top: 50%;
                                        }
                                        &:hover {
                                            transform: rotate(-180deg);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .block.crosssell {
                        .item {
                            .discount-flag {
                                width: 45px;
                                height: 45px;
                                margin: 10px;
                                border-radius: 3em;
                                overflow: hidden;
                                position: absolute;
                                z-index: 2;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background: $hover;
                                transform: rotate(-25deg);
                                font-size: 18px;
                                color: $white;
                                @include PlayfairDisplayBold;
                                left: 25px;
                                @media screen and (min-width: $bp-medium) {
                                    width: 60px;
                                    height: 60px;
                                }
                                @media screen and (min-width: $bp-large) {
                                    left: 40px;
                                }
                                .discount {
                                    margin-top: -5px;
                                    @media screen and (min-width: $bp-medium) {
                                        margin-top: -6px;
                                        font-size: 24px;
                                    }
                                    @media screen and (min-width: $bp-large) {
                                        margin-top: -10px;
                                    }
                                }
                                .percent {
                                    font-size: 10.5px;
                                    padding-top: 5px;
                                    @media screen and (min-width: $bp-medium) {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
