body.cms-nosotros {
    .message.global.demo {
        display: none;
    }
    .page-wrapper {
        font-family: Source Sans Pro;
        .breadcrumbs {
            display: none;
        }
        .page-header {
            background: transparent;
            .header {
                background-color: rgba(0, 0, 0, 0.65);
            }
        }
        .rootmenu {
            background-color: rgba(0, 0, 0, 0.65) !important;
        }
        .footer {
            .block.newsletter {
                display: none;
            }
        }
        .page-title-wrapper {
            position: absolute;
            text-align: center;
            top: 45%;
            width: 100%;
            .page-title {
                color: white;
                font-family: Playfair Display;
                font-size: 50px;
                font-weight: 800;
                font-style: normal;
                letter-spacing: 1px;
                text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.75);
                &:before,
                &:after {
                    content: "~";
                    font-size: 50px;
                    display: inline;
                    margin: 0 15px;
                }
            }
        }
        .column.main {
            margin-top: 25%;
        }
        .banner-top {
            position: absolute;
            top: 0;
            width: 100%;
            img {
                width: 100%;
                &.only-desk {
                    display: block;
                }
                &.only-tab {
                    display: none;
                }
                &.only-mob {
                    display: none;
                }
            }
        }
        .page-main {
            .cont-page {
                max-width: 1140px;
                margin: 100px auto 0;
                display: table;
                p {
                    font-size: 16px;
                    text-align: justify;
                }
                .col {
                    width: 100%;
                    &.left {
                        .img-container {
                            display: none;
                        }
                    }
                    &.right {
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    body.cms-nosotros {
        .page-wrapper {
            .page-main {
                .cont-page {
                    padding: 0 25px;
                    margin: 60px auto 0;
                    max-width: 100%;
                }
            }
        }
    }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    body.cms-nosotros {
        .page-wrapper {
            .column.main {
                margin-top: 21%;
            }
        }
    }
}

@media only screen and (max-width: 1025px) {
    body.cms-nosotros {
        .page-wrapper {
            .column.main {
                margin-top: 45%;
            }
            .page-title-wrapper {
                .page-title {
                    font-size: 40px;
                }
            }
            .banner-top {
                img {
                    &.only-desk {
                        display: none;
                    }
                    &.only-tab {
                        display: block;
                    }
                    &.only-mob {
                        display: none;
                    }
                }
            }
        }
    }
}

/* Landscape */

@media (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    body.cms-nosotros {
        .page-wrapper {
            .banner-top {
                img {
                    &.only-desk {
                        display: block !important;
                    }
                    &.only-tab {
                        display: none !important;
                    }
                    &.only-mob {
                        display: none !important;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    body.cms-nosotros {
        .page-wrapper {
            .page-title-wrapper {
                top: 35%;
                .page-title {
                    font-size: 23px;
                    span {
                        margin: 0 auto;
                        display: inline;
                    }
                    &:before,
                    &:after {
                        font-size: 20px;
                    }
                }
            }
            .column.main {
                margin-top: 50%;
            }
            .banner-top {
                img {
                    &.only-desk {
                        display: none;
                    }
                    &.only-tab {
                        display: none;
                    }
                    &.only-mob {
                        display: block;
                    }
                }
            }
            .page-main {
                .cont-page {
                    margin: 30px auto 0;
                    .col {
                        width: 100%;
                        margin-bottom: 50px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $bp-large) {
    .cms-nosotros {
        .page-wrapper {
            .page-main {
                .cont-page {
                    margin-top: 30px;
                    .col.left {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 50px;
                        .text-container {
                            width: 48%;
                        }
                        .img-container {
                            width: 48%;
                            text-align: center;
                            display: block !important;
                        }
                    }
                    .col.right {
                        img {
                            height: 100%;
                        }
                        .img-mobile {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
