body.cms-terminos-y-condiciones,
body.cms-aviso-de-privacidad,
body.cms-privacidad {
  .message.global.demo {
    display:none
  }
  .page-wrapper {
    font-family: Source Sans Pro;

    .breadcrumbs {
      display: none;
    }

    .page-title {
      font-family: Playfair Display;
      font-weight: 900;
      text-align: center;
      text-transform: none;
      font-size: 30px;
      line-height: 34px;
      margin: 55px 40px;
      width: auto;
      display: block;
      color: black;
    }

    .footer {
      .block.newsletter {
        display: none;
      }
    }

    .page-main {
      .cont-page {
        max-width: 1000px;
        margin: 0 auto 50px;

        &.large {
          max-width: 1140px;
        }

        p {
          font-family: Source Sans Pro;
          font-size: 16px;
          line-height: 24px;
          text-align: justify;
          width: 100%;
          display: table;
        }

        strong {
          font-weight: 900;
          color: black;
        }

        a {
          color: #93002a;
          text-decoration: none;

          &.mail {
            color: black;
          }
        }

        h3 {
          font-family: Playfair Display;
          font-weight: 900;
          text-align: center;
          font-size: 25px;
          width: 100%;
          margin-bottom: 40px;
          color: black;
        }

        label {
          font-size: 16px;
        }

        .back-gray {
          border-radius: 10px;
          background: #f3f3f3;
          padding: 30px 10%;
          width: auto;
          margin: 0 auto;
          display: block;

          p {
            margin: 0;
            text-align: left;
          }

          &.small {
            float: left;
            width: 15%;
            margin: 0 0 30px 0;
          }

          &.medium {
            width: 40%;
            margin: 0 auto 30px;
          }
        }

        .list-icos {
          display: flex;
          flex-wrap: wrap;
          padding: 0;
          margin: 60px 0 50px 0;

          li {
            list-style: none;
            margin-right: 39px;
            flex: 1;
            text-align: center;
            max-width: 130px;
            margin: 0 auto;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(8) {
              max-width: 80px;
            }

            &:nth-child(4),
            &:nth-child(5) {
              max-width: 105px;
            }

            &:nth-child(6) {
              max-width: 135px;
            }

            h4 {
              text-transform: uppercase;
              font-size: 12px;
              text-align: left;
              margin: 15px 0 0;
              font-weight: 900;
            }

            p {
              font-size: 12px;
              line-height: 16px;
              text-align: left;
              margin: 0;
            }
          }
        }
      }
    }

    .styled-checkbox {
      position: absolute; // take it out of document flow
      opacity: 0; // hide it
      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
      }

      & + label:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 15px;
        height: 15px;
        background: white;
        border: 2px solid #4a4a4a;
      }

      &:hover + label:before {
        background: white;
      }

      &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
      }

      &:checked + label:before {
        background: white;
        border-color: #93002a;
      }

      &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
      }

      &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
      }

      &:checked + label:after {
        content: '';
        position: absolute;
        left: 5px;
        top: 9px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 #93002a,
        4px 0 0 #93002a,
        4px -2px 0 #93002a,
        4px -4px 0 #93002a,
        4px -6px 0 #93002a,
        4px -8px 0 #93002a;
        transform: rotate(45deg);
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  body.cms-terminos-y-condiciones,
  body.cms-aviso-de-privacidad,
  body.cms-privacidad {
    .page-wrapper {
      .page-main {
        .cont-page {
          padding: 0 25px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  body.cms-terminos-y-condiciones,
  body.cms-aviso-de-privacidad,
  body.cms-privacidad {
    .page-wrapper {
      .page-main {
        .cont-page {
          .list-icos {
            li {
              text-align: center;
              max-width: 130px !important;
              flex: 1 130px;
              margin: 15px auto;

              h4 {
                text-align: center;
              }

              p {
                text-align: center;
              }
            }
          }

          .back-gray.small {
            width: 25%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  body.cms-terminos-y-condiciones,
  body.cms-aviso-de-privacidad,
  body.cms-privacidad {
    .page-wrapper {
      .page-main {
        .cont-page {
          .list-icos {
            li {
              text-align: center;
              max-width: 150px !important;
              flex: 1 130px;
              margin: 15px auto;

              h4 {
                text-align: center;
              }

              p {
                text-align: center;
              }
            }
          }

          .back-gray.small {
            width: 50%;
          }
        }
      }
    }
  }
}