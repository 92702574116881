.nav-before-open.nav-open {
    .action.nav-toggle {
        top: 0 !important;
        left: 0;
        @media screen and (min-width: $bp-medium) {
            top: -126px !important;
        }
    }
    .action.nav-toggle:before {
        background: url("../images/icons/icon-close.svg");
        content: "";
        width: 54px;
        height: 71px;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
        background-position: center;
        position: absolute;
        background-color: black;
        top: 0;
        left: 0;
        @media screen and (min-width: $bp-medium) {
            right: -34px;
            left: initial;
        }
    }
    .action.nav-toggle::after {
        background: none;
    }
}

.nav-open .page-wrapper {
    @media screen and (max-width: $bp-large) {
        overflow: visible;
        background: rgba(0, 0, 0, 0.5);
        content: "";
        display: block;
        height: 100%;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        left: calc(100% - 55px);
    }
}

.page-wrapper {
    .page-header {
        width: 100%;
        border: none;
        margin-bottom: 0;
        @media screen and (min-width: $bp-large) {
            position: relative;
            z-index: 4;
        }

        .header {
            z-index: 14;
            padding-top: 0;
            background-color: rgba(0, 0, 0, 0.75);
            display: flex;
            align-items: center;
            min-height: 70px;
            justify-content: space-between;
            @media screen and (min-width: $bp-large) {
                min-height: 120px;
            }
            @media screen and (min-width: $bp-medium) {
                justify-content: space-between;
                align-items: center;
            }
            @media screen and (min-width: $bp-large) {
                margin: 0;
                padding: 0;
                max-width: 100%;
            }

            .action {
                flex: 1;

                &::before {
                    color: $white;
                }

                @media screen and (min-width: $bp-medium) and (max-width: $bp-large) {
                    display: block;
                    position: relative;
                    top: 0;
                    left: 0;
                    flex: inherit;
                }
            }

            .nav-toggle {
                top: auto;
            }

            .logo {
                position: absolute;
                left: 0;
                right: 0;
                width: 160px;
                margin: 0 auto;
                z-index: 3;
                float: none;
                @media screen and (min-width: $bp-large) {
                    order: 2;
                    margin: 0 auto;
                    width: 249px;
                    padding-top: 10px;
                }

                img {
                    @media screen and (max-width: $bp-small) {
                        width: auto;
                    }
                    @media screen and (min-width: $bp-large) {
                        width: 100%;
                        height: 110px;
                    }
                }
            }

            > .block.block-search {
                @media screen and (max-width: $bp-large) {
                    display: none;
                }
            }

            .minicart-wrapper {
                margin-top: 0 !important;
                @media screen and (min-width: $bp-medium) and (max-width: $bp-large) {
                    margin-top: 15px;
                }

                .action.showcart {
                    &:before {
                        display: none;
                    }
                    .icon-icon-carrito-relleno {
                        display: none;
                    }
                    &:hover {
                        @media screen and (min-width: $bp-large) {
                            .icon-icon-carrito-relleno {
                                display: block;
                                position: absolute;
                                top: 6px;
                            }
                        }
                    }
                }
            }
            .miniquote-wrapper {
                .action.showquote {
                    .icon-icon-cotizacion-relleno {
                        display: none;
                    }
                    &:hover {
                        @media screen and (min-width: $bp-large) {
                            .icon-icon-quote {
                                display: none;
                            }
                            .icon-icon-cotizacion-relleno {
                                display: block;
                                top: 6px;
                            }
                        }
                    }
                }
            }

            .search-bar {
                visibility: hidden;
                align-items: center;
                margin-left: 20px;
                display: none;
                @media screen and (min-width: $bp-medium) {
                    margin-left: 0;
                    z-index: 2;
                }
                @media screen and (min-width: $bp-large) {
                    display: flex;
                    order: 1;
                    visibility: visible;
                }

                .block-search {
                    @media screen and (min-width: $bp-medium) and (max-width: $bp-large) {
                        width: auto;
                        float: none;
                        padding-left: 0;
                    }

                    .block {
                        .form {
                            .field {
                                .label {
                                    @media screen and (min-width: $bp-medium) and (max-width: $bp-large) {
                                        position: relative;
                                        width: 30px;
                                        overflow: visible;
                                        margin-top: 0;
                                        margin-right: 5px;
                                    }
                                }

                                .control {
                                    border: none;
                                    @media screen and (min-width: $bp-medium) {
                                        padding-bottom: 0;
                                    }

                                    @media screen and (min-width: $bp-large) {
                                        padding: 0 20px;
                                    }

                                    .actions {
                                        @media screen and (min-width: $bp-medium) {
                                            right: 15px;
                                            top: 30px;
                                        }
                                        @media screen and (min-width: $bp-medium) {
                                            right: 0;
                                            top: 0;
                                        }

                                        .action {
                                            display: none;
                                            @media screen and (min-width: $bp-large) {
                                                display: block;
                                                opacity: 1;
                                                padding: 2px 0 0;
                                            }
                                            &::before {
                                                @media screen and (min-width: $bp-medium) {
                                                    display: none;
                                                }
                                            }

                                            .icon-icon-search {
                                                @media screen and (min-width: $bp-medium) {
                                                    position: relative;
                                                }
                                            }
                                            .icon-icon-search-black {
                                                @media screen and (min-width: $bp-large) {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .form.minisearch.active {
                            .field {
                                .control {
                                    .uk-position-relative {
                                        .actions {
                                            .action {
                                                .icon-icon-search {
                                                    @media screen and (min-width: $bp-large) {
                                                        display: none;
                                                    }
                                                }
                                                .icon-icon-search-black {
                                                    @media screen and (min-width: $bp-large) {
                                                        display: inline-block !important;
                                                        position: relative;
                                                        overflow: visible;
                                                        top: -17px;
                                                        right: 30px;
                                                    }
                                                    &:before {
                                                        @media screen and (min-width: $bp-large) {
                                                            display: block !important;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .header-right {
                visibility: hidden;
                display: flex;
                align-items: center;
                flex: 1;
                justify-content: flex-end;
                @media screen and (min-width: $bp-large) {
                    flex: inherit;
                    align-items: center;
                    display: flex;
                    order: 3;
                    margin-right: 20px;
                    visibility: visible;
                    position: relative;
                    top: -20px;
                }

                .store-info,
                .header.links {
                    width: 0;
                    @media screen and (min-width: $bp-large) {
                        width: auto;
                        display: flex;
                        height: auto;
                        min-height: auto;
                        margin-right: 15px;
                    }
                }
                .header.links {
                    @media screen and (min-width: $bp-large) {
                        margin-right: 0;
                    }
                }

                .store-info {
                    .whatssap {
                        @media screen and (min-width: $bp-large) {
                            display: flex;
                        }
                        .icon-icon-whatsapp-relleno {
                            display: none;
                        }
                        &:hover {
                            @media screen and (min-width: $bp-large) {
                                .icon-icon-whatsapp-relleno {
                                    display: block;
                                    position: absolute;
                                    top: 3px;
                                }
                            }
                        }
                    }
                }

                .header {
                    background-color: transparent;
                    margin-left: 5px;
                    @media screen and (min-width: $bp-large) {
                        margin-left: 0;
                    }

                    .link-header_container {
                        @media screen and (min-width: $bp-large) {
                            display: flex;
                            align-items: center;
                        }
                        a {
                            &:hover {
                                text-decoration: none;
                            }
                            &:visited {
                                text-decoration: none;
                            }
                            @media screen and (min-width: $bp-large) {
                                margin-right: 0 !important;
                            }
                        }
                        .cotizacion {
                            position: relative;
                            .icon-icon-cotizacion-relleno {
                                display: none;
                            }
                            &:hover {
                                @media screen and (min-width: $bp-large) {
                                    .icon-icon-cotizacion-relleno {
                                        display: block;
                                    }
                                    .icon-icon-quote {
                                        display: none;
                                    }
                                }
                            }
                        }
                        .uk-margin-small-right {
                            @media screen and (min-width: $bp-large) {
                                display: flex;
                                align-items: center;
                            }
                            .icon-icon-account-relleno {
                                display: none;
                            }
                            &:hover {
                                @media screen and (min-width: $bp-large) {
                                    .icon-icon-account-relleno {
                                        display: block;
                                        position: absolute;
                                        top: 3px;
                                    }
                                }
                            }
                            .account-text {
                                display: none;
                                @media screen and (min-width: $bp-large) {
                                    display: block;
                                    margin: 0 0 0 10px;
                                    @include SourceSansRegular;
                                    font-size: 18px;
                                    color: $white;
                                }
                            }
                        }
                        .uk-inline {
                            @media screen and (min-width: $bp-large) {
                                margin-right: 0;
                            }
                            a {
                                @media screen and (min-width: $bp-large) {
                                    display: flex;
                                    align-items: center;
                                }
                                .icon-icon-account-relleno {
                                    @media screen and (min-width: $bp-large) {
                                        display: none;
                                        position: absolute;
                                        left: 0;
                                        z-index: 99;
                                    }
                                }
                                .account-text {
                                    display: none;
                                    @media screen and (min-width: $bp-large) {
                                        display: block;
                                        margin: 0 0 0 10px;
                                        @include SourceSansRegular;
                                        font-size: 18px;
                                        color: $white;
                                    }
                                }
                            }
                            .uk-dropdown {
                                padding: 0;
                                @media screen and (min-width: $bp-large) {
                                    left: -85px !important;
                                    top: 32px !important;
                                }
                                div {
                                    p {
                                        padding: 10px;
                                        margin: 0;
                                        display: flex;
                                        justify-content: center;
                                        text-transform: uppercase;
                                        @include SourceSansRegular;
                                        font-size: 18px;
                                        &:hover {
                                            background-color: $p-red;
                                            a {
                                                color: $white;
                                            }
                                        }
                                        a {
                                            color: $p-black;
                                        }
                                    }
                                }
                                a {
                                    .flecha-up {
                                        @media screen and (min-width: $bp-large) {
                                            width: 0px;
                                            height: 0px;
                                            border-left: 10px solid transparent;
                                            border-right: 10px solid transparent;
                                            border-bottom: 20px solid $white;
                                            font-size: 0px;
                                            line-height: 0px;
                                            position: absolute;
                                            left: 43%;
                                            top: -12px;
                                            z-index: -1;
                                        }
                                    }
                                }
                            }
                            &:hover,
                            &:visited {
                                .icon-icon-account-relleno {
                                    display: inline-block;
                                }
                            }
                        }
                        .links-account-container {
                            &::before {
                                background: rgba(0, 0, 0, 0.6);
                            }

                            .uk-offcanvas-bar {
                                width: 97%;
                                padding: 0;
                                background-color: $p-black;
                                @media screen and (min-width: $bp-medium) {
                                    width: 50%;
                                }
                                @media screen and (min-width: $bp-large) {
                                    width: 28%;
                                    background-color: $white;
                                }
                                .head {
                                    display: flex;
                                    justify-content: center;
                                    margin-top: 20px;

                                    .title {
                                        p {
                                            @include PlayfairDisplayBlack;
                                            font-size: 22px;
                                            color: $white;
                                            @media screen and (min-width: $bp-large) {
                                                color: $p-red;
                                            }
                                        }
                                    }

                                    .close-btn {
                                        position: absolute;
                                        top: 10px;
                                        right: 10px;
                                        background: transparent;
                                        border: none;
                                    }

                                    .mobile {
                                        @media screen and (min-width: $bp-large) {
                                            display: none;
                                        }
                                    }

                                    .desktop {
                                        @media screen and (max-width: $bp-large) {
                                            display: none;
                                        }
                                        @media screen and (min-width: $bp-large) {
                                            right: auto;
                                            left: 20px;
                                        }
                                    }
                                }

                                .uk-subnav {
                                    margin: 0;

                                    li {
                                        width: 50%;
                                        text-align: center;
                                        padding-left: 0;

                                        margin-bottom: 0;

                                        a {
                                            @include SourceSansRegular;
                                            font-size: 18px;
                                            color: $white;
                                            padding: 10px 5px;
                                            background-color: $p-red;
                                            @media screen and (min-width: $bp-large) {
                                                color: $white;
                                            }
                                        }
                                    }

                                    .uk-active {
                                        border: none;

                                        a {
                                            background-color: $p-black;
                                            border-left: solid 1px #979797;
                                            border-right: solid 1px #979797;
                                            border-top: solid 1px #979797;
                                            @media screen and (min-width: $bp-large) {
                                                color: $p-black;
                                                background-color: $white;
                                            }
                                        }
                                    }
                                }

                                .uk-switcher {
                                    padding: 20px;
                                    margin-top: 0 !important;
                                }
                            }
                        }
                    }
                }

                .store-info {
                    .store-phone {
                        display: none;
                        @include SourceSansRegular;
                        font-size: 18px;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: justify;
                        color: $white;
                        @media screen and (min-width: $bp-large) {
                            display: block;
                            margin: 0;
                        }
                    }
                }

                .minicart-wrapper {
                    margin-top: 0;
                    margin-left: 10px;
                    visibility: visible;
                    @media screen and (min-width: 424px) {
                        margin-right: 10px;
                    }
                    @media screen and (min-width: $bp-medium) {
                        margin-left: 15px;
                        margin-right: 15px;
                        order: 5;
                    }
                }
                .miniquote-wrapper {
                    @media screen and (min-width: $bp-medium) {
                        order: 4;
                        margin-right: 0;
                    }
                }
            }

            .mobile-right-menu {
                visibility: visible;
                @media screen and (min-width: $bp-large) {
                    visibility: hidden;
                    width: 0;
                }

                button {
                    background: none;
                    border: 0;
                    padding: 0;
                    border-radius: 0;
                }

                .icons-container {
                    display: flex;
                    align-items: center;
                    border: none;
                    width: 100%;
                    position: absolute;
                    top: 100%;
                    right: 0;
                    margin: 0 auto;
                    background-color: $p-black;
                    justify-content: center;
                    padding: 10px 10px 20px;

                    .miniquote-wrapper {
                        display: inline-block;
                        margin-right: 10px;
                        margin-left: 5px;
                        @media screen and (min-width: $bp-medium) {
                            margin-right: 15px;
                        }
                        .action.showquote.showcart {
                            display: flex;
                        }
                    }

                    .custom-search {
                        padding: 0px 10px;
                        width: inherit;
                        margin: 0 !important;
                        display: block;

                        .minisearch {
                            width: 100%;

                            .control {
                                left: 0;
                                display: flex;
                                position: initial;
                                padding: 0;
                                background-color: transparent;

                                .input-container {
                                    width: 100%;
                                    margin: 0 auto 0 0;
                                }

                                .actions {
                                    width: 45px;
                                    z-index: 9;
                                    display: flex;

                                    .action {
                                        display: inline-block;
                                    }
                                }
                            }
                        }
                    }
                    .link-header_container {
                        display: flex;
                        .uk-inline {
                            margin: 0 5px;
                            a {
                                display: flex;
                            }
                            .uk-dropdown {
                                padding: 0;
                                left: auto !important;
                                right: 0;
                                top: 40px !important;
                                div {
                                    p {
                                        padding: 10px;
                                        margin: 0;
                                        text-align: center;
                                        text-transform: uppercase;
                                        @include SourceSansRegular;
                                        font-size: 18px;
                                        &:hover {
                                            background-color: $p-red;
                                        }
                                        a {
                                            color: $p-black;
                                            &:hover {
                                                color: $white;
                                            }
                                        }
                                    }
                                }
                                a {
                                    .flecha-up {
                                        width: 0px;
                                        height: 0px;
                                        border-left: 10px solid transparent;
                                        border-right: 10px solid transparent;
                                        border-bottom: 20px solid $white;
                                        font-size: 0px;
                                        line-height: 0px;
                                        position: absolute;
                                        right: 0;
                                        top: -12px;
                                        z-index: -1;
                                    }
                                }
                            }
                        }
                    }

                    .store-info {
                        width: auto;
                        display: block;
                        .whatssap {
                            display: flex;
                            span {
                                margin-right: 5px !important;
                            }
                        }
                    }
                }
            }
            .miniquote-wrapper {
                display: none;
                @media screen and (min-width: $bp-large) {
                    display: inline-block;
                }
            }
        }
    }
}

.whatsapp-tooltip {
    @media screen and (min-width: $bp-large) {
        display: none !important;
    }
    &.uk-active {
        @media screen and (min-width: $bp-large) {
            display: none;
        }
    }

    .uk-tooltip-inner {
        @include SourceSansRegular;
        font-size: 18px;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: justify;
        color: $white;
    }
}

.uk-offcanvas-page {
    .page-wrapper {
        .page-header {
            .header {
                .header-right {
                    visibility: visible;
                }

                .mobile-right-menu {
                    visibility: hidden;
                }
            }
        }
    }
}

//Global Message Notice

.message.global.demo {
    background-color: $p-red;
    .content {
        span {
            @include SourceSansRegular;
            font-size: 16px;
            a {
                text-decoration: underline;
                color: $white;
            }
        }
    }
}
