.page-product-configurable,
.catalog-product-view {
    .magnifier-preview img {
        max-height: none !important;
    }

    .page-wrapper {
        .breadcrumbs {
            display: none;
        }

        .page-main {
            padding: 0 20px;

            .columns {
                .column {
                    .product-info-main {
                        .page-title-wrapper {
                            padding: 0 20px;
                            @media screen and (min-width: $bp-medium) {
                                padding: 0 20px 0 0;
                            }

                            .block-title {
                                .page-title {
                                    margin: 20px 0;
                                    text-align: center;
                                    font-size: 1.6rem;
                                    @media screen and (min-width: $bp-medium) {
                                        text-align: left;
                                    }

                                    span {
                                        @include PlayfairDisplayBlack;
                                        font-size: 24px;
                                        color: $p-black;
                                        line-height: normal;
                                        letter-spacing: normal;
                                        @media screen and (min-width: $bp-large) {
                                            font-size: 30px;
                                        }
                                    }
                                }
                            }
                        }

                        .product-reviews-summary {
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            align-items: center;
                            margin-bottom: 20px;
                            @media screen and (min-width: $bp-large) {
                                flex-direction: row;
                                justify-content: flex-start;
                            }

                            .rating-summary {
                                display: flex;
                                left: 0;

                                .rating-result {
                                    width: 125px;
                                    margin-left: 0;

                                    &::before {
                                        content: "";
                                        width: 100%;
                                        background-image: url("../images/empty-stars-small.svg");
                                        background-repeat: no-repeat;
                                    }

                                    span {
                                        &::before {
                                            position: sticky;
                                            content: "";
                                            background-image: url("../images/filled-stars-small.svg");
                                            background-repeat: no-repeat;
                                        }
                                    }
                                }
                            }

                            .reviews-actions {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                @media screen and (min-width: $bp-large) {
                                    flex-direction: row;
                                }

                                .action {
                                    @include SourceSansRegular;
                                    font-size: 16px;
                                    color: $p-red;
                                    text-decoration: underline;
                                }

                                .view {
                                    @media screen and (min-width: $bp-large) {
                                        margin-right: 20px;
                                        margin-left: 10px;
                                    }
                                }
                            }
                        }

                        .product {
                            margin-bottom: 20px;
                            padding: 0 20px;
                            @media screen and (min-width: $bp-medium) {
                                padding: 0 20px 0 0;
                            }

                            .value {
                                @include SourceSansRegular;
                                font-size: 16px;
                                color: $p-black;
                            }

                            p {
                                @include SourceSansRegular;
                                font-size: 16px;
                                font-stretch: normal;
                                line-height: normal;
                                letter-spacing: normal;
                                color: $p-black;
                            }
                        }

                        .product-info-price {
                            .price-box {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                flex-direction: row-reverse;
                                @media screen and (min-width: $bp-large) {
                                    justify-content: flex-end;
                                }

                                .text-old-price {
                                    display: none;
                                }

                                .normal-price {
                                    .text-old-price {
                                        @include SourceSansBold;
                                        font-size: 16px;
                                        width: 100%;
                                        text-align: left;
                                        color: $p-red;

                                        p {
                                            margin-bottom: 0;
                                        }
                                    }

                                    .price-container {
                                        display: flex;
                                        flex-direction: column;
                                        line-height: 1.4;
                                        margin-bottom: 15px;

                                        .price-label {
                                            @include SourceSansBold;
                                            font-size: 16px;
                                            color: $p-red;
                                        }

                                        .price-wrapper {
                                            @include PlayfairDisplayBlack;
                                            font-size: 30px;
                                            color: $p-red;
                                        }
                                    }
                                }

                                .price-container {
                                    display: flex;
                                    flex-direction: column;
                                    line-height: 1.4;

                                    .price-label {
                                        @include SourceSansBold;
                                        font-size: 16px;
                                        color: $p-red;
                                    }

                                    .price-wrapper {
                                        @include PlayfairDisplayBlack;
                                        font-size: 30px;
                                        color: $p-red;
                                        text-align: left;
                                    }
                                }

                                .old-price {
                                    color: $p-black;
                                    display: flex;
                                    justify-content: center;
                                    flex-direction: column;
                                    align-items: center;
                                    text-align: center;
                                    text-decoration: none;

                                    .text-old-price {
                                        @include SourceSansBold;
                                        font-size: 16px;
                                        width: 100%;
                                        text-align: left;
                                        display: block;

                                        p {
                                            margin-bottom: 0;
                                        }
                                    }

                                    .text {
                                        @include SourceSansBold;
                                        font-size: 16px;
                                        margin-bottom: 0;
                                        width: 100%;
                                        text-align: left;
                                    }

                                    .price-container {
                                        text-decoration: line-through;

                                        .price-label {
                                            color: $p-black;
                                        }

                                        .price-wrapper {
                                            color: $p-black;
                                            text-align: left;
                                        }
                                    }
                                }

                                .special-price {
                                    display: flex;
                                    justify-content: center;
                                    flex-direction: column;
                                    align-items: center;
                                    text-align: center;
                                    @media screen and (min-width: $bp-large) {
                                        margin-right: 15px;
                                    }

                                    .text-special-price {
                                        @include SourceSansBold;
                                        font-size: 16px;
                                        color: $p-red;

                                        p {
                                            margin-bottom: 0;
                                        }
                                    }

                                    .text {
                                        @include SourceSansBold;
                                        font-size: 16px;
                                        color: $p-red;
                                        margin-bottom: 0;
                                    }

                                    .price-container {
                                        text-align: left;
                                        width: 100%;

                                        .price-wrapper {
                                            color: $p-red;
                                        }
                                    }
                                }
                            }

                            .info-container {
                                background: $p-black;
                                text-align: center;
                                padding: 10px 20px;
                                @media screen and (min-width: $bp-large) {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                }

                                .text {
                                    @include SourceSansRegular;
                                    font-size: 16px;
                                    color: $white;
                                    margin: 0;
                                }

                                .mobile {
                                    @media screen and (min-width: $bp-large) {
                                        display: none;
                                    }
                                }

                                .desktop {
                                    @media screen and (max-width: $bp-medium) {
                                        display: none;
                                    }
                                }

                                .order {
                                    @media screen and (min-width: $bp-large) {
                                        margin-right: 5px;
                                    }
                                }
                            }

                            .desktop {
                                display: none;
                                @media screen and (min-width: $bp-large) {
                                    display: block;
                                    margin-bottom: 20px;
                                }
                            }

                            .mobile {
                                margin-bottom: 20px;
                                @media screen and (min-width: $bp-large) {
                                    display: none;
                                }
                            }
                        }

                        aplazo-placement {
                            font-family: "Playfair Display";
                        }

                        .product-store-credit-discount {
                            @include SourceSansRegular;
                            font-size: 16px;
                            color: $p-black;
                        }

                        .product-addto-links {
                            display: none;
                        }

                        .links-usp {
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            grid-template-rows: 1fr 1fr;
                            gap: 20px 10px;
                            padding: 20px;
                            border: 1px solid $hover;
                            margin-bottom: 20px;
                            @media screen and (min-width: $bp-large) {
                                padding: 20px 40px;
                            }

                            .content-usp {
                                display: grid;
                                grid-template-columns: 35% 1fr;
                                align-items: center;

                                .icon {
                                    text-align: center;
                                    font-size: 7px;
                                }

                                .text {
                                    margin: 0;
                                    @include SourceSansRegular;
                                    font-size: 14px;
                                    color: $hover;
                                    margin-left: 5px;
                                    text-align: left;
                                    @media screen and (min-width: $bp-large) {
                                        font-size: 16px;
                                    }
                                }
                            }
                        }

                        .product-add-form {
                            form {
                                .product-options-wrapper {
                                    .fieldset {
                                        .swatch-opt {
                                            .swatch-attribute {
                                                display: grid;
                                                grid-template-columns: auto 1fr;
                                                grid-template-rows: 1fr auto;
                                                gap: 0px 0px;

                                                .swatch-attribute-label {
                                                    @include SourceSansBold;
                                                    font-size: 16px;
                                                    color: $p-black;
                                                    grid-area: 1 / 1 / 2 / 2;
                                                }

                                                .swatch-attribute-selected-option {
                                                    display: none;
                                                }

                                                .swatch-attribute-options {
                                                    margin: 0 0 0 10px;
                                                    grid-area: 1 / 2 / 2 / 3;

                                                    .swatch-option {
                                                        @include SourceSansRegular;
                                                        font-size: 16px;
                                                        color: $p-black;
                                                        outline: none;
                                                        overflow: visible;
                                                        border: 1px solid
                                                            $p-gray;
                                                        line-height: initial;
                                                        height: auto;
                                                        width: auto;
                                                        box-shadow: none;
                                                        background-color: $white;
                                                        padding: 5px 10px;
                                                        margin-bottom: 10px;
                                                    }

                                                    .selected {
                                                        @include SourceSansBold;
                                                        color: $white;
                                                        border: solid 1px
                                                            #93002a;
                                                        background-color: #93002a !important;
                                                    }
                                                }

                                                .mage-error {
                                                    grid-area: 2 / 1 / 3 / 3;
                                                    display: block;
                                                    font-size: 14px;
                                                    text-align: center;
                                                    font-weight: normal;
                                                    width: 100%;
                                                    padding: 5px;
                                                    border: 1px dashed red;
                                                    @include SourceSansRegular;
                                                    box-sizing: border-box;
                                                    margin-bottom: 15px;
                                                }
                                            }
                                        }
                                    }
                                }

                                .box-tocart {
                                    @media screen and (min-width: $bp-medium) {
                                        width: 100%;
                                    }

                                    .fieldset {
                                        display: flex;
                                        @media screen and (min-width: $bp-medium) {
                                            margin-bottom: 0;
                                        }

                                        .field {
                                            padding-right: 20px;
                                            margin-bottom: 0;

                                            .label {
                                                display: none;
                                            }

                                            .control {
                                                padding: 5px 8px;
                                                border: solid 1px $p-gray;
                                                display: flex;
                                                align-items: center;
                                                height: 45px;
                                            }

                                            .input-text {
                                                height: auto;
                                                border: none;
                                                width: 100%;
                                                min-width: 30px;
                                                max-width: 60px;
                                                margin: 0 5px;
                                                padding: 0;
                                                @include SourceSansRegular;
                                                font-size: 20px;
                                                color: $p-black;
                                            }
                                        }

                                        .actions {
                                            width: 100%;
                                            @media screen and (min-width: $bp-medium) {
                                                padding: 0;
                                            }

                                            .action,
                                            .quotation {
                                                display: flex;
                                                justify-content: center;
                                                border: 1px solid $p-red;
                                                background-color: $p-red;
                                                height: 45px;
                                                border-radius: 0;
                                                line-height: 1.6rem;
                                                padding: 10px 20px;
                                                @media screen and (min-width: $bp-medium) {
                                                    width: 100%;
                                                }

                                                span {
                                                    @include SourceSansRegular;
                                                    font-size: 20px;
                                                    color: $white;
                                                    text-transform: uppercase;
                                                    @media screen and (min-width: $bp-medium) {
                                                        font-size: 14px;
                                                    }
                                                    @media screen and (min-width: $bp-large) {
                                                        font-size: 20px;
                                                    }
                                                }
                                            }

                                            .quotation {
                                                border: 1px solid $p-black !important;
                                                background-color: $p-black !important;
                                                margin-top: 20px;
                                                width: 100%;
                                                @media screen and (min-width: $bp-large) {
                                                    margin: 20px 0;
                                                }
                                            }

                                            .links-container-desktop {
                                                display: flex;
                                                justify-content: space-between;
                                                margin-bottom: 20px;
                                                padding: 0 20px;
                                                display: none;
                                                @media screen and (min-width: $bp-medium) {
                                                    padding: 0 20px 0 0;
                                                }
                                                @media screen and (min-width: $bp-large) {
                                                    padding: 0;
                                                    display: flex;
                                                    justify-content: space-around;
                                                    margin-bottom: 0;
                                                    margin-top: 20px;
                                                }

                                                .wishlist,
                                                .share {
                                                    display: flex;
                                                    align-items: center;

                                                    .action {
                                                        margin-left: 10px;
                                                        padding: 0;
                                                        background-color: transparent;
                                                        @include SourceSansRegular;
                                                        font-size: 16px;
                                                        border: none;

                                                        span {
                                                            color: $p-red;
                                                            display: flex;
                                                            align-items: center;
                                                            text-transform: lowercase;
                                                        }

                                                        &:hover {
                                                            text-decoration: none;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .links-container-mobile {
                                    display: flex;
                                    justify-content: space-between;
                                    margin-bottom: 20px;
                                    padding: 0 20px;
                                    @media screen and (min-width: $bp-medium) {
                                        padding: 0 20px 0 0;
                                    }
                                    @media screen and (min-width: $bp-large) {
                                        padding: 0 20px 0 0;
                                        display: none;
                                    }

                                    .mp_social_share_inline_under_cart {
                                        display: none;
                                    }

                                    .wishlist,
                                    .share {
                                        display: flex;
                                        align-items: center;

                                        .action {
                                            margin-left: 10px;
                                            padding: 0;
                                            background-color: transparent;
                                            @include SourceSansRegular;
                                            font-size: 16px;
                                            color: $p-red;
                                            border: none;
                                        }
                                    }
                                }

                                .product-options-bottom {
                                    .box-tocart {
                                        .fieldset {
                                            display: flex;
                                            @media screen and (min-width: $bp-large) {
                                                margin-bottom: 0;
                                            }

                                            .field {
                                                padding-right: 20px;
                                                margin-bottom: 0;

                                                .label {
                                                    display: none;
                                                }

                                                .control {
                                                    padding: 5px 8px;
                                                    border: solid 1px $p-gray;
                                                    display: flex;
                                                    align-items: center;
                                                }

                                                .input-text {
                                                    height: auto;
                                                    border: none;
                                                    width: 30px;
                                                    @include SourceSansRegular;
                                                    font-size: 20px;
                                                    color: $p-black;
                                                }
                                            }

                                            .actions {
                                                width: 100%;

                                                .action {
                                                    display: flex;
                                                    justify-content: center;
                                                    border: 1px solid $p-red;
                                                    background-color: $p-red;
                                                    height: 45px;
                                                    border-radius: 0;
                                                    line-height: 1.6rem;
                                                    padding: 10px 20px;

                                                    span {
                                                        @include SourceSansRegular;
                                                        font-size: 20px;
                                                        color: $white;
                                                        text-transform: uppercase;
                                                        @media screen and (min-width: $bp-medium) {
                                                            font-size: 14px;
                                                        }
                                                        @media screen and (min-width: $bp-large) {
                                                            font-size: 20px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .links-container {
                                        display: flex;
                                        justify-content: space-between;
                                        margin-bottom: 20px;
                                        padding: 0 20px;
                                        @media screen and (min-width: $bp-medium) {
                                            padding: 0 20px 0 0;
                                        }

                                        div {
                                            display: flex;
                                            align-items: center;

                                            .action {
                                                margin-left: 10px;
                                                padding: 0;
                                                background-color: transparent;
                                                @include SourceSansRegular;
                                                font-size: 16px;
                                                color: $p-red;
                                                border: none;
                                            }
                                        }
                                    }

                                    .links-usp {
                                        display: grid;
                                        grid-template-columns: 1fr 1fr;
                                        grid-template-rows: 1fr 1fr;
                                        gap: 20px 10px;
                                        padding: 20px;
                                        border: 1px solid $hover;
                                        margin-bottom: 20px;
                                        @media screen and (min-width: $bp-large) {
                                            padding: 20px 40px;
                                        }

                                        .content-usp {
                                            display: grid;
                                            grid-template-columns: 35% 1fr;
                                            align-items: center;

                                            .icon {
                                                text-align: center;
                                                font-size: 7px;
                                            }

                                            .text {
                                                margin: 0;
                                                @include SourceSansRegular;
                                                font-size: 14px;
                                                color: $hover;
                                                margin-left: 5px;
                                                @media screen and (min-width: $bp-large) {
                                                    font-size: 16px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .product {
                        .gallery-placeholder {
                            .fotorama-item {
                                @media screen and (min-width: $bp-medium) {
                                    margin-top: 20px;
                                }

                                .fotorama__wrap {
                                    .fotorama__stage {
                                        @media screen and (min-width: $bp-medium) {
                                            left: 0 !important;
                                        }
                                        @media only screen and (max-width: $bp-large) and (min-width: $bp-medium) {
                                            height: 374px !important;
                                        }
                                        @media screen and (min-width: $bp-large) {
                                            max-height: 618px;
                                        }

                                        .fotorama__arr--next,
                                        .fotorama__arr--prev {
                                            .fotorama__arr__arr {
                                                background: url(../images/icons/icon-arrow.svg);
                                                width: 30px;
                                                height: 50px;
                                                background-repeat: no-repeat;
                                                background-position: center;
                                                transform: rotate(0deg);
                                            }
                                        }

                                        .fotorama__arr--prev {
                                            .fotorama__arr__arr {
                                                transform: rotate(-180deg);
                                            }
                                        }

                                        .fotorama__stage__shaft {
                                            .fotorama__stage__frame {
                                                .fotorama__img {
                                                    width: 100%;
                                                    @media screen and (min-width: $bp-medium) {
                                                        transform: translate3d(
                                                            -50%,
                                                            0%,
                                                            0
                                                        );
                                                        top: 0;
                                                        height: 100%;
                                                        width: auto !important;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .fotorama__nav-wrap {
                                        @media screen and (min-width: $bp-medium) {
                                            right: 0;
                                            left: inherit;
                                        }

                                        .fotorama__nav {
                                            .fotorama__nav__shaft {
                                                .fotorama__thumb-border {
                                                    border: 1px solid $p-gray;
                                                    margin-top: 0;
                                                }

                                                .fotorama__nav__frame {
                                                    opacity: 0.4;
                                                }

                                                .fotorama__active {
                                                    opacity: 1;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.description {
                            .description-container {
                                display: flex;
                                flex-direction: column;
                                border: solid 1px $p-gray;
                                padding: 20px 30px;
                                margin-bottom: 30px;
                                width: 100%;
                                box-sizing: border-box;
                                @media screen and (min-width: $bp-medium) {
                                    flex-direction: initial;
                                }

                                .attribute-title {
                                    display: block;
                                    @include PlayfairDisplayBlack;
                                    font-size: 24px;
                                    font-stretch: normal;
                                    line-height: normal;
                                    letter-spacing: normal;
                                    color: $p-black;
                                    margin-bottom: 20px;
                                }

                                .overview {
                                    width: 100%;
                                    @media screen and (min-width: $bp-medium) {
                                        width: 50%;
                                    }
                                }

                                .value {
                                    margin-right: 30px;
                                }

                                p,
                                .label,
                                .data {
                                    @include SourceSansRegular;
                                    font-size: 16px;
                                    font-stretch: normal;
                                    line-height: normal;
                                    letter-spacing: normal;
                                    color: $p-black;
                                    padding-top: 0;
                                }
                            }
                        }

                        &.reviews {
                            display: flex;
                            flex-direction: column-reverse;
                            border: solid 1px $p-gray;
                            padding: 20px 30px;
                            margin-bottom: 30px;
                            width: 100%;
                            box-sizing: border-box;
                            @media screen and (min-width: $bp-medium) {
                                flex-direction: initial;
                            }

                            .review-list {
                                padding-top: 0;
                                @media screen and (min-width: $bp-medium) {
                                    padding-top: 50px;
                                    width: 50%;
                                }

                                .block-content {
                                    .review-items {
                                        .review-item {
                                            padding-top: 0;
                                            border-bottom: 0;
                                            padding-bottom: 10px;

                                            .review-title {
                                                @include SourceSansRegular;
                                                font-size: 20px;
                                                font-stretch: normal;
                                                line-height: normal;
                                                letter-spacing: normal;
                                                color: $p-black;
                                                margin-bottom: 10px;
                                                text-transform: capitalize;
                                            }

                                            .review-top {
                                                display: flex;
                                                align-items: center;

                                                .review-ratings {
                                                    margin-right: 20px;
                                                    margin-bottom: 0;

                                                    .rating-summary {
                                                        .rating-result {
                                                            width: 125px;
                                                            margin-left: 0;

                                                            &::before {
                                                                content: "";
                                                                width: 100%;
                                                                background-image: url("../images/empty-stars-small.svg");
                                                                background-repeat: no-repeat;
                                                            }

                                                            span {
                                                                &::before {
                                                                    position: sticky;
                                                                    content: "";
                                                                    background-image: url("../images/filled-stars-small.svg");
                                                                    background-repeat: no-repeat;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                .review-details {
                                                    display: flex;
                                                    @media screen and (min-width: $bp-medium) {
                                                        margin-left: 0;
                                                    }

                                                    p {
                                                        @include SourceSansBold;
                                                        font-size: 16px;
                                                        font-stretch: normal;
                                                        line-height: normal;
                                                        letter-spacing: normal;
                                                        color: $p-black;
                                                        margin: 0;
                                                        text-transform: capitalize;
                                                    }

                                                    .review-date {
                                                        margin-left: 5px;
                                                    }
                                                }
                                            }

                                            .review-content {
                                                @include SourceSansRegular;
                                                font-size: 16px;
                                                font-stretch: normal;
                                                line-height: normal;
                                                letter-spacing: normal;
                                                color: $p-black;
                                                text-transform: initial;
                                            }

                                            &:last-of-type {
                                                padding-bottom: 0;
                                            }
                                        }
                                    }

                                    .toolbar {
                                        border-top: 1px solid $p-gray;
                                        @media screen and (min-width: $bp-large) {
                                            display: none;
                                        }
                                    }
                                }
                            }

                            .review-add {
                                width: 100%;
                                @media screen and (min-width: $bp-medium) {
                                    width: 50%;
                                }

                                .attribute-title {
                                    display: block;
                                    @include PlayfairDisplayBlack;
                                    font-size: 24px;
                                    font-stretch: normal;
                                    line-height: normal;
                                    letter-spacing: normal;
                                    color: $p-black;
                                    margin-bottom: 20px;
                                }

                                .block-title {
                                    display: block;

                                    span {
                                        @include SourceSansRegular;
                                        font-size: 20px;
                                        font-stretch: normal;
                                        line-height: normal;
                                        letter-spacing: normal;
                                        color: $p-black;
                                    }
                                }

                                .block-content {
                                    form {
                                        max-width: 100%;
                                        margin-bottom: 20px;

                                        .review-field-ratings {
                                            .label {
                                                display: none;
                                            }

                                            .review-control-vote {
                                                display: inline-block !important;
                                                position: relative;
                                                margin-bottom: 0 !important;

                                                &:before {
                                                    display: none;
                                                }

                                                label[class^="rating-"] {
                                                    display: inline-block;
                                                    cursor: pointer;
                                                    padding: 0;
                                                    position: initial;

                                                    &:before {
                                                        display: none;
                                                    }

                                                    .rating-star {
                                                        display: inline-block;
                                                        font-size: 19px;
                                                        height: auto;
                                                        width: auto;
                                                        position: inherit;
                                                        overflow: initial;
                                                        clip: inherit;

                                                        &:before {
                                                            content: "";
                                                            background-image: url("../images/emptyStar-big.svg");
                                                            width: 30px;
                                                            height: 30px;
                                                            background-repeat: no-repeat;
                                                            background-size: contain;
                                                            background-position: center;
                                                            display: inline-block;
                                                        }
                                                    }

                                                    &.fill {
                                                        .rating-star {
                                                            &:before {
                                                                content: "";
                                                                background-image: url("../images/filledStar-big.svg");
                                                                width: 30px;
                                                                height: 30px;
                                                                background-repeat: no-repeat;
                                                                background-size: contain;
                                                                display: inline-block;
                                                                background-position: center;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .form-inputs {
                                            display: flex;
                                            flex-wrap: wrap;
                                            justify-content: space-between;
                                            margin: 0;
                                            @media screen and (min-width: $bp-medium) {
                                                margin-right: 30px;
                                            }

                                            .review-field-nickname,
                                            .review-field-summary {
                                                margin-bottom: 10px;
                                                width: 49%;

                                                .control {
                                                    .input-text {
                                                        border: 1px solid
                                                            $p-black;
                                                        height: 45px;

                                                        &::placeholder {
                                                            @include SourceSansRegular;
                                                            font-size: 16px;
                                                            color: $p-black;
                                                        }

                                                        &:focus {
                                                            box-shadow: none;
                                                        }
                                                    }
                                                }
                                            }

                                            .review-field-text {
                                                width: 100%;

                                                .control {
                                                    textarea {
                                                        border: 1px solid
                                                            $p-black;
                                                        height: 150px;
                                                        resize: none;

                                                        &::placeholder {
                                                            @include SourceSansRegular;
                                                            font-size: 16px;
                                                            color: $p-black;
                                                        }

                                                        &:focus {
                                                            box-shadow: none;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .actions-toolbar {
                                            margin: 0;

                                            .primary {
                                                max-width: 200px;
                                                width: 100%;

                                                .action {
                                                    height: 45px;
                                                    background-color: $p-black;
                                                    width: 100%;

                                                    span {
                                                        @include SourceSansRegular;
                                                        font-size: 20px;
                                                        text-transform: uppercase;
                                                        color: $white;
                                                    }

                                                    &:hover {
                                                        background-color: $white;

                                                        span {
                                                            color: $p-black;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .detailed {
                        .items {
                            border: 1px solid $p-gray;

                            .title {
                                .data {
                                    @include PlayfairDisplayBlack;
                                    font-size: 24px;
                                    color: $p-black;
                                    border: none;

                                    .counter {
                                        display: none;
                                    }
                                }
                            }

                            .content {
                                border: none;

                                .block {
                                    .block-title {
                                        display: block;

                                        strong {
                                            @include SourceSansRegular;
                                            font-size: 20px;
                                            color: $p-black;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .block.related,
                    .block.upsell {
                        padding: 0;

                        .block-title {
                            strong {
                                font-size: 24px !important;
                                @media screen and (min-width: $bp-large) {
                                    font-size: 30px !important;
                                }
                            }
                        }

                        .block-content {
                            .uk-position-relative {
                                .uk-slider-items {
                                    padding-bottom: 15px;
                                    @media screen and (min-width: $bp-medium) {
                                        padding-bottom: 0;
                                    }
                                    /*                   @media screen and (max-width: 375px) and (min-width: 360px) {
                                      transform: translate3d(262px,0,0) !important;
                                    }
                                    @media screen and (max-width: 424px) and (min-width: 376px) {
                                      transform: translate3d(310px,0,0) !important;
                                    }
                                    @media screen and (max-width: 359px) and (min-width: 320px) {
                                      transform: translate3d(232px,0,0) !important;
                                    } */

                                    .item {
                                        .product-item-info {
                                            .product {
                                                .product {
                                                    height: 45px;
                                                    @media screen and (min-width: $bp-large) {
                                                        height: 55px;
                                                    }

                                                    .product-item-link {
                                                        @media screen and (min-width: $bp-large) {
                                                            font-size: 20px;
                                                        }
                                                    }
                                                }

                                                .price-box {
                                                    .price-container {
                                                        .price-wrapper {
                                                            span {
                                                                @media screen and (min-width: $bp-large) {
                                                                    font-size: 24px;
                                                                }
                                                            }
                                                        }
                                                    }

                                                    .old-price {
                                                        .price-container {
                                                            .price-wrapper {
                                                                span {
                                                                    @media screen and (min-width: $bp-large) {
                                                                        font-size: 24px;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                .actions {
                                                    .actions-primary {
                                                        .action {
                                                            span {
                                                                @media screen and (min-width: $bp-large) {
                                                                    font-size: 20px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .arrows {
                                    .uk-icon {
                                        background: url(../images/icons/icon-arrow-izq-oscura.svg);
                                        content: "";
                                        width: 50px;
                                        height: 55px;
                                        background-repeat: no-repeat;
                                        padding: 0;
                                        margin: 0;
                                        background-position: center;
                                        background-color: rgba(
                                            255,
                                            255,
                                            255,
                                            0.25
                                        );
                                        border-radius: 0;

                                        &:hover {
                                            background: url(../images/icons/icon-arrow.svg);
                                            content: "";
                                            width: 50px;
                                            height: 55px;
                                            background-repeat: no-repeat;
                                            padding: 0;
                                            margin: 0;
                                            background-position: center;
                                            background-color: $white;
                                            border-radius: 0;
                                        }

                                        svg {
                                            display: none;
                                        }
                                    }

                                    .uk-position-center-right {
                                        transform: rotate(-180deg);
                                        top: 42%;
                                        right: -15px;
                                        @media screen and (min-width: $bp-large) {
                                            top: 40%;
                                        }
                                        @media screen and (min-width: $bp-xlarge) {
                                            top: 45%;
                                        }
                                        @media screen and (min-width: $bp-xxlarge) {
                                            top: 50%;
                                        }

                                        &:hover {
                                            transform: rotate(0deg);
                                        }
                                    }

                                    .uk-position-center-left {
                                        transform: translateY(0);
                                        top: 42%;
                                        left: -15px;
                                        @media screen and (min-width: $bp-large) {
                                            top: 40%;
                                        }
                                        @media screen and (min-width: $bp-xlarge) {
                                            top: 45%;
                                        }
                                        @media screen and (min-width: $bp-xxlarge) {
                                            top: 50%;
                                        }

                                        &:hover {
                                            transform: rotate(-180deg);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .block.upsell {
                        margin-top: 40px;

                        .block-content {
                            .arrows {
                                .uk-position-center-right {
                                    @media screen and (min-width: $bp-xxlarge) {
                                        top: 40%;
                                    }
                                }

                                .uk-position-center-left {
                                    transform: translateY(0);
                                    top: 42%;
                                    left: -15px;
                                    @media screen and (min-width: $bp-large) {
                                        top: 40%;
                                    }
                                    @media screen and (min-width: $bp-xlarge) {
                                        top: 45%;
                                    }
                                    @media screen and (min-width: $bp-xxlarge) {
                                        top: 50%;
                                    }

                                    &:hover {
                                        transform: rotate(-180deg);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .swatch-option-tooltip {
        display: none !important;
    }
}

//page-product-configurable

.page-product-configurable {
    .page-wrapper {
        .page-main {
            .columns {
                .column {
                    .product-info-main {
                        .product-info-price {
                            .price-box {
                                flex-direction: row;
                                @media screen and (min-width: $bp-large) {
                                    justify-content: flex-start;
                                }

                                .normal-price {
                                    width: auto;
                                    @media screen and (min-width: $bp-large) {
                                        margin-right: 15px;
                                    }

                                    .text-special-price {
                                        @include SourceSansBold;
                                        font-size: 16px;
                                        color: $p-red;

                                        p {
                                            margin-bottom: 0;
                                        }
                                    }

                                    .price-container {
                                        .price-wrapper {
                                            @media screen and (min-width: $bp-large) {
                                                text-align: left;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
