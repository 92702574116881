@charset "UTF-8";
/* Mixin de ejemplo para fuentes, se manda a llamar @include regular; donde necesites usar el mixin, para no repetir código
@mixin regular {
  font-family: 'Open Sans';
  font-weight: normal;
  font-style: normal;
}*/
/*Meter resets de estilos de librerias (UIKIT) aquí*/
/* Mixin de ejemplo para botón con animación, se manda a llamar @include btn-black; donde necesites usar el mixin, para no repetir código*/
@media screen and (min-width: 979px) {
  .block-search {
    position: absolute !important;
    top: 0;
    width: 350px !important;
    left: 0 !important;
  }
}
.block-search .minisearch .label.active:before {
  display: none;
}
.block-search .minisearch .label.active .icon-icon-close {
  display: block;
}
.block-search .minisearch .label.active .icon-icon-search {
  display: none;
}
.block-search .minisearch .label:before {
  display: none;
}
.block-search .minisearch .label .icon-icon-close {
  display: none;
}
.block-search .minisearch .label .icon-icon-search {
  display: block;
}
.block-search .minisearch .control {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: none;
  width: 100%;
  position: absolute;
  top: 100%;
  left: -999999999px;
  right: 0;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
}
@media screen and (min-width: 979px) {
  .block-search .minisearch .control {
    left: 0;
    background: none;
  }
}
.block-search .minisearch .control .input-container {
  width: 100%;
  height: 45px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}
.block-search .minisearch .control .input-container ::placeholder {
  color: #4a4a4a;
}
.block-search .minisearch .control .input-container:focus {
  box-shadow: none;
  outline: none;
}
@media screen and (min-width: 979px) {
  .block-search .minisearch .control .input-container ::placeholder {
    color: #fff;
  }
}
.block-search .minisearch .control input {
  height: 45px;
  background: #fff;
  border: 0;
  left: 0;
  top: 0;
  margin: 0;
  z-index: 9;
  color: #000000;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
}
@media screen and (min-width: 979px) {
  .block-search .minisearch .control input {
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid #fff;
    color: #fff;
  }
}
.block-search .minisearch .control input:focus {
  color: #000000;
}
@media screen and (min-width: 979px) {
  .block-search .minisearch .control input:focus {
    border: 1px solid #fff;
    background: #fff;
    box-shadow: none;
  }
}
.block-search .minisearch .control .actions {
  position: absolute;
  z-index: 2;
  right: 0;
  height: 45px;
  top: 0;
}
.block-search .minisearch .control .actions .action {
  border: 0;
  background: none;
  border-radius: 0;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
  opacity: 1;
}
.block-search .minisearch .control .actions .action::before {
  display: none;
}
.block-search .minisearch .control .actions .action .search-icon:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 35px;
  line-height: 33px;
  color: #4a4a4a;
  content: "\e615";
  font-family: "icons-blank-theme";
  vertical-align: middle;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  text-align: center;
}
.block-search .minisearch .control .actions .action > span {
  border: 0;
  clip: initial;
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: initial;
  width: auto;
}
.block-search .minisearch .control .actions .action > span.icon-icon-search-black {
  display: none;
}
.block-search .minisearch.active .control {
  left: 0;
}
.block-search .minisearch.active .control .actions .action {
  display: block !important;
}
.block-search .minisearch.active .control .actions .action > span.icon-icon-search {
  display: none;
}
.block-search .minisearch.active .control .actions .action > span.icon-icon-search-black {
  display: block;
}
.block-search .minisearch.active .field .control .uk-position-relative .actions button .icon-icon-search-black {
  display: block !important;
  position: relative;
}
.block-search .minisearch.active .field .control .uk-position-relative .actions button .icon-icon-search {
  display: none !important;
}

@media screen and (min-width: 979px) {
  .catalogsearch-result-index .page-wrapper .sections {
    background-color: transparent;
  }
}
.catalogsearch-result-index .page-wrapper .page-title-wrapper {
  position: relative;
}
.catalogsearch-result-index .page-wrapper .page-title-wrapper .block-title {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 60px;
  top: auto;
  left: 0;
  right: 0;
}
@media screen and (min-width: 768px) {
  .catalogsearch-result-index .page-wrapper .page-title-wrapper .block-title {
    margin-top: 40px;
  }
}
@media screen and (min-width: 1366px) {
  .catalogsearch-result-index .page-wrapper .page-title-wrapper .block-title {
    margin-top: 60px;
  }
}
@media screen and (min-width: 1600px) {
  .catalogsearch-result-index .page-wrapper .page-title-wrapper .block-title {
    margin-top: 90px;
  }
}
@media screen and (min-width: 1920px) {
  .catalogsearch-result-index .page-wrapper .page-title-wrapper .block-title {
    margin-top: 8%;
  }
}
.catalogsearch-result-index .page-wrapper .page-title-wrapper .block-title .page-title {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 20px;
  color: #fff;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
  margin: 0;
}
@media screen and (min-width: 768px) {
  .catalogsearch-result-index .page-wrapper .page-title-wrapper .block-title .page-title {
    font-size: 36px;
  }
}
@media screen and (min-width: 979px) {
  .catalogsearch-result-index .page-wrapper .page-title-wrapper .block-title .page-title {
    font-size: 50px;
  }
}
.catalogsearch-result-index .page-wrapper .page-title-wrapper .block-title .page-title span::before {
  content: "~ ";
}
.catalogsearch-result-index .page-wrapper .page-title-wrapper .block-title .page-title span::after {
  content: " ~";
}
.catalogsearch-result-index .page-wrapper .banner-container {
  display: flex;
  justify-content: center;
  margin-top: -70px;
  height: 223px;
  overflow: hidden;
}
@media screen and (min-width: 979px) {
  .catalogsearch-result-index .page-wrapper .banner-container {
    margin-top: -120px;
    height: auto;
  }
}
@media screen and (min-width: 1199px) {
  .catalogsearch-result-index .page-wrapper .banner-container {
    margin-top: -170px;
  }
}
@media screen and (min-width: 1366px) {
  .catalogsearch-result-index .page-wrapper .banner-container {
    margin-top: -170px;
  }
}
.catalogsearch-result-index .page-wrapper .banner-container img {
  width: auto;
  object-fit: contain;
  height: 100%;
  max-width: none;
}
@media screen and (min-width: 979px) {
  .catalogsearch-result-index .page-wrapper .banner-container img {
    width: 100%;
  }
}
.catalogsearch-result-index .page-wrapper .breadcrumbs {
  display: none;
}
.catalogsearch-result-index .page-wrapper .page-header {
  background-color: transparent !important;
}
.catalogsearch-result-index .page-wrapper .page-header .header .header-right .mobile-right-menu #right-menu {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
.catalogsearch-result-index .page-wrapper .page-main {
  margin-top: 0 !important;
}
.catalogsearch-result-index .page-wrapper .page-main .columns {
  margin-top: 20px;
}
.catalogsearch-result-index .page-wrapper .page-main .columns .column .search .toolbar {
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .catalogsearch-result-index .page-wrapper .page-main .columns .column .search .toolbar {
    padding: 0;
    justify-content: space-between;
  }
}
@media screen and (min-width: 768px) {
  .catalogsearch-result-index .page-wrapper .page-main .columns .column .search .toolbar .toolbar-amount {
    padding: 0;
  }
}
.catalogsearch-result-index .page-wrapper .page-main .columns .column .search .toolbar .limiter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}
@media screen and (min-width: 768px) {
  .catalogsearch-result-index .page-wrapper .page-main .columns .column .search .toolbar .limiter {
    margin: 0;
  }
}
@media screen and (min-width: 768px) {
  .catalogsearch-result-index .page-wrapper .page-main .columns .column .search .toolbar .toolbar-sorter {
    padding: 0;
    text-align: right;
    width: 100%;
    justify-content: flex-end;
  }
}
.catalogsearch-result-index .page-wrapper .page-main .columns .column .search .block {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0px;
}
.catalogsearch-result-index .page-wrapper .page-main .columns .column .search .block .title {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  margin-right: 10px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .catalogsearch-result-index .page-wrapper .page-main .columns .column .search .block .title {
    width: auto;
    margin-bottom: 0;
    margin-right: 5px;
  }
}
@media screen and (min-width: 979px) {
  .catalogsearch-result-index .page-wrapper .page-main .columns .column .search .block .title {
    font-size: 20px;
  }
}
.catalogsearch-result-index .page-wrapper .page-main .columns .column .search .block .item {
  display: flex;
  margin: 0 5px;
}
.catalogsearch-result-index .page-wrapper .page-main .columns .column .search .block .item a {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  color: #fff;
  padding: 5px 10px;
  background-color: #b88f01;
}
@media screen and (min-width: 979px) {
  .catalogsearch-result-index .page-wrapper .page-main .columns .column .search .block .item a {
    font-size: 14px;
  }
}
.catalogsearch-result-index .page-wrapper .page-main .columns .column .search div:nth-child(1) {
  margin-top: 0;
}
@media screen and (min-width: 768px) {
  .catalogsearch-result-index .page-wrapper .page-main .columns .column .search div:nth-child(1) {
    justify-content: space-between;
  }
}
.catalogsearch-result-index .page-wrapper .page-main .columns .column .search div:nth-child(1) .limiter {
  display: none;
}
@media screen and (min-width: 768px) {
  .catalogsearch-result-index .page-wrapper .page-main .columns .column .search div:nth-child(1) .toolbar-amount {
    width: 45%;
    text-align: left;
  }
}
@media screen and (min-width: 1600px) {
  .catalogsearch-result-index .page-wrapper .page-main .columns .column .search div:nth-child(1) .toolbar-amount {
    width: 74%;
  }
}
@media screen and (min-width: 768px) {
  .catalogsearch-result-index .page-wrapper .page-main .columns .column .search div:nth-child(3) {
    justify-content: flex-end;
  }
}
.catalogsearch-result-index .page-wrapper .page-main .columns .column .search div:nth-child(3) .pages {
  display: block !important;
}
.catalogsearch-result-index .page-wrapper .page-main .columns .column .search div:nth-child(3) .toolbar-sorter {
  display: none;
}
@media screen and (min-width: 768px) {
  .catalogsearch-result-index .page-wrapper .page-main .columns .column .search div:nth-child(4) {
    justify-content: flex-end;
  }
}
.catalogsearch-result-index .page-wrapper .page-main .columns .column .search div:nth-child(4) .pages {
  display: block !important;
}
.catalogsearch-result-index .page-wrapper .page-main .columns .column .search div:nth-child(4) .toolbar-sorter {
  display: none;
}
.catalogsearch-result-index .page-wrapper .page-main .columns .column .search .products .products .item .product-item-info .product .product-item-inner .product .actions-secondary {
  display: none !important;
}

.blog__post-list .grid-gallery {
  display: grid;
  grid-auto-rows: 200px;
  grid-gap: 0;
  grid-auto-flow: row dense;
}
@media all and (min-width: 375px) {
  .blog__post-list .grid-gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media all and (min-width: 768px) {
  .blog__post-list .grid-gallery {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media all and (min-width: 979px) {
  .blog__post-list .grid-gallery {
    grid-template-columns: repeat(5, 1fr);
  }
}
.blog__post-list .grid-gallery .grid-gallery__item .blog__post-item {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.blog__post-list .grid-gallery .grid-gallery__item .blog__post-item .blog__post-header {
  display: none;
  position: absolute;
  height: 90%;
  width: 100%;
  z-index: 1;
}
.blog__post-list .grid-gallery .grid-gallery__item .blog__post-item .blog__post-header h3 {
  border: 1px solid white;
  height: 100%;
  margin: 0 20px;
}
.blog__post-list .grid-gallery .grid-gallery__item .blog__post-item .blog__post-header h3 a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 0 0 3px #000000;
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  height: 100%;
  width: 100%;
}
.blog__post-list .grid-gallery .grid-gallery__item .blog__post-item .img-container {
  height: 100%;
  width: 100%;
}
.blog__post-list .grid-gallery .grid-gallery__item .blog__post-item .img-container .grid-gallery__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s display;
}
.blog__post-list .grid-gallery .grid-gallery__item .blog__post-item:hover .blog__post-header {
  display: block;
}
.blog__post-list .grid-gallery .grid-gallery__item .blog__post-item:hover .img-container {
  filter: blur(2px);
  transform: scale(1.1);
}
.blog__post-list .grid-gallery .grid-gallery__item .blog__post-item:hover .img-container .grid-gallery__image {
  filter: grayscale(1);
}
.blog__post-list .grid-gallery .grid-gallery__item:nth-child(11n+1) {
  grid-column: span 2;
  grid-row: span 1;
}
@media all and (min-width: 768px) {
  .blog__post-list .grid-gallery .grid-gallery__item:nth-child(11n+1) {
    grid-column: span 1;
    grid-row: span 2;
  }
}
@media all and (min-width: 979px) {
  .blog__post-list .grid-gallery .grid-gallery__item:nth-child(11n+1) {
    grid-column: span 1;
    grid-row: span 2;
  }
}
.blog__post-list .grid-gallery .grid-gallery__item:nth-child(11n+2) {
  grid-column: span 1;
  grid-row: span 2;
}
@media all and (min-width: 768px) {
  .blog__post-list .grid-gallery .grid-gallery__item:nth-child(11n+2) {
    grid-column: span 2;
    grid-row: span 1;
  }
}
@media all and (min-width: 979px) {
  .blog__post-list .grid-gallery .grid-gallery__item:nth-child(11n+2) {
    grid-column: span 2;
    grid-row: span 1;
  }
}
.blog__post-list .grid-gallery .grid-gallery__item:nth-child(11n+3) {
  grid-column: span 1;
  grid-row: span 1;
}
@media all and (min-width: 768px) {
  .blog__post-list .grid-gallery .grid-gallery__item:nth-child(11n+3) {
    grid-column: span 1;
    grid-row: span 1;
  }
}
.blog__post-list .grid-gallery .grid-gallery__item:nth-child(11n+4) {
  grid-column: span 1;
  grid-row: span 1;
}
@media all and (min-width: 768px) {
  .blog__post-list .grid-gallery .grid-gallery__item:nth-child(11n+4) {
    grid-column: span 1;
    grid-row: span 1;
  }
}
.blog__post-list .grid-gallery .grid-gallery__item:nth-child(11n+5) {
  display: none;
}
@media all and (min-width: 768px) {
  .blog__post-list .grid-gallery .grid-gallery__item:nth-child(11n+5) {
    display: block;
    grid-column: span 2;
    grid-row: span 1;
  }
}
@media all and (min-width: 979px) {
  .blog__post-list .grid-gallery .grid-gallery__item:nth-child(11n+5) {
    grid-column: span 1;
    grid-row: span 1;
  }
}
.blog__post-list .grid-gallery .grid-gallery__item:nth-child(11n+6) {
  display: none;
}
@media all and (min-width: 768px) {
  .blog__post-list .grid-gallery .grid-gallery__item:nth-child(11n+6) {
    display: block;
    grid-column: span 1;
    grid-row: span 1;
  }
}
.blog__post-list .grid-gallery .grid-gallery__item:nth-child(11n+7) {
  display: none;
}
@media all and (min-width: 979px) {
  .blog__post-list .grid-gallery .grid-gallery__item:nth-child(11n+7) {
    grid-column: span 2;
    grid-row: span 2;
    display: block;
  }
}
.blog__post-list .grid-gallery .grid-gallery__item:nth-child(11n+8) {
  display: none;
}
@media all and (min-width: 979px) {
  .blog__post-list .grid-gallery .grid-gallery__item:nth-child(11n+8) {
    grid-column: span 2;
    grid-row: span 1;
    display: block;
  }
}
.blog__post-list .grid-gallery .grid-gallery__item:nth-child(11n+9) {
  display: none;
}
@media all and (min-width: 979px) {
  .blog__post-list .grid-gallery .grid-gallery__item:nth-child(11n+9) {
    display: block;
    grid-column: span 1;
    grid-row: span 1;
  }
}

.blog .page-wrapper .page-header,
.blog-search-result .page-wrapper .page-header {
  background-color: transparent;
}
.blog .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu,
.blog-search-result .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu {
  background-color: rgba(0, 0, 0, 0.75);
}
.blog .page-wrapper .breadcrumbs,
.blog-search-result .page-wrapper .breadcrumbs {
  display: none;
}
.blog .page-wrapper .page-main .image-view .image-container,
.blog-search-result .page-wrapper .page-main .image-view .image-container {
  position: relative;
  height: 56.25vh;
  overflow: hidden;
  margin-top: -111px;
  height: 100%;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blog .page-wrapper .page-main .image-view .image-container,
  .blog-search-result .page-wrapper .page-main .image-view .image-container {
    margin-top: -70px;
  }
}
@media screen and (min-width: 979px) {
  .blog .page-wrapper .page-main .image-view .image-container,
  .blog-search-result .page-wrapper .page-main .image-view .image-container {
    margin-top: -169px;
  }
}
.blog .page-wrapper .page-main .image-view .image-container h1,
.blog-search-result .page-wrapper .page-main .image-view .image-container h1 {
  position: absolute;
  left: 0;
  top: 50px;
  color: white;
  right: 0;
  text-align: center;
  font-size: 40px;
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  margin: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
}
@media screen and (min-width: 979px) {
  .blog .page-wrapper .page-main .image-view .image-container h1,
  .blog-search-result .page-wrapper .page-main .image-view .image-container h1 {
    font-size: 60px;
    top: 15%;
  }
}
.blog .page-wrapper .page-main .image-view .image-container .img,
.blog-search-result .page-wrapper .page-main .image-view .image-container .img {
  object-fit: contain;
  height: 100%;
  max-width: none;
  width: auto;
}
@media screen and (min-width: 768px) {
  .blog .page-wrapper .page-main .image-view .image-container .img,
  .blog-search-result .page-wrapper .page-main .image-view .image-container .img {
    object-fit: cover;
    width: 100%;
  }
}
.blog .page-wrapper .page-main .page-title-wrapper,
.blog-search-result .page-wrapper .page-main .page-title-wrapper {
  display: none;
}
.blog .page-wrapper .page-main .page-title-wrapper .block-title .page-title,
.blog-search-result .page-wrapper .page-main .page-title-wrapper .block-title .page-title {
  margin: 0 0 15px;
}
.blog .page-wrapper .page-main .page-title-wrapper .block-title .page-title span,
.blog-search-result .page-wrapper .page-main .page-title-wrapper .block-title .page-title span {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: #000000;
}
.blog .page-wrapper .page-main .blog__sidebar-search,
.blog-search-result .page-wrapper .page-main .blog__sidebar-search {
  padding: 40px 20px;
  margin-bottom: 0;
  background: #ebebeb;
}
@media screen and (min-width: 979px) {
  .blog .page-wrapper .page-main .blog__sidebar-search,
  .blog-search-result .page-wrapper .page-main .blog__sidebar-search {
    padding: 50px 18% 60px;
  }
}
.blog .page-wrapper .page-main .blog__sidebar-search form .input-container,
.blog-search-result .page-wrapper .page-main .blog__sidebar-search form .input-container {
  border-bottom: 3px solid;
}
.blog .page-wrapper .page-main .blog__sidebar-search form .input-container .input-text,
.blog-search-result .page-wrapper .page-main .blog__sidebar-search form .input-container .input-text {
  border: none;
  background: #ebebeb;
  text-align: center;
  padding-bottom: 15px;
}
@media screen and (min-width: 979px) {
  .blog .page-wrapper .page-main .blog__sidebar-search form .input-container .input-text,
  .blog-search-result .page-wrapper .page-main .blog__sidebar-search form .input-container .input-text {
    height: 50px;
    padding-left: 0;
    padding-right: 0;
  }
}
.blog .page-wrapper .page-main .blog__sidebar-search form .input-container .input-text::placeholder,
.blog-search-result .page-wrapper .page-main .blog__sidebar-search form .input-container .input-text::placeholder {
  color: #000000;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
}
@media screen and (min-width: 979px) {
  .blog .page-wrapper .page-main .blog__sidebar-search form .input-container .input-text::placeholder,
  .blog-search-result .page-wrapper .page-main .blog__sidebar-search form .input-container .input-text::placeholder {
    font-size: 30px;
  }
}
.blog .page-wrapper .page-main .blog__sidebar-search form .input-container .action,
.blog-search-result .page-wrapper .page-main .blog__sidebar-search form .input-container .action {
  border: none;
  background: #ebebeb;
  top: -10px;
}
.blog .page-wrapper .page-main .columns,
.blog-search-result .page-wrapper .page-main .columns {
  padding: 20px 20px 0;
}
@media screen and (min-width: 979px) {
  .blog .page-wrapper .page-main .columns,
  .blog-search-result .page-wrapper .page-main .columns {
    margin-top: 60px;
  }
}
.blog .page-wrapper .page-main .columns .column,
.blog-search-result .page-wrapper .page-main .columns .column {
  padding-bottom: 0;
}
@media screen and (min-width: 768px) {
  .blog .page-wrapper .page-main .columns .column,
  .blog-search-result .page-wrapper .page-main .columns .column {
    width: 70%;
  }
}
@media screen and (min-width: 979px) {
  .blog .page-wrapper .page-main .columns .column,
  .blog-search-result .page-wrapper .page-main .columns .column {
    width: 80%;
  }
}
.blog .page-wrapper .page-main .columns .column .toolbar,
.blog-search-result .page-wrapper .page-main .columns .column .toolbar {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
}
.blog .page-wrapper .page-main .columns .column .toolbar .toolbar-amount,
.blog-search-result .page-wrapper .page-main .columns .column .toolbar .toolbar-amount {
  color: #000000;
  font-size: 14px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  line-height: normal;
}
.blog .page-wrapper .page-main .columns .column .toolbar .pages,
.blog-search-result .page-wrapper .page-main .columns .column .toolbar .pages {
  display: none;
}
.blog .page-wrapper .page-main .columns .column .toolbar .limiter,
.blog-search-result .page-wrapper .page-main .columns .column .toolbar .limiter {
  margin-left: 15px;
  display: flex;
  align-items: flex-end;
}
.blog .page-wrapper .page-main .columns .column .toolbar .limiter #limiter,
.blog-search-result .page-wrapper .page-main .columns .column .toolbar .limiter #limiter {
  background: #ebebeb;
  border: none;
  width: 60px;
  height: 24px;
  padding: 0 10px;
}
.blog .page-wrapper .page-main .columns .column .toolbar .limiter .limiter-label,
.blog .page-wrapper .page-main .columns .column .toolbar .limiter .limiter-text,
.blog-search-result .page-wrapper .page-main .columns .column .toolbar .limiter .limiter-label,
.blog-search-result .page-wrapper .page-main .columns .column .toolbar .limiter .limiter-text {
  font-size: 14px;
  color: #000000;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
}
@media screen and (min-width: 979px) {
  .blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item,
  .blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item {
    border: none;
    padding-bottom: 60px;
  }
}
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt {
  display: flex;
  flex-direction: column;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
@media screen and (min-width: 979px) {
  .blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt,
  .blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt {
    flex-direction: row;
  }
}
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .img-container img,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .img-container img {
  max-width: 100%;
  padding: 0;
}
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt p,
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt h5,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt p,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt h5 {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .read-more,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .read-more {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #d20615;
  text-decoration: underline;
}
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt div iframe,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt div iframe {
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt div iframe,
  .blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt div iframe {
    height: 415px;
  }
}
@media screen and (min-width: 979px) {
  .blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header,
  .blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header {
    margin-left: 15px;
    margin-right: 70px;
    width: 100%;
    border-bottom: 1px solid #a1a1a1;
    margin-bottom: 0px;
    font-size: 14px;
  }
}
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-title,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-title {
  margin-top: 15px;
}
@media screen and (min-width: 979px) {
  .blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-title,
  .blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-title {
    margin-top: 0;
    margin-bottom: 25px;
  }
}
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-title a,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-title a {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 26px;
  color: #000000;
}
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  margin: 0 0 20px;
}
@media screen and (min-width: 979px) {
  .blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta,
  .blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta {
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr;
  }
}
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-area: 1/1/2/2;
}
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-time,
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-difficult,
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-comments,
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-author,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-time,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-difficult,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-comments,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-author {
  display: flex;
  align-items: center;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  color: #000000;
  margin-right: 45px;
}
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-time .icon,
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-time .fa-user,
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-difficult .icon,
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-difficult .fa-user,
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-comments .icon,
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-comments .fa-user,
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-author .icon,
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-author .fa-user,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-time .icon,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-time .fa-user,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-difficult .icon,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-difficult .fa-user,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-comments .icon,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-comments .fa-user,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-author .icon,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-author .fa-user {
  margin-right: 5px;
}
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-comments,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-comments {
  display: block;
}
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-difficult,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-difficult {
  align-items: end;
}
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-author,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-author {
  margin-top: 20px;
}
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-author span a,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .meta-container .blog__post-meta-author span a {
  color: #000000;
}
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .socials,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .socials {
  grid-area: 2/1/3/2;
  margin-top: 20px;
}
@media screen and (min-width: 979px) {
  .blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .socials,
  .blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .socials {
    grid-area: 1/2/2/3;
    margin-top: 0;
    text-align: right;
  }
}
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .socials .addthis_sharing_toolbox,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .socials .addthis_sharing_toolbox {
  margin-left: 0;
}
@media screen and (min-width: 979px) {
  .blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .socials .addthis_sharing_toolbox,
  .blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__post-meta .socials .addthis_sharing_toolbox {
    margin: 0;
  }
}
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__content .content,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__content .content {
  font-size: 14px;
  line-height: 24px;
}
@media screen and (min-width: 979px) {
  .blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__content .content,
  .blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-excerpt .blog__post-header .blog__content .content {
    overflow: hidden;
    height: 70px;
  }
}
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-footer,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-footer {
  display: flex;
}
@media screen and (min-width: 979px) {
  .blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-footer,
  .blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-footer {
    margin-bottom: 0;
  }
}
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-footer .blog__post-footer-categories,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-footer .blog__post-footer-categories {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #a3a3a3;
  margin-left: 0;
}
.blog .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-footer .blog__post-footer-categories a,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-list-wrapper .blog__post-list .blog__post-item .blog__post-footer .blog__post-footer-categories a {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #a3a3a3;
}
.blog .page-wrapper .page-main .columns .column .blog__post-view .blog__post-meta,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-view .blog__post-meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0 20px;
}
.blog .page-wrapper .page-main .columns .column .blog__post-view .blog__post-meta .blog__post-meta-time,
.blog .page-wrapper .page-main .columns .column .blog__post-view .blog__post-meta .blog__post-meta-author,
.blog .page-wrapper .page-main .columns .column .blog__post-view .blog__post-meta .blog__post-meta-comments,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-view .blog__post-meta .blog__post-meta-time,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-view .blog__post-meta .blog__post-meta-author,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-view .blog__post-meta .blog__post-meta-comments {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.blog .page-wrapper .page-main .columns .column .blog__post-view .blog__post-meta .blog__post-meta-author span a,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-view .blog__post-meta .blog__post-meta-author span a {
  color: #000000;
}
.blog .page-wrapper .page-main .columns .column .blog__post-view p,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-view p {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.blog .page-wrapper .page-main .columns .column .blog__post-view .blog__post-featured-image,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-view .blog__post-featured-image {
  width: 100%;
}
.blog .page-wrapper .page-main .columns .column .blog__post-view .blog__post-footer .blog__post-footer-categories,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-view .blog__post-footer .blog__post-footer-categories {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.blog .page-wrapper .page-main .columns .column .blog__post-view .blog__post-footer .blog__post-footer-categories a,
.blog-search-result .page-wrapper .page-main .columns .column .blog__post-view .blog__post-footer .blog__post-footer-categories a {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.blog .page-wrapper .page-main .columns .column .blog__copyright-wrapper,
.blog-search-result .page-wrapper .page-main .columns .column .blog__copyright-wrapper {
  display: none;
}
@media screen and (min-width: 768px) {
  .blog .page-wrapper .page-main .columns .sidebar,
  .blog-search-result .page-wrapper .page-main .columns .sidebar {
    width: 30%;
  }
}
@media screen and (min-width: 979px) {
  .blog .page-wrapper .page-main .columns .sidebar,
  .blog-search-result .page-wrapper .page-main .columns .sidebar {
    width: 20%;
  }
}
.blog .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .block.block-collapsible-nav,
.blog-search-result .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .block.block-collapsible-nav {
  margin-bottom: 40px;
  position: initial;
}
.blog .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .block.block-collapsible-nav .title,
.blog-search-result .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .block.block-collapsible-nav .title {
  margin-bottom: 15px;
}
.blog .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .block.block-collapsible-nav .title strong,
.blog-search-result .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .block.block-collapsible-nav .title strong {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 25px;
}
.blog .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .block.block-collapsible-nav .block-collapsible-content,
.blog-search-result .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .block.block-collapsible-nav .block-collapsible-content {
  background-color: #ebebeb;
  padding: 20px 20px 20px 0;
}
.blog .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .block.block-collapsible-nav .block-collapsible-content .nav li,
.blog-search-result .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .block.block-collapsible-nav .block-collapsible-content .nav li {
  display: flex;
  align-items: center;
  padding-left: 20px;
  height: 50px;
}
.blog .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .block.block-collapsible-nav .block-collapsible-content .nav li a,
.blog-search-result .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .block.block-collapsible-nav .block-collapsible-content .nav li a {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
.blog .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .block.block-collapsible-nav .block-collapsible-content .nav li.current,
.blog-search-result .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .block.block-collapsible-nav .block-collapsible-content .nav li.current {
  border-left: 8px solid #ad8334;
  padding-left: 12px;
}
.blog .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .block.block-collapsible-nav .block-collapsible-content .nav li.current a,
.blog-search-result .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .block.block-collapsible-nav .block-collapsible-content .nav li.current a {
  font-weight: 800;
}
.blog .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .blog__sidebar-recent .block-title,
.blog-search-result .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .blog__sidebar-recent .block-title {
  margin-bottom: 25px;
}
.blog .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .blog__sidebar-recent .block-title strong,
.blog-search-result .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .blog__sidebar-recent .block-title strong {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  color: #000000;
  font-size: 22px;
}
.blog .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .blog__sidebar-recent .block-content .items .item,
.blog-search-result .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .blog__sidebar-recent .block-content .items .item {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}
.blog .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .blog__sidebar-recent .block-content .items .item a .blog__post-featured-image,
.blog-search-result .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .blog__sidebar-recent .block-content .items .item a .blog__post-featured-image {
  max-width: 100%;
  padding: 0;
}
.blog .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .blog__sidebar-recent .block-content .items .item a:nth-child(2),
.blog-search-result .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .blog__sidebar-recent .block-content .items .item a:nth-child(2) {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  line-height: 16px;
  padding: 15px;
  text-align: center;
  background-color: #ebebeb;
}
.blog .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .blog__sidebar-tag-cloud,
.blog-search-result .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .blog__sidebar-tag-cloud {
  display: none;
}
.blog .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .blog__sidebar-search form,
.blog-search-result .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .blog__sidebar-search form {
  display: flex;
}
.blog .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .blog__sidebar-search form .input-text,
.blog-search-result .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .blog__sidebar-search form .input-text {
  height: 45px;
  border-radius: 0;
  border: 1px solid #4a4a4a;
  box-shadow: none;
  font-size: 16px;
}
.blog .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .blog__sidebar-search form .input-text::placeholder,
.blog-search-result .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .blog__sidebar-search form .input-text::placeholder {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.blog .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .blog__sidebar-search form .action,
.blog-search-result .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .blog__sidebar-search form .action {
  position: relative;
  height: 45px;
  border-radius: 0;
  background: #93002a;
  border: 1px solid #93002a;
}
.blog .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .block-collapsible-nav .title strong,
.blog-search-result .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .block-collapsible-nav .title strong {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 26px;
  color: #000000;
}
.blog .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .blog__sidebar-rss-link,
.blog-search-result .page-wrapper .page-main .columns .sidebar .blog__sidebar-main .blog__sidebar-rss-link {
  display: none;
}

.blog-post-view .page-wrapper .page-header {
  background-color: #000000;
}
@media screen and (min-width: 979px) {
  .blog-post-view .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu {
    background-color: #000000;
  }
}
.blog-post-view .page-wrapper .page-main {
  padding-top: 0;
}
.blog-post-view .page-wrapper .page-main .image-view {
  display: none;
}
@media screen and (min-width: 979px) {
  .blog-post-view .page-wrapper .page-main .columns {
    padding: 20px 40px 0;
  }
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view {
    padding-right: 30px;
  }
}
@media screen and (min-width: 979px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view {
    padding-right: 45px;
  }
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .page-title-wrapper {
  display: block;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .page-title-wrapper .page-title {
  text-align: center;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .page-title-wrapper .page-title span {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 40px;
  line-height: 24px;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .page-title-wrapper .page-title span::before, .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .page-title-wrapper .page-title span::after {
  content: "~";
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-featured-image {
  order: -1;
}
@media screen and (min-width: 768px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-featured-image {
    padding: 0 0 20px;
  }
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .content-cms-block {
  background: #ebebeb;
  padding: 30px 25px 50px;
  margin-bottom: 30px;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .content-cms-block h1 {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 25px;
  line-height: 26px;
  color: #000000;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .content-cms-block h3 {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .content-cms-block p {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .content-cms-block iframe {
  max-width: 100%;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .content-cms-block ul li {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .content-cms-block ol li {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-meta {
  width: 100%;
  align-items: flex-start;
}
@media screen and (min-width: 979px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-meta {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
  }
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-meta .meta-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
@media screen and (min-width: 979px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-meta .meta-container {
    grid-area: 1/1/2;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    padding-left: 10%;
  }
}
@media screen and (min-width: 979px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-meta .meta-container .blog__post-meta-author {
    margin-top: 20px;
  }
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-meta .socials {
  display: flex;
}
@media screen and (min-width: 979px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-meta .socials {
    grid-area: 1/2/1;
  }
}
@media screen and (min-width: 979px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-meta .socials .addthis_sharing_toolbox {
    margin-right: 0;
    margin-left: 0;
  }
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-footer {
  display: flex;
}
@media screen and (min-width: 979px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-footer {
    margin-bottom: 10px;
  }
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-footer .blog__post-footer-categories {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #a3a3a3;
  margin-left: 0;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-footer .blog__post-footer-categories a {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #a3a3a3;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products .block-title {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 25px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 15px;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products {
  margin: 0;
}
@media screen and (max-width: 630px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .uk-child-width-1-2 > * {
    width: 50%;
  }
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .uk-grid > * {
  padding-left: 30px;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .uk-grid {
  margin-left: -30px;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item {
  margin-left: 0;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info {
  width: 100%;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info .discount-flag {
  width: 45px;
  height: 45px;
  margin: 10px;
  border-radius: 3em;
  overflow: hidden;
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b88f01;
  transform: rotate(-25deg);
  font-size: 18px;
  color: #fff;
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  left: 25px;
}
@media screen and (min-width: 768px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info .discount-flag {
    width: 60px;
    height: 60px;
  }
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info .discount-flag .discount {
  margin-top: -5px;
}
@media screen and (min-width: 768px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info .discount-flag .discount {
    margin-top: -6px;
    font-size: 24px;
  }
}
@media screen and (min-width: 979px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info .discount-flag .discount {
    margin-top: -10px;
  }
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info .discount-flag .percent {
  font-size: 10.5px;
  padding-top: 5px;
}
@media screen and (min-width: 768px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info .discount-flag .percent {
    font-size: 14px;
  }
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info .product-item-photo {
  text-align: center;
  display: block;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info .product .product {
  text-align: center;
  font-size: 0;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info .product .product .product-item-link {
  margin-top: 10px;
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  color: #000000;
  height: 45px;
  overflow: hidden;
  display: block;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info .product .product .product-item-link {
    margin-top: 30px;
    font-size: 20px;
    height: 55px;
  }
}
@media screen and (min-width: 979px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info .product .product .product-item-link {
    margin-top: 20px;
  }
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info .product .price-box {
  text-align: center;
  margin-bottom: 10px;
  height: 64px;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info .product .price-box .text-old-price {
  display: none;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info .product .price-box .normal-price .text-special-price {
  display: none;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info .product .price-box .old-price .text-old-price {
  display: none;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info .product .price-box .old-price .price-container {
  font-size: 18px;
}
@media screen and (min-width: 768px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info .product .price-box .old-price .price-container {
    font-size: 24px;
  }
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info .product .price-box .old-price .price-container .price-wrapper .price {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info .product .price-box .price-container .price-wrapper .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info .product .price-box .price-container .price-wrapper .price {
    font-size: 24px;
  }
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info .product .price-box .special-price .text-special-price {
  display: none;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .products.list .item .product-item-info .product .field {
  display: none;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .uk-icon {
  background: url(../images/icons/icon-arrow-izq-oscura.svg);
  content: "";
  width: 50px;
  height: 55px;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 0;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .uk-icon:hover {
  background: url(../images/icons/icon-arrow.svg);
  content: "";
  width: 50px;
  height: 55px;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  background-position: center;
  background-color: #fff;
  border-radius: 0;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .uk-icon svg {
  display: none;
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .uk-position-center-right {
  transform: rotate(-180deg);
  top: 42%;
  right: -15px;
}
@media screen and (min-width: 979px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .uk-position-center-right {
    top: 40%;
  }
}
@media screen and (min-width: 1199px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .uk-position-center-right {
    top: 45%;
  }
}
@media screen and (min-width: 1366px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .uk-position-center-right {
    top: 50%;
  }
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .uk-position-center-right:hover {
  transform: rotate(0deg);
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .uk-position-center-left {
  transform: translateY(0);
  top: 42%;
  left: -15px;
}
@media screen and (min-width: 979px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .uk-position-center-left {
    top: 40%;
  }
}
@media screen and (min-width: 1199px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .uk-position-center-left {
    top: 45%;
  }
}
@media screen and (min-width: 1366px) {
  .blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .uk-position-center-left {
    top: 50%;
  }
}
.blog-post-view .page-wrapper .page-main .columns .column .blog__post-view .blog__post-view-related-products ol .products .uk-position-center-left:hover {
  transform: rotate(-180deg);
}

.page-with-filter .page-wrapper .page-header {
  background-color: transparent;
}
@media screen and (min-width: 979px) {
  .page-with-filter .page-wrapper .page-header {
    background-color: transparent;
  }
}
.page-with-filter .page-wrapper .page-header .header .header-right .mobile-right-menu #right-menu {
  background-color: rgba(0, 0, 0, 0.75);
}
.page-with-filter .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
.page-with-filter .page-wrapper .page-main .category-view .category-image {
  position: relative;
  height: 223px;
  overflow: hidden;
  margin-top: -70px;
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 979px) {
  .page-with-filter .page-wrapper .page-main .category-view .category-image {
    margin-top: -120px;
    height: auto;
  }
}
@media screen and (min-width: 1199px) {
  .page-with-filter .page-wrapper .page-main .category-view .category-image {
    margin-top: -170px;
  }
}
.page-with-filter .page-wrapper .page-main .category-view .category-image h1 {
  position: absolute;
  left: 0;
  top: 0;
  color: white;
  right: 0;
  text-align: center;
  font-size: 40px;
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  margin: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
}
.page-with-filter .page-wrapper .page-main .category-view .category-image h1::before {
  content: "~ ";
}
.page-with-filter .page-wrapper .page-main .category-view .category-image h1::after {
  content: " ~";
}
@media screen and (max-width: 979px) {
  .page-with-filter .page-wrapper .page-main .category-view .category-image h1 {
    padding: 0 5vw;
    margin-top: 5vh;
  }
}
@media screen and (min-width: 979px) {
  .page-with-filter .page-wrapper .page-main .category-view .category-image h1 {
    top: 30%;
    font-size: 50px;
  }
}
.page-with-filter .page-wrapper .page-main .category-view .category-image .image {
  width: auto;
  object-fit: contain;
  height: 100%;
  max-width: initial;
}
@media screen and (min-width: 979px) {
  .page-with-filter .page-wrapper .page-main .category-view .category-image .image {
    width: 100%;
  }
}
.page-with-filter .page-wrapper .page-main .columns {
  padding: 0 15px;
}
@media screen and (min-width: 979px) {
  .page-with-filter .page-wrapper .page-main .columns {
    padding: 0 20px;
  }
}
.page-with-filter .page-wrapper .page-main .columns .column {
  padding-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .page-with-filter .page-wrapper .page-main .columns .column {
    width: 80%;
  }
}
.page-with-filter .page-wrapper .page-main .columns .column .toolbar {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .page-with-filter .page-wrapper .page-main .columns .column .toolbar {
    justify-content: flex-end;
    align-items: center;
    display: flex;
  }
}
.page-with-filter .page-wrapper .page-main .columns .column .toolbar .toolbar-amount {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.page-with-filter .page-wrapper .page-main .columns .column .toolbar .toolbar-sorter .sorter-label {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.page-with-filter .page-wrapper .page-main .columns .column .toolbar .toolbar-sorter .sorter-options {
  border: 1px solid #4a4a4a;
}
.page-with-filter .page-wrapper .page-main .columns .column .toolbar .toolbar-sorter .sorter-options .sorter-label {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.page-with-filter .page-wrapper .page-main .columns .column .toolbar .field .label,
.page-with-filter .page-wrapper .page-main .columns .column .toolbar .field .control,
.page-with-filter .page-wrapper .page-main .columns .column .toolbar .field .limiter-text {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.page-with-filter .page-wrapper .page-main .columns .column .toolbar .field .control .limiter-options {
  border: 1px solid #4a4a4a;
}
.page-with-filter .page-wrapper .page-main .columns .column .toolbar .field .control .limiter-options option:focus {
  background-color: #93002a !important;
}
@media screen and (min-width: 768px) {
  .page-with-filter .page-wrapper .page-main .columns .column .toolbar .pages {
    margin-right: 15%;
  }
}
@media screen and (min-width: 979px) {
  .page-with-filter .page-wrapper .page-main .columns .column .toolbar .pages {
    margin-right: 30%;
  }
}
.page-with-filter .page-wrapper .page-main .columns .column .toolbar .pages .items {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-with-filter .page-wrapper .page-main .columns .column .toolbar .pages .items .item {
  display: flex;
}
.page-with-filter .page-wrapper .page-main .columns .column .toolbar .pages .items .item .page {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.page-with-filter .page-wrapper .page-main .columns .column .toolbar .pages .items .current .page {
  background-color: #93002a;
  padding: 0;
  width: 25px;
  height: 25px;
  line-height: 25px;
  color: #fff;
}
.page-with-filter .page-wrapper .page-main .columns .column .toolbar .pages .items .pages-item-next .action,
.page-with-filter .page-wrapper .page-main .columns .column .toolbar .pages .items .pages-item-previous .action {
  background: url(../images/icons/icon-right.svg);
  content: "";
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  background-position: center;
  border: none;
}
.page-with-filter .page-wrapper .page-main .columns .column .toolbar .pages .items .pages-item-next .action::before,
.page-with-filter .page-wrapper .page-main .columns .column .toolbar .pages .items .pages-item-previous .action::before {
  display: none;
}
.page-with-filter .page-wrapper .page-main .columns .column .toolbar .pages .items .pages-item-previous {
  transform: rotate(-180deg);
}
.page-with-filter .page-wrapper .page-main .columns .column .products {
  margin: 0;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item {
  margin-bottom: 0;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info {
  position: relative;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .discount-flag {
  width: 45px;
  height: 45px;
  margin: 10px;
  border-radius: 3em;
  overflow: hidden;
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b88f01;
  transform: rotate(-25deg);
  font-size: 18px;
  color: #fff;
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  left: -10px;
  top: 18px;
}
@media screen and (min-width: 768px) {
  .page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .discount-flag {
    width: 60px;
    height: 60px;
  }
}
@media screen and (min-width: 979px) {
  .page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .discount-flag {
    top: 26px;
  }
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-reviews-summary {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  margin-top: 0;
  height: 28px;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-reviews-summary .rating-summary {
  margin: 0;
  left: 0;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-reviews-summary .rating-summary .rating-result {
  width: 125px;
  margin: 0;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-reviews-summary .rating-summary .rating-result::before {
  content: "";
  width: 100%;
  background-image: url("../images/empty-stars-small.svg");
  background-repeat: no-repeat;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-reviews-summary .rating-summary .rating-result span::before {
  position: sticky;
  content: "";
  background-image: url("../images/filled-stars-small.svg");
  background-repeat: no-repeat;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-reviews-summary .reviews-actions {
  display: none;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details {
  height: 100%;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .product {
  font-size: 0;
  text-align: center;
  height: 45px;
  overflow: hidden;
  margin-bottom: 0;
  margin-top: 0;
}
@media screen and (min-width: 979px) {
  .page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .product {
    height: 60px;
  }
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .product .product-item-link {
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  color: #000000;
  display: -webkit-box;
  line-height: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (min-width: 768px) {
  .page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .product .product-item-link {
    font-size: 16px;
  }
}
@media screen and (min-width: 979px) {
  .page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .product .product-item-link {
    font-size: 20px;
  }
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .price-box {
  height: 45px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .price-box {
    line-height: 26px;
    height: 55px;
  }
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .price-box .text-old-price {
  display: none;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .price-box .price-container .price-wrapper .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .price-box .price-container .price-wrapper .price {
    font-size: 20px;
  }
}
@media screen and (min-width: 979px) {
  .page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .price-box .price-container .price-wrapper .price {
    font-size: 24px;
  }
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .price-box .normal-price .text-special-price {
  display: none;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .price-box .normal-price .price-container .price-label {
  display: none;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .price-box .normal-price .price-container .price-wrapper .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .price-box .normal-price .price-container .price-wrapper .price {
    font-size: 20px;
  }
}
@media screen and (min-width: 979px) {
  .page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .price-box .normal-price .price-container .price-wrapper .price {
    font-size: 24px;
  }
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .price-box .old-price .text {
  display: none;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .price-box .old-price .text-old-price {
  display: none;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .price-box .old-price .price-container .price-wrapper .price {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .price-box .old-price .price-container .price-wrapper .price {
    font-size: 20px;
  }
}
@media screen and (min-width: 979px) {
  .page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .price-box .old-price .price-container .price-wrapper .price {
    font-size: 24px;
  }
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .price-box .special-price .text-special-price {
  display: none;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .swatch-attribute .swatch-attribute-options {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .swatch-attribute .swatch-attribute-options .swatch-option {
  font-size: 16px;
  color: #000000;
  outline: none;
  overflow: visible;
  border: 1px solid #4a4a4a;
  line-height: initial;
  height: auto;
  width: auto;
  box-shadow: none;
  background-color: #fff;
  padding: 5px 10px;
  margin-bottom: 10px;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .swatch-attribute .swatch-attribute-options .swatch-option.selected {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  color: #fff;
  border: solid 1px #93002a;
  background-color: #93002a !important;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .product-item-inner {
  display: block;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .product-item-inner .product {
  height: auto;
  margin-bottom: 0;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .product-item-inner .product .actions-primary {
  display: flex;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .product-item-inner .product .actions-primary .stock.unavailable {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 10px;
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  border: 1px solid #93002a;
  width: 100%;
}
@media screen and (min-width: 979px) {
  .page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .product-item-inner .product .actions-primary .stock.unavailable {
    font-size: 22px;
    height: 48px;
  }
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .product-item-inner .product .actions-primary .stock.unavailable span {
  text-transform: uppercase;
  color: #93002a;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .product-item-inner .product .actions-primary form {
  width: 100%;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .product-item-inner .product .actions-primary form .action {
  width: 100%;
  height: 40px;
  border: solid 1px #93002a;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #93002a;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 0;
  transition: 0.5s;
  font-size: 16px;
  padding: 7px 8px;
}
@media screen and (min-width: 979px) {
  .page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .product-item-inner .product .actions-primary form .action {
    height: 45px;
    font-size: 22px;
  }
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .product-item-inner .product .actions-primary form .action:hover {
  background: #93002a;
  color: #fff;
}
.page-with-filter .page-wrapper .page-main .columns .column .products .products .item .product-item-info .product-item-details .product-item-inner .product .actions-primary form .action span {
  text-transform: uppercase;
}
.page-with-filter .page-wrapper .page-main .columns .column div:nth-child(2) {
  display: block;
}
.page-with-filter .page-wrapper .page-main .columns .column div:nth-child(2) .pages {
  display: none;
}
.page-with-filter .page-wrapper .page-main .columns .column div:nth-child(2) .limiter {
  display: none;
}
.page-with-filter .page-wrapper .page-main .columns .column div:nth-child(2) .toolbar-sorter {
  flex-direction: row;
  justify-content: center;
}
.page-with-filter .page-wrapper .page-main .columns .column div:nth-child(4) {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .page-with-filter .page-wrapper .page-main .columns .column div:nth-child(4) {
    flex-direction: row;
  }
}
.page-with-filter .page-wrapper .page-main .columns .column div:nth-child(4) .field {
  display: flex;
  align-items: center;
}
.page-with-filter .page-wrapper .page-main .columns .column div:nth-child(4) .toolbar-sorter {
  display: none;
}
@media screen and (min-width: 768px) {
  .page-with-filter .page-wrapper .page-main .columns .sidebar {
    width: 20%;
  }
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-title strong {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: #000000;
  display: block;
  text-align: center;
  padding: 10px;
  border: 1px solid #4a4a4a;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content {
  border: 1px solid #4a4a4a;
  border-top: 0;
  border-bottom: none;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .block-subtitle {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: #000000;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-current .items {
  display: none;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-current .filter-current-subtitle {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-current .filter-current-subtitle:after {
  content: "+";
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-current + .filter-actions {
  display: none;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-current.active .items {
  display: flex;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-current.active .filter-current-subtitle {
  border-bottom: 0;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-current.active .filter-current-subtitle:after {
  content: "";
  border-top: 1px solid #4a4a4a;
  display: block;
  width: 12px;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-current.active + .filter-actions {
  display: block;
  border-bottom: 1px solid #4a4a4a;
  padding-bottom: 15px;
  margin: 0;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-current .filter-current-subtitle {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  border-bottom: 1px solid #4a4a4a;
  margin: 0;
  padding: 10px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-current .items {
  padding: 0 10px;
  flex-wrap: wrap;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-current .items .item {
  background: #ececec;
  padding: 5px;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-current .items .item .filter-container {
  display: flex;
  align-items: center;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-current .items .item .filter-label,
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-current .items .item .filter-value {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 14px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-right: 5px;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-current .items .item .filter-label {
  font-weight: normal;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-current .items .item .action {
  display: flex;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-actions {
  padding: 0 10px;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-actions .filter-clear {
  height: 40px;
  border: solid 1px #93002a;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #93002a;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 0;
  transition: 0.5s;
  width: 100%;
  height: 30px !important;
  font-size: 16px !important;
}
@media screen and (min-width: 979px) {
  .page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-actions .filter-clear {
    height: 45px;
    font-size: 22px;
  }
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-actions .filter-clear:hover {
  background: #93002a;
  color: #fff;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-actions .filter-clear span {
  text-transform: uppercase;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-actions .filter-clear:hover {
  text-decoration: none;
  background: #000000;
  border: 1px solid #000000;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-item .filter-options-title {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  border-bottom: 1px solid #4a4a4a;
  margin: 0;
  padding: 10px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #4a4a4a;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-item .filter-options-title:after {
  content: "";
  width: 16px;
  height: 16px;
  background: url("../images/catalog/more.svg") no-repeat;
  display: block;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-item.active .filter-options-title {
  border-bottom: 0;
  border-bottom: none;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-item.active .filter-options-title:after {
  content: "";
  background: url("../images/catalog/less.svg") no-repeat;
  background-position: center;
  display: block;
  width: 16px;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-item.active .filter-options-content {
  border-bottom: 1px solid #4a4a4a;
  padding-bottom: 20px;
  max-height: 200px;
  overflow: auto;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content {
  padding: 0 10px;
  margin: 0;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .price-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .price-form span {
  width: 10px;
  height: 2px;
  background: #4a4a4a;
  position: relative;
  top: 10px;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .price-form .input-form {
  width: 45%;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .price-form .input-form label {
  display: block;
  text-align: left;
  font-size: 12px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  color: #000000;
}
@media screen and (min-width: 979px) {
  .page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .price-form .input-form label {
    font-size: 14px;
  }
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .price-form .input-form input {
  border: 1px solid #000000;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
  font-size: 12px !important;
  color: #000000 !important;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .price-form .input-form input ::placeholder {
  color: #4a4a4a;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .price-form .input-form input:focus {
  box-shadow: none;
  outline: none;
}
@media screen and (min-width: 979px) {
  .page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .price-form .input-form input {
    font-size: 14px !important;
  }
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .price-form .input-form input::placeholder {
  color: #000000;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .price-form .action-button {
  margin-top: 10px;
  height: 40px;
  border: solid 1px #93002a;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #93002a;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 0;
  transition: 0.5s;
  width: 100%;
  height: 30px !important;
  font-size: 16px !important;
  text-transform: uppercase;
  border: 1px solid #000000 !important;
  color: #fff !important;
  background-color: #000000 !important;
}
@media screen and (min-width: 979px) {
  .page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .price-form .action-button {
    height: 45px;
    font-size: 22px;
  }
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .price-form .action-button:hover {
  background: #93002a;
  color: #fff;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .price-form .action-button:hover {
  text-decoration: none;
  background: #fff !important;
  border: 1px solid #000000;
  color: #000000 !important;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .swatch-attribute .swatch-attribute-options a {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #000000;
  display: flex;
  align-items: center;
  min-height: 18px;
  background: url("../images/catalog/unchecked.svg") no-repeat;
  background-position: left center;
  background-color: transparent !important;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .swatch-attribute .swatch-attribute-options a .swatch-option {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #000000;
  border: 0;
  background: none;
  padding: 0 0 0 24px;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .swatch-attribute .swatch-attribute-options a .swatch-option:hover {
  outline: none;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .swatch-attribute .swatch-attribute-options a .swatch-option.text.selected {
  background-color: transparent !important;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .swatch-attribute .swatch-attribute-options a .swatch-option.selected {
  outline: none;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .swatch-attribute .swatch-attribute-options a:hover {
  background: url("../images/catalog/checked.svg") no-repeat;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .swatch-attribute .swatch-attribute-options a.mana-selected {
  background: url("../images/catalog/checked.svg") no-repeat;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .swatch-attribute .swatch-attribute-options a.mana-selected:hover {
  background: url("../images/catalog/unchecked.svg") no-repeat;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .items .item a {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #000000;
  display: flex;
  align-items: center;
  min-height: 18px;
  background: url("../images/catalog/unchecked.svg") no-repeat;
  background-position: left center;
  background-color: transparent !important;
  padding-left: 24px;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .items .item a span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #000000;
}
.page-with-filter .page-wrapper .page-main .columns .sidebar .block .block-content .filter-options .filter-options-content .items .item a:hover {
  background: url("../images/catalog/checked.svg") no-repeat;
}

.block.related,
.block.crosssell,
.block.upsell {
  margin-bottom: 0;
  padding: 0 15px;
}
.block.related .block-title,
.block.crosssell .block-title,
.block.upsell .block-title {
  text-align: center;
  margin-bottom: 20px;
}
.block.related .block-title strong,
.block.crosssell .block-title strong,
.block.upsell .block-title strong {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: #000000;
}
.block.related .block-content .block-actions,
.block.crosssell .block-content .block-actions,
.block.upsell .block-content .block-actions {
  display: none;
}
.block.related .block-content .wrapper,
.block.crosssell .block-content .wrapper,
.block.upsell .block-content .wrapper {
  margin: 0 auto;
  width: 100%;
}
.block.related .block-content .wrapper .uk-slider-items,
.block.crosssell .block-content .wrapper .uk-slider-items,
.block.upsell .block-content .wrapper .uk-slider-items {
  /*         display: flex;
  justify-content: center; */
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info {
  width: 100%;
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .discount-flag,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .discount-flag,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .discount-flag {
  width: 45px;
  height: 45px;
  margin: 10px;
  border-radius: 3em;
  overflow: hidden;
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b88f01;
  transform: rotate(-25deg);
  font-size: 18px;
  color: #fff;
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  left: 25px;
}
@media screen and (min-width: 768px) {
  .block.related .block-content .wrapper .uk-slider-items .item .product-item-info .discount-flag,
  .block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .discount-flag,
  .block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .discount-flag {
    width: 60px;
    height: 60px;
  }
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .discount-flag .discount,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .discount-flag .discount,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .discount-flag .discount {
  margin-top: -5px;
}
@media screen and (min-width: 768px) {
  .block.related .block-content .wrapper .uk-slider-items .item .product-item-info .discount-flag .discount,
  .block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .discount-flag .discount,
  .block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .discount-flag .discount {
    margin-top: -6px;
    font-size: 24px;
  }
}
@media screen and (min-width: 979px) {
  .block.related .block-content .wrapper .uk-slider-items .item .product-item-info .discount-flag .discount,
  .block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .discount-flag .discount,
  .block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .discount-flag .discount {
    margin-top: -10px;
  }
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .discount-flag .percent,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .discount-flag .percent,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .discount-flag .percent {
  font-size: 10.5px;
  padding-top: 5px;
}
@media screen and (min-width: 768px) {
  .block.related .block-content .wrapper .uk-slider-items .item .product-item-info .discount-flag .percent,
  .block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .discount-flag .percent,
  .block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .discount-flag .percent {
    font-size: 14px;
  }
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product-item-photo,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product-item-photo,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product-item-photo {
  text-align: center;
  display: block;
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .product,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product {
  text-align: center;
  font-size: 0;
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .product .product-item-link,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product .product-item-link,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product .product-item-link {
  margin-top: 10px;
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  color: #000000;
  height: 45px;
  overflow: hidden;
  display: block;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .product .product-item-link,
  .block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product .product-item-link,
  .block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product .product-item-link {
    margin-top: 30px;
    font-size: 20px;
    height: 55px;
  }
}
@media screen and (min-width: 979px) {
  .block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .product .product-item-link,
  .block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product .product-item-link,
  .block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product .product-item-link {
    margin-top: 20px;
  }
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box {
  text-align: center;
  margin-bottom: 10px;
  height: 64px;
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .text-old-price,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .text-old-price,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .text-old-price {
  display: none;
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .normal-price .text-special-price,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .normal-price .text-special-price,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .normal-price .text-special-price {
  display: none;
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .old-price .text-old-price,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .old-price .text-old-price,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .old-price .text-old-price {
  display: none;
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .old-price .price-container,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .old-price .price-container,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .old-price .price-container {
  font-size: 18px;
}
@media screen and (min-width: 768px) {
  .block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .old-price .price-container,
  .block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .old-price .price-container,
  .block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .old-price .price-container {
    font-size: 24px;
  }
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .old-price .price-container .price-wrapper .price,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .old-price .price-container .price-wrapper .price,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .old-price .price-container .price-wrapper .price {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .price-container .price-wrapper .price,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .price-container .price-wrapper .price,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .price-container .price-wrapper .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .price-container .price-wrapper .price,
  .block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .price-container .price-wrapper .price,
  .block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .price-container .price-wrapper .price {
    font-size: 24px;
  }
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .special-price .text-special-price,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .special-price .text-special-price,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .price-box .special-price .text-special-price {
  display: none;
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .field,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .field,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .field {
  display: none;
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions {
  height: auto;
  margin-bottom: 0;
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary {
  width: 100%;
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .stock.unavailable,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .stock.unavailable,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .stock.unavailable {
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 10px;
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  border: 1px solid #93002a;
}
@media screen and (min-width: 768px) {
  .block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .stock.unavailable,
  .block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .stock.unavailable,
  .block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .stock.unavailable {
    font-size: 20px;
  }
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .stock.unavailable span,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .stock.unavailable span,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .stock.unavailable span {
  text-transform: uppercase;
  color: #93002a;
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .action,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .action,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .action {
  height: 40px;
  border: solid 1px #93002a;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #93002a;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 0;
  transition: 0.5s;
  border-radius: 0;
  padding: 0;
  width: 100%;
  height: 45px;
  font-size: 16px;
  padding: 7px 10px;
}
@media screen and (min-width: 979px) {
  .block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .action,
  .block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .action,
  .block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .action {
    height: 45px;
    font-size: 22px;
  }
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .action:hover,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .action:hover,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .action:hover {
  background: #93002a;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .action,
  .block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .action,
  .block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .action {
    font-size: 20px !important;
  }
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .action span,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .action span,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .actions-primary .action span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .secondary-addto-links,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .secondary-addto-links,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-item-actions .secondary-addto-links {
  display: none;
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-reviews-summary,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-reviews-summary,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-reviews-summary {
  display: flex;
  justify-content: center;
  margin: 0;
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-reviews-summary .rating-summary,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-reviews-summary .rating-summary,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-reviews-summary .rating-summary {
  margin: 0;
  height: 30px;
  left: 0;
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-reviews-summary .rating-summary .rating-result,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-reviews-summary .rating-summary .rating-result,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-reviews-summary .rating-summary .rating-result {
  width: 125px;
  margin: 0;
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-reviews-summary .rating-summary .rating-result::before,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-reviews-summary .rating-summary .rating-result::before,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-reviews-summary .rating-summary .rating-result::before {
  content: "";
  width: 100%;
  background-image: url("../images/empty-stars-small.svg");
  background-repeat: no-repeat;
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-reviews-summary .rating-summary .rating-result span::before,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-reviews-summary .rating-summary .rating-result span::before,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-reviews-summary .rating-summary .rating-result span::before {
  position: sticky;
  content: "";
  background-image: url("../images/filled-stars-small.svg");
  background-repeat: no-repeat;
}
.block.related .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-reviews-summary .reviews-actions,
.block.crosssell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-reviews-summary .reviews-actions,
.block.upsell .block-content .wrapper .uk-slider-items .item .product-item-info .product .product-reviews-summary .reviews-actions {
  display: none;
}

.page-product-configurable .magnifier-preview img,
.catalog-product-view .magnifier-preview img {
  max-height: none !important;
}
.page-product-configurable .page-wrapper .breadcrumbs,
.catalog-product-view .page-wrapper .breadcrumbs {
  display: none;
}
.page-product-configurable .page-wrapper .page-main,
.catalog-product-view .page-wrapper .page-main {
  padding: 0 20px;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .page-title-wrapper,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .page-title-wrapper {
  padding: 0 20px;
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .page-title-wrapper,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .page-title-wrapper {
    padding: 0 20px 0 0;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .page-title-wrapper .block-title .page-title,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .page-title-wrapper .block-title .page-title {
  margin: 20px 0;
  text-align: center;
  font-size: 1.6rem;
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .page-title-wrapper .block-title .page-title,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .page-title-wrapper .block-title .page-title {
    text-align: left;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .page-title-wrapper .block-title .page-title span,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .page-title-wrapper .block-title .page-title span {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: #000000;
  line-height: normal;
  letter-spacing: normal;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .page-title-wrapper .block-title .page-title span,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .page-title-wrapper .block-title .page-title span {
    font-size: 30px;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-reviews-summary,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-reviews-summary {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-reviews-summary,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-reviews-summary {
    flex-direction: row;
    justify-content: flex-start;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-reviews-summary .rating-summary,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-reviews-summary .rating-summary {
  display: flex;
  left: 0;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-reviews-summary .rating-summary .rating-result,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-reviews-summary .rating-summary .rating-result {
  width: 125px;
  margin-left: 0;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-reviews-summary .rating-summary .rating-result::before,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-reviews-summary .rating-summary .rating-result::before {
  content: "";
  width: 100%;
  background-image: url("../images/empty-stars-small.svg");
  background-repeat: no-repeat;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-reviews-summary .rating-summary .rating-result span::before,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-reviews-summary .rating-summary .rating-result span::before {
  position: sticky;
  content: "";
  background-image: url("../images/filled-stars-small.svg");
  background-repeat: no-repeat;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-reviews-summary .reviews-actions,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-reviews-summary .reviews-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-reviews-summary .reviews-actions,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-reviews-summary .reviews-actions {
    flex-direction: row;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-reviews-summary .reviews-actions .action,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-reviews-summary .reviews-actions .action {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #93002a;
  text-decoration: underline;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-reviews-summary .reviews-actions .view,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-reviews-summary .reviews-actions .view {
    margin-right: 20px;
    margin-left: 10px;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product {
  margin-bottom: 20px;
  padding: 0 20px;
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product {
    padding: 0 20px 0 0;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product .value,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product .value {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product p,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product p {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box {
    justify-content: flex-end;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .text-old-price,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .text-old-price {
  display: none;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .normal-price .text-old-price,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .normal-price .text-old-price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  width: 100%;
  text-align: left;
  color: #93002a;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .normal-price .text-old-price p,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .normal-price .text-old-price p {
  margin-bottom: 0;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .normal-price .price-container,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .normal-price .price-container {
  display: flex;
  flex-direction: column;
  line-height: 1.4;
  margin-bottom: 15px;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .normal-price .price-container .price-label,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .normal-price .price-container .price-label {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  color: #93002a;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .normal-price .price-container .price-wrapper,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .normal-price .price-container .price-wrapper {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 30px;
  color: #93002a;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .price-container,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .price-container {
  display: flex;
  flex-direction: column;
  line-height: 1.4;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .price-container .price-label,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .price-container .price-label {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  color: #93002a;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .price-container .price-wrapper,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .price-container .price-wrapper {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 30px;
  color: #93002a;
  text-align: left;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .old-price,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .old-price {
  color: #000000;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-decoration: none;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .old-price .text-old-price,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .old-price .text-old-price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  width: 100%;
  text-align: left;
  display: block;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .old-price .text-old-price p,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .old-price .text-old-price p {
  margin-bottom: 0;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .old-price .text,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .old-price .text {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  margin-bottom: 0;
  width: 100%;
  text-align: left;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .old-price .price-container,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .old-price .price-container {
  text-decoration: line-through;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .old-price .price-container .price-label,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .old-price .price-container .price-label {
  color: #000000;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .old-price .price-container .price-wrapper,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .old-price .price-container .price-wrapper {
  color: #000000;
  text-align: left;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .special-price,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .special-price {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .special-price,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .special-price {
    margin-right: 15px;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .special-price .text-special-price,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .special-price .text-special-price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  color: #93002a;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .special-price .text-special-price p,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .special-price .text-special-price p {
  margin-bottom: 0;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .special-price .text,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .special-price .text {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  color: #93002a;
  margin-bottom: 0;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .special-price .price-container,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .special-price .price-container {
  text-align: left;
  width: 100%;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .special-price .price-container .price-wrapper,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .special-price .price-container .price-wrapper {
  color: #93002a;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .info-container,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .info-container {
  background: #000000;
  text-align: center;
  padding: 10px 20px;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .info-container,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .info-container .text,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .info-container .text {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #fff;
  margin: 0;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .info-container .mobile,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .info-container .mobile {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .info-container .desktop,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .info-container .desktop {
    display: none;
  }
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .info-container .order,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .info-container .order {
    margin-right: 5px;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .desktop,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .desktop {
  display: none;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .desktop,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .desktop {
    display: block;
    margin-bottom: 20px;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .mobile,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .mobile {
  margin-bottom: 20px;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .mobile,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-info-price .mobile {
    display: none;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main aplazo-placement,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main aplazo-placement {
  font-family: "Playfair Display";
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-store-credit-discount,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-store-credit-discount {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-addto-links,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-addto-links {
  display: none;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .links-usp,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .links-usp {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px 10px;
  padding: 20px;
  border: 1px solid #b88f01;
  margin-bottom: 20px;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .links-usp,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .links-usp {
    padding: 20px 40px;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .links-usp .content-usp,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .links-usp .content-usp {
  display: grid;
  grid-template-columns: 35% 1fr;
  align-items: center;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .links-usp .content-usp .icon,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .links-usp .content-usp .icon {
  text-align: center;
  font-size: 7px;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .links-usp .content-usp .text,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .links-usp .content-usp .text {
  margin: 0;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #b88f01;
  margin-left: 5px;
  text-align: left;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .links-usp .content-usp .text,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .links-usp .content-usp .text {
    font-size: 16px;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-wrapper .fieldset .swatch-opt .swatch-attribute,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-wrapper .fieldset .swatch-opt .swatch-attribute {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr auto;
  gap: 0px 0px;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-wrapper .fieldset .swatch-opt .swatch-attribute .swatch-attribute-label,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-wrapper .fieldset .swatch-opt .swatch-attribute .swatch-attribute-label {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  grid-area: 1/1/2/2;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-wrapper .fieldset .swatch-opt .swatch-attribute .swatch-attribute-selected-option,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-wrapper .fieldset .swatch-opt .swatch-attribute .swatch-attribute-selected-option {
  display: none;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-wrapper .fieldset .swatch-opt .swatch-attribute .swatch-attribute-options,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-wrapper .fieldset .swatch-opt .swatch-attribute .swatch-attribute-options {
  margin: 0 0 0 10px;
  grid-area: 1/2/2/3;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-wrapper .fieldset .swatch-opt .swatch-attribute .swatch-attribute-options .swatch-option,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-wrapper .fieldset .swatch-opt .swatch-attribute .swatch-attribute-options .swatch-option {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  outline: none;
  overflow: visible;
  border: 1px solid #4a4a4a;
  line-height: initial;
  height: auto;
  width: auto;
  box-shadow: none;
  background-color: #fff;
  padding: 5px 10px;
  margin-bottom: 10px;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-wrapper .fieldset .swatch-opt .swatch-attribute .swatch-attribute-options .selected,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-wrapper .fieldset .swatch-opt .swatch-attribute .swatch-attribute-options .selected {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  color: #fff;
  border: solid 1px #93002a;
  background-color: #93002a !important;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-wrapper .fieldset .swatch-opt .swatch-attribute .mage-error,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-wrapper .fieldset .swatch-opt .swatch-attribute .mage-error {
  grid-area: 2/1/3/3;
  display: block;
  font-size: 14px;
  text-align: center;
  font-weight: normal;
  width: 100%;
  padding: 5px;
  border: 1px dashed red;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  box-sizing: border-box;
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart {
    width: 100%;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset {
  display: flex;
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset {
    margin-bottom: 0;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .field,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .field {
  padding-right: 20px;
  margin-bottom: 0;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .field .label,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .field .label {
  display: none;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .field .control,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .field .control {
  padding: 5px 8px;
  border: solid 1px #4a4a4a;
  display: flex;
  align-items: center;
  height: 45px;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .field .input-text,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .field .input-text {
  height: auto;
  border: none;
  width: 100%;
  min-width: 30px;
  max-width: 60px;
  margin: 0 5px;
  padding: 0;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: #000000;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions {
    padding: 0;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .action,
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .quotation,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .action,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .quotation {
  display: flex;
  justify-content: center;
  border: 1px solid #93002a;
  background-color: #93002a;
  height: 45px;
  border-radius: 0;
  line-height: 1.6rem;
  padding: 10px 20px;
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .action,
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .quotation,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .action,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .quotation {
    width: 100%;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .action span,
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .quotation span,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .action span,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .quotation span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .action span,
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .quotation span,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .action span,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .quotation span {
    font-size: 14px;
  }
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .action span,
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .quotation span,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .action span,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .quotation span {
    font-size: 20px;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .quotation,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .quotation {
  border: 1px solid #000000 !important;
  background-color: #000000 !important;
  margin-top: 20px;
  width: 100%;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .quotation,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .quotation {
    margin: 20px 0;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .links-container-desktop,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .links-container-desktop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 20px;
  display: none;
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .links-container-desktop,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .links-container-desktop {
    padding: 0 20px 0 0;
  }
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .links-container-desktop,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .links-container-desktop {
    padding: 0;
    display: flex;
    justify-content: space-around;
    margin-bottom: 0;
    margin-top: 20px;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .links-container-desktop .wishlist,
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .links-container-desktop .share,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .links-container-desktop .wishlist,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .links-container-desktop .share {
  display: flex;
  align-items: center;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .links-container-desktop .wishlist .action,
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .links-container-desktop .share .action,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .links-container-desktop .wishlist .action,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .links-container-desktop .share .action {
  margin-left: 10px;
  padding: 0;
  background-color: transparent;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  border: none;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .links-container-desktop .wishlist .action span,
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .links-container-desktop .share .action span,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .links-container-desktop .wishlist .action span,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .links-container-desktop .share .action span {
  color: #93002a;
  display: flex;
  align-items: center;
  text-transform: lowercase;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .links-container-desktop .wishlist .action:hover,
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .links-container-desktop .share .action:hover,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .links-container-desktop .wishlist .action:hover,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .box-tocart .fieldset .actions .links-container-desktop .share .action:hover {
  text-decoration: none;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .links-container-mobile,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .links-container-mobile {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 20px;
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .links-container-mobile,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .links-container-mobile {
    padding: 0 20px 0 0;
  }
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .links-container-mobile,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .links-container-mobile {
    padding: 0 20px 0 0;
    display: none;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .links-container-mobile .mp_social_share_inline_under_cart,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .links-container-mobile .mp_social_share_inline_under_cart {
  display: none;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .links-container-mobile .wishlist,
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .links-container-mobile .share,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .links-container-mobile .wishlist,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .links-container-mobile .share {
  display: flex;
  align-items: center;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .links-container-mobile .wishlist .action,
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .links-container-mobile .share .action,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .links-container-mobile .wishlist .action,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .links-container-mobile .share .action {
  margin-left: 10px;
  padding: 0;
  background-color: transparent;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #93002a;
  border: none;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .box-tocart .fieldset,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .box-tocart .fieldset {
  display: flex;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .box-tocart .fieldset,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .box-tocart .fieldset {
    margin-bottom: 0;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .box-tocart .fieldset .field,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .box-tocart .fieldset .field {
  padding-right: 20px;
  margin-bottom: 0;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .box-tocart .fieldset .field .label,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .box-tocart .fieldset .field .label {
  display: none;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .box-tocart .fieldset .field .control,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .box-tocart .fieldset .field .control {
  padding: 5px 8px;
  border: solid 1px #4a4a4a;
  display: flex;
  align-items: center;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .box-tocart .fieldset .field .input-text,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .box-tocart .fieldset .field .input-text {
  height: auto;
  border: none;
  width: 30px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: #000000;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .box-tocart .fieldset .actions,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .box-tocart .fieldset .actions {
  width: 100%;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .box-tocart .fieldset .actions .action,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .box-tocart .fieldset .actions .action {
  display: flex;
  justify-content: center;
  border: 1px solid #93002a;
  background-color: #93002a;
  height: 45px;
  border-radius: 0;
  line-height: 1.6rem;
  padding: 10px 20px;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .box-tocart .fieldset .actions .action span,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .box-tocart .fieldset .actions .action span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .box-tocart .fieldset .actions .action span,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .box-tocart .fieldset .actions .action span {
    font-size: 14px;
  }
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .box-tocart .fieldset .actions .action span,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .box-tocart .fieldset .actions .action span {
    font-size: 20px;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .links-container,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .links-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 20px;
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .links-container,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .links-container {
    padding: 0 20px 0 0;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .links-container div,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .links-container div {
  display: flex;
  align-items: center;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .links-container div .action,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .links-container div .action {
  margin-left: 10px;
  padding: 0;
  background-color: transparent;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #93002a;
  border: none;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .links-usp,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .links-usp {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px 10px;
  padding: 20px;
  border: 1px solid #b88f01;
  margin-bottom: 20px;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .links-usp,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .links-usp {
    padding: 20px 40px;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .links-usp .content-usp,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .links-usp .content-usp {
  display: grid;
  grid-template-columns: 35% 1fr;
  align-items: center;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .links-usp .content-usp .icon,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .links-usp .content-usp .icon {
  text-align: center;
  font-size: 7px;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .links-usp .content-usp .text,
.catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .links-usp .content-usp .text {
  margin: 0;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #b88f01;
  margin-left: 5px;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .links-usp .content-usp .text,
  .catalog-product-view .page-wrapper .page-main .columns .column .product-info-main .product-add-form form .product-options-bottom .links-usp .content-usp .text {
    font-size: 16px;
  }
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item,
  .catalog-product-view .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item {
    margin-top: 20px;
  }
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__stage,
  .catalog-product-view .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__stage {
    left: 0 !important;
  }
}
@media only screen and (max-width: 979px) and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__stage,
  .catalog-product-view .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__stage {
    height: 374px !important;
  }
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__stage,
  .catalog-product-view .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__stage {
    max-height: 618px;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__stage .fotorama__arr--next .fotorama__arr__arr,
.page-product-configurable .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__stage .fotorama__arr--prev .fotorama__arr__arr,
.catalog-product-view .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__stage .fotorama__arr--next .fotorama__arr__arr,
.catalog-product-view .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__stage .fotorama__arr--prev .fotorama__arr__arr {
  background: url(../images/icons/icon-arrow.svg);
  width: 30px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(0deg);
}
.page-product-configurable .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__stage .fotorama__arr--prev .fotorama__arr__arr,
.catalog-product-view .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__stage .fotorama__arr--prev .fotorama__arr__arr {
  transform: rotate(-180deg);
}
.page-product-configurable .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__stage .fotorama__stage__shaft .fotorama__stage__frame .fotorama__img,
.catalog-product-view .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__stage .fotorama__stage__shaft .fotorama__stage__frame .fotorama__img {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__stage .fotorama__stage__shaft .fotorama__stage__frame .fotorama__img,
  .catalog-product-view .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__stage .fotorama__stage__shaft .fotorama__stage__frame .fotorama__img {
    transform: translate3d(-50%, 0%, 0);
    top: 0;
    height: 100%;
    width: auto !important;
  }
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__nav-wrap,
  .catalog-product-view .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__nav-wrap {
    right: 0;
    left: inherit;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__nav-wrap .fotorama__nav .fotorama__nav__shaft .fotorama__thumb-border,
.catalog-product-view .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__nav-wrap .fotorama__nav .fotorama__nav__shaft .fotorama__thumb-border {
  border: 1px solid #4a4a4a;
  margin-top: 0;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__nav-wrap .fotorama__nav .fotorama__nav__shaft .fotorama__nav__frame,
.catalog-product-view .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__nav-wrap .fotorama__nav .fotorama__nav__shaft .fotorama__nav__frame {
  opacity: 0.4;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__nav-wrap .fotorama__nav .fotorama__nav__shaft .fotorama__active,
.catalog-product-view .page-wrapper .page-main .columns .column .product .gallery-placeholder .fotorama-item .fotorama__wrap .fotorama__nav-wrap .fotorama__nav .fotorama__nav__shaft .fotorama__active {
  opacity: 1;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.description .description-container,
.catalog-product-view .page-wrapper .page-main .columns .column .product.description .description-container {
  display: flex;
  flex-direction: column;
  border: solid 1px #4a4a4a;
  padding: 20px 30px;
  margin-bottom: 30px;
  width: 100%;
  box-sizing: border-box;
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product.description .description-container,
  .catalog-product-view .page-wrapper .page-main .columns .column .product.description .description-container {
    flex-direction: initial;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.description .description-container .attribute-title,
.catalog-product-view .page-wrapper .page-main .columns .column .product.description .description-container .attribute-title {
  display: block;
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 20px;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.description .description-container .overview,
.catalog-product-view .page-wrapper .page-main .columns .column .product.description .description-container .overview {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product.description .description-container .overview,
  .catalog-product-view .page-wrapper .page-main .columns .column .product.description .description-container .overview {
    width: 50%;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.description .description-container .value,
.catalog-product-view .page-wrapper .page-main .columns .column .product.description .description-container .value {
  margin-right: 30px;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.description .description-container p,
.page-product-configurable .page-wrapper .page-main .columns .column .product.description .description-container .label,
.page-product-configurable .page-wrapper .page-main .columns .column .product.description .description-container .data,
.catalog-product-view .page-wrapper .page-main .columns .column .product.description .description-container p,
.catalog-product-view .page-wrapper .page-main .columns .column .product.description .description-container .label,
.catalog-product-view .page-wrapper .page-main .columns .column .product.description .description-container .data {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  padding-top: 0;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews {
  display: flex;
  flex-direction: column-reverse;
  border: solid 1px #4a4a4a;
  padding: 20px 30px;
  margin-bottom: 30px;
  width: 100%;
  box-sizing: border-box;
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product.reviews,
  .catalog-product-view .page-wrapper .page-main .columns .column .product.reviews {
    flex-direction: initial;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-list,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-list {
  padding-top: 0;
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-list,
  .catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-list {
    padding-top: 50px;
    width: 50%;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item {
  padding-top: 0;
  border-bottom: 0;
  padding-bottom: 10px;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item .review-title,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item .review-title {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item .review-top,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item .review-top {
  display: flex;
  align-items: center;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item .review-top .review-ratings,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item .review-top .review-ratings {
  margin-right: 20px;
  margin-bottom: 0;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item .review-top .review-ratings .rating-summary .rating-result,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item .review-top .review-ratings .rating-summary .rating-result {
  width: 125px;
  margin-left: 0;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item .review-top .review-ratings .rating-summary .rating-result::before,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item .review-top .review-ratings .rating-summary .rating-result::before {
  content: "";
  width: 100%;
  background-image: url("../images/empty-stars-small.svg");
  background-repeat: no-repeat;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item .review-top .review-ratings .rating-summary .rating-result span::before,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item .review-top .review-ratings .rating-summary .rating-result span::before {
  position: sticky;
  content: "";
  background-image: url("../images/filled-stars-small.svg");
  background-repeat: no-repeat;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item .review-top .review-details,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item .review-top .review-details {
  display: flex;
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item .review-top .review-details,
  .catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item .review-top .review-details {
    margin-left: 0;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item .review-top .review-details p,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item .review-top .review-details p {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin: 0;
  text-transform: capitalize;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item .review-top .review-details .review-date,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item .review-top .review-details .review-date {
  margin-left: 5px;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item .review-content,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item .review-content {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  text-transform: initial;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item:last-of-type,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .review-items .review-item:last-of-type {
  padding-bottom: 0;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .toolbar,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .toolbar {
  border-top: 1px solid #4a4a4a;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .toolbar,
  .catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-list .block-content .toolbar {
    display: none;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add,
  .catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add {
    width: 50%;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .attribute-title,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .attribute-title {
  display: block;
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 20px;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-title,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-title {
  display: block;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-title span,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-title span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form {
  max-width: 100%;
  margin-bottom: 20px;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .review-field-ratings .label,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .review-field-ratings .label {
  display: none;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .review-field-ratings .review-control-vote,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .review-field-ratings .review-control-vote {
  display: inline-block !important;
  position: relative;
  margin-bottom: 0 !important;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .review-field-ratings .review-control-vote:before,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .review-field-ratings .review-control-vote:before {
  display: none;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .review-field-ratings .review-control-vote label[class^=rating-],
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .review-field-ratings .review-control-vote label[class^=rating-] {
  display: inline-block;
  cursor: pointer;
  padding: 0;
  position: initial;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .review-field-ratings .review-control-vote label[class^=rating-]:before,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .review-field-ratings .review-control-vote label[class^=rating-]:before {
  display: none;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .review-field-ratings .review-control-vote label[class^=rating-] .rating-star,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .review-field-ratings .review-control-vote label[class^=rating-] .rating-star {
  display: inline-block;
  font-size: 19px;
  height: auto;
  width: auto;
  position: inherit;
  overflow: initial;
  clip: inherit;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .review-field-ratings .review-control-vote label[class^=rating-] .rating-star:before,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .review-field-ratings .review-control-vote label[class^=rating-] .rating-star:before {
  content: "";
  background-image: url("../images/emptyStar-big.svg");
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: inline-block;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .review-field-ratings .review-control-vote label[class^=rating-].fill .rating-star:before,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .review-field-ratings .review-control-vote label[class^=rating-].fill .rating-star:before {
  content: "";
  background-image: url("../images/filledStar-big.svg");
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  background-position: center;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs,
  .catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs {
    margin-right: 30px;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-nickname,
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-summary,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-nickname,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-summary {
  margin-bottom: 10px;
  width: 49%;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-nickname .control .input-text,
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-summary .control .input-text,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-nickname .control .input-text,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-summary .control .input-text {
  border: 1px solid #000000;
  height: 45px;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-nickname .control .input-text::placeholder,
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-summary .control .input-text::placeholder,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-nickname .control .input-text::placeholder,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-summary .control .input-text::placeholder {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-nickname .control .input-text:focus,
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-summary .control .input-text:focus,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-nickname .control .input-text:focus,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-summary .control .input-text:focus {
  box-shadow: none;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-text,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-text {
  width: 100%;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-text .control textarea,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-text .control textarea {
  border: 1px solid #000000;
  height: 150px;
  resize: none;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-text .control textarea::placeholder,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-text .control textarea::placeholder {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-text .control textarea:focus,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .form-inputs .review-field-text .control textarea:focus {
  box-shadow: none;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .actions-toolbar,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .actions-toolbar {
  margin: 0;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .actions-toolbar .primary,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .actions-toolbar .primary {
  max-width: 200px;
  width: 100%;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .actions-toolbar .primary .action,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .actions-toolbar .primary .action {
  height: 45px;
  background-color: #000000;
  width: 100%;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .actions-toolbar .primary .action span,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .actions-toolbar .primary .action span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .actions-toolbar .primary .action:hover,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .actions-toolbar .primary .action:hover {
  background-color: #fff;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .actions-toolbar .primary .action:hover span,
.catalog-product-view .page-wrapper .page-main .columns .column .product.reviews .review-add .block-content form .actions-toolbar .primary .action:hover span {
  color: #000000;
}
.page-product-configurable .page-wrapper .page-main .columns .column .detailed .items,
.catalog-product-view .page-wrapper .page-main .columns .column .detailed .items {
  border: 1px solid #4a4a4a;
}
.page-product-configurable .page-wrapper .page-main .columns .column .detailed .items .title .data,
.catalog-product-view .page-wrapper .page-main .columns .column .detailed .items .title .data {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: #000000;
  border: none;
}
.page-product-configurable .page-wrapper .page-main .columns .column .detailed .items .title .data .counter,
.catalog-product-view .page-wrapper .page-main .columns .column .detailed .items .title .data .counter {
  display: none;
}
.page-product-configurable .page-wrapper .page-main .columns .column .detailed .items .content,
.catalog-product-view .page-wrapper .page-main .columns .column .detailed .items .content {
  border: none;
}
.page-product-configurable .page-wrapper .page-main .columns .column .detailed .items .content .block .block-title,
.catalog-product-view .page-wrapper .page-main .columns .column .detailed .items .content .block .block-title {
  display: block;
}
.page-product-configurable .page-wrapper .page-main .columns .column .detailed .items .content .block .block-title strong,
.catalog-product-view .page-wrapper .page-main .columns .column .detailed .items .content .block .block-title strong {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: #000000;
}
.page-product-configurable .page-wrapper .page-main .columns .column .block.related,
.page-product-configurable .page-wrapper .page-main .columns .column .block.upsell,
.catalog-product-view .page-wrapper .page-main .columns .column .block.related,
.catalog-product-view .page-wrapper .page-main .columns .column .block.upsell {
  padding: 0;
}
.page-product-configurable .page-wrapper .page-main .columns .column .block.related .block-title strong,
.page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-title strong,
.catalog-product-view .page-wrapper .page-main .columns .column .block.related .block-title strong,
.catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-title strong {
  font-size: 24px !important;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .block.related .block-title strong,
  .page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-title strong,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.related .block-title strong,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-title strong {
    font-size: 30px !important;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .uk-slider-items,
.page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .uk-slider-items,
.catalog-product-view .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .uk-slider-items,
.catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .uk-slider-items {
  padding-bottom: 15px;
  /*                   @media screen and (max-width: 375px) and (min-width: 360px) {
    transform: translate3d(262px,0,0) !important;
  }
  @media screen and (max-width: 424px) and (min-width: 376px) {
    transform: translate3d(310px,0,0) !important;
  }
  @media screen and (max-width: 359px) and (min-width: 320px) {
    transform: translate3d(232px,0,0) !important;
  } */
}
@media screen and (min-width: 768px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .uk-slider-items,
  .page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .uk-slider-items,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .uk-slider-items,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .uk-slider-items {
    padding-bottom: 0;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .product,
.page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .product,
.catalog-product-view .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .product,
.catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .product {
  height: 45px;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .product,
  .page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .product,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .product,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .product {
    height: 55px;
  }
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .product .product-item-link,
  .page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .product .product-item-link,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .product .product-item-link,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .product .product-item-link {
    font-size: 20px;
  }
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .price-box .price-container .price-wrapper span,
  .page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .price-box .price-container .price-wrapper span,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .price-box .price-container .price-wrapper span,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .price-box .price-container .price-wrapper span {
    font-size: 24px;
  }
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .price-box .old-price .price-container .price-wrapper span,
  .page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .price-box .old-price .price-container .price-wrapper span,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .price-box .old-price .price-container .price-wrapper span,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .price-box .old-price .price-container .price-wrapper span {
    font-size: 24px;
  }
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .actions .actions-primary .action span,
  .page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .actions .actions-primary .action span,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .actions .actions-primary .action span,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .uk-slider-items .item .product-item-info .product .actions .actions-primary .action span {
    font-size: 20px;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-icon,
.page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-icon,
.catalog-product-view .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-icon,
.catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-icon {
  background: url(../images/icons/icon-arrow-izq-oscura.svg);
  content: "";
  width: 50px;
  height: 55px;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 0;
}
.page-product-configurable .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-icon:hover,
.page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-icon:hover,
.catalog-product-view .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-icon:hover,
.catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-icon:hover {
  background: url(../images/icons/icon-arrow.svg);
  content: "";
  width: 50px;
  height: 55px;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  background-position: center;
  background-color: #fff;
  border-radius: 0;
}
.page-product-configurable .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-icon svg,
.page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-icon svg,
.catalog-product-view .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-icon svg,
.catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-icon svg {
  display: none;
}
.page-product-configurable .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-position-center-right,
.page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-position-center-right,
.catalog-product-view .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-position-center-right,
.catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-position-center-right {
  transform: rotate(-180deg);
  top: 42%;
  right: -15px;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-position-center-right,
  .page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-position-center-right,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-position-center-right,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-position-center-right {
    top: 40%;
  }
}
@media screen and (min-width: 1199px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-position-center-right,
  .page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-position-center-right,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-position-center-right,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-position-center-right {
    top: 45%;
  }
}
@media screen and (min-width: 1366px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-position-center-right,
  .page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-position-center-right,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-position-center-right,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-position-center-right {
    top: 50%;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-position-center-right:hover,
.page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-position-center-right:hover,
.catalog-product-view .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-position-center-right:hover,
.catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-position-center-right:hover {
  transform: rotate(0deg);
}
.page-product-configurable .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-position-center-left,
.page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-position-center-left,
.catalog-product-view .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-position-center-left,
.catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-position-center-left {
  transform: translateY(0);
  top: 42%;
  left: -15px;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-position-center-left,
  .page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-position-center-left,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-position-center-left,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-position-center-left {
    top: 40%;
  }
}
@media screen and (min-width: 1199px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-position-center-left,
  .page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-position-center-left,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-position-center-left,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-position-center-left {
    top: 45%;
  }
}
@media screen and (min-width: 1366px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-position-center-left,
  .page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-position-center-left,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-position-center-left,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-position-center-left {
    top: 50%;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-position-center-left:hover,
.page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-position-center-left:hover,
.catalog-product-view .page-wrapper .page-main .columns .column .block.related .block-content .uk-position-relative .arrows .uk-position-center-left:hover,
.catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .uk-position-relative .arrows .uk-position-center-left:hover {
  transform: rotate(-180deg);
}
.page-product-configurable .page-wrapper .page-main .columns .column .block.upsell,
.catalog-product-view .page-wrapper .page-main .columns .column .block.upsell {
  margin-top: 40px;
}
@media screen and (min-width: 1366px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .arrows .uk-position-center-right,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .arrows .uk-position-center-right {
    top: 40%;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .arrows .uk-position-center-left,
.catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .arrows .uk-position-center-left {
  transform: translateY(0);
  top: 42%;
  left: -15px;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .arrows .uk-position-center-left,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .arrows .uk-position-center-left {
    top: 40%;
  }
}
@media screen and (min-width: 1199px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .arrows .uk-position-center-left,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .arrows .uk-position-center-left {
    top: 45%;
  }
}
@media screen and (min-width: 1366px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .arrows .uk-position-center-left,
  .catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .arrows .uk-position-center-left {
    top: 50%;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .block.upsell .block-content .arrows .uk-position-center-left:hover,
.catalog-product-view .page-wrapper .page-main .columns .column .block.upsell .block-content .arrows .uk-position-center-left:hover {
  transform: rotate(-180deg);
}
.page-product-configurable .swatch-option-tooltip,
.catalog-product-view .swatch-option-tooltip {
  display: none !important;
}

.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box {
  flex-direction: row;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box {
    justify-content: flex-start;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .normal-price {
  width: auto;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .normal-price {
    margin-right: 15px;
  }
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .normal-price .text-special-price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  color: #93002a;
}
.page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .normal-price .text-special-price p {
  margin-bottom: 0;
}
@media screen and (min-width: 979px) {
  .page-product-configurable .page-wrapper .page-main .columns .column .product-info-main .product-info-price .price-box .normal-price .price-container .price-wrapper {
    text-align: left;
  }
}

.product-carousel .row-full-width-inner {
  max-width: 100%;
}
.product-carousel h1,
.product-carousel h2,
.product-carousel h3.h4.h5,
.product-carousel h6 {
  margin-top: 40px;
  text-align: center;
  font-size: 24px;
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .product-carousel h1,
  .product-carousel h2,
  .product-carousel h3.h4.h5,
  .product-carousel h6 {
    font-size: 30px;
  }
}
.product-carousel .product-items .slick-arrow {
  background: none !important;
}
.product-carousel .product-items .slick-arrow.slick-prev {
  left: 0;
}
.product-carousel .product-items .slick-arrow.slick-next {
  right: 0;
}
.product-carousel .product-items .slick-list .slick-slide .product-item {
  padding: 0 20px;
}
.product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-photo {
  display: block;
  text-align: center;
}
.product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-photo .product-image-container .product-image-wrapper .product-image-photo {
  height: auto;
}
.product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .product-item-name .product-item-link {
  margin-top: 10px;
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  color: #000000;
  height: 45px;
  overflow: hidden;
  display: block;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .product-item-name .product-item-link {
    margin-top: 30px;
    font-size: 20px;
    height: 55px;
  }
}
@media screen and (min-width: 979px) {
  .product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .product-item-name .product-item-link {
    margin-top: 20px;
  }
}
.product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .price-box {
  height: 45px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .price-box {
    line-height: 26px;
  }
}
.product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .price-box .text-old-price {
  display: none;
}
.product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .price-box .price-container .price-wrapper .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .price-box .price-container .price-wrapper .price {
    font-size: 24px;
  }
}
.product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .price-box .old-price .text-old-price {
  display: none;
}
.product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .price-box .old-price .text {
  display: none;
}
.product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .price-box .old-price .price-container .price-wrapper .price {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .price-box .old-price .price-container .price-wrapper .price {
    font-size: 24px;
  }
}
.product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .price-box .special-price .text-special-price {
  display: none;
}
.product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .price-box .special-price .text {
  display: none;
}
.product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .price-box .normal-price .text-special-price {
  display: none;
}
.product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .price-box .normal-price .price-container .price-label {
  display: none;
}
.product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .product-item-inner .product-item-actions .actions-primary {
  width: 100%;
}
.product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .product-item-inner .product-item-actions .actions-primary .stock.unavailable {
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 10px;
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  border: 1px solid #93002a;
}
@media screen and (min-width: 768px) {
  .product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .product-item-inner .product-item-actions .actions-primary .stock.unavailable {
    font-size: 20px;
  }
}
@media screen and (min-width: 979px) {
  .product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .product-item-inner .product-item-actions .actions-primary .stock.unavailable {
    height: 48px;
  }
}
.product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .product-item-inner .product-item-actions .actions-primary .stock.unavailable span {
  text-transform: uppercase;
  color: #93002a;
}
.product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .product-item-inner .product-item-actions .actions-primary form .action {
  height: 40px;
  border: solid 1px #93002a;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #93002a;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 0;
  transition: 0.5s;
  font-size: 16px;
  height: 45px;
  padding: 7px 10px;
  width: 100%;
}
@media screen and (min-width: 979px) {
  .product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .product-item-inner .product-item-actions .actions-primary form .action {
    height: 45px;
    font-size: 22px;
  }
}
.product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .product-item-inner .product-item-actions .actions-primary form .action:hover {
  background: #93002a;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .product-item-inner .product-item-actions .actions-primary form .action {
    font-size: 20px;
  }
}
.product-carousel .product-items .slick-list .slick-slide .product-item .product-item-info .product-item-details .product-item-inner .product-item-actions .actions-primary form .action span {
  text-transform: uppercase;
}

.owl-ext-banner .owl-prev:after {
  content: url("../images/icons/icon-prev.svg") !important;
  font-size: initial !important;
}

.owl-ext-banner .owl-next:after {
  content: url("../images/icons/icon-next.svg") !important;
  font-size: initial !important;
}

.owl-theme .owl-nav [class*=owl-] {
  visibility: visible !important;
  opacity: 1 !important;
  transition: opacity 0.3s linear !important;
}

.nav-before-open.nav-open .action.nav-toggle {
  top: 0 !important;
  left: 0;
}
@media screen and (min-width: 768px) {
  .nav-before-open.nav-open .action.nav-toggle {
    top: -126px !important;
  }
}
.nav-before-open.nav-open .action.nav-toggle:before {
  background: url("../images/icons/icon-close.svg");
  content: "";
  width: 54px;
  height: 71px;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  background-position: center;
  position: absolute;
  background-color: black;
  top: 0;
  left: 0;
}
@media screen and (min-width: 768px) {
  .nav-before-open.nav-open .action.nav-toggle:before {
    right: -34px;
    left: initial;
  }
}
.nav-before-open.nav-open .action.nav-toggle::after {
  background: none;
}

@media screen and (max-width: 979px) {
  .nav-open .page-wrapper {
    overflow: visible;
    background: rgba(0, 0, 0, 0.5);
    content: "";
    display: block;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    left: calc(100% - 55px);
  }
}

.page-wrapper .page-header {
  width: 100%;
  border: none;
  margin-bottom: 0;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header {
    position: relative;
    z-index: 4;
  }
}
.page-wrapper .page-header .header {
  z-index: 14;
  padding-top: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  min-height: 70px;
  justify-content: space-between;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header {
    min-height: 120px;
  }
}
@media screen and (min-width: 768px) {
  .page-wrapper .page-header .header {
    justify-content: space-between;
    align-items: center;
  }
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header {
    margin: 0;
    padding: 0;
    max-width: 100%;
  }
}
.page-wrapper .page-header .header .action {
  flex: 1;
}
.page-wrapper .page-header .header .action::before {
  color: #fff;
}
@media screen and (min-width: 768px) and (max-width: 979px) {
  .page-wrapper .page-header .header .action {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    flex: inherit;
  }
}
.page-wrapper .page-header .header .nav-toggle {
  top: auto;
}
.page-wrapper .page-header .header .logo {
  position: absolute;
  left: 0;
  right: 0;
  width: 160px;
  margin: 0 auto;
  z-index: 3;
  float: none;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .logo {
    order: 2;
    margin: 0 auto;
    width: 249px;
    padding-top: 10px;
  }
}
@media screen and (max-width: 599px) {
  .page-wrapper .page-header .header .logo img {
    width: auto;
  }
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .logo img {
    width: 100%;
    height: 110px;
  }
}
@media screen and (max-width: 979px) {
  .page-wrapper .page-header .header > .block.block-search {
    display: none;
  }
}
.page-wrapper .page-header .header .minicart-wrapper {
  margin-top: 0 !important;
}
@media screen and (min-width: 768px) and (max-width: 979px) {
  .page-wrapper .page-header .header .minicart-wrapper {
    margin-top: 15px;
  }
}
.page-wrapper .page-header .header .minicart-wrapper .action.showcart:before {
  display: none;
}
.page-wrapper .page-header .header .minicart-wrapper .action.showcart .icon-icon-carrito-relleno {
  display: none;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .minicart-wrapper .action.showcart:hover .icon-icon-carrito-relleno {
    display: block;
    position: absolute;
    top: 6px;
  }
}
.page-wrapper .page-header .header .miniquote-wrapper .action.showquote .icon-icon-cotizacion-relleno {
  display: none;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .miniquote-wrapper .action.showquote:hover .icon-icon-quote {
    display: none;
  }
  .page-wrapper .page-header .header .miniquote-wrapper .action.showquote:hover .icon-icon-cotizacion-relleno {
    display: block;
    top: 6px;
  }
}
.page-wrapper .page-header .header .search-bar {
  visibility: hidden;
  align-items: center;
  margin-left: 20px;
  display: none;
}
@media screen and (min-width: 768px) {
  .page-wrapper .page-header .header .search-bar {
    margin-left: 0;
    z-index: 2;
  }
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .search-bar {
    display: flex;
    order: 1;
    visibility: visible;
  }
}
@media screen and (min-width: 768px) and (max-width: 979px) {
  .page-wrapper .page-header .header .search-bar .block-search {
    width: auto;
    float: none;
    padding-left: 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 979px) {
  .page-wrapper .page-header .header .search-bar .block-search .block .form .field .label {
    position: relative;
    width: 30px;
    overflow: visible;
    margin-top: 0;
    margin-right: 5px;
  }
}
.page-wrapper .page-header .header .search-bar .block-search .block .form .field .control {
  border: none;
}
@media screen and (min-width: 768px) {
  .page-wrapper .page-header .header .search-bar .block-search .block .form .field .control {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .search-bar .block-search .block .form .field .control {
    padding: 0 20px;
  }
}
@media screen and (min-width: 768px) {
  .page-wrapper .page-header .header .search-bar .block-search .block .form .field .control .actions {
    right: 15px;
    top: 30px;
  }
}
@media screen and (min-width: 768px) {
  .page-wrapper .page-header .header .search-bar .block-search .block .form .field .control .actions {
    right: 0;
    top: 0;
  }
}
.page-wrapper .page-header .header .search-bar .block-search .block .form .field .control .actions .action {
  display: none;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .search-bar .block-search .block .form .field .control .actions .action {
    display: block;
    opacity: 1;
    padding: 2px 0 0;
  }
}
@media screen and (min-width: 768px) {
  .page-wrapper .page-header .header .search-bar .block-search .block .form .field .control .actions .action::before {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .page-wrapper .page-header .header .search-bar .block-search .block .form .field .control .actions .action .icon-icon-search {
    position: relative;
  }
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .search-bar .block-search .block .form .field .control .actions .action .icon-icon-search-black {
    display: none;
  }
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .search-bar .block-search .block .form.minisearch.active .field .control .uk-position-relative .actions .action .icon-icon-search {
    display: none;
  }
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .search-bar .block-search .block .form.minisearch.active .field .control .uk-position-relative .actions .action .icon-icon-search-black {
    display: inline-block !important;
    position: relative;
    overflow: visible;
    top: -17px;
    right: 30px;
  }
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .search-bar .block-search .block .form.minisearch.active .field .control .uk-position-relative .actions .action .icon-icon-search-black:before {
    display: block !important;
  }
}
.page-wrapper .page-header .header .header-right {
  visibility: hidden;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right {
    flex: inherit;
    align-items: center;
    display: flex;
    order: 3;
    margin-right: 20px;
    visibility: visible;
    position: relative;
    top: -20px;
  }
}
.page-wrapper .page-header .header .header-right .store-info,
.page-wrapper .page-header .header .header-right .header.links {
  width: 0;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .store-info,
  .page-wrapper .page-header .header .header-right .header.links {
    width: auto;
    display: flex;
    height: auto;
    min-height: auto;
    margin-right: 15px;
  }
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .header.links {
    margin-right: 0;
  }
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .store-info .whatssap {
    display: flex;
  }
}
.page-wrapper .page-header .header .header-right .store-info .whatssap .icon-icon-whatsapp-relleno {
  display: none;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .store-info .whatssap:hover .icon-icon-whatsapp-relleno {
    display: block;
    position: absolute;
    top: 3px;
  }
}
.page-wrapper .page-header .header .header-right .header {
  background-color: transparent;
  margin-left: 5px;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .header {
    margin-left: 0;
  }
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .header .link-header_container {
    display: flex;
    align-items: center;
  }
}
.page-wrapper .page-header .header .header-right .header .link-header_container a:hover {
  text-decoration: none;
}
.page-wrapper .page-header .header .header-right .header .link-header_container a:visited {
  text-decoration: none;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .header .link-header_container a {
    margin-right: 0 !important;
  }
}
.page-wrapper .page-header .header .header-right .header .link-header_container .cotizacion {
  position: relative;
}
.page-wrapper .page-header .header .header-right .header .link-header_container .cotizacion .icon-icon-cotizacion-relleno {
  display: none;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .header .link-header_container .cotizacion:hover .icon-icon-cotizacion-relleno {
    display: block;
  }
  .page-wrapper .page-header .header .header-right .header .link-header_container .cotizacion:hover .icon-icon-quote {
    display: none;
  }
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .header .link-header_container .uk-margin-small-right {
    display: flex;
    align-items: center;
  }
}
.page-wrapper .page-header .header .header-right .header .link-header_container .uk-margin-small-right .icon-icon-account-relleno {
  display: none;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .header .link-header_container .uk-margin-small-right:hover .icon-icon-account-relleno {
    display: block;
    position: absolute;
    top: 3px;
  }
}
.page-wrapper .page-header .header .header-right .header .link-header_container .uk-margin-small-right .account-text {
  display: none;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .header .link-header_container .uk-margin-small-right .account-text {
    display: block;
    margin: 0 0 0 10px;
    font-family: "Source Sans Pro";
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    color: #fff;
  }
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .header .link-header_container .uk-inline {
    margin-right: 0;
  }
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .header .link-header_container .uk-inline a {
    display: flex;
    align-items: center;
  }
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .header .link-header_container .uk-inline a .icon-icon-account-relleno {
    display: none;
    position: absolute;
    left: 0;
    z-index: 99;
  }
}
.page-wrapper .page-header .header .header-right .header .link-header_container .uk-inline a .account-text {
  display: none;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .header .link-header_container .uk-inline a .account-text {
    display: block;
    margin: 0 0 0 10px;
    font-family: "Source Sans Pro";
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    color: #fff;
  }
}
.page-wrapper .page-header .header .header-right .header .link-header_container .uk-inline .uk-dropdown {
  padding: 0;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .header .link-header_container .uk-inline .uk-dropdown {
    left: -85px !important;
    top: 32px !important;
  }
}
.page-wrapper .page-header .header .header-right .header .link-header_container .uk-inline .uk-dropdown div p {
  padding: 10px;
  margin: 0;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
}
.page-wrapper .page-header .header .header-right .header .link-header_container .uk-inline .uk-dropdown div p:hover {
  background-color: #93002a;
}
.page-wrapper .page-header .header .header-right .header .link-header_container .uk-inline .uk-dropdown div p:hover a {
  color: #fff;
}
.page-wrapper .page-header .header .header-right .header .link-header_container .uk-inline .uk-dropdown div p a {
  color: #000000;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .header .link-header_container .uk-inline .uk-dropdown a .flecha-up {
    width: 0px;
    height: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #fff;
    font-size: 0px;
    line-height: 0px;
    position: absolute;
    left: 43%;
    top: -12px;
    z-index: -1;
  }
}
.page-wrapper .page-header .header .header-right .header .link-header_container .uk-inline:hover .icon-icon-account-relleno, .page-wrapper .page-header .header .header-right .header .link-header_container .uk-inline:visited .icon-icon-account-relleno {
  display: inline-block;
}
.page-wrapper .page-header .header .header-right .header .link-header_container .links-account-container::before {
  background: rgba(0, 0, 0, 0.6);
}
.page-wrapper .page-header .header .header-right .header .link-header_container .links-account-container .uk-offcanvas-bar {
  width: 97%;
  padding: 0;
  background-color: #000000;
}
@media screen and (min-width: 768px) {
  .page-wrapper .page-header .header .header-right .header .link-header_container .links-account-container .uk-offcanvas-bar {
    width: 50%;
  }
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .header .link-header_container .links-account-container .uk-offcanvas-bar {
    width: 28%;
    background-color: #fff;
  }
}
.page-wrapper .page-header .header .header-right .header .link-header_container .links-account-container .uk-offcanvas-bar .head {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.page-wrapper .page-header .header .header-right .header .link-header_container .links-account-container .uk-offcanvas-bar .head .title p {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 22px;
  color: #fff;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .header .link-header_container .links-account-container .uk-offcanvas-bar .head .title p {
    color: #93002a;
  }
}
.page-wrapper .page-header .header .header-right .header .link-header_container .links-account-container .uk-offcanvas-bar .head .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .header .link-header_container .links-account-container .uk-offcanvas-bar .head .mobile {
    display: none;
  }
}
@media screen and (max-width: 979px) {
  .page-wrapper .page-header .header .header-right .header .link-header_container .links-account-container .uk-offcanvas-bar .head .desktop {
    display: none;
  }
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .header .link-header_container .links-account-container .uk-offcanvas-bar .head .desktop {
    right: auto;
    left: 20px;
  }
}
.page-wrapper .page-header .header .header-right .header .link-header_container .links-account-container .uk-offcanvas-bar .uk-subnav {
  margin: 0;
}
.page-wrapper .page-header .header .header-right .header .link-header_container .links-account-container .uk-offcanvas-bar .uk-subnav li {
  width: 50%;
  text-align: center;
  padding-left: 0;
  margin-bottom: 0;
}
.page-wrapper .page-header .header .header-right .header .link-header_container .links-account-container .uk-offcanvas-bar .uk-subnav li a {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #fff;
  padding: 10px 5px;
  background-color: #93002a;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .header .link-header_container .links-account-container .uk-offcanvas-bar .uk-subnav li a {
    color: #fff;
  }
}
.page-wrapper .page-header .header .header-right .header .link-header_container .links-account-container .uk-offcanvas-bar .uk-subnav .uk-active {
  border: none;
}
.page-wrapper .page-header .header .header-right .header .link-header_container .links-account-container .uk-offcanvas-bar .uk-subnav .uk-active a {
  background-color: #000000;
  border-left: solid 1px #979797;
  border-right: solid 1px #979797;
  border-top: solid 1px #979797;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .header .link-header_container .links-account-container .uk-offcanvas-bar .uk-subnav .uk-active a {
    color: #000000;
    background-color: #fff;
  }
}
.page-wrapper .page-header .header .header-right .header .link-header_container .links-account-container .uk-offcanvas-bar .uk-switcher {
  padding: 20px;
  margin-top: 0 !important;
}
.page-wrapper .page-header .header .header-right .store-info .store-phone {
  display: none;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #fff;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .header-right .store-info .store-phone {
    display: block;
    margin: 0;
  }
}
.page-wrapper .page-header .header .header-right .minicart-wrapper {
  margin-top: 0;
  margin-left: 10px;
  visibility: visible;
}
@media screen and (min-width: 424px) {
  .page-wrapper .page-header .header .header-right .minicart-wrapper {
    margin-right: 10px;
  }
}
@media screen and (min-width: 768px) {
  .page-wrapper .page-header .header .header-right .minicart-wrapper {
    margin-left: 15px;
    margin-right: 15px;
    order: 5;
  }
}
@media screen and (min-width: 768px) {
  .page-wrapper .page-header .header .header-right .miniquote-wrapper {
    order: 4;
    margin-right: 0;
  }
}
.page-wrapper .page-header .header .mobile-right-menu {
  visibility: visible;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .mobile-right-menu {
    visibility: hidden;
    width: 0;
  }
}
.page-wrapper .page-header .header .mobile-right-menu button {
  background: none;
  border: 0;
  padding: 0;
  border-radius: 0;
}
.page-wrapper .page-header .header .mobile-right-menu .icons-container {
  display: flex;
  align-items: center;
  border: none;
  width: 100%;
  position: absolute;
  top: 100%;
  right: 0;
  margin: 0 auto;
  background-color: #000000;
  justify-content: center;
  padding: 10px 10px 20px;
}
.page-wrapper .page-header .header .mobile-right-menu .icons-container .miniquote-wrapper {
  display: inline-block;
  margin-right: 10px;
  margin-left: 5px;
}
@media screen and (min-width: 768px) {
  .page-wrapper .page-header .header .mobile-right-menu .icons-container .miniquote-wrapper {
    margin-right: 15px;
  }
}
.page-wrapper .page-header .header .mobile-right-menu .icons-container .miniquote-wrapper .action.showquote.showcart {
  display: flex;
}
.page-wrapper .page-header .header .mobile-right-menu .icons-container .custom-search {
  padding: 0px 10px;
  width: inherit;
  margin: 0 !important;
  display: block;
}
.page-wrapper .page-header .header .mobile-right-menu .icons-container .custom-search .minisearch {
  width: 100%;
}
.page-wrapper .page-header .header .mobile-right-menu .icons-container .custom-search .minisearch .control {
  left: 0;
  display: flex;
  position: initial;
  padding: 0;
  background-color: transparent;
}
.page-wrapper .page-header .header .mobile-right-menu .icons-container .custom-search .minisearch .control .input-container {
  width: 100%;
  margin: 0 auto 0 0;
}
.page-wrapper .page-header .header .mobile-right-menu .icons-container .custom-search .minisearch .control .actions {
  width: 45px;
  z-index: 9;
  display: flex;
}
.page-wrapper .page-header .header .mobile-right-menu .icons-container .custom-search .minisearch .control .actions .action {
  display: inline-block;
}
.page-wrapper .page-header .header .mobile-right-menu .icons-container .link-header_container {
  display: flex;
}
.page-wrapper .page-header .header .mobile-right-menu .icons-container .link-header_container .uk-inline {
  margin: 0 5px;
}
.page-wrapper .page-header .header .mobile-right-menu .icons-container .link-header_container .uk-inline a {
  display: flex;
}
.page-wrapper .page-header .header .mobile-right-menu .icons-container .link-header_container .uk-inline .uk-dropdown {
  padding: 0;
  left: auto !important;
  right: 0;
  top: 40px !important;
}
.page-wrapper .page-header .header .mobile-right-menu .icons-container .link-header_container .uk-inline .uk-dropdown div p {
  padding: 10px;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
}
.page-wrapper .page-header .header .mobile-right-menu .icons-container .link-header_container .uk-inline .uk-dropdown div p:hover {
  background-color: #93002a;
}
.page-wrapper .page-header .header .mobile-right-menu .icons-container .link-header_container .uk-inline .uk-dropdown div p a {
  color: #000000;
}
.page-wrapper .page-header .header .mobile-right-menu .icons-container .link-header_container .uk-inline .uk-dropdown div p a:hover {
  color: #fff;
}
.page-wrapper .page-header .header .mobile-right-menu .icons-container .link-header_container .uk-inline .uk-dropdown a .flecha-up {
  width: 0px;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #fff;
  font-size: 0px;
  line-height: 0px;
  position: absolute;
  right: 0;
  top: -12px;
  z-index: -1;
}
.page-wrapper .page-header .header .mobile-right-menu .icons-container .store-info {
  width: auto;
  display: block;
}
.page-wrapper .page-header .header .mobile-right-menu .icons-container .store-info .whatssap {
  display: flex;
}
.page-wrapper .page-header .header .mobile-right-menu .icons-container .store-info .whatssap span {
  margin-right: 5px !important;
}
.page-wrapper .page-header .header .miniquote-wrapper {
  display: none;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .header .miniquote-wrapper {
    display: inline-block;
  }
}

@media screen and (min-width: 979px) {
  .whatsapp-tooltip {
    display: none !important;
  }
}
@media screen and (min-width: 979px) {
  .whatsapp-tooltip.uk-active {
    display: none;
  }
}
.whatsapp-tooltip .uk-tooltip-inner {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #fff;
}

.uk-offcanvas-page .page-wrapper .page-header .header .header-right {
  visibility: visible;
}
.uk-offcanvas-page .page-wrapper .page-header .header .mobile-right-menu {
  visibility: hidden;
}

.message.global.demo {
  background-color: #93002a;
}
.message.global.demo .content span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
}
.message.global.demo .content span a {
  text-decoration: underline;
  color: #fff;
}

.page-footer .footer.content {
  margin: 0 auto;
  padding: 0;
  border: 0;
  max-width: 100%;
}
.page-footer .footer.content .block.newsletter {
  padding: 10px 20px 20px 20px;
  margin-bottom: 0;
  background: #93002a;
  width: auto;
  min-width: 100%;
  max-width: 100%;
}
@media screen and (min-width: 768px) and (max-width: 979px) {
  .page-footer .footer.content .block.newsletter {
    padding: 10px 35px 20px 35px;
  }
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .block.newsletter {
    display: flex;
    align-items: center;
    width: auto;
    justify-content: space-around;
    height: auto;
    flex-wrap: wrap;
    float: none;
  }
}
@media screen and (min-width: 979px) {
  .page-footer .footer.content .block.newsletter {
    justify-content: space-evenly;
    padding: 0;
    height: auto;
    flex-wrap: nowrap;
  }
}
.page-footer .footer.content .block.newsletter .info-dscto {
  padding: 20px 0;
  text-align: center;
}
@media screen and (min-width: 979px) {
  .page-footer .footer.content .block.newsletter .info-dscto {
    width: 100%;
    text-align: right;
    padding: 20px 50px 20px 0;
  }
}
.page-footer .footer.content .block.newsletter .info-dscto .text {
  color: #fff;
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  margin: 0;
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .block.newsletter .info-dscto .text {
    font-size: 20px;
  }
}
.page-footer .footer.content .block.newsletter .info-dscto .welcome {
  text-transform: uppercase;
}
.page-footer .footer.content .block.newsletter .info .text {
  font-size: 14px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 10px;
  width: 80%;
}
@media screen and (min-width: 768px) and (max-width: 979px) {
  .page-footer .footer.content .block.newsletter .info .text {
    padding: 0;
    width: 100%;
  }
}
@media screen and (min-width: 979px) {
  .page-footer .footer.content .block.newsletter .info .text {
    width: 100%;
    padding: 0;
    font-size: 22px;
  }
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .block.newsletter .content {
    width: 100%;
  }
}
@media screen and (min-width: 979px) {
  .page-footer .footer.content .block.newsletter .content {
    margin: 30px 0;
  }
}
.page-footer .footer.content .block.newsletter .content .form.subscribe {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .block.newsletter .content .form.subscribe {
    display: flex;
    width: 100%;
    margin: 0;
    align-items: flex-start;
    flex-wrap: nowrap;
  }
}
.page-footer .footer.content .block.newsletter .content .form.subscribe .newsletter {
  max-width: 100%;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .block.newsletter .content .form.subscribe .newsletter {
    margin-right: 0;
  }
}
@media screen and (min-width: 979px) {
  .page-footer .footer.content .block.newsletter .content .form.subscribe .newsletter {
    margin-right: 0;
  }
}
.page-footer .footer.content .block.newsletter .content .form.subscribe .newsletter .control:before {
  display: none;
}
.page-footer .footer.content .block.newsletter .content .form.subscribe .newsletter .control input {
  padding: 0 10px;
  height: 45px;
  border: 0;
  border-radius: 0;
  width: 100%;
  font-size: 20px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin: 0;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  background-clip: initial;
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .block.newsletter .content .form.subscribe .newsletter .control input {
    width: 100%;
  }
}
@media screen and (min-width: 979px) {
  .page-footer .footer.content .block.newsletter .content .form.subscribe .newsletter .control input {
    width: 100%;
  }
}
.page-footer .footer.content .block.newsletter .content .form.subscribe .newsletter .control ::placeholder {
  opacity: 1;
  color: #4a4a4a;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
}
@media screen and (min-width: 979px) {
  .page-footer .footer.content .block.newsletter .content .form.subscribe .newsletter .control ::placeholder {
    font-size: 20px;
    text-align: left;
  }
}
.page-footer .footer.content .block.newsletter .content .form.subscribe .actions {
  display: block;
  width: 100%;
}
.page-footer .footer.content .block.newsletter .content .form.subscribe .actions .action.subscribe {
  width: 100%;
  border-radius: 0;
  margin-top: 10px;
  background-color: #000000;
  border: none;
  margin-left: 0;
  height: 45px;
  position: relative;
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .block.newsletter .content .form.subscribe .actions .action.subscribe {
    margin-top: 0;
  }
}
@media screen and (min-width: 979px) {
  .page-footer .footer.content .block.newsletter .content .form.subscribe .actions .action.subscribe {
    width: 150px;
    text-align: center;
    font-size: 20px;
    padding: 0;
    transition: 0.5s;
  }
}
.page-footer .footer.content .block.newsletter .content .form.subscribe .actions .action.subscribe span {
  color: #fff;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  text-transform: uppercase;
}
.page-footer .footer.content .links-container {
  padding: 30px;
  background: #000000;
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .links-container {
    padding: 30px 20px 0 30px;
  }
}
@media screen and (min-width: 979px) {
  .page-footer .footer.content .links-container {
    padding: 30px 70px 15px;
  }
}
.page-footer .footer.content .links-container .sections {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-left: 0;
}
@media screen and (min-width: 768px) and (max-width: 979px) {
  .page-footer .footer.content .links-container .sections {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    align-items: flex-start;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 979px) {
  .page-footer .footer.content .links-container .sections {
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) and (max-width: 979px) {
  .page-footer .footer.content .links-container .sections .col1 {
    grid-area: 1/1/2/2;
  }
}
@media screen and (min-width: 768px) and (max-width: 979px) {
  .page-footer .footer.content .links-container .sections .col2 {
    grid-area: 1/2/2/3;
  }
}
.page-footer .footer.content .links-container .sections .column {
  margin: 0;
  width: 65%;
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .links-container .sections .column {
    width: 31%;
  }
}
@media screen and (min-width: 979px) {
  .page-footer .footer.content .links-container .sections .column {
    width: 21%;
  }
}
.page-footer .footer.content .links-container .sections .column .list {
  list-style: disc;
  padding: 0;
  margin: 10px 0 0;
}
.page-footer .footer.content .links-container .sections .column .list .item {
  font-size: 18px;
  margin-bottom: 5px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  padding-left: 10px;
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .links-container .sections .column .list .item {
    font-size: 12px;
  }
}
@media screen and (min-width: 979px) {
  .page-footer .footer.content .links-container .sections .column .list .item {
    margin-bottom: 0;
    line-height: 1.33;
  }
}
.page-footer .footer.content .links-container .sections .column .list .item a {
  color: #fff;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
}
.page-footer .footer.content .links-container .sections .info-footer {
  padding: 0;
  margin: 20px 0;
  width: 100%;
}
@media screen and (min-width: 768px) and (max-width: 979px) {
  .page-footer .footer.content .links-container .sections .info-footer {
    grid-area: 2/1/3/3;
  }
}
@media screen and (min-width: 979px) {
  .page-footer .footer.content .links-container .sections .info-footer {
    width: 35%;
    margin: 0;
  }
}
@media screen and (min-width: 1199px) {
  .page-footer .footer.content .links-container .sections .info-footer {
    width: 45%;
  }
}
@media screen and (min-width: 1366px) {
  .page-footer .footer.content .links-container .sections .info-footer {
    width: 41%;
  }
}
@media screen and (min-width: 1600px) {
  .page-footer .footer.content .links-container .sections .info-footer {
    width: 35%;
  }
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .links-container .sections .info-footer .list {
    width: 100%;
  }
}
.page-footer .footer.content .links-container .sections .info-footer .list .item {
  list-style: none;
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .links-container .sections .info-footer .list .item .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-gap: 0px 25px;
  }
}
.page-footer .footer.content .links-container .sections .info-footer .list .item .container .text-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
}
.page-footer .footer.content .links-container .sections .info-footer .list .item .container .text-container .first {
  font-weight: bold;
}
.page-footer .footer.content .links-container .sections .info-footer .list .item .container .text-container .text {
  font-size: 18px;
  font-stretch: normal;
  line-height: 1.58;
  letter-spacing: normal;
  text-align: left;
  margin: 0;
}
@media screen and (min-width: 979px) {
  .page-footer .footer.content .links-container .sections .info-footer .list .item .container .text-container .text {
    font-size: 16px;
  }
}
.page-footer .footer.content .links-container .sections .info-footer .list .item .container .text-container .spacing {
  margin: 10px 0;
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .links-container .sections .info-footer .list .item .container .email {
    grid-area: 3/1/4/3;
  }
}
.page-footer .footer.content .links-container .sections .info-footer .list .item .container .email a {
  font-size: 18px;
  text-align: left;
  text-decoration: underline;
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .links-container .sections .info-footer .list .item .container .col1 {
    grid-area: 1/1/2/2;
  }
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .links-container .sections .info-footer .list .item .container .col2 {
    grid-area: 1/2/2/3;
  }
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .links-container .sections .info-footer .list .item .container .col3 {
    grid-area: 2/1/3/2;
  }
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .links-container .sections .info-footer .list .item .container .col4 {
    grid-area: 2/2/3/3;
  }
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .links-container .info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
}
@media screen and (min-width: 979px) {
  .page-footer .footer.content .links-container .info {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 1fr;
    gap: 1px 1px;
  }
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .links-container .info .logos {
    width: 100%;
  }
}
@media screen and (min-width: 979px) {
  .page-footer .footer.content .links-container .info .logos {
    width: auto;
    margin: 0;
  }
}
.page-footer .footer.content .links-container .info .logos .subtitle {
  font-size: 18px;
  font-stretch: normal;
  line-height: 1.58;
  letter-spacing: normal;
  text-align: center;
  margin: 0;
}
@media screen and (min-width: 979px) {
  .page-footer .footer.content .links-container .info .logos .subtitle {
    text-align: left;
  }
}
.page-footer .footer.content .links-container .info .logos .list {
  display: flex;
  margin: 10px 0 30px;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .links-container .info .logos .list {
    justify-content: center;
    margin: 0;
  }
}
@media screen and (min-width: 979px) {
  .page-footer .footer.content .links-container .info .logos .list {
    justify-content: inherit;
  }
  .page-footer .footer.content .links-container .info .logos .list a,
  .page-footer .footer.content .links-container .info .logos .list .icon {
    margin: 0 20px 0 0;
  }
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .links-container .info .logos .list a {
    margin: 0 10px;
  }
}
@media screen and (min-width: 979px) {
  .page-footer .footer.content .links-container .info .logos .list a {
    margin: 0;
  }
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .links-container .info .logos .list i {
    margin: 0 5px;
  }
}
.page-footer .footer.content .links-container .info .logos:last-child .list {
  margin-bottom: 0;
}
@media screen and (min-width: 979px) {
  .page-footer .footer.content .links-container .info .logos:last-child .list {
    margin-top: 1em;
  }
}
.page-footer .footer.content .links-container .info .logos:last-child .list .icon {
  margin-bottom: 20px;
}
@media screen and (min-width: 979px) {
  .page-footer .footer.content .links-container .info .logos:last-child .list .icon {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 979px) {
  .page-footer .footer.content .links-container .info .logos:nth-child(2) {
    justify-content: center;
  }
}
.page-footer .footer.content .links-container .info .logos:nth-child(2) .list {
  width: 100%;
  margin: auto;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .links-container .info .logos:nth-child(2) .list {
    margin: 15px 0 0;
    width: 100%;
  }
}
.page-footer .footer.content .links-container .info .logos:nth-child(2) .list .icon {
  padding: 0 5px;
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .links-container .info .logos:nth-child(2) .list .icon {
    padding: 0;
  }
}
.page-footer .footer.content .links-container .info .logos:nth-child(2) .list .icon-icon-mc,
.page-footer .footer.content .links-container .info .logos:nth-child(2) .list .icon-icon-visa,
.page-footer .footer.content .links-container .info .logos:nth-child(2) .list .icon-icon-amex,
.page-footer .footer.content .links-container .info .logos:nth-child(2) .list .icon-icon-conekta {
  padding-bottom: 25px;
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .links-container .info .logos:nth-child(2) .list .icon-icon-mc,
  .page-footer .footer.content .links-container .info .logos:nth-child(2) .list .icon-icon-visa,
  .page-footer .footer.content .links-container .info .logos:nth-child(2) .list .icon-icon-amex,
  .page-footer .footer.content .links-container .info .logos:nth-child(2) .list .icon-icon-conekta {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .links-container .info .logos.methods {
    display: flex;
  }
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .links-container .info .logos.methods .col1 {
    justify-content: flex-end;
  }
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .links-container .info .logos.methods .col2 {
    justify-content: flex-start;
  }
}
.page-footer .footer.content .copyright {
  margin: 0;
  color: #fff;
  background: #000000;
  text-align: center;
  padding: 0 20px 20px 20px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .copyright {
    padding: 10px 0 30px;
  }
}
.page-footer .footer.content .copyright span {
  display: flex;
  width: 70%;
  margin: auto;
}
@media screen and (min-width: 768px) {
  .page-footer .footer.content .copyright span {
    justify-content: center;
    width: 100%;
  }
}

.onepage-index-index .page-footer .footer.content .copyright {
  padding: 30px 0;
}

body .page-wrapper .page-header {
  background-color: #000000;
}
body .page-wrapper .page-header .header .header-right .minicart-wrapper #minicart-content-toggle:before,
body .page-wrapper .page-header .header .header-right .minicart-wrapper #miniquote-content-toggle:before,
body .page-wrapper .page-header .header .header-right .miniquote-wrapper #minicart-content-toggle:before,
body .page-wrapper .page-header .header .header-right .miniquote-wrapper #miniquote-content-toggle:before {
  background: rgba(0, 0, 0, 0.75) !important;
}
body .page-wrapper .sections {
  z-index: 14;
}
@media screen and (max-width: 979px) {
  body .page-wrapper .sections {
    background: #000000 !important;
  }
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections {
    z-index: 3;
  }
}
body .page-wrapper .sections .section-items .section-item-content {
  margin-top: 0;
  padding-top: 0;
}
body .page-wrapper .sections .section-items .section-item-content .page-main {
  margin-top: 0;
  padding: 0;
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu {
    background-color: #000000;
  }
}
@media screen and (max-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu {
    width: 100%;
    display: flex;
  }
}
@media screen and (max-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu #rw-menutop {
    display: block;
  }
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .nav-10 a {
  color: #b88f01;
}
@media screen and (max-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .nav-10 a {
    background-color: #000000 !important;
  }
}
@media screen and (max-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .nav-10 .active {
    background-color: #3e0012 !important;
  }
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .bullet-level3 .level3-popup .category-item a:hover {
    color: #fff;
    background-color: #93002a;
  }
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .bullet-level3 .level3-popup .category-item a:hover .sub-cat-name::before {
    background: url(../images/media/arrow-menu-hover.png);
    content: "";
    margin: 0 10px 0 0;
    display: inline-block;
    width: 10px;
    height: 8px;
    background-repeat: no-repeat;
    background-position: center;
  }
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .bullet-level3 .level3-popup .category-item a .sub-cat-name {
    font-weight: bold;
  }
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .bullet-level3 .level3-popup .category-item a .sub-cat-name::before {
    background: url(../images/media/arrow-menu.png);
    content: "";
    margin: 0 10px 0 0;
    display: inline-block;
    width: 10px;
    height: 8px;
    background-repeat: no-repeat;
    background-position: center;
  }
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item {
  border: none !important;
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item.hover .megamenu {
  display: block;
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item a {
  border: none !important;
  text-transform: capitalize;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  padding-bottom: 20px;
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item a::first-letter {
  text-transform: capitalize;
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-click .rootmenu-arrow::before {
  top: 8px;
}
@media screen and (min-width: 768px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-click .rootmenu-arrow::before {
    top: 5px;
  }
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-click .rootmenu-arrow.rootmenu-rotate::before {
  top: 8px;
}
@media screen and (min-width: 768px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-click .rootmenu-arrow.rootmenu-rotate::before {
    top: 5px;
  }
}
@media screen and (max-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item a {
    color: #fff !important;
    background-color: #000000;
    padding: 10px 20px !important;
    font-size: 18px;
  }
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-click {
    background-color: #000000;
    height: auto;
  }
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-click .rootmenu-arrow {
    background: #000000 !important;
  }
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-click .rootmenu-arrow::after {
    display: none;
  }
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-click .rootmenu-arrow::before {
    background: url(../images/icons/icon-menu.svg);
    content: "";
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
    background-position: 50%;
    border-radius: 0;
    transform: none;
    border: none;
  }
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-click .rootmenu-rotate {
    margin-top: 10px;
  }
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-click .rootmenu-rotate::before {
    background: url(../images/icons/menu-menos-info.svg);
    content: "";
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
    background-position: 50%;
    border-radius: 0;
    top: 5px;
    transform: none;
    border: none;
  }
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .has-sub-cat {
    border: none !important;
    background-color: black !important;
    color: #fff !important;
    padding: 15px 20px !important;
    font-size: 18px;
  }
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu {
    background-color: #3e0012;
    border-top: none;
  }
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix {
    padding: 0;
  }
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix {
    padding: 0;
  }
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item {
    color: #fff;
  }
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item a {
    background: transparent;
    padding: 10px 20px;
    font-size: 18px;
  }
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .rootmenu-click {
    background-color: #3e0012;
    padding-right: 12px;
  }
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .rootmenu-click .rootmenu-arrow {
    background-color: #3e0012 !important;
  }
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level3-popup .category-item a {
    padding: 10px;
    font-size: 18px;
  }
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level3-popup .category-item .rootmenu-click {
    padding-right: 12px;
  }
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level3-popup .category-item .level4-popup .category-item a {
    padding: 10px;
    font-size: 18px;
  }
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu {
  border-top: none;
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu {
    display: none;
    padding: 10px 10px 25px;
    transition: none;
  }
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item a {
    padding: 10px;
  }
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item a:hover {
    color: #fff;
    background-color: #93002a;
  }
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item a span {
  text-transform: capitalize;
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item a .cat-arrow {
    display: none;
  }
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item a span::first-letter {
  text-transform: capitalize;
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item a .sub-cat-name {
  text-decoration: none;
}
@media screen and (max-width: 1366px) and (min-width: 1199px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item a .sub-cat-name {
    font-size: 15px;
  }
}
@media screen and (min-width: 1366px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item a .sub-cat-name {
    font-size: 18px;
  }
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item a .level2-name:hover {
    color: #fff;
  }
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item a .level2-name {
    font-family: "Source Sans Pro";
    font-weight: bold;
    font-style: normal;
  }
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level3-popup {
  border: none;
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level3-popup {
    background-color: #fff;
    top: 0;
    display: block;
    position: relative;
    box-shadow: none;
    left: 0;
    min-width: 100%;
  }
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level3-popup .bullet-level4 a .sub-cat-name {
    font-weight: normal;
  }
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level3-popup .bullet-level4 a .sub-cat-name::before {
    display: none;
  }
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level3-popup .category-item {
    margin-left: 20px;
  }
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level3-popup .category-item:hover {
  text-decoration: none;
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level3-popup .category-item a {
    padding: 10px;
  }
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level3-popup .category-item a span {
  text-transform: capitalize;
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level3-popup .category-item a span::first-letter {
  text-transform: capitalize;
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level3-popup .category-item a .sub-cat-name {
  text-decoration: none;
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level3-popup .category-item .level4-popup {
  border-bottom: none;
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level3-popup .category-item .level4-popup {
    position: relative;
    left: 0;
    box-shadow: none;
    border: none;
    top: 0;
    display: block;
    background-color: #fff;
    min-width: 100%;
  }
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level3-popup .category-item .level4-popup .category-item {
    margin-left: 0;
    padding-left: 20px;
  }
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level3-popup .category-item .level4-popup .category-item:hover {
    background-color: #93002a;
  }
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level3-popup .category-item .level4-popup .category-item a:hover {
    color: #fff;
  }
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level3-popup .category-item .level4-popup .category-item a span {
  text-decoration: none;
}
@media screen and (max-width: 1366px) and (min-width: 1199px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level3-popup .category-item .level4-popup .category-item a span {
    font-size: 15px;
  }
}
@media screen and (min-width: 1366px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level3-popup .category-item .level4-popup .category-item a span {
    font-size: 18px;
  }
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .root-sub-col-6 {
    display: flex;
    justify-content: flex-end;
  }
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .root-sub-col-6 .level2-popup {
    display: flex;
    flex-direction: column;
    width: 25%;
  }
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block {
  width: 100%;
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block {
    width: 50%;
  }
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block div[data-appearance=contained] {
  width: 100%;
  margin: 0;
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block div[data-appearance=contained] .slick-list {
  width: 100% !important;
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block div[data-appearance=contained] .slick-list .slick-track .block-title {
  position: initial !important;
  text-align: center;
  border-bottom: none;
  border-top: 1px solid #fff;
  margin: 0;
  padding: 20px 0;
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block div[data-appearance=contained] .slick-list .slick-track .block-title {
    padding: 0;
    text-align: left;
    margin-bottom: 15px;
  }
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block div[data-appearance=contained] .slick-list .slick-track .block-title strong {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #fff;
  margin: 0;
  text-transform: uppercase;
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block div[data-appearance=contained] .slick-list .slick-track .block-title strong {
    color: #000000;
  }
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block div[data-appearance=contained] .slick-list .slick-track .product-items {
  margin: 0;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  height: auto !important;
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block div[data-appearance=contained] .slick-list .slick-track .product-items {
    justify-content: flex-start;
    height: 100% !important;
  }
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block div[data-appearance=contained] .slick-list .slick-track .product-items .product-item {
  padding: 20px 0;
  background: #fff;
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block div[data-appearance=contained] .slick-list .slick-track .product-items .product-item .discount-flag {
  width: 45px;
  height: 45px;
  margin: 10px;
  border-radius: 3em;
  overflow: hidden;
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b88f01;
  transform: rotate(-25deg);
  font-size: 18px;
  color: #fff;
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  top: -15px;
  left: -18px;
}
@media screen and (min-width: 768px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block div[data-appearance=contained] .slick-list .slick-track .product-items .product-item .discount-flag {
    width: 50px;
    height: 50px;
  }
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block div[data-appearance=contained] .slick-list .slick-track .product-items .product-item .discount-flag {
    left: -10px;
  }
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block div[data-appearance=contained] .slick-list .slick-track .product-items .product-item .discount-flag .discount {
  margin-top: -5px;
}
@media screen and (min-width: 768px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block div[data-appearance=contained] .slick-list .slick-track .product-items .product-item .discount-flag .discount {
    margin-top: -6px;
  }
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block div[data-appearance=contained] .slick-list .slick-track .product-items .product-item .discount-flag .percent {
  font-size: 10.5px;
  padding-top: 5px;
}
@media screen and (min-width: 768px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block div[data-appearance=contained] .slick-list .slick-track .product-items .product-item .discount-flag .percent {
    font-size: 14px;
    padding-top: 0;
  }
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block div[data-appearance=contained] .slick-list .slick-track .product-items .product-item .product-item-info .product-item-photo {
  width: 100%;
  display: block;
  text-align: center;
  background-color: transparent !important;
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block div[data-appearance=contained] .slick-list .slick-track .product-items .product-item .product-item-info .product-item-details .product-item-name .product-item-link {
  background-color: transparent !important;
  padding: 0 !important;
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  color: #000000 !important;
  padding: 0 10px !important;
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block div[data-appearance=contained] .slick-list .slick-track .product-items .product-item .product-item-info .product-item-details .price-box {
  display: none;
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block div[data-appearance=contained] .slick-list .slick-track .product-items .product-item .product-item-info .product-item-details .product-item-inner {
  display: none;
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block .carousel-container {
  padding: 20px 12px;
  margin: 0;
  width: 100%;
  display: block;
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block .carousel-container {
    padding: 0 10px;
  }
}
@media screen and (min-width: 768px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block .carousel-container .products .owl-stage-outer {
    position: relative !important;
  }
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block .carousel-container .products .owl-stage-outer .owl-stage {
  width: 100% !important;
  display: flex;
  justify-content: center;
  transform: none !important;
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block .carousel-container .products .owl-stage-outer .owl-stage .owl-item {
  display: none;
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block .carousel-container .products .owl-stage-outer .owl-stage .owl-item:nth-child(-n+2) {
  display: block;
  padding: 0 5px;
  width: 47.5% !important;
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block .carousel-container .products .owl-stage-outer .owl-stage .owl-item:nth-child(-n+3) {
    width: 150px !important;
    display: block;
  }
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block .carousel-container .products .owl-stage-outer .owl-stage .owl-item:nth-child(2), body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block .carousel-container .products .owl-stage-outer .owl-stage .owl-item:nth-child(3) {
    margin: 0 30px;
    margin-top: 10px;
  }
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block .carousel-container .products .owl-stage-outer .owl-stage .owl-item {
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
  }
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol {
  padding: 0;
  margin: 0;
  position: relative !important;
  left: 0 !important;
  top: 0 !important;
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item {
  width: 100%;
  margin-bottom: 0;
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .product {
  padding: 0 !important;
  background: transparent;
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .product img {
    width: 140px;
  }
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info {
  padding-top: 10px;
  text-align: center;
  height: 70px;
  overflow: hidden;
  font-size: 0;
  line-height: normal;
}
body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .product-item-link {
  background-color: transparent;
  padding: 0 !important;
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .product-item-link {
    color: #000000;
  }
}
@media screen and (max-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .nav-11:hover a {
    background-color: #000000 !important;
    color: #b88f01 !important;
  }
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .nav-11 .active {
    background-color: #000000 !important;
    color: #b88f01 !important;
  }
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .nav-11 a {
    background-color: #000000 !important;
    color: #b88f01 !important;
    font-size: 18px;
  }
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .nav-2 .megamenu .root-col-4 {
    width: 100% !important;
  }
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .nav-2 .megamenu .root-col-4 .nav-2-1 a {
    width: 20%;
  }
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .nav-2 .megamenu .root-col-4 .nav-2-1 .level3-popup {
    display: flex !important;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .nav-2 .megamenu .root-col-4 .nav-2-1 .level3-popup .bullet-level4 a {
    width: 100%;
  }
}
@media screen and (min-width: 979px) {
  body .page-wrapper .sections .section-items .section-item-content .page-main .rootmenu .mobile-sub .nav-11 a {
    color: #b88f01 !important;
  }
}
body .page-wrapper .page-main {
  padding-left: 0;
  padding-right: 0;
  max-width: 100%;
}
body .page-wrapper .page-footer .footer .block .content .form .field .control label .mage-error {
  display: block;
  font-size: 14px;
  text-align: center;
  width: auto;
  padding: 5px 20px;
  border: 1px dashed red;
  box-sizing: border-box;
  background: #fff;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
}

.cms-home .page-header {
  background-color: transparent !important;
}
.cms-home .page-header .header .header-right .mobile-right-menu #right-menu {
  background-color: rgba(0, 0, 0, 0.75);
}
.cms-home .sections .section-items .section-item-content .page-main .rootmenu {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
.cms-home .page-main {
  margin: 0;
  padding: 0;
  max-width: 100%;
  margin-top: -71px;
}
@media screen and (min-width: 979px) {
  .cms-home .page-main {
    margin-top: -120px;
  }
}
@media screen and (min-width: 1199px) {
  .cms-home .page-main {
    margin-top: -169px;
  }
}
.cms-home .page-main .page.messages {
  position: absolute;
  width: 100%;
}
.cms-home .page-main .page.messages .messages .message {
  z-index: 9;
  top: 70px;
}
@media screen and (min-width: 979px) {
  .cms-home .page-main .page.messages .messages .message {
    top: 120px;
  }
}
@media screen and (min-width: 1199px) {
  .cms-home .page-main .page.messages .messages .message {
    top: 169px;
  }
}
.cms-home .page-main .columns .column {
  padding-bottom: 10px;
  overflow: hidden;
}
@media screen and (min-width: 979px) {
  .cms-home .page-main .columns .column .owl-stage-outer img {
    object-fit: cover;
  }
}
.cms-home .page-main .columns .column .owl-controls {
  top: 50%;
  height: 50%;
}
.cms-home .page-main .columns .column .owl-controls .owl-nav .owl-prev,
.cms-home .page-main .columns .column .owl-controls .owl-nav .owl-next {
  background: url(../images/icons/icon-prev.svg);
  content: "";
  width: 50px;
  height: 55px;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 0;
}
.cms-home .page-main .columns .column .owl-controls .owl-nav .owl-prev::after,
.cms-home .page-main .columns .column .owl-controls .owl-nav .owl-next::after {
  display: none;
}
.cms-home .page-main .columns .column .owl-controls .owl-nav .owl-prev:hover,
.cms-home .page-main .columns .column .owl-controls .owl-nav .owl-next:hover {
  background: url(../images/icons/icon-next.svg);
  content: "";
  width: 50px;
  height: 55px;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  background-position: center;
  background-color: #000000;
  border-radius: 0;
}
.cms-home .page-main .columns .column .owl-controls .owl-nav .owl-next {
  transform: rotate(-180deg);
}
.cms-home .page-main .columns .column .owl-controls .owl-nav .owl-next:hover {
  transform: rotate(0deg);
}
.cms-home .page-main .columns .column .owl-controls .owl-nav .owl-prev:hover {
  transform: rotate(-180deg);
}
@media screen and (min-width: 979px) {
  .cms-home .page-main .columns .column .owl-ext-banner .top-slider .owl-stage-outer * {
    height: 100vh;
  }
}
.cms-home .page-main .columns .column .owl-ext-banner .top-slider .owl-stage-outer .owl-stage .owl-item .item img {
  object-fit: cover;
  height: 100vh;
}
.cms-home .page-main .columns .column .owl-ext-banner .owl-carousel .owl-controls .owl-dots {
  left: 0;
  right: 0;
  top: auto;
  bottom: 0;
}
.cms-home .page-main .columns .column .owl-ext-banner .owl-carousel .owl-controls .owl-dots .owl-dot.active span {
  background: #b88f01;
}
.cms-home .page-main .columns .column .widget {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .cms-home .page-main .columns .column .widget .main-container {
    display: flex;
    justify-content: center;
  }
}
.cms-home .page-main .columns .column .widget .main-container .img-container {
  margin-top: 10px;
}
@media screen and (min-width: 768px) {
  .cms-home .page-main .columns .column .widget .main-container .img-container {
    display: none;
  }
  .cms-home .page-main .columns .column .widget .main-container .img-container:nth-child(-n+2) {
    display: block;
    width: 100%;
  }
  .cms-home .page-main .columns .column .widget .main-container .img-container:nth-child(1) {
    margin-right: 10px;
  }
}
@media screen and (min-width: 979px) {
  .cms-home .page-main .columns .column .widget .main-container .img-container:nth-child(-n+3) {
    display: block;
    width: 100%;
  }
  .cms-home .page-main .columns .column .widget .main-container .img-container:nth-child(3) {
    margin-left: 10px;
  }
}
.cms-home .page-main .columns .column .widget .steps {
  background-color: #93002a;
  padding: 30px 20px;
}
@media screen and (min-width: 768px) {
  .cms-home .page-main .columns .column .widget .steps {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
}
@media screen and (min-width: 979px) {
  .cms-home .page-main .columns .column .widget .steps {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 35px;
    padding: 40px 30px;
  }
}
.cms-home .page-main .columns .column .widget .steps .container {
  display: grid;
  grid-template-columns: 25% 75%;
  align-items: center;
  margin-bottom: 20px;
}
.cms-home .page-main .columns .column .widget .steps .container::after, .cms-home .page-main .columns .column .widget .steps .container::before {
  display: none;
}
@media screen and (min-width: 768px) {
  .cms-home .page-main .columns .column .widget .steps .container {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 979px) {
  .cms-home .page-main .columns .column .widget .steps .container {
    margin-bottom: 0;
    grid-template-columns: 25% 82%;
  }
}
.cms-home .page-main .columns .column .widget .steps .container .img {
  margin-right: 20px;
  text-align: center;
}
.cms-home .page-main .columns .column .widget .steps .container .text-container .text {
  margin: 0;
  color: #fff;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
}
.cms-home .page-main .columns .column .widget .steps .container .text-container .title {
  font-size: 22px;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .cms-home .page-main .columns .column .widget .steps .container .text-container .title {
    text-transform: initial;
  }
}
.cms-home .page-main .columns .column .widget .steps .container .text-container .subtitle {
  font-size: 18px;
}
.cms-home .page-main .columns .column .carousel-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
@media screen and (min-width: 979px) {
  .cms-home .page-main .columns .column .carousel-container .owl-stage-outer * {
    height: auto;
  }
}
.cms-home .page-main .columns .column .carousel-container .block-title h2 {
  text-align: center;
  font-size: 24px;
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .cms-home .page-main .columns .column .carousel-container .block-title h2 {
    font-size: 30px;
  }
}
.cms-home .page-main .columns .column .carousel-container .products {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol {
  padding-left: 0;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .discount-flag {
  width: 45px;
  height: 45px;
  margin: 10px;
  border-radius: 3em;
  overflow: hidden;
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b88f01;
  transform: rotate(-25deg);
  font-size: 18px;
  color: #fff;
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  top: -15px;
}
@media screen and (min-width: 768px) {
  .cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .discount-flag {
    font-size: 24px;
    width: 60px;
    height: 60px;
  }
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .discount-flag .discount {
  margin-top: -5px;
}
@media screen and (min-width: 768px) {
  .cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .discount-flag .discount {
    margin-top: -8px;
  }
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .discount-flag .percent {
  font-size: 10.5px;
  padding-top: 10px;
  padding-top: 5px;
}
@media screen and (min-width: 768px) {
  .cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .discount-flag .percent {
    font-size: 14px;
  }
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery {
  margin-top: 0;
  padding: 0;
  margin: 0 15px;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .product-item-link {
  margin-top: 10px;
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  color: #000000;
  height: 45px;
  overflow: hidden;
  display: block;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .product-item-link {
    margin-top: 30px;
    font-size: 20px;
    height: 55px;
  }
}
@media screen and (min-width: 979px) {
  .cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .product-item-link {
    margin-top: 20px;
  }
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .cd-price {
  color: #000000;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .cd-price .price-box {
  height: 45px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .cd-price .price-box {
    line-height: 26px;
  }
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .cd-price .price-box .text-old-price {
  display: none;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .cd-price .price-box .price-container .price-wrapper .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .cd-price .price-box .price-container .price-wrapper .price {
    font-size: 24px;
  }
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .cd-price .price-box .old-price .text-old-price {
  display: none;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .cd-price .price-box .old-price .text {
  display: none;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .cd-price .price-box .old-price .price-container .price-wrapper .price {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .cd-price .price-box .old-price .price-container .price-wrapper .price {
    font-size: 24px;
  }
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .cd-price .price-box .special-price .text-special-price {
  display: none;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .cd-price .price-box .special-price .text {
  display: none;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .cd-price .price-box .normal-price .text-special-price {
  display: none;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .cd-price .price-box .normal-price .price-container .price-label {
  display: none;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .product {
  height: 88px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .product .product-reviews-summary {
  display: flex;
  justify-content: center;
  margin: 0;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .product .product-reviews-summary .rating-summary {
  margin: 0;
  height: 30px;
  left: 0;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .product .product-reviews-summary .rating-summary .rating-result {
  width: 125px;
  margin: 0;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .product .product-reviews-summary .rating-summary .rating-result::before {
  content: "";
  width: 100%;
  background-image: url("../images/empty-stars-small.svg");
  background-repeat: no-repeat;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .product .product-reviews-summary .rating-summary .rating-result span::before {
  position: sticky;
  content: "";
  background-image: url("../images/filled-stars-small.svg");
  background-repeat: no-repeat;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .product .product-reviews-summary .reviews-actions {
  display: none;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .product .product {
  text-align: center;
  margin-bottom: 0;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .product .product .actions-primary {
  width: 100%;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .product .product .actions-primary .stock.unavailable {
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 10px;
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  border: 1px solid #93002a;
}
@media screen and (min-width: 768px) {
  .cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .product .product .actions-primary .stock.unavailable {
    font-size: 20px;
  }
}
@media screen and (min-width: 979px) {
  .cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .product .product .actions-primary .stock.unavailable {
    height: 48px;
  }
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .product .product .actions-primary .stock.unavailable span {
  text-transform: uppercase;
  color: #93002a;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .product .product .actions-primary form .action {
  height: 40px;
  border: solid 1px #93002a;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #93002a;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 0;
  transition: 0.5s;
  font-size: 16px;
  height: 45px;
  padding: 7px 10px;
  width: 100%;
}
@media screen and (min-width: 979px) {
  .cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .product .product .actions-primary form .action {
    height: 45px;
    font-size: 22px;
  }
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .product .product .actions-primary form .action:hover {
  background: #93002a;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .product .product .actions-primary form .action {
    font-size: 20px;
  }
}
.cms-home .page-main .columns .column .carousel-container .products .owl-stage-outer .owl-stage .owl-item ol .item .cd-gallery li .cd-item-info .product .product .actions-primary form .action span {
  text-transform: uppercase;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-controls {
  left: 0;
  top: 26%;
  width: 100%;
}
@media screen and (min-width: 424px) {
  .cms-home .page-main .columns .column .carousel-container .products .owl-controls {
    top: 33%;
  }
}
@media screen and (min-width: 768px) {
  .cms-home .page-main .columns .column .carousel-container .products .owl-controls {
    left: 0;
    top: 38%;
    width: 100%;
  }
}
@media screen and (min-width: 979px) {
  .cms-home .page-main .columns .column .carousel-container .products .owl-controls {
    top: 42%;
  }
}
.cms-home .page-main .columns .column .carousel-container .products .owl-controls .owl-nav .owl-prev,
.cms-home .page-main .columns .column .carousel-container .products .owl-controls .owl-nav .owl-next {
  background: url(../images/icons/icon-arrow-izq-oscura.svg);
  content: "";
  width: 50px;
  height: 55px;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 0;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-controls .owl-nav .owl-prev::after,
.cms-home .page-main .columns .column .carousel-container .products .owl-controls .owl-nav .owl-next::after {
  display: none;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-controls .owl-nav .owl-prev:hover,
.cms-home .page-main .columns .column .carousel-container .products .owl-controls .owl-nav .owl-next:hover {
  background: url(../images/icons/icon-arrow.svg);
  content: "";
  width: 50px;
  height: 55px;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  background-position: center;
  background-color: #fff;
  border-radius: 0;
}
.cms-home .page-main .columns .column .carousel-container .products .owl-controls .owl-nav .owl-next {
  transform: rotate(-180deg);
}
.cms-home .page-main .columns .column .carousel-container .products .owl-controls .owl-nav .owl-next:hover {
  transform: rotate(0deg);
}
.cms-home .page-main .columns .column .carousel-container .products .owl-controls .owl-nav .owl-prev:hover {
  transform: rotate(-180deg);
}
.cms-home .page-main .columns .column .blog__main-recent .block-title {
  text-align: center;
  margin: 20px 0;
}
.cms-home .page-main .columns .column .blog__main-recent .block-title h2 {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .cms-home .page-main .columns .column .blog__main-recent .block-title h2 {
    font-size: 30px;
  }
}
.cms-home .page-main .brands {
  padding: 20px 10px;
}
@media screen and (min-width: 979px) {
  .cms-home .page-main .brands {
    padding: 20px 40px;
  }
}
.cms-home .page-main .brands .title p {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: #000000;
  text-align: center;
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .cms-home .page-main .brands .title p {
    font-size: 30px;
  }
}
.cms-home .page-main .brands .brands-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cms-home .page-main .brands .brands-img .uk-slider-items {
  width: 100%;
  align-items: center;
}
.cms-home .page-main .brands .brands-img .uk-slider-items .uk-text-center img {
  width: 100%;
  height: 100%;
  max-width: 110px;
  max-height: 60px;
  object-fit: contain;
}
@media screen and (min-width: 768px) {
  .cms-home .page-main .brands .brands-img .uk-slider-items .uk-text-center img {
    max-width: 182px;
    max-height: 86px;
  }
}
@media screen and (min-width: 979px) {
  .cms-home .page-main .brands .brands-img .uk-slider-items .uk-text-center img {
    max-height: 100px;
    max-width: 200px;
  }
}
.cms-home .page-main .brands .brands-img .uk-slidenav {
  margin: 15px 0;
  background-color: hsla(0, 0%, 100%, 0.25);
}
.cms-home .page-main .brands .brands-img .uk-slidenav:hover {
  background-color: #fff;
}
.cms-home .page-main .brands .brands-img .uk-slidenav:focus,
.cms-home .page-main .brands .brands-img .uk-slidenav:hover,
.cms-home .page-main .brands .brands-img .uk-slidenav:visited {
  color: #000000 !important;
}
.cms-home .page-main .brands .brands-img .img {
  text-align: center;
  margin: 0 10px;
}
@media screen and (min-width: 768px) {
  .cms-home .page-main .brands .brands-img .img {
    margin: 0 20px;
  }
}
.cms-home .page-main .brands .brands-img .img:nth-child(n+4) {
  display: none;
}
@media screen and (min-width: 768px) {
  .cms-home .page-main .brands .brands-img .img:nth-child(4) {
    display: block;
  }
}
@media screen and (min-width: 979px) {
  .cms-home .page-main .brands .brands-img .img:nth-child(5) {
    display: block;
  }
  .cms-home .page-main .brands .brands-img .img:nth-child(6) {
    display: block;
  }
}
@media screen and (min-width: 979px) {
  .cms-home .page-bottom {
    max-width: 100%;
    margin-left: inherit;
    margin-right: inherit;
  }
}

.customer-account-logoutsuccess .page-main .page-title-wrapper {
  margin-top: 25px;
}
.customer-account-logoutsuccess .page-main .page-title-wrapper .block-title h1 {
  color: #000000;
  font-size: 40px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  margin: 0 15px;
}
.customer-account-logoutsuccess .page-main .columns .column p {
  font-size: 16px;
  color: #000000;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  margin: 0 15px;
}

@media screen and (min-width: 768px) and (max-width: 979px) {
  .nav-sections {
    -webkit-overflow-scrolling: touch;
    transition: left 0.3s;
    height: 100%;
    left: -80%;
    left: -100%;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 47% !important;
  }
  .nav-sections .section-items .section-item-title {
    display: block;
    float: left;
    text-align: center;
  }
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub {
    display: block;
  }
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item {
    position: relative;
    display: block;
    text-align: left;
  }
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item a {
    padding-left: 25px;
  }
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-click {
    display: block;
  }
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .has-sub-cat {
    border-top: none;
    text-align: left;
    padding-left: 25px;
  }
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .has-sub-cat::after {
    display: none;
  }
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .halfmenu,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-submenu,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu {
    position: relative;
    width: 100%;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    display: none;
  }
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .halfmenu .clearfix .clearfix,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-submenu .clearfix .clearfix,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix {
    width: 100%;
  }
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .halfmenu .clearfix .clearfix .clearfix,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-submenu .clearfix .clearfix .clearfix,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix {
    width: 100%;
  }
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .halfmenu .clearfix .clearfix .clearfix .category-item,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-submenu .clearfix .clearfix .clearfix .category-item,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item {
    display: flex;
    flex-direction: column;
  }
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .halfmenu .clearfix .clearfix .clearfix .category-item a .cat-arrow,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-submenu .clearfix .clearfix .clearfix .category-item a .cat-arrow,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item a .cat-arrow {
    display: none;
  }
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .halfmenu .clearfix .clearfix .clearfix .category-item .level3-popup,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-submenu .clearfix .clearfix .clearfix .category-item .level3-popup,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level3-popup {
    position: relative;
    left: 0;
    top: 0;
    display: none;
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding-left: 20px;
  }
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .halfmenu .clearfix .clearfix .clearfix .category-item .level3-popup .category-item a,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-submenu .clearfix .clearfix .clearfix .category-item .level3-popup .category-item a,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level3-popup .category-item a {
    float: none;
  }
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .halfmenu .clearfix .clearfix .clearfix .category-item .level4-popup,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-submenu .clearfix .clearfix .clearfix .category-item .level4-popup,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .clearfix .clearfix .category-item .level4-popup {
    position: static;
    background-color: transparent;
    border: none;
    box-shadow: none;
    display: none;
    padding-left: 20px;
  }
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .halfmenu .clearfix .rootmegamenu_block .carousel-container .products,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-submenu .clearfix .rootmegamenu_block .carousel-container .products,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block .carousel-container .products {
    height: 100% !important;
  }
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .halfmenu .clearfix .rootmegamenu_block .carousel-container .products ol,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-submenu .clearfix .rootmegamenu_block .carousel-container .products ol,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .clearfix .rootmegamenu_block .carousel-container .products ol {
    position: relative !important;
    left: 0 !important;
  }
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .halfmenu .category-item a .cat-arrow,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-submenu .category-item a .cat-arrow,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .category-item a .cat-arrow {
    display: none;
  }
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .halfmenu .category-item .rootmenu-submenu-sub,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-submenu .category-item .rootmenu-submenu-sub,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .category-item .rootmenu-submenu-sub {
    position: relative;
    left: 0;
    display: none;
  }
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .halfmenu .category-item .rootmenu-submenu-sub .category-item .rootmenu-submenu-sub-sub,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .rootmenu-submenu .category-item .rootmenu-submenu-sub .category-item .rootmenu-submenu-sub-sub,
  .nav-sections .section-item-content .page-main .rootmenu .mobile-sub .category-item .megamenu .category-item .rootmenu-submenu-sub .category-item .rootmenu-submenu-sub-sub {
    display: none;
    position: static;
  }
  .nav-open .nav-sections {
    box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
    left: 0;
    z-index: 99;
  }
  html.nav-before-open {
    overflow: hidden;
  }
  .nav-before-open {
    height: 100%;
    overflow-x: hidden;
    width: 100%;
  }
  .nav-before-open body {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .nav-open .page-wrapper {
    left: calc(100% - 55px);
    overflow: visible !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 979px) and (min-width: 768px) {
  .nav-open .page-wrapper {
    left: calc(100% - 408px);
  }
}
@media screen and (min-width: 768px) and (max-width: 979px) {
  .nav-before-open .page-wrapper {
    transition: left 0.3s;
    height: 100%;
    position: relative;
  }
}
@media screen and (min-width: 979px) {
  html.nav-before-open {
    overflow: initial;
  }
}

.ves-megamenu li.level0 > a {
  text-transform: capitalize !important;
}

.sections {
  margin-bottom: 0 !important;
}
@media screen and (max-width: 768px) {
  .sections {
    background-color: black;
  }
}
@media screen and (min-width: 979px) {
  .sections {
    background: transparent;
  }
}
.sections .section-items .section-item-title {
  /*border-width: 0;
  background: transparent;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  @media screen and (min-width: $bp-medium) {
    float: none;
    padding: 20px;
  }
  @media screen and (min-width: $bp-large) {
    display: none;
  }
  .nav-sections-item-switch {
    color: $white;
    padding-left: 10px;
    font-size: 18px;
  }*/
  display: none;
}
.sections .section-items .section-item-content {
  /*padding: 0;
  @media screen and (max-width: $bp-medium) {
    margin-top: 10px;
  }

  .block {
    padding-top: 0;
    position: static;
    top: 70px;
    width: 100%;

    .navigation {
      @media screen and (min-width: $bp-large) {
        max-width: 100%;
      }

      .ves-megamenu {
        background-color: $p-black;
        @media screen and (min-width: $bp-medium) {
          display: flex;
          flex-direction: column;
        }
        @media screen and (min-width: $bp-large) {
          display: flex;
          flex-direction: row;
          justify-content: center;
          background: rgba(0, 0, 0, 0.75);
          position: initial;
        }

        .nav-item {
          border-bottom: none;
          border-top: none;
          @media screen and (min-width: $bp-medium) {
            margin: 0;
          }
          @media screen and (min-width: $bp-large) {
            position: static;
          }

          .nav-anchor {
            @include SourceSansRegular;
            font-size: 18px;
            color: $white;
            @media screen and (min-width: $bp-medium) {
              width: 100%;
            }
            @media screen and (min-width: $bp-large) {
              color: $white;
            }

            &:hover {
              @media screen and (min-width: $bp-large) {
                background: $white;
                color: $p-black !important;
              }
            }

            .opener {
              padding: 15px 20px;
              @media screen and (min-width: $bp-medium) {
                display: block;
              }
              @media screen and (min-width: $bp-large) {
                display: none;
              }
            }
          }

          &.active > a {
            background-color: $p-blackred;
            border-color: transparent;
            @media screen and (min-width: $bp-large) {
              background-color: $white;
              color: $p-black !important;
            }
          }

          .ui-state-focus {
            background-color: $p-blackred;
            @media screen and (min-width: $bp-large) {
              background-color: $white;
              color: $p-black;
            }
          }

          .submenu {
            background-color: $p-blackred;
            @media screen and (min-width: $bp-medium) {
              top: auto !important;
              position: relative;
              overflow-x: hidden;
              width: 100% !important;
              border: 0;
              padding: 0;
            }
            @media screen and (min-width: $bp-large) {
              position: absolute;
              top: 45px !important;
              width: auto;
              background-color: $white;
              overflow: visible;
            }

            .content-wrap {
              @media screen and (min-width: $bp-large) {
                display: flex;
              }

              .megamenu-content {
                @media screen and (max-width: $bp-medium) {
                  padding-bottom: 20px;
                }
                @media screen and (min-width: $bp-large) {
                  width: 50%;
                  display: flex;
                }

                .level1 {
                  @media screen and (min-width: $bp-medium) {
                    display: flex;
                    flex-direction: column;
                  }
                  @media screen and (min-width: $bp-medium) {
                    width: 100%;
                  }

                  .row {
                    padding: 0;
                    @media screen and (min-width: $bp-large) {
                      width: 100%;
                      display: flex;
                      justify-content: flex-end;
                    }

                    .mega-col {
                      @media screen and (min-width: $bp-medium) {
                        width: 100%;
                      }
                      @media screen and (min-width: $bp-large) {
                        width: 34%;
                      }

                      .nav-item {
                        padding: 0;
                        @media screen and (min-width: $bp-large) {
                          line-height: 50px;
                        }

                        .nav-anchor {
                          padding-left: 40px;
                          background-color: $p-blackred;
                          @media screen and (min-width: $bp-large) {
                            background-color: $white;
                            color: $p-black;
                            padding: 0;
                            font-weight: bold;
                          }

                          .opener {
                            @media screen and (min-width: $bp-medium) {
                              right: 0;
                            }
                          }
                        }

                        .submenu {
                          @media screen and (min-width: $bp-medium) {
                            left: 0 !important;
                            box-shadow: none;
                          }
                          @media screen and (min-width: $bp-large) {
                            overflow-x: visible;
                            left: 10% !important;
                            box-shadow: none;
                            top: auto !important;
                            position: initial;
                          }

                          .content-wrap {
                            .megamenu-content {
                              .level2 {
                                .item-content2,
                                .item-content1 {
                                  padding-left: 0;

                                  .nav-item {
                                    .nav-anchor {
                                      @media screen and (min-width: $bp-medium) {
                                        padding-left: 60px;
                                      }
                                      @media screen and (min-width: $bp-large) {
                                        font-weight: normal;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }

                  .item-content1,
                  .item-content2 {
                    padding-left: 40px;
                    @media screen and (min-width: $bp-large) {
                      padding-left: 20px;
                    }

                    .nav-item {
                      padding-left: 0;
                      @media screen and (min-width: $bp-medium) {
                        margin: 0;
                      }
                      @media screen and (min-width: $bp-large) {
                        padding-left: 0;
                      }

                      .nav-anchor {
                        @media screen and (min-width: $bp-medium) {
                          padding-left: 0;
                        }
                        @media screen and (min-width: $bp-large) {
                          color: $p-black;
                        }
                      }
                    }
                  }
                }
              }

              .megamenu-sidebar {
                display: block;
                @media screen and (min-width: $bp-large) {
                  display: flex;
                  margin-right: 25px;
                }

                .block {
                  margin-bottom: 20px;

                  .block-title {
                    @media screen and (max-width: $bp-medium) {
                      display: block;
                      text-align: center;
                      width: 90%;
                      margin: 0 auto 20px;
                      border-top: 1px solid white;
                      padding-top: 20px;
                    }
                    @media screen and (min-width: $bp-large) {
                      margin-bottom: 0;
                      margin-top: 8px;
                    }

                    strong {
                      @include SourceSansBold;
                      font-size: 18px;
                      color: $white;
                      text-transform: uppercase;
                      @media screen and (min-width: $bp-large) {
                        color: $p-black;
                        line-height: 50px;
                      }
                    }
                  }

                  .block-content {
                    .products-grid {
                      .product-items {
                        @media screen and (max-width: $bp-medium) {
                          display: flex;
                          justify-content: space-between;
                          padding: 0 20px;
                        }
                        @media screen and (min-width: $bp-large) {
                          display: flex;
                          justify-content: flex-start;
                        }

                        .product-item {
                          @media screen and (max-width: $bp-medium) {
                            box-sizing: content-box;
                            display: flex;
                            width: calc((100% / 2) - 5px);
                            border-bottom: none;
                          }
                          @media screen and (min-width: $bp-large) {
                            width: 30%;
                          }

                          &:nth-child(2n + 1) {
                            @media screen and (max-width: $bp-medium) {
                              margin-left: 0;
                            }
                          }

                          &:nth-child(3) {
                            @media screen and (max-width: $bp-medium) {
                              display: none;
                            }
                          }

                          .product-item-info {
                            .product-item-photo {
                              .product-image-container {
                                @media screen and (max-width: $bp-medium) {
                                  max-width: 100%;
                                  width: 100% !important;
                                }

                                .product-image-wrapper {
                                  padding-bottom: 100% !important;
                                }
                              }
                            }

                            .product-item-details {
                              .product-item-name {
                                @media screen and (min-width: $bp-large) {
                                  text-align: center;
                                }

                                .product-item-link {
                                  @include SourceSansRegular;
                                  font-size: 16px;
                                  color: $white;
                                  text-align: center;
                                  @media screen and (min-width: $bp-large) {
                                    color: $p-black;
                                  }
                                }
                              }

                              .price-final_price {
                                display: none;
                              }

                              .product-item-inner {
                                display: none;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &.level-0 {
            > a {
              text-transform: capitalize;
            }
          }
        }

        .ofertas {
          .nav-anchor {
            span {
              color: $hover;
            }
          }
        }
      }
    }
  }*/
}
.sections .section-items .section-item-content .page-main {
  margin-top: 0;
}
.sections .section-items .section-item-content .header {
  background-color: #3e0012;
}
.sections .section-items .section-item-content .header li a {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #fff;
  padding-left: 20px;
  border-top: none;
}

.messages-container {
  z-index: 2;
  position: absolute;
  top: 145px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .messages-container {
    top: 115px;
  }
}
@media screen and (min-width: 979px) {
  .messages-container {
    top: 165px;
  }
}
@media screen and (min-width: 1199px) {
  .messages-container {
    top: 215px;
  }
}

.cms-no-route .page-wrapper .page-main {
  padding: 0 15px;
}
.cms-no-route .page-wrapper .page-main .page-title-wrapper {
  text-align: center;
}
@media screen and (min-width: 768px) {
  .cms-no-route .page-wrapper .page-main .page-title-wrapper .block-title .page-title {
    margin-bottom: 25px;
  }
}
.cms-no-route .page-wrapper .page-main .page-title-wrapper .block-title .page-title .base {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 26px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .cms-no-route .page-wrapper .page-main .page-title-wrapper .block-title .page-title .base {
    font-size: 30px;
  }
}
@media screen and (min-width: 768px) {
  .cms-no-route .page-wrapper .page-main .columns {
    width: 85%;
    margin: auto;
  }
}
@media screen and (min-width: 979px) {
  .cms-no-route .page-wrapper .page-main .columns {
    width: 45%;
  }
}
@media screen and (min-width: 768px) {
  .cms-no-route .page-wrapper .page-main .columns .column {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .cms-no-route .page-wrapper .page-main .columns .column .error-page .steps {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 979px) {
  .cms-no-route .page-wrapper .page-main .columns .column .error-page .steps {
    padding-left: 10%;
  }
}
.cms-no-route .page-wrapper .page-main .columns .column .error-page .steps li {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #000000;
  margin-bottom: 0;
}
@media screen and (min-width: 979px) {
  .cms-no-route .page-wrapper .page-main .columns .column .error-page .steps li {
    padding-left: 10%;
    font-size: 16px;
  }
}
.cms-no-route .page-wrapper .page-main .columns .column .error-page .steps li a {
  color: #93002a;
  text-decoration: underline;
}
.cms-no-route .page-wrapper .page-main .columns .column .error-page .btn {
  text-align: center;
}
.cms-no-route .page-wrapper .page-main .columns .column .error-page .btn .button {
  border-radius: 0;
  background-color: #93002a;
  border: 1px solid #93002a;
}
.cms-no-route .page-wrapper .page-main .columns .column .error-page .btn .button .action {
  color: #fff;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
}
.cms-no-route .page-wrapper .page-main .columns .column .error-page .img-container {
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .cms-no-route .page-wrapper .page-main .columns .column .error-page .img-container {
    text-align: center;
  }
}

.account .page-wrapper .page-main {
  padding: 20px;
}
.account .page-wrapper .page-main .page-title-wrapper {
  display: none;
}
.account .page-wrapper .page-main .columns .column {
  padding: 20px 15px;
}
@media screen and (min-width: 768px) {
  .account .page-wrapper .page-main .columns .column {
    padding: 0;
  }
}
.account .page-wrapper .page-main .columns .column .block.block-dashboard-orders .block-content .table-wrapper .data thead tr th {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
.account .page-wrapper .page-main .columns .column .block.block-dashboard-orders .block-content .table-wrapper .data tbody tr td {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.account .page-wrapper .page-main .columns .column .block.block-dashboard-orders .block-content .table-wrapper .data tbody tr td::before {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
}
.account .page-wrapper .page-main .columns .column .block.block-dashboard-orders .block-content .table-wrapper .data tbody tr .actions .action span {
  color: #93002a;
  text-decoration: underline;
}
.account .page-wrapper .page-main .columns .column .block.block-order-details-comments {
  margin-bottom: 20px;
}
.account .page-wrapper .page-main .columns .column .block.block-order-details-comments .block-content .order-comments .comment-date,
.account .page-wrapper .page-main .columns .column .block.block-order-details-comments .block-content .order-comments .comment-content {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  line-height: normal;
}
.account .page-wrapper .page-main .columns .column .order-details-items.ordered .order-title {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .account .page-wrapper .page-main .columns .column .order-details-items.ordered .order-title {
    flex-direction: row;
    align-items: center;
  }
}
@media screen and (min-width: 768px) {
  .account .page-wrapper .page-main .columns .column .order-details-items.ordered .order-title strong {
    margin: 0;
    padding-right: 10px;
  }
}
.account .page-wrapper .page-main .columns .column .order-details-items.ordered .order-title .action.track span {
  font-size: 16px;
  color: #93002a;
  text-decoration: underline;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
}
.account .page-wrapper .page-main .columns .column .block-dashboard-addresses .block-title .action span {
  font-size: 16px;
  color: #93002a;
  text-decoration: underline;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
}
.account .page-wrapper .page-main .columns .column .block-reviews-dashboard .block-title .action span {
  font-size: 16px;
  color: #93002a;
  text-decoration: underline;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
}
.account .page-wrapper .page-main .columns .column .block-reviews-dashboard .block-content .items .item .product-name a {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
.account .page-wrapper .page-main .columns .column .block-reviews-dashboard .block-content .items .item .rating-summary .label span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .account .page-wrapper .page-main .columns .column .block-addresses-list {
    overflow: auto;
  }
}
@media screen and (min-width: 768px) {
  .account .page-wrapper .page-main .columns .column .block-addresses-list .block-content .table-wrapper .data thead tr th {
    font-size: 16px;
    color: #000000;
    font-family: "Source Sans Pro";
    font-weight: bold;
    font-style: normal;
  }
}
.account .page-wrapper .page-main .columns .column .block-addresses-list .block-content .table-wrapper .data tbody tr td {
  font-size: 16px;
  color: #000000;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
}
.account .page-wrapper .page-main .columns .column .block-addresses-list .block-content .table-wrapper .data tbody tr td::before {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
}
.account .page-wrapper .page-main .columns .column .block-addresses-list .block-content .table-wrapper .data tbody tr .actions .action span {
  font-size: 16px;
  color: #93002a;
  text-decoration: underline;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
}
.account .page-wrapper .page-main .columns .column .block-addresses-list .block-content .empty {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.account .page-wrapper .page-main .columns .column .form {
  max-width: 1000px;
}
.account .page-wrapper .page-main .columns .column .form .fieldset {
  margin-bottom: 20px;
}
.account .page-wrapper .page-main .columns .column .form .legend {
  margin-left: 0;
  display: block;
  width: 100%;
  margin-bottom: 15px !important;
}
.account .page-wrapper .page-main .columns .column .form .upload-required {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.account .page-wrapper .page-main .columns .column .form .field {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .account .page-wrapper .page-main .columns .column .form .field {
    width: 30%;
  }
}
.account .page-wrapper .page-main .columns .column .form .field .label {
  width: fit-content;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.account .page-wrapper .page-main .columns .column .form .field .control {
  width: 100%;
}
.account .page-wrapper .page-main .columns .column .form .field .control input {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
  height: 45px;
  border: solid 1px #4a4a4a;
}
.account .page-wrapper .page-main .columns .column .form .field .control input ::placeholder {
  color: #4a4a4a;
}
.account .page-wrapper .page-main .columns .column .form .field .control input:focus {
  box-shadow: none;
  outline: none;
}
.account .page-wrapper .page-main .columns .column .form .field.field-name-firstname, .account .page-wrapper .page-main .columns .column .form .field.field-name-lastname, .account .page-wrapper .page-main .columns .column .form .field.field.email, .account .page-wrapper .page-main .columns .column .form .field.field.field-custom-rfc, .account .page-wrapper .page-main .columns .column .form .field.field.field-custom-company_name, .account .page-wrapper .page-main .columns .column .form .field.field.password {
  display: inline-flex;
  width: 100%;
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .account .page-wrapper .page-main .columns .column .form .field.field-name-firstname, .account .page-wrapper .page-main .columns .column .form .field.field-name-lastname, .account .page-wrapper .page-main .columns .column .form .field.field.email, .account .page-wrapper .page-main .columns .column .form .field.field.field-custom-rfc, .account .page-wrapper .page-main .columns .column .form .field.field.field-custom-company_name, .account .page-wrapper .page-main .columns .column .form .field.field.password {
    width: 45%;
    margin-right: 20px;
  }
}
.account .page-wrapper .page-main .columns .column .form .field.choice {
  flex-direction: inherit;
  align-items: center;
  margin-bottom: 10px;
  /* checkbox aspect */
  /* checked mark aspect */
  /* checked mark aspect changes */
}
@media screen and (min-width: 768px) {
  .account .page-wrapper .page-main .columns .column .form .field.choice {
    width: 45%;
  }
}
.account .page-wrapper .page-main .columns .column .form .field.choice:before {
  padding: 0;
  width: auto;
}
.account .page-wrapper .page-main .columns .column .form .field.choice [type=checkbox]:not(:checked),
.account .page-wrapper .page-main .columns .column .form .field.choice [type=checkbox]:checked {
  left: -9999px;
  position: absolute;
  top: 20px;
  visibility: hidden;
}
.account .page-wrapper .page-main .columns .column .form .field.choice [type=checkbox]:not(:checked) + label,
.account .page-wrapper .page-main .columns .column .form .field.choice [type=checkbox]:checked + label {
  position: relative;
  padding-left: 1.4em;
  cursor: pointer;
}
.account .page-wrapper .page-main .columns .column .form .field.choice [type=checkbox]:not(:checked) + label:before,
.account .page-wrapper .page-main .columns .column .form .field.choice [type=checkbox]:checked + label:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 3px;
  width: 12px;
  height: 12px;
  border: 2px solid #4a4a4a;
  background: rgba(255, 255, 255, 0.15);
}
.account .page-wrapper .page-main .columns .column .form .field.choice [type=checkbox]:checked + label:before {
  background: #4a4a4a;
}
.account .page-wrapper .page-main .columns .column .form .field.choice [type=checkbox]:not(:checked) + label:after,
.account .page-wrapper .page-main .columns .column .form .field.choice [type=checkbox]:checked + label:after {
  content: "";
  left: 3px;
  top: 5px;
  border: 2px solid #fff;
  background: transparent;
  opacity: 0;
  transform: rotate(-45deg);
  position: absolute;
  width: 9px;
  height: 5px;
  font-size: 1.4em;
  line-height: 0.8;
  transition: all 0.2s;
}
.account .page-wrapper .page-main .columns .column .form .field.choice [type=checkbox]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
  transform: rotate(-45deg);
}
.account .page-wrapper .page-main .columns .column .form .field.choice [type=checkbox]:checked + label:after {
  opacity: 1;
  border-top: none;
  border-right: none;
}
.account .page-wrapper .page-main .columns .column .documents-container {
  max-width: 930px;
}
.account .page-wrapper .page-main .columns .column .js-upload {
  flex-direction: column;
  border: 2px dashed #4a4a4a;
}
@media screen and (min-width: 979px) {
  .account .page-wrapper .page-main .columns .column .js-upload {
    flex-direction: initial;
  }
}
.account .page-wrapper .page-main .columns .column .js-upload img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}
.account .page-wrapper .page-main .columns .column .js-upload .info-container {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 979px) {
  .account .page-wrapper .page-main .columns .column .js-upload .info-container {
    display: block;
  }
}
.account .page-wrapper .page-main .columns .column .js-upload .info-container .uk-form-custom {
  float: left;
}
.account .page-wrapper .page-main .columns .column .js-upload .info-container .example-document {
  position: relative;
}
.account .page-wrapper .page-main .columns .column .js-upload .info-container .example-document:hover .tooltip {
  display: block;
  position: absolute;
  top: -130px;
  left: 0;
  background: white;
  padding: 20px;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.75);
  border: solid 1px #707070;
  border-radius: 10px;
  z-index: 1;
  min-width: 140px;
  min-height: 140px;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 424px) {
  .account .page-wrapper .page-main .columns .column .js-upload .info-container .example-document:hover .tooltip {
    top: -175px;
  }
}
@media screen and (min-width: 768px) {
  .account .page-wrapper .page-main .columns .column .js-upload .info-container .example-document:hover .tooltip {
    top: -190px;
    right: 0;
    left: auto;
    max-width: 280px;
    max-height: 335px;
  }
}
@media screen and (min-width: 979px) {
  .account .page-wrapper .page-main .columns .column .js-upload .info-container .example-document:hover .tooltip {
    top: -110px;
    left: 120px;
    min-width: 240px;
    min-height: 280px;
    max-width: 100%;
    max-height: 100%;
  }
}
.account .page-wrapper .page-main .columns .column .js-upload .info-container .example-document:hover .tooltip img {
  width: 100%;
  height: 100%;
}
.account .page-wrapper .page-main .columns .column .js-upload .info-container .example-document:hover .tooltip::after, .account .page-wrapper .page-main .columns .column .js-upload .info-container .example-document:hover .tooltip::before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.account .page-wrapper .page-main .columns .column .js-upload .info-container .example-document:hover .tooltip::after {
  border-right-color: #fff;
  border-width: 30px;
  margin-top: -30px;
}
.account .page-wrapper .page-main .columns .column .js-upload .info-container .example-document:hover .tooltip::before {
  border-right-color: #707070;
  border-width: 32px;
  margin-top: -32px;
}
.account .page-wrapper .page-main .columns .column .js-upload .info-container .example-document .tooltip {
  display: none;
}
.account .page-wrapper .page-main .columns .column .js-upload .info-container .example-document .example-link {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #93002a;
  text-decoration: underline;
}
.account .page-wrapper .page-main .columns .column .actions-toolbar {
  max-width: 920px;
  margin-left: 0;
}
.account .page-wrapper .page-main .columns .column .actions-toolbar .primary .action {
  height: 40px;
  border: solid 1px #93002a;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #93002a;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 0;
  transition: 0.5s;
}
@media screen and (min-width: 979px) {
  .account .page-wrapper .page-main .columns .column .actions-toolbar .primary .action {
    height: 45px;
    font-size: 22px;
  }
}
.account .page-wrapper .page-main .columns .column .actions-toolbar .primary .action:hover {
  background: #93002a;
  color: #fff;
}
.account .page-wrapper .page-main .columns .column .block .block-title strong,
.account .page-wrapper .page-main .columns .column .block .block-title a {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: #000000;
}
.account .page-wrapper .page-main .columns .column .block .block-content .box .box-title span {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.account .page-wrapper .page-main .columns .column .block .block-content .box .box-content p,
.account .page-wrapper .page-main .columns .column .block .block-content .box .box-content address {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.account .page-wrapper .page-main .columns .column .block .block-content .box .box-actions a {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #93002a;
  text-decoration: underline;
}
.account .page-wrapper .page-main .columns .column .block .block-content .box .box-actions .change-password {
  margin-left: 10px;
}
.account .page-wrapper .page-main .columns .column .block .alert-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  background-color: rgba(147, 0, 42, 0.2);
  border: 1px solid #93002a;
}
@media screen and (min-width: 768px) {
  .account .page-wrapper .page-main .columns .column .block .alert-container {
    height: 60px;
    padding: 0 20px;
  }
}
@media screen and (min-width: 979px) {
  .account .page-wrapper .page-main .columns .column .block .alert-container {
    padding: 0;
  }
}
.account .page-wrapper .page-main .columns .column .block .alert-container .img {
  width: 38px;
  margin-right: 20px;
  margin-right: 20px;
}
.account .page-wrapper .page-main .columns .column .block .alert-container .text {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.account .page-wrapper .page-main .columns .column .block .alert-container .text strong {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.account .page-wrapper .page-main .columns .column .block .alert-container .text a {
  color: #93002a;
  text-decoration: underline;
}
.account .page-wrapper .page-main .columns .column .message {
  color: #000000;
  background-color: rgba(147, 0, 42, 0.2);
  text-align: center;
  border: 1px solid #93002a;
}
@media screen and (min-width: 768px) {
  .account .page-wrapper .page-main .columns .column .message {
    padding: 20px;
  }
}
@media screen and (min-width: 979px) {
  .account .page-wrapper .page-main .columns .column .message {
    width: 50%;
    margin: 10px auto 10px 15%;
  }
}
.account .page-wrapper .page-main .columns .column .message span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
}
.account .page-wrapper .page-main .columns .column .message span::before {
  position: relative;
  top: 5px;
}
.account .page-wrapper .page-main .columns .column .actions-toolbar .secondary span {
  font-size: 16px;
  color: #93002a;
  text-decoration: underline;
}
.account .page-wrapper .page-main .columns .form-address-edit .fieldset .legend span,
.account .page-wrapper .page-main .columns .form .fieldset .legend span {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: #000000;
}
.account .page-wrapper .page-main .columns .form-address-edit .fieldset .field .label span,
.account .page-wrapper .page-main .columns .form .fieldset .field .label span {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.account .page-wrapper .page-main .columns .form-address-edit .actions-toolbar .primary .action,
.account .page-wrapper .page-main .columns .form .actions-toolbar .primary .action {
  height: 40px;
  border: solid 1px #93002a;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #93002a;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 0;
  transition: 0.5s;
  padding: 7px 15px;
}
@media screen and (min-width: 979px) {
  .account .page-wrapper .page-main .columns .form-address-edit .actions-toolbar .primary .action,
  .account .page-wrapper .page-main .columns .form .actions-toolbar .primary .action {
    height: 45px;
    font-size: 22px;
  }
}
.account .page-wrapper .page-main .columns .form-address-edit .actions-toolbar .primary .action:hover,
.account .page-wrapper .page-main .columns .form .actions-toolbar .primary .action:hover {
  background: #93002a;
  color: #fff;
}
.account .page-wrapper .page-main .columns .form-address-edit .actions-toolbar .secondary .action span,
.account .page-wrapper .page-main .columns .form .actions-toolbar .secondary .action span {
  font-size: 16px;
  color: #93002a;
  text-decoration: underline;
}
.account .page-wrapper .page-main .columns .form-address-edit .password-info p,
.account .page-wrapper .page-main .columns .form .password-info p {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.account .page-wrapper .page-main .columns .form-address-edit .password-info p a,
.account .page-wrapper .page-main .columns .form .password-info p a {
  font-size: 16px;
  color: #93002a;
  text-decoration: underline;
}
.account .page-wrapper .page-main .columns .toolbar .pager .toolbar-amount {
  margin-bottom: 10px;
  padding-top: 5px;
}
@media screen and (min-width: 979px) {
  .account .page-wrapper .page-main .columns .toolbar .pager .toolbar-amount {
    padding-top: 7px;
  }
}
.account .page-wrapper .page-main .columns .toolbar .pager .toolbar-amount .toolbar-number {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.account .page-wrapper .page-main .columns .toolbar .pager .limiter .limiter-label,
.account .page-wrapper .page-main .columns .toolbar .pager .limiter .limiter-text {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.account .page-wrapper .page-main .columns .toolbar .pager .limiter .limiter-options {
  border: 1px solid #4a4a4a;
}
.account .page-wrapper .page-main .columns .form-wishlist-items .products-grid .product-items .product-item .product-item-info .product-item-name {
  text-align: center;
}
.account .page-wrapper .page-main .columns .form-wishlist-items .products-grid .product-items .product-item .product-item-info .product-item-name .product-item-link {
  margin-top: 10px;
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .account .page-wrapper .page-main .columns .form-wishlist-items .products-grid .product-items .product-item .product-item-info .product-item-name .product-item-link {
    margin-top: 35px;
    font-size: 20px;
    height: 50px;
  }
}
@media screen and (min-width: 979px) {
  .account .page-wrapper .page-main .columns .form-wishlist-items .products-grid .product-items .product-item .product-item-info .product-item-name .product-item-link {
    margin-top: 20px;
  }
}
.account .page-wrapper .page-main .columns .form-wishlist-items .products-grid .product-items .product-item .product-item-info .price-box {
  height: 45px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .account .page-wrapper .page-main .columns .form-wishlist-items .products-grid .product-items .product-item .product-item-info .price-box {
    line-height: 26px;
    height: 55px;
  }
}
.account .page-wrapper .page-main .columns .form-wishlist-items .products-grid .product-items .product-item .product-item-info .price-box .price-as-configured .price-container .price-wrapper .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .account .page-wrapper .page-main .columns .form-wishlist-items .products-grid .product-items .product-item .product-item-info .price-box .price-as-configured .price-container .price-wrapper .price {
    font-size: 24px;
  }
}
.account .page-wrapper .page-main .columns .form-wishlist-items .products-grid .product-items .product-item .product-item-info .product-item-inner {
  text-align: center;
}
.account .page-wrapper .page-main .columns .form-wishlist-items .products-grid .product-items .product-item .product-item-info .product-item-inner .field .control .product-item-comment {
  border: 1px solid #4a4a4a;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
}
.account .page-wrapper .page-main .columns .form-wishlist-items .products-grid .product-items .product-item .product-item-info .product-item-inner .box-tocart {
  display: flex;
  justify-content: center;
  float: none;
  margin: 0;
}
.account .page-wrapper .page-main .columns .form-wishlist-items .products-grid .product-items .product-item .product-item-info .product-item-inner .box-tocart .fieldset .field {
  display: flex;
  justify-content: center;
  align-items: center;
}
.account .page-wrapper .page-main .columns .form-wishlist-items .products-grid .product-items .product-item .product-item-info .product-item-inner .box-tocart .fieldset .field .label {
  margin-right: 5px;
}
@media screen and (min-width: 768px) {
  .account .page-wrapper .page-main .columns .form-wishlist-items .products-grid .product-items .product-item .product-item-info .product-item-inner .box-tocart .fieldset .field .label {
    margin-right: 0;
  }
}
.account .page-wrapper .page-main .columns .form-wishlist-items .products-grid .product-items .product-item .product-item-info .product-item-inner .box-tocart .fieldset .field .label span {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.account .page-wrapper .page-main .columns .form-wishlist-items .products-grid .product-items .product-item .product-item-info .product-item-inner .box-tocart .fieldset .field .control {
  width: auto;
}
.account .page-wrapper .page-main .columns .form-wishlist-items .products-grid .product-items .product-item .product-item-info .product-item-inner .box-tocart .fieldset .field .control .input-text {
  border: 1px solid #4a4a4a;
}
.account .page-wrapper .page-main .columns .form-wishlist-items .products-grid .product-items .product-item .product-item-info .product-item-inner .box-tocart .fieldset .product-item-actions .actions-primary .action {
  height: 40px;
  border: solid 1px #93002a;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #93002a;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 0;
  transition: 0.5s;
}
@media screen and (min-width: 979px) {
  .account .page-wrapper .page-main .columns .form-wishlist-items .products-grid .product-items .product-item .product-item-info .product-item-inner .box-tocart .fieldset .product-item-actions .actions-primary .action {
    height: 45px;
    font-size: 22px;
  }
}
.account .page-wrapper .page-main .columns .form-wishlist-items .products-grid .product-items .product-item .product-item-info .product-item-inner .box-tocart .fieldset .product-item-actions .actions-primary .action:hover {
  background: #93002a;
  color: #fff;
}
.account .page-wrapper .page-main .columns .form-wishlist-items .products-grid .product-items .product-item .product-item-info .product-item-inner .product-item-actions {
  display: flex;
  justify-content: center;
  float: none;
}
.account .page-wrapper .page-main .columns .form-wishlist-items .products-grid .product-items .product-item .product-item-info .product-item-inner .product-item-actions a span {
  font-size: 16px;
  color: #93002a;
  text-decoration: underline;
}
.account .page-wrapper .page-main .columns .form-wishlist-items .actions-toolbar .primary {
  display: flex;
  flex-wrap: wrap;
}
.account .page-wrapper .page-main .columns .form-wishlist-items .actions-toolbar .primary button {
  height: 40px;
  border: solid 1px #93002a;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #93002a;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 0;
  transition: 0.5s;
}
@media screen and (min-width: 979px) {
  .account .page-wrapper .page-main .columns .form-wishlist-items .actions-toolbar .primary button {
    height: 45px;
    font-size: 22px;
  }
}
.account .page-wrapper .page-main .columns .form-wishlist-items .actions-toolbar .primary button:hover {
  background: #93002a;
  color: #fff;
}
.account .page-wrapper .page-main .columns .form-wishlist-items .actions-toolbar .secondary a span {
  font-size: 16px;
  color: #93002a;
  text-decoration: underline;
}
.account .page-wrapper .page-main .columns .sidebar .block.block-collapsible-nav {
  position: initial !important;
}
.account .page-wrapper .page-main .columns .sidebar .block.block-collapsible-nav .block-collapsible-nav-title {
  display: none;
}
.account .page-wrapper .page-main .columns .sidebar .block.block-collapsible-nav .block-collapsible-nav-content {
  display: block;
}
.account .page-wrapper .page-main .columns .sidebar .block .content {
  padding-top: 0;
  background-color: #fff;
  border: 1px solid #4a4a4a;
  padding-bottom: 0;
}
.account .page-wrapper .page-main .columns .sidebar .block .content .nav .nav a {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  text-transform: uppercase;
  padding: 10px 10px 10px 20px;
  border-left: none;
}
.account .page-wrapper .page-main .columns .sidebar .block .content .nav .nav a:hover {
  background-color: #93002a;
  color: #fff;
}
.account .page-wrapper .page-main .columns .sidebar .block .content .nav .nav a:hover strong {
  color: #fff;
}
.account .page-wrapper .page-main .columns .sidebar .block .content .nav .nav a strong {
  padding-left: 0;
}
.account .page-wrapper .page-main .columns .sidebar .block .content .nav .nav strong {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  padding: 10px 10px 10px 20px;
}
.account .page-wrapper .page-main .columns .sidebar .block .content .nav .nav .delimiter {
  display: none;
}
.account .page-wrapper .page-main .columns .sidebar .block .content .nav .current strong {
  border-color: inherit;
  border-left: none;
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  color: #93002a;
}
.account .page-wrapper .page-main .columns .sidebar-additional .block .block-title strong {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .account .page-wrapper .page-main .columns .sidebar-additional .block .block-title strong {
    font-size: 20px;
  }
}
@media screen and (min-width: 979px) {
  .account .page-wrapper .page-main .columns .sidebar-additional .block .block-title strong {
    font-size: 24px;
  }
}
.account .page-wrapper .page-main .columns .sidebar-additional .block .block-content .form #cart-sidebar-reorder .product-item .product-item-name .product-item-link span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.account .page-wrapper .page-main .columns .sidebar-additional .block .block-content .form .actions-toolbar {
  margin: 15px auto;
}
@media screen and (min-width: 768px) {
  .account .page-wrapper .page-main .columns .sidebar-additional .block .block-content .form .actions-toolbar {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
.account .page-wrapper .page-main .columns .sidebar-additional .block .block-content .form .actions-toolbar .primary .action {
  margin: 0;
}
@media screen and (min-width: 768px) {
  .account .page-wrapper .page-main .columns .sidebar-additional .block .block-content .form .actions-toolbar .primary .action span {
    font-size: 16px;
  }
}
@media screen and (min-width: 979px) {
  .account .page-wrapper .page-main .columns .sidebar-additional .block .block-content .form .actions-toolbar .primary .action span {
    font-size: 20x;
  }
}

.documents-container-modal {
  max-width: 680px;
  left: 0 !important;
  right: 0;
  width: 90%;
  margin: 20px auto;
}
@media screen and (min-width: 768px) {
  .documents-container-modal {
    margin: 0 auto;
    width: 100%;
  }
}
.documents-container-modal .action-close:before {
  background: url(../images/icons/icon-cerrar.svg);
  content: "";
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: cover;
}
.documents-container-modal .modal-inner-wrap {
  width: 100%;
  border-radius: 5px;
}
.documents-container-modal .modal-inner-wrap .modal-header {
  padding-top: 0;
}
.documents-container-modal .modal-inner-wrap .documents-modal {
  padding: 0;
}
@media screen and (min-width: 768px) {
  .documents-container-modal .modal-inner-wrap .documents-modal {
    padding: 30px 30px;
  }
}
.documents-container-modal .modal-inner-wrap .documents-modal > .uk-flex {
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .documents-container-modal .modal-inner-wrap .documents-modal > .uk-flex {
    flex-direction: initial;
  }
}
@media screen and (min-width: 768px) {
  .documents-container-modal .modal-inner-wrap .documents-modal .uk-flex {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-gap: 0 10px;
  }
}
.documents-container-modal .modal-inner-wrap .documents-modal .uk-flex .title {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .documents-container-modal .modal-inner-wrap .documents-modal .uk-flex .title {
    grid-area: 1/1/2/3;
  }
}
@media screen and (min-width: 768px) {
  .documents-container-modal .modal-inner-wrap .documents-modal .uk-flex .container {
    grid-area: 2/1/3/2;
  }
}
@media screen and (min-width: 768px) {
  .documents-container-modal .modal-inner-wrap .documents-modal .uk-flex .text {
    grid-area: 2/2/3/3;
  }
}
.documents-container-modal .modal-inner-wrap .documents-modal .content .info,
.documents-container-modal .modal-inner-wrap .documents-modal .content li {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.documents-container-modal .modal-inner-wrap .documents-modal .content li {
  margin-bottom: 3px;
  list-style: disc;
  text-align: left;
}
.documents-container-modal .modal-inner-wrap .documents-modal .container {
  width: 100%;
  max-width: 100%;
  text-align: center;
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .documents-container-modal .modal-inner-wrap .documents-modal .container {
    max-width: 120px;
    margin-top: 60px;
    margin-bottom: 0;
  }
}
.documents-container-modal .modal-inner-wrap .documents-modal .text {
  width: 100%;
}
.documents-container-modal .modal-inner-wrap .documents-modal .uk-button-secondary {
  height: 40px;
  border: solid 1px #93002a;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #93002a;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 0;
  transition: 0.5s;
  width: 100%;
  background: #000000;
  border: 1px solid #000000;
  color: #fff;
  font-size: 15px;
}
@media screen and (min-width: 979px) {
  .documents-container-modal .modal-inner-wrap .documents-modal .uk-button-secondary {
    height: 45px;
    font-size: 22px;
  }
}
.documents-container-modal .modal-inner-wrap .documents-modal .uk-button-secondary:hover {
  background: #93002a;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .documents-container-modal .modal-inner-wrap .documents-modal .uk-button-secondary {
    width: 70%;
    font-size: 20px;
  }
}
.documents-container-modal .modal-inner-wrap .documents-modal .uk-button-secondary:hover {
  background: #000000;
  color: #fff;
}

.wishlist-index-index .page-wrapper .page-main .columns .column .page-title-wrapper {
  display: block;
}
.wishlist-index-index .page-wrapper .page-main .columns .column .page-title-wrapper .block-title .page-title {
  margin-top: 0;
  margin-bottom: 15px;
}
.wishlist-index-index .page-wrapper .page-main .columns .column .page-title-wrapper .block-title .page-title .base {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: #000000;
}

.uk-active div #offcanvas-login .background-login .custom-login .close-arrow {
  display: none;
}
.uk-active div #offcanvas-login .background-login .custom-login .block-title p {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 22px;
  text-align: center;
  color: #fff;
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-login .background-login .custom-login .block-title p {
    color: #000000;
  }
}
.uk-active div #offcanvas-login .background-login .custom-login .block-title .helper {
  display: none;
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .field {
  margin: 0;
}
@media screen and (min-width: 768px) {
  .uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .field .control {
    width: 100%;
  }
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .field .control .input-text {
  height: 45px;
  border: 1px solid #fff !important;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 0;
  margin: 5px 0;
  padding-left: 15px !important;
  color: #fff;
  text-align: left !important;
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .field .control .input-text {
    background: #fff;
    border: 1px solid #000000 !important;
    color: #4a4a4a;
  }
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .field .control .input-text::placeholder {
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  color: #fff;
  padding-left: 0;
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .field .control .input-text::placeholder {
    color: #4a4a4a;
  }
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .field .control .input-text:focus {
  color: #4a4a4a;
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .field .control input:not([disabled]):focus {
  background-color: #fff;
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .field .control input:not([disabled]):focus::placeholder {
  color: #4a4a4a;
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .field .control .mage-error {
  display: block;
  font-size: 14px;
  text-align: center;
  font-weight: normal;
  width: 100%;
  padding: 5px;
  border: 1px dashed red;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  box-sizing: border-box;
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .password .secondary {
  text-align: center;
}
@media screen and (min-width: 768px) {
  .uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .password .secondary .action {
    display: inline-block;
  }
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .password .secondary .action span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #fff;
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .password .secondary .action span {
    color: #000000;
  }
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .password .secondary .text {
  color: #93002a;
  text-decoration: underline;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .actions-toolbar .messages {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .actions-toolbar .messages .error {
  font-size: 16px;
  color: red;
  text-align: center;
  border: 1px dashed red;
  padding: 5px;
  width: 100%;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .rememberme .control {
  text-align: center;
  /* checkbox aspect */
  /* checked mark aspect */
  /* checked mark aspect changes */
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .rememberme .control .label span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #fff;
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .rememberme .control .label span {
    color: #000000;
  }
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .rememberme .control [type=checkbox]:not(:checked),
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .rememberme .control [type=checkbox]:checked {
  left: -9999px;
  position: absolute;
  top: 20px;
  visibility: hidden;
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .rememberme .control [type=checkbox]:not(:checked) + label,
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .rememberme .control [type=checkbox]:checked + label {
  position: relative;
  padding-left: 1.2em;
  cursor: pointer;
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .rememberme .control [type=checkbox]:not(:checked) + label:before,
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .rememberme .control [type=checkbox]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  width: 12px;
  height: 12px;
  border: 2px solid #fff;
  background: rgba(255, 255, 255, 0.15);
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .rememberme .control [type=checkbox]:not(:checked) + label:before,
  .uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .rememberme .control [type=checkbox]:checked + label:before {
    border: 2px solid #4a4a4a;
    top: 10px;
  }
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .rememberme .control [type=checkbox]:checked + label:before {
  background: #93002a;
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .rememberme .control [type=checkbox]:not(:checked) + label:after,
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .rememberme .control [type=checkbox]:checked + label:after {
  content: "";
  left: 3px;
  top: 10px;
  border: 2px solid #fff;
  background: transparent;
  opacity: 0;
  transform: rotate(-45deg);
  position: absolute;
  width: 9px;
  height: 5px;
  font-size: 1.4em;
  line-height: 0.8;
  transition: all 0.2s;
}
@media screen and (min-width: 768px) {
  .uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .rememberme .control [type=checkbox]:not(:checked) + label:after,
  .uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .rememberme .control [type=checkbox]:checked + label:after {
    top: 12px;
    height: 3px;
  }
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .rememberme .control [type=checkbox]:not(:checked) + label:after,
  .uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .rememberme .control [type=checkbox]:checked + label:after {
    border: 2px solid #fff;
    top: 14px;
  }
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .rememberme .control [type=checkbox]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
  transform: rotate(-45deg);
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .rememberme .control [type=checkbox]:checked + label:after {
  opacity: 1;
  border-top: none;
  border-right: none;
}
@media screen and (min-width: 768px) {
  .uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .actions-toolbar .primary {
    width: 100%;
  }
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .actions-toolbar .primary .action {
  background-color: #93002a;
  color: #fff;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  border: none;
  border-radius: 0;
  padding: 10px 0;
  text-transform: uppercase;
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .actions-toolbar .primary .action {
    width: 100%;
  }
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .social-login .title .text {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 22px;
  color: #fff;
  text-align: center;
  margin: 20px 0 0;
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .social-login .title .text {
    color: #000000;
  }
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .social-login .pslogin-block .pslogin-buttons {
  width: 100%;
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .social-login .pslogin-block .pslogin-buttons ul .pslogin-button {
  width: 100%;
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .social-login .pslogin-block .pslogin-buttons ul .pslogin-button .pslogin-button-link .pslogin-button-auto {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 45px;
  margin-top: 20px;
  background-color: #1d71b8;
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .social-login .pslogin-block .pslogin-buttons ul .pslogin-button .pslogin-button-link .pslogin-button-auto .pslogin-button-icon {
  left: 45px;
  top: auto;
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .social-login .pslogin-block .pslogin-buttons ul .pslogin-button .pslogin-button-link .pslogin-button-auto .pslogin-button-text {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  min-width: auto;
  margin-left: 10px;
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .social-login .logined {
  padding-top: 20px;
  text-align: center;
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .social-login .logined span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #fff;
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .social-login .logined span {
    color: #000000;
  }
}
.uk-active div #offcanvas-login .background-login .custom-login .block-content .fieldset .social-login .logined a {
  color: #93002a;
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .uk-active div #offcanvas-register .background-uikit .form {
    min-width: auto;
    width: 100%;
  }
}
.uk-active div #offcanvas-register .background-uikit .form .block-title p {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 22px;
  text-align: center;
  color: #fff;
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-register .background-uikit .form .block-title p {
    color: #000000;
  }
}
.uk-active div #offcanvas-register .background-uikit .form .block-title .helper {
  display: none;
}
.uk-active div #offcanvas-register .background-uikit .form .field .label {
  display: none;
}
@media screen and (min-width: 768px) {
  .uk-active div #offcanvas-register .background-uikit .form .field .control {
    width: 100%;
  }
}
.uk-active div #offcanvas-register .background-uikit .form .field .control .input-text {
  height: 45px;
  border: 1px solid #fff !important;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 0;
  margin: 5px 0;
  padding: 0;
  padding-left: 15px;
  color: #fff;
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-register .background-uikit .form .field .control .input-text {
    color: #4a4a4a;
    background: #fff;
    border: 1px solid #000000 !important;
  }
}
.uk-active div #offcanvas-register .background-uikit .form .field .control .input-text::placeholder {
  font-size: 16px;
  color: #fff;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  padding-left: 0;
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-register .background-uikit .form .field .control .input-text::placeholder {
    color: #4a4a4a;
  }
}
.uk-active div #offcanvas-register .background-uikit .form .field .control .input-text:focus {
  color: #4a4a4a;
}
.uk-active div #offcanvas-register .background-uikit .form .field .control input:not([disabled]):focus {
  background-color: #fff;
}
.uk-active div #offcanvas-register .background-uikit .form .field .control input:not([disabled]):focus::placeholder {
  color: #4a4a4a;
}
.uk-active div #offcanvas-register .background-uikit .form .field .control .mage-error {
  display: block;
  font-size: 14px;
  text-align: center;
  font-weight: normal;
  width: 100%;
  padding: 5px;
  border: 1px dashed red;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  box-sizing: border-box;
  margin: 10px 0 0 !important;
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-register .background-uikit .form .field .control #password-error {
    margin-top: 2px;
  }
}
.uk-active div #offcanvas-register .background-uikit .form .fieldset {
  margin: 0;
}
.uk-active div #offcanvas-register .background-uikit .form .fieldset .field {
  margin: 0;
}
.uk-active div #offcanvas-register .background-uikit .form .fieldset .field .label {
  display: none;
}
.uk-active div #offcanvas-register .background-uikit .form .fieldset .field .control #password-strength-meter-container {
  margin: 5px 0;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
.uk-active div #offcanvas-register .background-uikit .form .password-container {
  display: flex;
  justify-content: center;
}
.uk-active div #offcanvas-register .background-uikit .form .password-container .password {
  width: 100%;
}
.uk-active div #offcanvas-register .background-uikit .form .password-container .password .control {
  width: 97%;
  margin-right: auto;
}
.uk-active div #offcanvas-register .background-uikit .form .password-container .password .control #password-strength-meter-container {
  display: none;
}
.uk-active div #offcanvas-register .background-uikit .form .password-container .password .input-text {
  padding-left: 5px;
}
.uk-active div #offcanvas-register .background-uikit .form .password-container .password .input-text::placeholder {
  padding-left: 0;
}
.uk-active div #offcanvas-register .background-uikit .form .password-container .confirmation {
  width: 100%;
}
.uk-active div #offcanvas-register .background-uikit .form .password-container .confirmation .control {
  width: 97%;
  margin-left: auto;
}
.uk-active div #offcanvas-register .background-uikit .form .password-container .confirmation .input-text {
  padding-left: 5px;
}
.uk-active div #offcanvas-register .background-uikit .form .password-container .confirmation .input-text::placeholder {
  padding-left: 0;
}
.uk-active div #offcanvas-register .background-uikit .form .business .control {
  text-align: center;
  /* checkbox aspect */
  /* checked mark aspect */
  /* checked mark aspect changes */
}
.uk-active div #offcanvas-register .background-uikit .form .business .control .label {
  display: block;
}
.uk-active div #offcanvas-register .background-uikit .form .business .control .label span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #fff;
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-register .background-uikit .form .business .control .label span {
    color: #000000;
  }
}
.uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:not(:checked),
.uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:checked {
  left: -9999px;
  position: absolute;
  top: 20px;
  visibility: hidden;
}
.uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:not(:checked) + label,
.uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:checked + label {
  position: relative;
  padding-left: 1.2em;
  cursor: pointer;
}
.uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:not(:checked) + label:before,
.uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:checked + label:before {
  content: "";
  position: absolute;
  left: 20px;
  top: 10px;
  width: 12px;
  height: 12px;
  border: 2px solid #fff;
  background: rgba(255, 255, 255, 0.15);
}
@media screen and (min-width: 424px) {
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:not(:checked) + label:before,
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:checked + label:before {
    left: 55px;
  }
}
@media screen and (min-width: 768px) {
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:not(:checked) + label:before,
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:checked + label:before {
    left: 35px;
  }
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:not(:checked) + label:before,
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:checked + label:before {
    border: 2px solid #4a4a4a;
    top: 10px;
  }
}
@media screen and (min-width: 1199px) {
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:not(:checked) + label:before,
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:checked + label:before {
    left: 20px;
  }
}
@media screen and (min-width: 1366px) {
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:not(:checked) + label:before,
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:checked + label:before {
    left: 35px;
  }
}
@media screen and (min-width: 1600px) {
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:not(:checked) + label:before,
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:checked + label:before {
    left: 65px;
  }
}
@media screen and (min-width: 1920px) {
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:not(:checked) + label:before,
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:checked + label:before {
    left: 115px;
  }
}
.uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:checked + label:before {
  background: #93002a;
}
.uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:not(:checked) + label:after,
.uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:checked + label:after {
  content: "";
  left: 23px;
  top: 12px;
  border: 2px solid #fff;
  background: transparent;
  opacity: 0;
  transform: rotate(-45deg);
  position: absolute;
  width: 9px;
  height: 5px;
  font-size: 1.4em;
  line-height: 0.8;
  transition: all 0.2s;
}
@media screen and (min-width: 424px) {
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:not(:checked) + label:after,
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:checked + label:after {
    left: 58px;
    top: 12px;
  }
}
@media screen and (min-width: 768px) {
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:not(:checked) + label:after,
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:checked + label:after {
    top: 14px;
    height: 3px;
    left: 38px;
  }
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:not(:checked) + label:after,
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:checked + label:after {
    border: 2px solid #fff;
  }
}
@media screen and (min-width: 1199px) {
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:not(:checked) + label:after,
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:checked + label:after {
    left: 23px;
  }
}
@media screen and (min-width: 1366px) {
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:not(:checked) + label:after,
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:checked + label:after {
    left: 38px;
  }
}
@media screen and (min-width: 1600px) {
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:not(:checked) + label:after,
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:checked + label:after {
    left: 68px;
  }
}
@media screen and (min-width: 1920px) {
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:not(:checked) + label:after,
  .uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:checked + label:after {
    left: 118px;
  }
}
.uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
  transform: rotate(-45deg);
}
.uk-active div #offcanvas-register .background-uikit .form .business .control [type=checkbox]:checked + label:after {
  opacity: 1;
  border-top: none;
  border-right: none;
}
.uk-active div #offcanvas-register .background-uikit .form .actions-toolbar {
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .uk-active div #offcanvas-register .background-uikit .form .actions-toolbar .primary {
    width: 100%;
  }
}
.uk-active div #offcanvas-register .background-uikit .form .actions-toolbar .primary .action {
  background-color: #93002a;
  color: #fff;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  border: none;
  border-radius: 0;
  padding: 10px 0;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .uk-active div #offcanvas-register .background-uikit .form .actions-toolbar .primary .action {
    width: 100%;
  }
}
.uk-active div #offcanvas-register .background-uikit .form .actions-toolbar .primary .messages {
  line-height: 1;
  margin-bottom: 20px;
}
.uk-active div #offcanvas-register .background-uikit .form .actions-toolbar .primary .messages .error {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #fff;
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-register .background-uikit .form .actions-toolbar .primary .messages .error {
    color: #000000;
  }
}
.uk-active div #offcanvas-register .background-uikit .form .actions-toolbar .primary .messages .error a {
  color: #b88f01;
  text-decoration: underline;
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-register .background-uikit .form .actions-toolbar .primary .messages .error a {
    color: #93002a;
  }
}
.uk-active div #offcanvas-register .background-uikit .form .social-login .title .text {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 22px;
  color: #fff;
  text-align: center;
  margin: 20px 0 0;
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-register .background-uikit .form .social-login .title .text {
    color: #000000;
  }
}
.uk-active div #offcanvas-register .background-uikit .form .social-login .pslogin-block .pslogin-buttons {
  width: 100%;
}
.uk-active div #offcanvas-register .background-uikit .form .social-login .pslogin-block .pslogin-buttons ul .pslogin-button {
  width: 100%;
}
.uk-active div #offcanvas-register .background-uikit .form .social-login .pslogin-block .pslogin-buttons ul .pslogin-button .pslogin-button-link .pslogin-button-auto {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 45px;
  margin-top: 20px;
  background-color: #1d71b8;
}
.uk-active div #offcanvas-register .background-uikit .form .social-login .pslogin-block .pslogin-buttons ul .pslogin-button .pslogin-button-link .pslogin-button-auto .pslogin-button-icon {
  left: 35px;
  top: auto;
}
.uk-active div #offcanvas-register .background-uikit .form .social-login .pslogin-block .pslogin-buttons ul .pslogin-button .pslogin-button-link .pslogin-button-auto .pslogin-button-text {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  min-width: auto;
  margin-left: 10px;
}
.uk-active div #offcanvas-register .background-uikit .form .social-login .legals {
  padding-top: 20px;
}
.uk-active div #offcanvas-register .background-uikit .form .social-login .legals .control {
  text-align: center;
  /* checkbox aspect */
  /* checked mark aspect */
  /* checked mark aspect changes */
}
.uk-active div #offcanvas-register .background-uikit .form .social-login .legals .control .label {
  display: block;
  width: 85%;
  margin: auto;
  line-height: 16px;
}
.uk-active div #offcanvas-register .background-uikit .form .social-login .legals .control .label span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #fff;
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-register .background-uikit .form .social-login .legals .control .label span {
    color: #000000;
  }
}
.uk-active div #offcanvas-register .background-uikit .form .social-login .legals .control .label span a {
  color: #b88f01;
  text-decoration: underline;
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-register .background-uikit .form .social-login .legals .control .label span a {
    color: #93002a;
  }
}
.uk-active div #offcanvas-register .background-uikit .form .social-login .legals .control [type=checkbox]:not(:checked),
.uk-active div #offcanvas-register .background-uikit .form .social-login .legals .control [type=checkbox]:checked {
  left: -9999px;
  position: absolute;
  top: 20px;
  visibility: hidden;
}
.uk-active div #offcanvas-register .background-uikit .form .social-login .legals .control [type=checkbox]:not(:checked) + label,
.uk-active div #offcanvas-register .background-uikit .form .social-login .legals .control [type=checkbox]:checked + label {
  position: relative;
  padding-left: 1.2em;
  cursor: pointer;
}
.uk-active div #offcanvas-register .background-uikit .form .social-login .legals .control [type=checkbox]:not(:checked) + label:before,
.uk-active div #offcanvas-register .background-uikit .form .social-login .legals .control [type=checkbox]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  width: 12px;
  height: 12px;
  border: 2px solid #fff;
  background: rgba(255, 255, 255, 0.15);
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-register .background-uikit .form .social-login .legals .control [type=checkbox]:not(:checked) + label:before,
  .uk-active div #offcanvas-register .background-uikit .form .social-login .legals .control [type=checkbox]:checked + label:before {
    border: 2px solid #4a4a4a;
    top: 10px;
  }
}
.uk-active div #offcanvas-register .background-uikit .form .social-login .legals .control [type=checkbox]:checked + label:before {
  background: #93002a;
}
.uk-active div #offcanvas-register .background-uikit .form .social-login .legals .control [type=checkbox]:not(:checked) + label:after,
.uk-active div #offcanvas-register .background-uikit .form .social-login .legals .control [type=checkbox]:checked + label:after {
  content: "";
  left: 3px;
  top: 10px;
  border: 2px solid #fff;
  background: transparent;
  opacity: 0;
  transform: rotate(-45deg);
  position: absolute;
  width: 9px;
  height: 5px;
  font-size: 1.4em;
  line-height: 0.8;
  transition: all 0.2s;
}
@media screen and (min-width: 768px) {
  .uk-active div #offcanvas-register .background-uikit .form .social-login .legals .control [type=checkbox]:not(:checked) + label:after,
  .uk-active div #offcanvas-register .background-uikit .form .social-login .legals .control [type=checkbox]:checked + label:after {
    top: 12px;
    height: 3px;
  }
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-register .background-uikit .form .social-login .legals .control [type=checkbox]:not(:checked) + label:after,
  .uk-active div #offcanvas-register .background-uikit .form .social-login .legals .control [type=checkbox]:checked + label:after {
    border: 2px solid #fff;
    top: 14px;
  }
}
.uk-active div #offcanvas-register .background-uikit .form .social-login .legals .control [type=checkbox]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
  transform: rotate(-45deg);
}
.uk-active div #offcanvas-register .background-uikit .form .social-login .legals .control [type=checkbox]:checked + label:after {
  opacity: 1;
  border-top: none;
  border-right: none;
}
.uk-active div #offcanvas-register .background-uikit .form .social-login .registered {
  margin-top: 20px;
  text-align: center;
}
.uk-active div #offcanvas-register .background-uikit .form .social-login .registered span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #fff;
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-register .background-uikit .form .social-login .registered span {
    color: #000000;
  }
}
.uk-active div #offcanvas-register .background-uikit .form .social-login .registered a {
  color: #b88f01;
  text-decoration: underline;
}
@media screen and (min-width: 979px) {
  .uk-active div #offcanvas-register .background-uikit .form .social-login .registered a {
    color: #93002a;
  }
}

.customer-account-create .page-wrapper .page-main {
  padding: 0 15px;
}
@media screen and (min-width: 768px) {
  .customer-account-create .page-wrapper .page-main .page-title-wrapper {
    text-align: center;
  }
}
.customer-account-create .page-wrapper .page-main .page-title-wrapper .block-title .page-title {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 22px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .customer-account-create .page-wrapper .page-main .columns .column .customer-form-before {
    min-width: 600px;
    width: 50%;
    margin: auto;
  }
}
.customer-account-create .page-wrapper .page-main .columns .column .customer-form-before .pslogin_register_container .pslogin-block {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .customer-account-create .page-wrapper .page-main .columns .column .customer-form-before .pslogin_register_container .pslogin-block {
    text-align: center;
  }
}
.customer-account-create .page-wrapper .page-main .columns .column .customer-form-before .pslogin_register_container .pslogin-block .pslogin-buttons ul {
  margin: 0;
}
.customer-account-create .page-wrapper .page-main .columns .column .customer-form-before .pslogin_register_container .pslogin-block .pslogin-buttons ul .pslogin-button {
  margin: 0;
}
.customer-account-create .page-wrapper .page-main .columns .column .customer-form-before .pslogin_register_container .pslogin-block .pslogin-buttons ul .pslogin-button .pslogin-button-link .pslogin-button-auto {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  height: 45px;
  background-color: #1d71b8;
}
.customer-account-create .page-wrapper .page-main .columns .column .customer-form-before .pslogin_register_container .pslogin-block .pslogin-buttons ul .pslogin-button .pslogin-button-link .pslogin-button-auto .pslogin-button-icon {
  left: 35px;
  top: auto;
}
.customer-account-create .page-wrapper .page-main .columns .column .customer-form-before .pslogin_register_container .pslogin-block .pslogin-buttons ul .pslogin-button .pslogin-button-link .pslogin-button-auto .pslogin-button-text {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  min-width: auto;
  margin-left: 10px;
}
.customer-account-create .page-wrapper .page-main .columns .column .customer-form-before .pslogin_register_container .pslogin-spacer table tbody td {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  color: #000000;
}
.customer-account-create .page-wrapper .page-main .columns .column .customer-form-before .pslogin_register_container .pslogin-spacer table tbody td .pslogin-border {
  border-top: 1px solid #000000;
}
@media screen and (min-width: 768px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form {
    margin: auto;
  }
}
@media screen and (min-width: 768px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .info {
    display: flex;
    margin-bottom: 30px;
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .legend {
    margin: 0 auto;
  }
}
.customer-account-create .page-wrapper .page-main .columns .column .form .info .legend span {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 20px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .field-name-firstname,
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .field-name-lastname {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
}
.customer-account-create .page-wrapper .page-main .columns .column .form .info .field-name-firstname .label,
.customer-account-create .page-wrapper .page-main .columns .column .form .info .field-name-lastname .label {
  display: none;
}
.customer-account-create .page-wrapper .page-main .columns .column .form .info .field-name-firstname .control .input-text,
.customer-account-create .page-wrapper .page-main .columns .column .form .info .field-name-lastname .control .input-text {
  height: 45px;
  border: 1px solid #4a4a4a;
}
.customer-account-create .page-wrapper .page-main .columns .column .form .info .field-name-firstname .control .input-text::placeholder,
.customer-account-create .page-wrapper .page-main .columns .column .form .info .field-name-lastname .control .input-text::placeholder {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #000000;
}
.customer-account-create .page-wrapper .page-main .columns .column .form .info .field-name-firstname .control .input-text:focus,
.customer-account-create .page-wrapper .page-main .columns .column .form .info .field-name-lastname .control .input-text:focus {
  box-shadow: none;
}
@media screen and (min-width: 768px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice {
    text-align: center;
  }
}
@media screen and (min-width: 768px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice {
    display: flex;
  }
}
@media screen and (min-width: 768px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice::before {
    width: 0;
  }
}
.customer-account-create .page-wrapper .page-main .columns .column .form .info .choice label {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.customer-account-create .page-wrapper .page-main .columns .column .form .info .choice label span {
  padding-left: 10px;
}
@media screen and (min-width: 768px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice label span {
    padding-left: 70px;
  }
}
@media screen and (min-width: 1366px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice label span {
    padding-left: 100px;
  }
}
@media screen and (min-width: 1920px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice label span {
    padding-left: 110px;
  }
}
.customer-account-create .page-wrapper .page-main .columns .column .form .info .choice {
  /* checkbox aspect */
  /* checked mark aspect */
  /* checked mark aspect changes */
}
.customer-account-create .page-wrapper .page-main .columns .column .form .info .choice .label {
  display: block;
}
.customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:not(:checked),
.customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:checked {
  left: -9999px;
  position: absolute;
  top: 20px;
  visibility: hidden;
}
.customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:not(:checked) + label,
.customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:checked + label {
  position: relative;
  padding-left: 1.2em;
  cursor: pointer;
}
.customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:not(:checked) + label:before,
.customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:checked + label:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 4px;
  width: 12px;
  height: 12px;
  border: 2px solid #4a4a4a;
  background: rgba(255, 255, 255, 0.15);
}
@media screen and (min-width: 768px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:not(:checked) + label:before,
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:checked + label:before {
    left: 65px;
  }
}
@media screen and (min-width: 979px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:not(:checked) + label:before,
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:checked + label:before {
    border: 2px solid #4a4a4a;
  }
}
@media screen and (min-width: 1366px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:not(:checked) + label:before,
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:checked + label:before {
    left: 70px;
  }
}
@media screen and (min-width: 1600px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:not(:checked) + label:before,
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:checked + label:before {
    left: 90px;
  }
}
@media screen and (min-width: 1920px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:not(:checked) + label:before,
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:checked + label:before {
    left: 108px;
  }
}
.customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:checked + label:before {
  background: #93002a;
}
.customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:not(:checked) + label:after,
.customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:checked + label:after {
  content: "";
  left: 3px;
  top: 7px;
  border: 2px solid #fff;
  background: transparent;
  opacity: 0;
  transform: rotate(-45deg);
  position: absolute;
  width: 9px;
  height: 5px;
  font-size: 1.4em;
  line-height: 0.8;
  transition: all 0.2s;
}
@media screen and (min-width: 768px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:not(:checked) + label:after,
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:checked + label:after {
    top: 8px;
    height: 3px;
    left: 68px;
  }
}
@media screen and (min-width: 979px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:not(:checked) + label:after,
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:checked + label:after {
    border: 2px solid #fff;
  }
}
@media screen and (min-width: 1366px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:not(:checked) + label:after,
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:checked + label:after {
    left: 73px;
  }
}
@media screen and (min-width: 1600px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:not(:checked) + label:after,
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:checked + label:after {
    left: 93px;
  }
}
@media screen and (min-width: 1920px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:not(:checked) + label:after,
  .customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:checked + label:after {
    left: 110px;
  }
}
.customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
  transform: rotate(-45deg);
}
.customer-account-create .page-wrapper .page-main .columns .column .form .info .choice [type=checkbox]:checked + label:after {
  opacity: 1;
  border-top: none;
  border-right: none;
}
.customer-account-create .page-wrapper .page-main .columns .column .form .account {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .account .legend {
    width: 100%;
    margin-left: 0;
  }
}
.customer-account-create .page-wrapper .page-main .columns .column .form .account .legend span {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 20px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .account .legend span {
    display: flex;
    justify-content: center;
  }
}
.customer-account-create .page-wrapper .page-main .columns .column .form .account .field {
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .account .field {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.customer-account-create .page-wrapper .page-main .columns .column .form .account .field .label {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .account .field .label {
    width: auto;
    padding: 0;
  }
}
.customer-account-create .page-wrapper .page-main .columns .column .form .account .field .control .input-text {
  height: 45px;
  border: 1px solid #4a4a4a;
}
.customer-account-create .page-wrapper .page-main .columns .column .form .account .field .control .input-text::placeholder {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #000000;
}
.customer-account-create .page-wrapper .page-main .columns .column .form .account .field .control .input-text:focus {
  box-shadow: none;
}
.customer-account-create .page-wrapper .page-main .columns .column .form .account .field .control #password-strength-meter-container .password-strength-meter {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .customer-account-create .page-wrapper .page-main .columns .column .form .actions-toolbar {
    margin-left: 0;
    display: flex;
    justify-content: space-around;
  }
}
.customer-account-create .page-wrapper .page-main .columns .column .form .actions-toolbar .primary .action {
  background: #000000;
  border-radius: 0;
  height: 45px;
  border: none;
}
.customer-account-create .page-wrapper .page-main .columns .column .form .actions-toolbar .primary .action span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  text-transform: uppercase;
}
.customer-account-create .page-wrapper .page-main .columns .column .form .actions-toolbar .secondary .action span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #93002a;
  text-decoration: underline;
}

.customer-account-forgotpassword .page-wrapper .page-main {
  padding: 0 15px;
}
@media screen and (min-width: 768px) {
  .customer-account-forgotpassword .page-wrapper .page-main {
    padding: 0 25px;
  }
}
@media screen and (min-width: 768px) {
  .customer-account-forgotpassword .page-wrapper .page-main .page-title-wrapper .block-title {
    text-align: center;
  }
}
.customer-account-forgotpassword .page-wrapper .page-main .page-title-wrapper .block-title .page-title {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 22px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .customer-account-forgotpassword .page-wrapper .page-main .columns .column .form {
    margin: 0 auto;
  }
}
.customer-account-forgotpassword .page-wrapper .page-main .columns .column .form .fieldset .note {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .customer-account-forgotpassword .page-wrapper .page-main .columns .column .form .fieldset .note {
    text-align: center;
  }
}
@media screen and (min-width: 768px) {
  .customer-account-forgotpassword .page-wrapper .page-main .columns .column .form .fieldset .email,
  .customer-account-forgotpassword .page-wrapper .page-main .columns .column .form .fieldset .captcha {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.customer-account-forgotpassword .page-wrapper .page-main .columns .column .form .fieldset .email .label,
.customer-account-forgotpassword .page-wrapper .page-main .columns .column .form .fieldset .captcha .label {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 20px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .customer-account-forgotpassword .page-wrapper .page-main .columns .column .form .fieldset .email .label,
  .customer-account-forgotpassword .page-wrapper .page-main .columns .column .form .fieldset .captcha .label {
    width: auto;
    padding: 0;
    font-size: 18px;
  }
}
.customer-account-forgotpassword .page-wrapper .page-main .columns .column .form .fieldset .email .control .input-text,
.customer-account-forgotpassword .page-wrapper .page-main .columns .column .form .fieldset .captcha .control .input-text {
  height: 45px;
  border: solid 1px #4a4a4a;
  background-color: #ffffff;
}
.customer-account-forgotpassword .page-wrapper .page-main .columns .column .form .fieldset .captcha .captcha .nested .field .control .action {
  background: #93002a;
  border-radius: 0;
}
.customer-account-forgotpassword .page-wrapper .page-main .columns .column .form .fieldset .captcha .captcha .nested .field .control .action span {
  font-size: 20px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  color: #fff;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .customer-account-forgotpassword .page-wrapper .page-main .columns .column .form .actions-toolbar {
    display: flex;
    justify-content: space-around;
    margin-left: 0;
  }
}
.customer-account-forgotpassword .page-wrapper .page-main .columns .column .form .actions-toolbar .primary .action {
  background: #000000;
  border-radius: 0;
  height: 45px;
}
.customer-account-forgotpassword .page-wrapper .page-main .columns .column .form .actions-toolbar .primary .action span {
  font-size: 20px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  color: #fff;
  text-transform: uppercase;
}
.customer-account-forgotpassword .page-wrapper .page-main .columns .column .form .actions-toolbar .secondary .action span {
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  color: #93002a;
  text-decoration: underline;
}

.customer-account-login .page-wrapper .messages-container {
  position: static;
}
.customer-account-login .page-wrapper .page-main {
  padding: 0 15px;
}
.customer-account-login .page-wrapper .page-main .page-title-wrapper .block-title .page-title span {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 22px;
  color: #000000;
}
.customer-account-login .page-wrapper .page-main .columns .column .login-container .block-customer-login .block-title strong {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 22px;
  color: #000000;
}
.customer-account-login .page-wrapper .page-main .columns .column .login-container .block-customer-login .block-content .form .fieldset::after {
  display: none;
}
.customer-account-login .page-wrapper .page-main .columns .column .login-container .block-customer-login .block-content .form .fieldset .note {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.customer-account-login .page-wrapper .page-main .columns .column .login-container .block-customer-login .block-content .form .fieldset .email label,
.customer-account-login .page-wrapper .page-main .columns .column .login-container .block-customer-login .block-content .form .fieldset .password label {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 20px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .customer-account-login .page-wrapper .page-main .columns .column .login-container .block-customer-login .block-content .form .fieldset .email label,
  .customer-account-login .page-wrapper .page-main .columns .column .login-container .block-customer-login .block-content .form .fieldset .password label {
    width: auto;
  }
}
@media screen and (min-width: 979px) {
  .customer-account-login .page-wrapper .page-main .columns .column .login-container .block-customer-login .block-content .form .fieldset .email label,
  .customer-account-login .page-wrapper .page-main .columns .column .login-container .block-customer-login .block-content .form .fieldset .password label {
    width: 30%;
    text-align: left;
  }
}
.customer-account-login .page-wrapper .page-main .columns .column .login-container .block-customer-login .block-content .form .fieldset .email .control .input-text,
.customer-account-login .page-wrapper .page-main .columns .column .login-container .block-customer-login .block-content .form .fieldset .password .control .input-text {
  height: 45px;
  border: 1px solid #4a4a4a;
}
.customer-account-login .page-wrapper .page-main .columns .column .login-container .block-customer-login .block-content .form .fieldset .email .control .input-text:focus,
.customer-account-login .page-wrapper .page-main .columns .column .login-container .block-customer-login .block-content .form .fieldset .password .control .input-text:focus {
  box-shadow: none;
}
@media screen and (min-width: 768px) {
  .customer-account-login .page-wrapper .page-main .columns .column .login-container .block-customer-login .block-content .form .fieldset .actions-toolbar {
    display: flex;
    justify-content: space-between;
    margin-left: 0;
  }
}
@media screen and (min-width: 979px) {
  .customer-account-login .page-wrapper .page-main .columns .column .login-container .block-customer-login .block-content .form .fieldset .actions-toolbar {
    justify-content: flex-start;
  }
}
@media screen and (min-width: 979px) {
  .customer-account-login .page-wrapper .page-main .columns .column .login-container .block-customer-login .block-content .form .fieldset .actions-toolbar .primary {
    margin-right: 10px;
  }
}
.customer-account-login .page-wrapper .page-main .columns .column .login-container .block-customer-login .block-content .form .fieldset .actions-toolbar .primary .action {
  height: 45px;
  border-radius: 0;
  background-color: #000000;
  border: none;
}
.customer-account-login .page-wrapper .page-main .columns .column .login-container .block-customer-login .block-content .form .fieldset .actions-toolbar .primary .action span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
}
.customer-account-login .page-wrapper .page-main .columns .column .login-container .block-customer-login .block-content .form .fieldset .actions-toolbar .secondary .action span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #93002a;
  text-decoration: underline;
}
.customer-account-login .page-wrapper .page-main .columns .column .login-container .block-new-customer .block-title strong {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 22px;
  color: #000000;
}
.customer-account-login .page-wrapper .page-main .columns .column .login-container .block-new-customer .block-content p {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.customer-account-login .page-wrapper .page-main .columns .column .login-container .block-new-customer .block-content .actions-toolbar .primary .action {
  height: 45px;
  border-radius: 0;
  background-color: #000000;
  border: none;
}
.customer-account-login .page-wrapper .page-main .columns .column .login-container .block-new-customer .block-content .actions-toolbar .primary .action span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
}

.customer-account-createpassword .page-wrapper .messages-container {
  position: relative;
  top: 0;
}
.customer-account-createpassword .page-wrapper .page-main {
  padding-left: 25px;
  padding-right: 25px;
}
.customer-account-createpassword .page-wrapper .page-main .page-title-wrapper .block-title .page-title {
  margin: 20px 0;
  line-height: 0.8;
}
@media screen and (min-width: 768px) {
  .customer-account-createpassword .page-wrapper .page-main .page-title-wrapper .block-title .page-title {
    text-align: center;
    margin: 35px 0;
    width: 100%;
  }
}
@media screen and (min-width: 979px) {
  .customer-account-createpassword .page-wrapper .page-main .page-title-wrapper .block-title .page-title {
    margin: 35px 0 45px;
  }
}
.customer-account-createpassword .page-wrapper .page-main .page-title-wrapper .block-title .page-title .base {
  font-size: 22px;
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  color: #000000;
}
@media screen and (min-width: 1199px) {
  .customer-account-createpassword .page-wrapper .page-main .page-title-wrapper .block-title .page-title .base {
    font-size: 30px;
  }
}
@media screen and (min-width: 768px) {
  .customer-account-createpassword .page-wrapper .page-main .columns .column .password {
    margin: 0 auto;
    min-width: 730px;
  }
}
@media screen and (min-width: 979px) {
  .customer-account-createpassword .page-wrapper .page-main .columns .column .password {
    width: 65%;
  }
}
@media screen and (min-width: 768px) {
  .customer-account-createpassword .page-wrapper .page-main .columns .column .password .fieldset .password {
    display: flex;
    justify-content: center;
    width: 97%;
  }
}
@media screen and (min-width: 1199px) {
  .customer-account-createpassword .page-wrapper .page-main .columns .column .password .fieldset .password {
    width: 100%;
  }
}
.customer-account-createpassword .page-wrapper .page-main .columns .column .password .fieldset .password .label {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
}
@media screen and (min-width: 768px) {
  .customer-account-createpassword .page-wrapper .page-main .columns .column .password .fieldset .password .label {
    text-align: left;
  }
}
.customer-account-createpassword .page-wrapper .page-main .columns .column .password .fieldset .password .label span {
  color: #000000;
  font-weight: 500;
  font-size: 18px;
}
.customer-account-createpassword .page-wrapper .page-main .columns .column .password .fieldset .password .label span::after {
  color: #000000;
}
@media screen and (min-width: 768px) {
  .customer-account-createpassword .page-wrapper .page-main .columns .column .password .fieldset .password .control {
    width: 50%;
  }
}
.customer-account-createpassword .page-wrapper .page-main .columns .column .password .fieldset .password .control .input-text {
  height: 40px;
  border: 1px solid #000000;
}
.customer-account-createpassword .page-wrapper .page-main .columns .column .password .fieldset .password .control #password-strength-meter-container .password-strength-meter {
  color: #000000;
  font-size: 14px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
}
.customer-account-createpassword .page-wrapper .page-main .columns .column .password .fieldset .confirmation {
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .customer-account-createpassword .page-wrapper .page-main .columns .column .password .fieldset .confirmation {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 979px) {
  .customer-account-createpassword .page-wrapper .page-main .columns .column .password .fieldset .confirmation {
    width: 100%;
    margin-top: 0;
  }
}
.customer-account-createpassword .page-wrapper .page-main .columns .column .password .fieldset .confirmation .label {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
}
@media screen and (min-width: 768px) {
  .customer-account-createpassword .page-wrapper .page-main .columns .column .password .fieldset .confirmation .label {
    text-align: left;
    font-size: 14px;
  }
}
.customer-account-createpassword .page-wrapper .page-main .columns .column .password .fieldset .confirmation .label span {
  color: #000000;
  font-weight: 500;
  font-size: 18px;
}
.customer-account-createpassword .page-wrapper .page-main .columns .column .password .fieldset .confirmation .label span::after {
  color: #000000;
}
@media screen and (min-width: 768px) {
  .customer-account-createpassword .page-wrapper .page-main .columns .column .password .fieldset .confirmation .control {
    width: 50%;
  }
}
.customer-account-createpassword .page-wrapper .page-main .columns .column .password .fieldset .confirmation .control .input-text {
  height: 40px;
  border: 1px solid #000000;
}
@media screen and (min-width: 768px) {
  .customer-account-createpassword .page-wrapper .page-main .columns .column .password .actions-toolbar {
    margin-left: 11.5%;
  }
}
.customer-account-createpassword .page-wrapper .page-main .columns .column .password .actions-toolbar .primary .action {
  background: #000000;
  border: 1px solid #000000;
  border-radius: 0;
  font-size: 16px;
  height: 40px;
  padding: 0;
  font-weight: 500;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
}
@media screen and (min-width: 768px) {
  .customer-account-createpassword .page-wrapper .page-main .columns .column .password .actions-toolbar .primary .action {
    width: 100%;
    padding: 0 15px;
  }
}

.account.sales-order-history .page-wrapper .page-main .columns .column .table-wrapper .data thead tr th {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
.account.sales-order-history .page-wrapper .page-main .columns .column .table-wrapper .data tbody tr .col {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.account.sales-order-history .page-wrapper .page-main .columns .column .table-wrapper .data tbody tr .col::before {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
}
.account.sales-order-history .page-wrapper .page-main .columns .column .table-wrapper .data tbody tr .actions .action span {
  color: #93002a;
  text-decoration: underline;
}
.account.sales-order-history .page-wrapper .page-main .columns .column .order-products-toolbar {
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .account.sales-order-history .page-wrapper .page-main .columns .column .order-products-toolbar {
    flex-direction: row;
    justify-content: flex-end;
  }
}
@media screen and (min-width: 768px) {
  .account.sales-order-history .page-wrapper .page-main .columns .column .order-products-toolbar .pager {
    display: flex;
    align-items: center;
  }
}
@media screen and (min-width: 768px) {
  .account.sales-order-history .page-wrapper .page-main .columns .column .order-products-toolbar .pager .toolbar-amount {
    margin: 0;
    padding: 0;
    margin-right: 25px;
  }
}
.account.sales-order-history .page-wrapper .page-main .columns .column .order-products-toolbar .pager .pages {
  float: none;
}
@media screen and (min-width: 768px) {
  .account.sales-order-history .page-wrapper .page-main .columns .column .order-products-toolbar .pager .pages {
    position: relative;
    width: auto;
  }
}
.account.sales-order-history .page-wrapper .page-main .columns .column .order-products-toolbar .pager .pages .items {
  display: flex;
  justify-content: center;
  align-items: center;
}
.account.sales-order-history .page-wrapper .page-main .columns .column .order-products-toolbar .pager .pages .items .item {
  display: flex;
}
.account.sales-order-history .page-wrapper .page-main .columns .column .order-products-toolbar .pager .pages .items .item .page {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.account.sales-order-history .page-wrapper .page-main .columns .column .order-products-toolbar .pager .pages .items .current .page {
  background-color: #93002a;
  padding: 0;
  width: 25px;
  height: 25px;
  line-height: 25px;
  color: #fff;
}
.account.sales-order-history .page-wrapper .page-main .columns .column .order-products-toolbar .pager .pages .items .pages-item-next .action,
.account.sales-order-history .page-wrapper .page-main .columns .column .order-products-toolbar .pager .pages .items .pages-item-previous .action {
  background: url(../images/icons/icon-right.svg);
  content: "";
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  background-position: center;
  border: none;
}
.account.sales-order-history .page-wrapper .page-main .columns .column .order-products-toolbar .pager .pages .items .pages-item-next .action::before,
.account.sales-order-history .page-wrapper .page-main .columns .column .order-products-toolbar .pager .pages .items .pages-item-previous .action::before {
  display: none;
}
.account.sales-order-history .page-wrapper .page-main .columns .column .order-products-toolbar .pager .pages .items .pages-item-previous {
  transform: rotate(-180deg);
}
@media screen and (min-width: 768px) {
  .account.sales-order-history .page-wrapper .page-main .columns .column .order-products-toolbar .pager .limiter {
    display: flex;
    align-items: center;
    margin-left: 25px;
  }
}
.account.sales-order-history .page-wrapper .page-main .columns .column .actions-toolbar {
  max-width: 100%;
}

.account.review-customer-index .page-wrapper .page-main .columns .column .table-wrapper .data thead tr th {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
.account.review-customer-index .page-wrapper .page-main .columns .column .table-wrapper .data tbody tr .col {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.account.review-customer-index .page-wrapper .page-main .columns .column .table-wrapper .data tbody tr .col::before {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
}
.account.review-customer-index .page-wrapper .page-main .columns .column .table-wrapper .data tbody tr .item .product-name a {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000 !important;
}
.account.review-customer-index .page-wrapper .page-main .columns .column .table-wrapper .data tbody tr .actions .action span {
  color: #93002a;
  text-decoration: underline;
}
.account.review-customer-index .page-wrapper .page-main .columns .column .actions-toolbar {
  max-width: 100%;
}

.wishlist-index-index .page-wrapper .page-main .columns .column .form-wishlist-items .products-grid .product-items .product-item .product-reviews-summary {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0;
}
.wishlist-index-index .page-wrapper .page-main .columns .column .form-wishlist-items .products-grid .product-items .product-item .product-reviews-summary .rating-summary {
  margin: 0;
  height: 30px;
  left: 0;
}
.wishlist-index-index .page-wrapper .page-main .columns .column .form-wishlist-items .products-grid .product-items .product-item .product-reviews-summary .rating-summary .rating-result {
  width: 125px;
  margin: 0;
}
.wishlist-index-index .page-wrapper .page-main .columns .column .form-wishlist-items .products-grid .product-items .product-item .product-reviews-summary .rating-summary .rating-result::before {
  content: "";
  width: 100%;
  background-image: url("../images/empty-stars-small.svg");
  background-repeat: no-repeat;
}
.wishlist-index-index .page-wrapper .page-main .columns .column .form-wishlist-items .products-grid .product-items .product-item .product-reviews-summary .rating-summary .rating-result span::before {
  position: sticky;
  content: "";
  background-image: url("../images/filled-stars-small.svg");
  background-repeat: no-repeat;
}
.wishlist-index-index .page-wrapper .page-main .columns .column .form-wishlist-items .products-grid .product-items .product-item .product-reviews-summary .reviews-actions {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.wishlist-index-index .page-wrapper .page-main .columns .column .form-wishlist-items .products-grid .product-items .product-item .product-reviews-summary .reviews-actions .action {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #93002a;
  text-decoration: underline;
  margin-right: 0;
}

.sales-order-view .page-wrapper .page-main .columns .column .items {
  border-bottom: none;
}
.sales-order-view .page-wrapper .page-main .columns .column .items .nav strong,
.sales-order-view .page-wrapper .page-main .columns .column .items .nav a {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: #000000;
}
.sales-order-view .page-wrapper .page-main .columns .column .order-details-items .order-title strong {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
.sales-order-view .page-wrapper .page-main .columns .column .order-details-items .table-wrapper .data thead tr th {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  border-bottom: 1px solid #979797;
}
.sales-order-view .page-wrapper .page-main .columns .column .order-details-items .table-wrapper .data tbody tr td {
  color: #000000;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
}
.sales-order-view .page-wrapper .page-main .columns .column .order-details-items .table-wrapper .data tbody tr td::before {
  color: #000000;
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
}
.sales-order-view .page-wrapper .page-main .columns .column .order-details-items .table-wrapper .data tbody tr .col.price,
.sales-order-view .page-wrapper .page-main .columns .column .order-details-items .table-wrapper .data tbody tr .col.subtotal {
  display: flex;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .sales-order-view .page-wrapper .page-main .columns .column .order-details-items .table-wrapper .data tbody tr .col.price,
  .sales-order-view .page-wrapper .page-main .columns .column .order-details-items .table-wrapper .data tbody tr .col.subtotal {
    display: table-cell;
  }
}
.sales-order-view .page-wrapper .page-main .columns .column .order-details-items .table-wrapper .data tbody tr .col.price .price-excluding-tax,
.sales-order-view .page-wrapper .page-main .columns .column .order-details-items .table-wrapper .data tbody tr .col.subtotal .price-excluding-tax {
  font-size: 16px;
}
.sales-order-view .page-wrapper .page-main .columns .column .order-details-items .table-wrapper .data tfoot tr .mark {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: #000000;
}
.sales-order-view .page-wrapper .page-main .columns .column .order-details-items .table-wrapper .data tfoot tr .amount .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 20px;
  color: #000000;
}
.sales-order-view .page-wrapper .page-main .columns .column .block-order-details-view .block-content .box .box-content {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}

.sales-order-invoice .page-main .items,
.sales-order-shipment .page-main .items,
.sales-order-creditmemo .page-main .items {
  border-bottom: none;
}
.sales-order-invoice .page-main .items .nav strong,
.sales-order-invoice .page-main .items .nav a,
.sales-order-shipment .page-main .items .nav strong,
.sales-order-shipment .page-main .items .nav a,
.sales-order-creditmemo .page-main .items .nav strong,
.sales-order-creditmemo .page-main .items .nav a {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: #000000;
}
.sales-order-invoice .page-main .order-details-items .actions-toolbar .action.print,
.sales-order-shipment .page-main .order-details-items .actions-toolbar .action.print,
.sales-order-creditmemo .page-main .order-details-items .actions-toolbar .action.print {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  color: #93002a;
  font-size: 16px;
  text-decoration: underline;
}
.sales-order-invoice .page-main .order-details-items .order-title,
.sales-order-shipment .page-main .order-details-items .order-title,
.sales-order-creditmemo .page-main .order-details-items .order-title {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .sales-order-invoice .page-main .order-details-items .order-title,
  .sales-order-shipment .page-main .order-details-items .order-title,
  .sales-order-creditmemo .page-main .order-details-items .order-title {
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 979px) {
  .sales-order-invoice .page-main .order-details-items .order-title,
  .sales-order-shipment .page-main .order-details-items .order-title,
  .sales-order-creditmemo .page-main .order-details-items .order-title {
    margin-bottom: 20px;
  }
}
.sales-order-invoice .page-main .order-details-items .order-title strong,
.sales-order-shipment .page-main .order-details-items .order-title strong,
.sales-order-creditmemo .page-main .order-details-items .order-title strong {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  color: #000000;
}
@media screen and (min-width: 979px) {
  .sales-order-invoice .page-main .order-details-items .order-title strong,
  .sales-order-shipment .page-main .order-details-items .order-title strong,
  .sales-order-creditmemo .page-main .order-details-items .order-title strong {
    margin-right: 25px;
  }
}
.sales-order-invoice .page-main .order-details-items .order-title .action,
.sales-order-shipment .page-main .order-details-items .order-title .action,
.sales-order-creditmemo .page-main .order-details-items .order-title .action {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  color: #93002a;
  font-size: 16px;
  text-decoration: underline;
}
.sales-order-invoice .page-main .order-details-items .table-wrapper .data thead tr th,
.sales-order-shipment .page-main .order-details-items .table-wrapper .data thead tr th,
.sales-order-creditmemo .page-main .order-details-items .table-wrapper .data thead tr th {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  border-bottom: 1px solid #979797;
}
.sales-order-invoice .page-main .order-details-items .table-wrapper .data tbody tr td,
.sales-order-shipment .page-main .order-details-items .table-wrapper .data tbody tr td,
.sales-order-creditmemo .page-main .order-details-items .table-wrapper .data tbody tr td {
  color: #000000;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
}
.sales-order-invoice .page-main .order-details-items .table-wrapper .data tbody tr td::before,
.sales-order-shipment .page-main .order-details-items .table-wrapper .data tbody tr td::before,
.sales-order-creditmemo .page-main .order-details-items .table-wrapper .data tbody tr td::before {
  color: #000000;
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
}
.sales-order-invoice .page-main .order-details-items .table-wrapper .data tbody tr .col.price,
.sales-order-invoice .page-main .order-details-items .table-wrapper .data tbody tr .col.subtotal,
.sales-order-shipment .page-main .order-details-items .table-wrapper .data tbody tr .col.price,
.sales-order-shipment .page-main .order-details-items .table-wrapper .data tbody tr .col.subtotal,
.sales-order-creditmemo .page-main .order-details-items .table-wrapper .data tbody tr .col.price,
.sales-order-creditmemo .page-main .order-details-items .table-wrapper .data tbody tr .col.subtotal {
  display: flex;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .sales-order-invoice .page-main .order-details-items .table-wrapper .data tbody tr .col.price,
  .sales-order-invoice .page-main .order-details-items .table-wrapper .data tbody tr .col.subtotal,
  .sales-order-shipment .page-main .order-details-items .table-wrapper .data tbody tr .col.price,
  .sales-order-shipment .page-main .order-details-items .table-wrapper .data tbody tr .col.subtotal,
  .sales-order-creditmemo .page-main .order-details-items .table-wrapper .data tbody tr .col.price,
  .sales-order-creditmemo .page-main .order-details-items .table-wrapper .data tbody tr .col.subtotal {
    display: table-cell;
  }
}
.sales-order-invoice .page-main .order-details-items .table-wrapper .data tbody tr .col.price .price-excluding-tax,
.sales-order-invoice .page-main .order-details-items .table-wrapper .data tbody tr .col.subtotal .price-excluding-tax,
.sales-order-shipment .page-main .order-details-items .table-wrapper .data tbody tr .col.price .price-excluding-tax,
.sales-order-shipment .page-main .order-details-items .table-wrapper .data tbody tr .col.subtotal .price-excluding-tax,
.sales-order-creditmemo .page-main .order-details-items .table-wrapper .data tbody tr .col.price .price-excluding-tax,
.sales-order-creditmemo .page-main .order-details-items .table-wrapper .data tbody tr .col.subtotal .price-excluding-tax {
  font-size: 16px;
}
.sales-order-invoice .page-main .order-details-items .table-wrapper .data tfoot tr .mark,
.sales-order-shipment .page-main .order-details-items .table-wrapper .data tfoot tr .mark,
.sales-order-creditmemo .page-main .order-details-items .table-wrapper .data tfoot tr .mark {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: #000000;
}
.sales-order-invoice .page-main .order-details-items .table-wrapper .data tfoot tr .amount .price,
.sales-order-shipment .page-main .order-details-items .table-wrapper .data tfoot tr .amount .price,
.sales-order-creditmemo .page-main .order-details-items .table-wrapper .data tfoot tr .amount .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 20px;
  color: #000000;
}
.sales-order-invoice .page-main .block-order-details-view .block-content .box .box-content,
.sales-order-shipment .page-main .block-order-details-view .block-content .box .box-content,
.sales-order-creditmemo .page-main .block-order-details-view .block-content .box .box-content {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}

.aw_store_credit-info-index .page-main .block.store-credit {
  margin-bottom: 0;
}
@media screen and (min-width: 979px) {
  .aw_store_credit-info-index .page-main .block.store-credit {
    margin-bottom: 20px;
  }
}
.aw_store_credit-info-index .page-main .block.store-credit .block-content .base {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  color: #000000;
}
.aw_store_credit-info-index .page-main .table-wrapper .data thead tr th {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  border-bottom: 1px solid #979797;
}
.aw_store_credit-info-index .page-main .table-wrapper .data tbody tr td {
  color: #000000;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
}
.aw_store_credit-info-index .page-main .table-wrapper .data tbody tr td::before {
  color: #000000;
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
}
.aw_store_credit-info-index .page-main .secondary .action span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  color: #93002a;
  font-size: 16px;
  text-decoration: underline;
}

@media screen and (min-width: 979px) {
  .customer-address-form .page-main .form-address-edit .fieldset .field .control {
    width: 30%;
  }
}
.customer-address-form .page-main .form-address-edit .fieldset .billing,
.customer-address-form .page-main .form-address-edit .fieldset .shipping {
  /* checkbox aspect */
  /* checked mark aspect */
  /* checked mark aspect changes */
}
.customer-address-form .page-main .form-address-edit .fieldset .billing .label,
.customer-address-form .page-main .form-address-edit .fieldset .shipping .label {
  display: block;
}
.customer-address-form .page-main .form-address-edit .fieldset .billing .label span,
.customer-address-form .page-main .form-address-edit .fieldset .shipping .label span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #fff;
}
@media screen and (min-width: 979px) {
  .customer-address-form .page-main .form-address-edit .fieldset .billing .label span,
  .customer-address-form .page-main .form-address-edit .fieldset .shipping .label span {
    color: #000000;
  }
}
.customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:not(:checked),
.customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:checked,
.customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:not(:checked),
.customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:checked {
  left: -9999px;
  position: absolute;
  top: 20px;
  visibility: hidden;
}
.customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:not(:checked) + label,
.customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:checked + label,
.customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:not(:checked) + label,
.customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:checked + label {
  position: relative;
  padding-left: 1.2em;
  cursor: pointer;
}
.customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:not(:checked) + label:before,
.customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:checked + label:before,
.customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:not(:checked) + label:before,
.customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 12px;
  height: 12px;
  border: 2px solid #4a4a4a;
  background: rgba(255, 255, 255, 0.15);
}
@media screen and (min-width: 424px) {
  .customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:not(:checked) + label:before,
  .customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:checked + label:before,
  .customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:not(:checked) + label:before,
  .customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:checked + label:before {
    left: 0;
  }
}
@media screen and (min-width: 768px) {
  .customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:not(:checked) + label:before,
  .customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:checked + label:before,
  .customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:not(:checked) + label:before,
  .customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:checked + label:before {
    left: 20%;
  }
}
@media screen and (min-width: 979px) {
  .customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:not(:checked) + label:before,
  .customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:checked + label:before,
  .customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:not(:checked) + label:before,
  .customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:checked + label:before {
    border: 2px solid #4a4a4a;
    top: 10px;
  }
}
@media screen and (min-width: 1199px) {
  .customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:not(:checked) + label:before,
  .customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:checked + label:before,
  .customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:not(:checked) + label:before,
  .customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:checked + label:before {
    left: 23%;
  }
}
.customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:checked + label:before,
.customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:checked + label:before {
  background: #93002a;
}
.customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:not(:checked) + label:after,
.customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:checked + label:after,
.customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:not(:checked) + label:after,
.customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:checked + label:after {
  content: "";
  left: 3px;
  top: 13px;
  border: 2px solid #fff;
  background: transparent;
  opacity: 0;
  transform: rotate(-45deg);
  position: absolute;
  width: 8px;
  height: 4px;
  font-size: 1.4em;
  line-height: 0.8;
  transition: all 0.2s;
}
@media screen and (min-width: 424px) {
  .customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:not(:checked) + label:after,
  .customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:checked + label:after,
  .customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:not(:checked) + label:after,
  .customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:checked + label:after {
    left: 3px;
    top: 13px;
  }
}
@media screen and (min-width: 768px) {
  .customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:not(:checked) + label:after,
  .customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:checked + label:after,
  .customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:not(:checked) + label:after,
  .customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:checked + label:after {
    top: 14px;
    height: 3px;
    left: 116px;
  }
}
@media screen and (min-width: 979px) {
  .customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:not(:checked) + label:after,
  .customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:checked + label:after,
  .customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:not(:checked) + label:after,
  .customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:checked + label:after {
    border: 2px solid #fff;
  }
}
@media screen and (min-width: 1199px) {
  .customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:not(:checked) + label:after,
  .customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:checked + label:after,
  .customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:not(:checked) + label:after,
  .customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:checked + label:after {
    left: 23.4%;
  }
}
@media screen and (min-width: 1366px) {
  .customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:not(:checked) + label:after,
  .customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:checked + label:after,
  .customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:not(:checked) + label:after,
  .customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:checked + label:after {
    left: 23.3%;
  }
}
@media screen and (min-width: 1920px) {
  .customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:not(:checked) + label:after,
  .customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:checked + label:after,
  .customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:not(:checked) + label:after,
  .customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:checked + label:after {
    left: 23.2%;
  }
}
.customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:not(:checked) + label:after,
.customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
  transform: rotate(-45deg);
}
.customer-address-form .page-main .form-address-edit .fieldset .billing [type=checkbox]:checked + label:after,
.customer-address-form .page-main .form-address-edit .fieldset .shipping [type=checkbox]:checked + label:after {
  opacity: 1;
  border-top: none;
  border-right: none;
}

.shipping-tracking-popup .page-wrapper .page-main {
  text-align: center;
}
.shipping-tracking-popup .page-wrapper .page-main .page-title-wrapper .page-title {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 28px;
  color: #000000;
}
.shipping-tracking-popup .page-wrapper .page-main .columns .page .order {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 22px;
  color: #000000;
}
.shipping-tracking-popup .page-wrapper .page-main .columns .page .table-wrapper .data tbody {
  display: inline-block;
}
.shipping-tracking-popup .page-wrapper .page-main .columns .page .table-wrapper .data tbody th {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 22px;
  color: #000000;
}
.shipping-tracking-popup .page-wrapper .page-main .columns .page .table-wrapper .data tbody td {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 22px;
  color: #000000;
}
.shipping-tracking-popup .page-wrapper .page-main .columns .page .actions .action {
  background-color: #93002a;
  border-radius: 0;
  border: none;
  padding: 10px 30px;
  height: 45px;
}
.shipping-tracking-popup .page-wrapper .page-main .columns .page .actions .action span {
  color: #fff;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 22px;
}

.page-wrapper .page-header .minicart-wrapper .action .counter {
  position: absolute;
  right: -12px;
  top: -8px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: #93002a;
  border: 1px solid #fff;
  padding: 0;
  margin: 0;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-wrapper .page-header .minicart-wrapper .action .counter .counter-number {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #fff;
  line-height: 0;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container {
  background-color: #fff;
  padding: 0;
  width: 95%;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container::-webkit-scrollbar-thumb {
  background: #9b9b9b;
  border-radius: 5px;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container::-webkit-scrollbar {
  width: 10px;
}
@media screen and (min-width: 768px) {
  .page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container {
    width: 55%;
  }
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container {
    min-width: 400px;
    max-width: 400px;
  }
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-title {
  text-align: center;
  padding: 25px 0;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-title #btn-minicart-close {
  left: 20px;
  top: 20px;
  position: absolute;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-title #btn-minicart-close::before {
  content: "";
  font-size: 10px;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-title .text {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 22px;
  color: #93002a;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-title button::before {
  color: #333333;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content {
  padding: 0 20px;
  text-align: center;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .minicart-items-wrapper {
  max-height: 450px;
  height: auto !important;
  border: none;
  padding: 0;
  width: 100%;
  margin: auto;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .minicart-items-wrapper #mini-cart .item {
  padding: 20px 0;
  margin: 0;
  border-bottom: 1px solid #93002a;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .minicart-items-wrapper #mini-cart .item .product {
  display: flex;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .minicart-items-wrapper #mini-cart .item .product .product-item-details {
  padding-left: 10px;
  width: 100%;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .minicart-items-wrapper #mini-cart .item .product .product-item-details .product-item-content {
  display: flex;
  justify-content: space-between;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .minicart-items-wrapper #mini-cart .item .product .product-item-details .product-item-content .product-item-name {
  display: flex;
  text-align: left;
  margin-right: 5px;
  height: 45px;
  overflow: hidden;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .minicart-items-wrapper #mini-cart .item .product .product-item-details .product-item-content .product-item-name {
    margin-right: 25px;
  }
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .minicart-items-wrapper #mini-cart .item .product .product-item-details .product-item-content .product-item-name a {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .minicart-items-wrapper #mini-cart .item .product .product-item-details .product-item-content .actions {
  margin: 0;
  display: flex;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .minicart-items-wrapper #mini-cart .item .product .product-item-details .product-item-content .actions .primary {
  display: none;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .minicart-items-wrapper #mini-cart .item .product .product-item-details .product-item-content .actions .secondary {
  display: flex;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .minicart-items-wrapper #mini-cart .item .product .product-item-details .product-item-content .actions .secondary .action::before {
  font-size: 11px;
  content: "";
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .minicart-items-wrapper #mini-cart .item .product .product-item-details .product-item-pricing {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .minicart-items-wrapper #mini-cart .item .product .product-item-details .product-item-pricing .price-container .price-wrapper .old-price {
  color: #000000;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .minicart-items-wrapper #mini-cart .item .product .product-item-details .product-item-pricing .price-container .price-wrapper .old-price .price {
  color: #000000;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .minicart-items-wrapper #mini-cart .item .product .product-item-details .product-item-pricing .price-container .price-wrapper .price-excluding-tax {
  font-size: 0;
  margin: 0;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .minicart-items-wrapper #mini-cart .item .product .product-item-details .product-item-pricing .price-container .price-wrapper .price-excluding-tax .minicart-price .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 20px;
  color: #93002a;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .minicart-items-wrapper #mini-cart .item .product .product-item-details .product-item-pricing .details-qty {
  margin-top: 0;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .minicart-items-wrapper #mini-cart .item .product .product-item-details .product-item-pricing .details-qty .qty-content {
  padding: 0 5px;
  border: 1px solid #4a4a4a;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .minicart-items-wrapper #mini-cart .item .product .product-item-details .product-item-pricing .details-qty .qty-content .item-qty {
  margin: 0;
  padding: 0;
  border: none;
  width: 35px;
  height: 30px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .minicart-items-wrapper #mini-cart .item .product .product-item-details .product-item-pricing .details-qty .update-cart-item {
  background-color: #93002a;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #fff;
  border-radius: 0;
  border: none;
  margin-top: 10px;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .content {
  margin-top: 20px;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .content #discount-coupon-form .fieldset {
  display: flex;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .content #discount-coupon-form .fieldset .field {
  width: 100%;
  margin-right: 10px;
  margin-bottom: 0;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .content #discount-coupon-form .fieldset .field .control {
  width: 100%;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .content #discount-coupon-form .fieldset .field .control .input-text {
  height: 45px;
  padding: 12px 20px;
  border: 1px solid #4a4a4a;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .content #discount-coupon-form .fieldset .field .control .input-text {
    padding: 12px 20px;
  }
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .content #discount-coupon-form .fieldset .field .control .input-text::placeholder {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #4a4a4a;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .content #discount-coupon-form .fieldset .actions-toolbar .primary .action {
  width: 100%;
  height: 45px;
  border-radius: 0;
  margin: 0;
  background-color: #000000;
  padding: 10px 20px;
  border: none;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .content #discount-coupon-form .fieldset .actions-toolbar .primary .action span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .subtotal {
  display: flex;
  justify-content: space-between;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .subtotal .label span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  color: #4a4a4a;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .subtotal .amount .price-wrapper .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  color: #4a4a4a;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .total {
  display: flex;
  justify-content: space-between;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .total .label span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  color: #4a4a4a;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .total .total .price-wrapper .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 24px;
  color: #4a4a4a;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .discount,
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .items-total {
  display: flex;
  justify-content: space-between;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .discount .text,
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .items-total .text {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  color: #4a4a4a;
  margin: 0;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .discount .amount,
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .items-total .amount {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  color: #4a4a4a;
  margin: 0;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content div.discount:nth-child(2) {
  justify-content: center;
  margin: 20px 0;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content div.discount:nth-child(2) .payment-option {
  width: 100%;
  cursor: pointer;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content div.discount:nth-child(2) .payment-option .payment-option-title {
  height: 45px;
  display: flex;
  align-items: center;
  background: #000000;
  justify-content: center;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content div.discount:nth-child(2) .payment-option .payment-option-title .action span {
  color: #fff;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  text-transform: uppercase;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content div.discount:nth-child(2) .payment-option .payment-option-content .form {
  display: flex;
  margin-top: 20px;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content div.discount:nth-child(2) .payment-option .payment-option-content .form .payment-option-inner {
  width: 100%;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content div.discount:nth-child(2) .payment-option .payment-option-content .form .payment-option-inner .field .label span {
  display: none;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content div.discount:nth-child(2) .payment-option .payment-option-content .form .payment-option-inner .field .control .input-text {
  height: 45px;
  border: 1px solid #4a4a4a !important;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content div.discount:nth-child(2) .payment-option .payment-option-content .form .payment-option-inner .field .control .input-text::placeholder {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #4a4a4a;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content div.discount:nth-child(2) .payment-option .payment-option-content .form .payment-option-inner .field .control .input-text:focus {
  box-shadow: none;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content div.discount:nth-child(2) .payment-option .payment-option-content .form .payment-option-inner .field .control .mage-error {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: 1px dashed red;
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  text-align: center;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content div.discount:nth-child(2) .payment-option .payment-option-content .form .actions-toolbar .primary .action {
  height: 45px;
  border-radius: 0;
  width: auto;
  margin: 0 0 0 10px;
  background-color: #000000;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content div.discount:nth-child(2) .payment-option .payment-option-content .form .actions-toolbar .primary .action span span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content div.discount:nth-child(4) .label span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #4a4a4a;
  line-height: 26px;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content div.discount:nth-child(4) .discount.price-container {
  margin: 0;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content div.discount:nth-child(4) .discount.price-container .price-wrapper .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #4a4a4a;
  line-height: 26px;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .items-total .text {
  font-size: 24px;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .items-total .amount {
  font-size: 24px;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .actions {
  margin-top: 20px;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .actions .primary .action {
  background-color: #93002a;
  width: 100%;
  border: none;
  border-radius: 0;
  height: 45px;
  font-size: 20px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .actions .secondary .action span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #93002a;
  text-transform: uppercase;
  text-decoration: underline;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .payment-methods {
  margin: 30px 0;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .payment-methods .logos .list {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .payment-methods .logos .list .icon {
  padding: 0 2px;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .payment-methods .logos .second {
  width: 60%;
  margin: 10px auto 0;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .payment-methods .help .contact-us {
  margin: 20px 0 10px;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .payment-methods .help .contact-us .text {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  margin: 0;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .payment-methods .help .whatssap .link {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .payment-methods .help .whatssap .link .text {
  margin: 0;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #4a4a4a;
  margin-left: 10px;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .block-empty {
  padding: 30% 0;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .block-empty .subtitle {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: #000000;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .block-empty .empty-img {
  margin: 20px 0;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .block-empty .btn {
  background-color: #93002a;
  border: none;
  border-radius: 0;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
}
.page-wrapper .page-header .minicart-wrapper #minicart-content-toggle .off-canvas-cart-container .block-content .block-empty .btn span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: #fff;
  padding: 10px 0;
}

.modals-wrapper .modal-popup .modal-inner-wrap .modal-content {
  padding: 0 50px;
}
.modals-wrapper .modal-popup .modal-inner-wrap .modal-content div {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  text-align: center;
}
.modals-wrapper .modal-popup .modal-inner-wrap .modal-footer {
  text-align: center;
}
.modals-wrapper .modal-popup .modal-inner-wrap .modal-footer button {
  background-color: #000000;
  border: none;
  border-radius: 0;
}
.modals-wrapper .modal-popup .modal-inner-wrap .modal-footer button span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #fff;
}

@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container {
    display: flex;
    flex-direction: row-reverse;
    margin: 10px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary {
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 0;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary {
    width: auto;
    top: 0 !important;
  }
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary {
    width: 25%;
    height: 100%;
    padding-bottom: 20px;
    margin-left: 20px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .summary {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .summary {
    margin-top: 20px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary #block-shipping {
  display: none;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .block.discount {
  padding: 10px 20px 20px;
  border: solid 1px #4a4a4a;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .block.discount {
    padding: 10px 10px 10px;
  }
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .block.discount {
    padding: 10px 20px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .block.discount .title {
  text-align: left;
  border-top: none;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .block.discount .title {
    display: flex;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .block.discount .title strong {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #4a4a4a;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .block.discount .title strong {
    margin: 0;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .block.discount .title::after {
  content: url(../images/checkout/mas-opciones.svg);
  right: 0;
  top: 0;
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .block.discount .title::after {
    top: -6px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .block.discount .content form .fieldset {
  margin: 0;
  margin-top: 20px;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .block.discount .content form .fieldset .field {
  margin-bottom: 15px;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .block.discount .content form .fieldset .field .control .input-text {
  height: 45px;
  border: 1px solid #4a4a4a;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .block.discount .content form .fieldset .field .control .input-text::placeholder {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #4a4a4a;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .block.discount .content form .fieldset .actions-toolbar .primary {
    width: 100%;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .block.discount .content form .fieldset .actions-toolbar .primary .action {
  height: 45px;
  background-color: #000000;
  color: #fff;
  transition: 0.5s;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .block.discount .content form .fieldset .actions-toolbar .primary .action:hover {
  background-color: #fff;
  color: #000000;
  border: 1px solid #000000;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .block.discount .content form .fieldset .actions-toolbar .primary .action {
    line-height: normal;
    display: flex;
    align-items: center;
    margin-right: 0;
    width: 100%;
    justify-content: center;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .block.discount .content form .fieldset .actions-toolbar .primary .action span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .block.discount .content form .fieldset .actions-toolbar .primary .action span {
    font-size: 14px;
  }
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .block.discount .content form .fieldset .actions-toolbar .primary .action span {
    font-size: 18px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .block.discount.active .title::after {
  content: url(../images/checkout/menos-opciones.svg);
  right: 0;
  top: -9px;
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .block.discount.active .title::after {
    top: -15px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .totals .mark {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #4a4a4a;
  line-height: 1.44;
  padding: 0;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .totals .amount {
  padding: 0;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .totals .amount .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #4a4a4a;
  line-height: 1.44;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .shipping {
  display: none;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .grand {
  border-top: 1px solid #93002a;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .grand .mark {
  font-size: 24px;
  padding-top: 20px;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .grand .mark {
    font-size: 18px;
  }
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .grand .mark {
    font-size: 24px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .grand .amount {
  padding-top: 20px;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .grand .amount {
    padding-bottom: 0;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .grand .amount .price {
  font-size: 24px;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .grand .amount .price {
    font-size: 20px;
  }
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .grand .amount .price {
    font-size: 24px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .checkout .item aplazo-placement {
  font-family: "Playfair Display";
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .checkout .item .action {
  height: 45px;
  background-color: #93002a;
  border-radius: 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1px;
  color: #fff;
  transition: 0.5s;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .checkout .item .action:hover {
  background-color: #fff;
  color: #93002a;
  border: 1px solid #93002a;
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .checkout .item .action {
    height: 100%;
    line-height: 16px;
  }
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .checkout .item .action {
    height: 45px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .checkout .item .action span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .checkout .item .action span {
    font-size: 16px;
  }
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .checkout .item .action span {
    font-size: 20px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .checkout .item .multicheckout {
  line-height: normal;
  height: 100%;
  padding: 10px;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .cart-summary .checkout .item .multicheckout::hover {
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form {
    width: auto;
    margin-right: 10px;
  }
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form {
    width: 75%;
    margin-right: 0;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items {
    display: table;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items thead tr .item {
  display: none;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items thead tr .item {
    display: table-cell;
  }
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items thead tr .col {
    font-size: 18px;
    font-family: "Playfair Display";
    font-weight: 800;
    font-style: normal;
    color: #000000;
    text-align: center;
  }
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items thead tr .col {
    font-size: 24px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart {
  border-top: 1px solid #4a4a4a;
  border-left: 1px solid #4a4a4a;
  border-right: 1px solid #4a4a4a;
  margin: 0 15px;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart:last-child {
  border-bottom: 1px solid #4a4a4a;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart {
    border-top: 1px solid #93002a;
    border-bottom: 1px solid #93002a !important;
    border-left: none;
    border-right: none;
  }
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info {
    padding: 20px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .price .old-price {
  color: #000000;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .price .old-price .price {
  color: #000000;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .price .price-excluding-tax {
  font-size: 0;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .price .price-excluding-tax .cart-price p {
  margin: 0 0 5px;
  color: #000000 !important;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .price .price-excluding-tax .cart-price p .price {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item {
  display: grid;
  grid-template-columns: 40% 58%;
  padding: 10px;
  align-items: flex-start;
  grid-column-gap: 10px;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item {
    margin: 20px;
    padding: 0;
    display: flex;
    width: 100%;
  }
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item {
    align-items: center;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-photo {
  position: relative;
  top: 0;
  max-width: 100%;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-photo {
    width: 120px;
    max-width: 120px;
    max-height: 120px;
  }
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-photo {
    width: 174px;
    max-width: 174px;
    max-height: 174px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-photo .product-image-container {
  width: 100% !important;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-photo .product-image-container {
    width: 120px !important;
  }
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-photo .product-image-container {
    width: 174px !important;
  }
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-photo .product-image-container .product-image-wrapper .product-image-photo {
    width: 120px;
    height: 120px;
    object-fit: cover;
  }
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-photo .product-image-container .product-image-wrapper .product-image-photo {
    width: 174px;
    height: 174px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-details {
  line-height: 1.6rem;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-details {
    display: flex;
    justify-content: flex-end;
    position: relative;
    left: -10px;
    flex-direction: column;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-details .cart.item {
  display: flex;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-details .cart.item div:before {
  margin: 0;
  position: static;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-details .product-item-name a {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .price,
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty,
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal {
  padding-top: 0;
  padding-bottom: 0;
  width: 26%;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .price,
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty,
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal {
    position: relative;
    vertical-align: bottom;
    padding-bottom: 20px;
    text-align: center;
  }
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .price,
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty,
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal {
    width: 16%;
    vertical-align: middle;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .price::before,
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty::before,
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal::before {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  text-transform: uppercase;
  padding-bottom: 20px;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .price .price-excluding-tax .cart-price .price,
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal .price-excluding-tax .cart-price .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #93002a;
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .price .price-excluding-tax .cart-price .price,
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal .price-excluding-tax .cart-price .price {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty {
    width: 12%;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty .field {
  border: 1px solid #4a4a4a;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty .field {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty .field {
    width: 70%;
    margin: auto;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty .field .control {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty .field .control {
    padding-bottom: 0;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty .field .control label {
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty .field .control label button {
  border: none;
  background-color: transparent;
  padding: 0;
  display: flex;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty .field .control label .input-text {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  border: none;
  padding: 0;
  width: 26%;
  margin: 0 2px;
  min-width: 25px;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal .flex-actions {
  display: none;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal .flex-actions {
    display: block;
  }
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal .flex-actions {
    display: none;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal .flex-actions .actions-toolbar {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin-left: 0;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal .flex-actions .actions-toolbar .action {
  border: none;
  background: transparent;
  padding-top: 0;
  padding: 0;
  margin: 0;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal .flex-actions .actions-toolbar .use-ajax.action.towishlist.action-towishlist {
  display: none;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal .price-excluding-tax .cart-price .price {
  color: #000000;
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal .price-excluding-tax .cart-price .price {
    padding-bottom: 0;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item-actions {
  box-sizing: border-box;
  display: block;
  float: left;
  text-align: center;
  width: 21%;
  padding-top: 0;
  padding-bottom: 0;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item-actions {
    display: none;
  }
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item-actions {
    display: table-cell;
    float: none;
    vertical-align: middle;
    width: 12%;
    padding-bottom: 20px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item-actions .actions-toolbar {
  display: flex;
  flex-direction: column-reverse;
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item-actions .actions-toolbar {
    flex-direction: row;
    justify-content: center;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item-actions .actions-toolbar .action {
  border: none;
  background: transparent;
  padding-top: 0;
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item-actions .actions-toolbar .action {
    margin: 0;
    padding: 0 10px;
    display: flex;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item-actions .actions-toolbar .use-ajax.action.towishlist.action-towishlist {
  display: none;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .main {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .main {
    justify-content: flex-end;
    flex-direction: row;
    margin: 10px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .main .continue,
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .main .clear,
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .main .update {
  display: flex;
  background: #fff;
  border: 1px solid #000000;
  border-radius: 0;
  padding: 10px 20px;
  color: #000000;
  transition: 0.5s;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .main .continue::before,
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .main .clear::before,
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .main .update::before {
  display: none;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .main .continue:hover,
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .main .clear:hover,
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .main .update:hover {
  background-color: #000000;
  color: #fff;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .main .continue span,
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .main .clear span,
.checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .main .update span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .main .continue span,
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .main .clear span,
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .main .update span {
    font-size: 16px;
  }
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .main .continue span,
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .main .clear span,
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .main .update span {
    font-size: 20px;
  }
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .main .clear,
  .checkout-cart-index .page-wrapper .page-main .columns .column .cart-container .form .main .update {
    margin-bottom: 0;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .steps {
  border: 1px solid #93002a;
  margin: 0 15px;
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .steps {
    margin: 30px 10px 0;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .steps .uk-slider-items .container {
  display: grid;
  grid-template-columns: 25% 75%;
  align-items: center;
  margin-top: 20px;
  padding: 0 15px;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .steps .uk-slider-items .container::after, .checkout-cart-index .page-wrapper .page-main .columns .column .steps .uk-slider-items .container::before {
  display: none;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .steps .uk-slider-items .container {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .steps .uk-slider-items .container {
    margin-bottom: 40px;
    margin-top: 40px;
    grid-template-columns: 25% 75%;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .steps .uk-slider-items .container .img {
  margin-right: 20px;
  text-align: center;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .steps .uk-slider-items .container .text-container .text {
  margin: 0;
  color: #93002a;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .steps .uk-slider-items .container .text-container .title {
  font-size: 22px;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .steps .uk-slider-items .container .text-container .title {
    text-transform: initial;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .steps .uk-slider-items .container .text-container .subtitle {
  font-size: 18px;
  color: #93002a;
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .steps .uk-slider-items .container .text-container .subtitle {
    font-size: 16px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .steps .uk-slider-nav {
  justify-content: center;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .steps .uk-slider-nav li {
  margin-bottom: 20px;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .steps .uk-slider-nav li a {
  background-color: #c8c8c8;
  border: 1px solid #c8c8c8;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .steps .uk-slider-nav .uk-active a {
  background-color: #93002a;
  border: 1px solid #93002a;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block {
  margin-top: 20px;
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .block {
    margin-top: 30px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-title {
  text-align: center;
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .block .block-title {
    margin-bottom: 20px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-title strong {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: #000000;
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .block .block-title strong {
    font-size: 30px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider {
  margin-top: 20px;
  margin-bottom: 0;
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .products {
    justify-content: center;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .products .item {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .products .item {
    width: 19.2%;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .products .item .product-item-info .photo .product-image-container .product-image-wrapper {
  padding-bottom: 100% !important;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .products .item .product-item-info .photo .product-image-container .product-image-wrapper .product-image-photo {
  margin-top: 0;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .products .item .product-item-info .details .product {
  font-size: 0;
  height: 65px;
  overflow: hidden;
  text-align: center;
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .products .item .product-item-info .details .product {
    height: 55px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .products .item .product-item-info .details .product a {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .products .item .product-item-info .details .product a {
    font-size: 20px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .products .item .product-item-info .details .price-box {
  text-align: center;
  margin-bottom: 10px;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .products .item .product-item-info .details .price-box .normal-price .price-container .price-wrapper .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .products .item .product-item-info .details .price-box .normal-price .price-container .price-wrapper .price {
    font-size: 20px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .products .item .product-item-info .details .price-box .price-container .price-wrapper .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .products .item .product-item-info .details .price-box .price-container .price-wrapper .price {
    font-size: 20px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .products .item .product-item-info .details .actions {
  height: auto;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .products .item .product-item-info .details .actions .actions-primary {
  width: 100%;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .products .item .product-item-info .details .actions .actions-primary .action {
  height: 40px;
  border: solid 1px #93002a;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #93002a;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 0;
  transition: 0.5s;
  font-size: 16px;
  padding: 7px 5px;
  width: 100%;
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .products .item .product-item-info .details .actions .actions-primary .action {
    height: 45px;
    font-size: 22px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .products .item .product-item-info .details .actions .actions-primary .action:hover {
  background: #93002a;
  color: #fff;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .products .item .product-item-info .details .actions .actions-primary .action span {
  text-transform: uppercase;
  font-size: 16px;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .products .item .product-item-info .details .actions .secondary-addto-links {
  display: none;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .arrows .uk-icon {
  background: url(../images/icons/icon-arrow-izq-oscura.svg);
  content: "";
  width: 50px;
  height: 55px;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 0;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .arrows .uk-icon:hover {
  background: url(../images/icons/icon-arrow.svg);
  content: "";
  width: 50px;
  height: 55px;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  background-position: center;
  background-color: #fff;
  border-radius: 0;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .arrows .uk-icon svg {
  display: none;
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .arrows .uk-position-center-right {
  transform: rotate(-180deg);
  top: 42%;
  right: -15px;
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .arrows .uk-position-center-right {
    top: 40%;
  }
}
@media screen and (min-width: 1199px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .arrows .uk-position-center-right {
    top: 45%;
  }
}
@media screen and (min-width: 1366px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .arrows .uk-position-center-right {
    top: 50%;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .arrows .uk-position-center-right:hover {
  transform: rotate(0deg);
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .arrows .uk-position-center-left {
  transform: translateY(0);
  top: 42%;
  left: -15px;
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .arrows .uk-position-center-left {
    top: 40%;
  }
}
@media screen and (min-width: 1199px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .arrows .uk-position-center-left {
    top: 45%;
  }
}
@media screen and (min-width: 1366px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .arrows .uk-position-center-left {
    top: 50%;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block .block-content .uk-slider .arrows .uk-position-center-left:hover {
  transform: rotate(-180deg);
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block.crosssell .item .discount-flag {
  width: 45px;
  height: 45px;
  margin: 10px;
  border-radius: 3em;
  overflow: hidden;
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b88f01;
  transform: rotate(-25deg);
  font-size: 18px;
  color: #fff;
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  left: 25px;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .block.crosssell .item .discount-flag {
    width: 60px;
    height: 60px;
  }
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .block.crosssell .item .discount-flag {
    left: 40px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block.crosssell .item .discount-flag .discount {
  margin-top: -5px;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .block.crosssell .item .discount-flag .discount {
    margin-top: -6px;
    font-size: 24px;
  }
}
@media screen and (min-width: 979px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .block.crosssell .item .discount-flag .discount {
    margin-top: -10px;
  }
}
.checkout-cart-index .page-wrapper .page-main .columns .column .block.crosssell .item .discount-flag .percent {
  font-size: 10.5px;
  padding-top: 5px;
}
@media screen and (min-width: 768px) {
  .checkout-cart-index .page-wrapper .page-main .columns .column .block.crosssell .item .discount-flag .percent {
    font-size: 14px;
  }
}

.checkout-onepage-success .page-wrapper .page-main .columns .column {
  padding-bottom: 0;
}
.checkout-onepage-success .page-wrapper .page-main .columns .column .iwd_success_page_wrapper {
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 1199px) {
  .checkout-onepage-success .page-wrapper .page-main .columns .column .iwd_success_page_wrapper {
    height: 80vh;
  }
}
@media screen and (min-width: 1199px) {
  .checkout-onepage-success .page-wrapper .page-main .columns .column .iwd_success_page_wrapper .background {
    width: 100%;
    height: 100%;
  }
}
.checkout-onepage-success .page-wrapper .page-main .columns .column .iwd_success_page_wrapper .background .img {
  min-height: 125vh;
  object-fit: cover;
}
@media screen and (min-width: 1199px) {
  .checkout-onepage-success .page-wrapper .page-main .columns .column .iwd_success_page_wrapper .background .img {
    width: 100%;
    min-height: auto;
  }
}
.checkout-onepage-success .page-wrapper .page-main .columns .column .iwd_success_page_wrapper .iwd_opc_alternative_wrapper {
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
}
.checkout-onepage-success .page-wrapper .page-main .columns .column .iwd_success_page_wrapper .iwd_opc_alternative_wrapper .iwd_opc_column .iwd_opc_alternative_column {
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 5px;
  margin: 5px;
  max-width: 350px;
}
@media screen and (min-width: 768px) {
  .checkout-onepage-success .page-wrapper .page-main .columns .column .iwd_success_page_wrapper .iwd_opc_alternative_wrapper .iwd_opc_column .iwd_opc_alternative_column {
    max-width: 640px;
    margin: 5px auto;
    padding: 25px;
  }
}
@media screen and (min-width: 979px) {
  .checkout-onepage-success .page-wrapper .page-main .columns .column .iwd_success_page_wrapper .iwd_opc_alternative_wrapper .iwd_opc_column .iwd_opc_alternative_column {
    margin: 0;
    padding: 30px;
    max-width: 670px;
  }
}
.checkout-onepage-success .page-wrapper .page-main .columns .column .iwd_success_page_wrapper .iwd_opc_alternative_wrapper .iwd_opc_column .iwd_opc_alternative_column .iwd_opc_universal_wrapper {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  color: #000000;
  font-size: 26px;
}
@media screen and (min-width: 768px) {
  .checkout-onepage-success .page-wrapper .page-main .columns .column .iwd_success_page_wrapper .iwd_opc_alternative_wrapper .iwd_opc_column .iwd_opc_alternative_column .iwd_opc_universal_wrapper {
    font-size: 36px;
  }
}
.checkout-onepage-success .page-wrapper .page-main .columns .column .iwd_success_page_wrapper .iwd_opc_alternative_wrapper .iwd_opc_column .iwd_opc_alternative_column .iwd_opc_success_page_create_account_info {
  font-size: 16px;
}
.checkout-onepage-success .page-wrapper .page-main .columns .column .iwd_success_page_wrapper .iwd_opc_alternative_wrapper .iwd_opc_column .iwd_opc_alternative_column .iwd_opc_column_content .iwd_success_page_info {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  color: #000000;
  margin-bottom: 0;
  font-size: 16px;
  padding: 0;
}
.checkout-onepage-success .page-wrapper .page-main .columns .column .iwd_success_page_wrapper .iwd_opc_alternative_wrapper .iwd_opc_column .iwd_opc_alternative_column .iwd_opc_column_content .iwd_success_page_info a {
  color: #93002a;
  text-decoration: underline;
}
.checkout-onepage-success .page-wrapper .page-main .columns .column .iwd_success_page_wrapper .iwd_opc_alternative_wrapper .iwd_opc_column .iwd_opc_alternative_column .iwd_opc_column_content .iwd_success_page_info .checkout-success h5 {
  display: none;
}
.checkout-onepage-success .page-wrapper .page-main .columns .column .iwd_success_page_wrapper .iwd_opc_alternative_wrapper .iwd_opc_column .iwd_opc_alternative_column .iwd_opc_column_content .iwd_success_page_info .text {
  margin: 0;
}
.checkout-onepage-success .page-wrapper .page-main .columns .column .iwd_success_page_wrapper .iwd_opc_alternative_wrapper .iwd_opc_column .iwd_opc_alternative_column .iwd_opc_column_content .iwd_opc_universal_wrapper .iwd_opc_button {
  margin-top: 20px;
  background-color: #93002a;
  border: #93002a;
  color: #fff;
  text-transform: uppercase;
  border-radius: 0;
}
@media screen and (min-width: 768px) {
  .checkout-onepage-success .page-wrapper .page-main .columns .column .iwd_success_page_wrapper .iwd_opc_alternative_wrapper .iwd_opc_column .iwd_opc_alternative_column .iwd_opc_column_content .iwd_opc_universal_wrapper .iwd_opc_button {
    width: 50%;
    margin: 20px auto 0;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
}
.checkout-onepage-success .page-wrapper .page-footer .footer .newsletter {
  display: none;
}

.multishipping-checkout-addresses .page-wrapper .page-header .header .nav-toggle {
  display: none;
}
.multishipping-checkout-addresses .page-wrapper .page-main {
  padding: 0 20px;
}
@media screen and (min-width: 768px) {
  .multishipping-checkout-addresses .page-wrapper .page-main .page-title-wrapper .block-title .page-title {
    margin: 20px 0;
  }
}
.multishipping-checkout-addresses .page-wrapper .page-main .page-title-wrapper .block-title .page-title span {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 36px;
  color: #000000;
}
.multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .title {
  margin: 0;
  line-height: normal;
}
@media screen and (min-width: 768px) {
  .multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .title {
    margin-bottom: 20px;
  }
}
.multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .title strong {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .table-wrapper .items thead tr th {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
@media screen and (min-width: 979px) {
  .multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .table-wrapper .items thead tr th {
    border-bottom: 1px solid #4a4a4a;
  }
}
.multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .table-wrapper .items tbody tr {
  border-bottom: 1px solid #4a4a4a;
}
@media screen and (min-width: 768px) {
  .multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .table-wrapper .items tbody tr td {
    padding: 15px 10px;
  }
}
.multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .table-wrapper .items tbody tr td strong a {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
.multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .table-wrapper .items tbody tr td::before {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .table-wrapper .items tbody tr .col.qty .field.qty .control .input-text {
  border: 1px solid #4a4a4a;
}
.multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .table-wrapper .items tbody tr .col.qty .field.qty .control .input-text::placeholder {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
.multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .table-wrapper .items tbody tr .col.address .field .control {
  position: relative;
}
.multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .table-wrapper .items tbody tr .col.address .field .control::after {
  font-family: FontAwesome;
  content: "\f107";
  position: absolute;
  color: #343434;
  font-size: 14px;
  right: 16px;
  line-height: 1;
  top: calc(50% - 7px);
}
.multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .table-wrapper .items tbody tr .col.address .field .control select {
  border: 1px solid #4a4a4a;
  border-radius: 0;
  min-height: 45px;
  color: #4a4a4a;
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  appearance: none;
  padding: 0 30px 0 10px;
}
.multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .table-wrapper .items tbody tr .col.actions .delete span {
  color: #93002a;
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  text-decoration: underline;
}
@media screen and (min-width: 768px) {
  .multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .actions-toolbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 979px) {
  .multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .actions-toolbar {
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
  }
}
@media screen and (min-width: 768px) {
  .multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .actions-toolbar .primary {
    margin-right: 0;
  }
}
.multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .actions-toolbar .primary .action {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #93002a;
  border-radius: 0;
  border: none;
  height: 45px;
  line-height: 24px;
  padding: 0;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .actions-toolbar .primary .action {
    margin-right: 0;
    padding: 15px;
  }
}
@media screen and (min-width: 979px) {
  .multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .actions-toolbar .primary .action {
    transition: 0.5s;
  }
}
@media screen and (min-width: 979px) {
  .multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .actions-toolbar .primary .action:hover {
    background-color: #fff;
    color: #93002a;
    border: 1px solid #93002a;
  }
}
.multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .actions-toolbar .primary .action span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .actions-toolbar .secondary {
    margin-top: 25px;
  }
}
@media screen and (min-width: 979px) {
  .multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .actions-toolbar .secondary {
    margin-top: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;
    gap: 0px 0px;
  }
}
.multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .actions-toolbar .secondary .action {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 0;
  border: 1px solid #000000;
  height: 45px;
  line-height: 24px;
  padding: 0;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .actions-toolbar .secondary .action {
    margin-left: 0;
    margin-right: 0;
    padding: 15px;
    width: 100%;
  }
}
@media screen and (min-width: 979px) {
  .multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .actions-toolbar .secondary .action {
    width: auto;
    margin-bottom: 0;
    transition: 0.5s;
  }
}
@media screen and (min-width: 979px) {
  .multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .actions-toolbar .secondary .action:hover {
    background-color: #000000;
    color: #fff;
  }
}
.multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .actions-toolbar .secondary .action span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  text-transform: uppercase;
}
@media screen and (min-width: 979px) {
  .multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .actions-toolbar .secondary .action.update {
    margin-bottom: 0;
    margin: 0 10px;
  }
}
.multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .actions-toolbar .secondary .action.back {
  border: none;
  height: 100%;
}
@media screen and (min-width: 979px) {
  .multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .actions-toolbar .secondary .action.back {
    margin-top: 0;
    grid-area: 2/1/3/3;
    justify-content: flex-end;
    height: auto;
    background-color: transparent;
  }
}
.multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .actions-toolbar .secondary .action.back span {
  font-size: 16px;
  color: #93002a;
  text-decoration: underline;
  text-transform: initial;
}
@media screen and (min-width: 768px) {
  .multishipping-checkout-addresses .page-wrapper .page-main .columns .column .multicheckout .actions-toolbar .secondary .action.back span {
    font-size: 18px;
  }
}

@media screen and (min-width: 979px) {
  .onepage-index-index .page-wrapper .page-header .header {
    min-height: 85px;
  }
}
.onepage-index-index .page-wrapper .page-header .header .logo {
  margin-left: 30px;
  padding-bottom: 10px;
  width: 145px;
}
.onepage-index-index .page-wrapper .page-header .header .logo img {
  max-height: 64px;
}
.onepage-index-index .page-wrapper .page-header .header .block.block-search {
  display: none;
}
.onepage-index-index .page-wrapper .page-header .header .header-right {
  top: 0;
}
.onepage-index-index .page-wrapper .page-header .header .header-right .help-container {
  margin-right: 55px;
}
.onepage-index-index .page-wrapper .page-header .header .header-right .help-container a {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  color: #fff;
  font-size: 18px;
}
.onepage-index-index .page-wrapper .page-header .header .header-right .help-container a:hover {
  text-decoration: underline;
}
.onepage-index-index .page-wrapper .page-header .header .header-right .store-info .whatssap .icon-icon-whatsapp-relleno {
  top: 0;
}
.onepage-index-index .page-title {
  margin-bottom: 0;
  display: block !important;
  text-align: center;
}
.onepage-index-index .page-title span {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 30px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}
@media screen and (max-width: 768px) {
  .onepage-index-index .columns {
    padding: 0 20px;
  }
}
.onepage-index-index .columns .kueski-widget {
  display: none;
}
.onepage-index-index .field-error {
  font-size: 1rem !important;
}
.onepage-index-index .iwd_main_wrapper .field .scroll-wrapper .iwd_opc_select_option:last-child {
  border-bottom: initial;
}
.onepage-index-index .page-main {
  max-width: 1366px !important;
}
.onepage-index-index .page-main .iwd_main_wrapper {
  margin: 0;
}
.onepage-index-index .page-main .iwd_main_wrapper div.mage-error[generated],
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_universal_wrapper.iwd_opc_message .iwd_opc_message_error {
  font-size: 16px !important;
}
.onepage-index-index .page-main .iwd_main_wrapper #iwd_opc_top {
  margin-top: 0;
  margin-bottom: 0;
}
.onepage-index-index .page-main .iwd_main_wrapper #iwd_opc_top .iwd_opc_column {
  float: left;
}
@media screen and (min-width: 1199px) {
  .onepage-index-index .page-main .iwd_main_wrapper #iwd_opc_top .iwd_opc_column {
    padding-left: 20px !important;
    padding-right: 15px;
  }
}
.onepage-index-index .page-main .iwd_main_wrapper #iwd_opc_top .iwd_opc_column .iwd_opc_alternative_column #iwd_opc_top_login_button,
.onepage-index-index .page-main .iwd_main_wrapper #iwd_opc_top .iwd_opc_column .iwd_opc_alternative_column .iwd_opc_button.active {
  background-color: #fff !important;
  border: solid 1px #4a4a4a;
  border-radius: 0;
  height: 45px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}
.onepage-index-index .page-main .iwd_main_wrapper #iwd_opc_top .iwd_opc_column .iwd_opc_alternative_column #iwd_opc_top_login_button ::placeholder,
.onepage-index-index .page-main .iwd_main_wrapper #iwd_opc_top .iwd_opc_column .iwd_opc_alternative_column .iwd_opc_button.active ::placeholder {
  color: #4a4a4a;
}
.onepage-index-index .page-main .iwd_main_wrapper #iwd_opc_top .iwd_opc_column .iwd_opc_alternative_column #iwd_opc_top_login_button:focus,
.onepage-index-index .page-main .iwd_main_wrapper #iwd_opc_top .iwd_opc_column .iwd_opc_alternative_column .iwd_opc_button.active:focus {
  box-shadow: none;
  outline: none;
}
@media screen and (min-width: 1199px) {
  .onepage-index-index .page-main .iwd_main_wrapper #iwd_opc_top .iwd_opc_column .iwd_opc_alternative_column #iwd_opc_top_login_button,
  .onepage-index-index .page-main .iwd_main_wrapper #iwd_opc_top .iwd_opc_column .iwd_opc_alternative_column .iwd_opc_button.active {
    width: 100%;
  }
}
@media screen and (min-width: 979px) {
  .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper {
    margin: 0 10px;
  }
}
@media screen and (min-width: 1199px) {
  .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container {
    display: flex;
  }
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container {
  width: 100%;
  display: block;
}
@media screen and (min-width: 979px) {
  .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container {
    width: 70%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content;
  }
}
@media screen and (min-width: 979px) {
  .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_address_column {
    grid-area: 1/1/3/2;
  }
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_address_column .iwd_opc_alternative_column .iwd_opc_column_content .fieldset .field .control .scroll-wrapper .iwd_opc_select_container .iwd_opc_select_option:last-child {
  border-bottom: 1px solid #4a4a4a;
}
@media screen and (min-width: 979px) {
  .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_shipping_column {
    grid-area: 1/2/2/3;
  }
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_shipping_column .iwd_opc_alternative_column .iwd_opc_column_content #iwd_opc_shipping_method .fieldset .form .mage-error {
  color: #e02b27;
  font-size: 1rem !important;
  margin-bottom: 10px;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_shipping_column .iwd_opc_alternative_column .iwd_opc_column_content #iwd_opc_shipping_method .fieldset .form #checkout-shipping-method-load .field .control .iwd_opc_select_container {
  border-radius: 0;
  border: 0;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_shipping_column .iwd_opc_alternative_column .iwd_opc_column_content #iwd_opc_shipping_method .fieldset .form #checkout-shipping-method-load .field .control .iwd_opc_select_container .iwd_opc_select_option {
  border-bottom: 1px solid #4a4a4a;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_shipping_column .iwd_opc_alternative_column .iwd_opc_column_content #iwd_opc_shipping_method .fieldset .form #checkout-shipping-method-load .field .control .iwd_opc_select_container .iwd_opc_select_option.selected {
  border: 2px solid #b88f01;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_shipping_column .iwd_opc_alternative_column .iwd_opc_column_content #iwd_opc_shipping_method .fieldset .form #checkout-shipping-method-load .field .control [data-element-id=iwd_opc_shipping_method_group] .iwd_opc_select_option:last-child {
  margin-top: 20px;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_shipping_column .iwd_opc_alternative_column .iwd_opc_column_content #iwd_opc_shipping_method .fieldset .form #checkout-shipping-method-load .field .control [data-element-id=iwd_opc_shipping_method_group] .iwd_opc_select_option.selected:last-child {
  margin-top: 0;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_shipping_column .iwd_opc_alternative_column .iwd_opc_column_content #iwd_opc_shipping_method .fieldset .form #checkout-shipping-method-load .field .control [data-element-id=iwd_opc_shipping_method_rates] .iwd_opc_select_option:last-child {
  margin-top: 20px;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_shipping_column .iwd_opc_alternative_column .iwd_opc_column_content #iwd_opc_shipping_method .fieldset .form #checkout-shipping-method-load .field .control [data-element-id=iwd_opc_shipping_method_rates] .iwd_opc_select_option.selected:last-child {
  margin-top: 0;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_shipping_column .iwd_opc_alternative_column .iwd_opc_column_content #iwd_opc_shipping_method .fieldset .form #checkout-shipping-method-load .field .control .iwd_opc_select_container:focus {
  box-shadow: none;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_shipping_column .iwd_opc_alternative_column .iwd_opc_column_content #iwd_opc_shipping_method .fieldset .form #checkout-shipping-method-load .field .control .scroll-wrapper {
  border-radius: 0;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_shipping_column .iwd_opc_alternative_column .iwd_opc_column_content #iwd_opc_shipping_method .fieldset .form #checkout-shipping-method-load .field .control .scroll-wrapper .iwd_opc_select_container {
  border-radius: 0;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_shipping_column .iwd_opc_alternative_column .iwd_opc_column_content #iwd_opc_shipping_method .fieldset .form #checkout-shipping-method-load .field .control .scroll-wrapper .iwd_opc_select_container:focus {
  box-shadow: none;
}
@media screen and (min-width: 979px) {
  .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_payment_column {
    grid-area: 2/2/3/3;
  }
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_payment_column #conektaIframeContainer {
  width: 100% !important;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_payment_column .iwd_opc_alternative_column .iwd_opc_column_content #co-payment-form .fieldset #checkout-payment-method-load .items .field .control .iwd_opc_select_container {
  border-radius: 0;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_payment_column .iwd_opc_alternative_column .iwd_opc_column_content #co-payment-form .fieldset #checkout-payment-method-load .items .field .control .iwd_opc_select_container .iwd_opc_select_option {
  border-bottom: 1px solid #4a4a4a;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_payment_column .iwd_opc_alternative_column .iwd_opc_column_content #co-payment-form .fieldset #checkout-payment-method-load .items .field .control .iwd_opc_select_container:focus {
  box-shadow: none;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_payment_column .iwd_opc_alternative_column .iwd_opc_column_content #co-payment-form .fieldset #checkout-payment-method-load .items .field .control .scroll-wrapper {
  border-radius: 0;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_payment_column .iwd_opc_alternative_column .iwd_opc_column_content #co-payment-form .fieldset #checkout-payment-method-load .items .field .control .scroll-wrapper .iwd_opc_select_container {
  border-radius: 0;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_payment_column .iwd_opc_alternative_column .iwd_opc_column_content #co-payment-form .fieldset #checkout-payment-method-load .items .field .control .scroll-wrapper .iwd_opc_select_container:focus {
  box-shadow: none;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_payment_column .iwd_opc_alternative_column .iwd_opc_column_content #co-payment-form .fieldset .factura-container {
  margin-bottom: 15px;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_payment_column .iwd_opc_alternative_column .iwd_opc_column_content #co-payment-form .fieldset .factura-container .fieldset {
  position: relative;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_payment_column .iwd_opc_alternative_column .iwd_opc_column_content #co-payment-form .fieldset .factura-container .fieldset label span {
  padding-left: 26px;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_payment_column .iwd_opc_alternative_column .iwd_opc_column_content #co-payment-form .fieldset .factura-container .fieldset input[type=checkbox] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_payment_column .iwd_opc_alternative_column .iwd_opc_column_content #co-payment-form .fieldset .factura-container .fieldset input[type=checkbox] ~ label {
  cursor: pointer;
  font-size: 14px;
  line-height: 19px;
  position: relative;
  display: block;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_payment_column .iwd_opc_alternative_column .iwd_opc_column_content #co-payment-form .fieldset .factura-container .fieldset input[type=checkbox] ~ label:before {
  width: 16px;
  height: 16px;
  border: 2px solid #4a4a4a;
  border-radius: 0;
  position: absolute;
  content: "";
  left: 0;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_payment_column .iwd_opc_alternative_column .iwd_opc_column_content #co-payment-form .fieldset .factura-container .fieldset input[type=checkbox]:checked ~ label:before {
  background: url(../images/checkout/checked.svg);
  content: "";
  background-repeat: no-repeat;
  background-position: center;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_payment_column .iwd_opc_alternative_column .iwd_opc_column_content #co-payment-form .fieldset .factura-container .fieldset .requires-invoice-msg {
  margin: 15px 0;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_payment_column .iwd_opc_alternative_column .iwd_opc_column_content #co-payment-form .fieldset .factura-container .fieldset .invoice-details .required-entry {
  height: 45px;
  font-size: 16px;
  border: 1px solid #4a4a4a;
  margin-bottom: 20px;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_payment_column .iwd_opc_alternative_column .iwd_opc_column_content #co-payment-form .fieldset .factura-container .fieldset .invoice-details .required-entry::placeholder {
  color: #4a4a4a;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_payment_column .iwd_opc_alternative_column .iwd_opc_column_content #co-payment-form .fieldset .factura-container .fieldset .invoice-details .control {
  position: relative;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_payment_column .iwd_opc_alternative_column .iwd_opc_column_content #co-payment-form .fieldset .factura-container .fieldset .invoice-details .control #use_of_cfdi {
  height: 45px;
  font-size: 16px;
  border: 1px solid #4a4a4a;
  color: #4a4a4a;
  appearance: none;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_payment_column .iwd_opc_alternative_column .iwd_opc_column_content #co-payment-form .fieldset .factura-container .fieldset .invoice-details .control:after {
  font-family: FontAwesome;
  content: "\f107";
  position: absolute;
  color: #343434;
  font-size: 14px;
  right: 16px;
  line-height: 1;
  top: calc(50% - 7px);
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_column {
  width: 100%;
  margin: 0 0 20px;
}
@media screen and (min-width: 768px) {
  .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_column {
    margin: 0 auto 20px;
    max-width: 70% !important;
  }
}
@media screen and (min-width: 1199px) {
  .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_column {
    margin: 0;
    max-width: 410px !important;
    padding: 0;
  }
}
@media screen and (min-width: 1366px) {
  .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_column {
    max-width: 442px !important;
  }
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_column .iwd_opc_alternative_column .iwd_opc_column_content .fieldset .field .control .scroll-wrapper {
  border-radius: 0;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_column .iwd_opc_alternative_column .iwd_opc_column_content .fieldset .field .control .scroll-wrapper .iwd_opc_select_container {
  border-radius: 0;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_column .iwd_opc_alternative_column .iwd_opc_column_content .fieldset .field .control .scroll-wrapper .iwd_opc_select_container:focus {
  box-shadow: none;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .steps-container .iwd_opc_column .iwd_opc_alternative_column .iwd_opc_column_content #opc-new-shipping-address .form .fieldset .field .control .select {
  border: 1px solid #4a4a4a;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary {
  width: 100%;
  height: fit-content;
}
@media screen and (min-width: 1199px) {
  .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary {
    padding: 0 20px;
    max-width: 442px;
  }
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary .summary-container {
  padding: 20px;
  border: 1px solid #4a4a4a;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary .summary-container .scroll-wrapper #iwd_opc_review_items .iwd_opc_review_item {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 2px solid #93002a;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary .summary-container .scroll-wrapper #iwd_opc_review_items .iwd_opc_review_item div:nth-child(2) {
  width: 100%;
  padding: 0 10px;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary .summary-container .scroll-wrapper #iwd_opc_review_items .iwd_opc_review_item .iwd_opc_review_item_cell .product-image-container .product-image-wrapper {
  height: initial;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary .summary-container .scroll-wrapper #iwd_opc_review_items .iwd_opc_review_item .iwd_opc_review_item_cell .iwd_opc_review_item_name {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  max-width: 200px;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary .summary-container .scroll-wrapper #iwd_opc_review_items .iwd_opc_review_item .iwd_opc_review_item_cell .iwd_opc_review_item_options {
  padding: 0;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary .summary-container .scroll-wrapper #iwd_opc_review_items .iwd_opc_review_item .iwd_opc_review_item_cell .iwd_opc_review_item_options .iwd_opc_review_item_option .iwd_opc_review_item_option_label {
  color: #000000;
  font-weight: bold;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary .summary-container .scroll-wrapper #iwd_opc_review_items .iwd_opc_review_item .iwd_opc_review_item_cell .iwd_opc_review_item_options .iwd_opc_review_item_option .iwd_opc_review_item_option_value {
  color: #93002a;
  font-weight: bold;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary .summary-container .scroll-wrapper #iwd_opc_review_items .iwd_opc_review_item .iwd_opc_review_item_cell .iwd_opc_review_item_subtotal.old-price {
  color: #000000;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary .summary-container .scroll-wrapper #iwd_opc_review_items .iwd_opc_review_item .iwd_opc_review_item_cell .iwd_opc_review_item_subtotal {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 20px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #93002a;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary .summary-container .scroll-wrapper #iwd_opc_review_items .iwd_opc_review_item:last-child {
  margin-bottom: 12px;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary .summary-container .separator {
  width: 100%;
  height: 2px;
  background: #93002a;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary .summary-container .iwd_opc_review_total {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-left: auto;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary .summary-container .iwd_opc_review_total .iwd_opc_review_total_cell {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
  width: inherit;
  display: flex;
  align-items: center;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary .summary-container .iwd_opc_review_total .iwd_opc_review_total_cell:last-child {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary .summary-container .iwd_opc_review_total.iwd_opc_grand_total {
  padding-top: 12px;
  position: relative;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary .summary-container .iwd_opc_review_total.iwd_opc_grand_total .iwd_opc_review_total_cell:last-child {
  font-size: 24px;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary .summary-container .iwd_opc_review_total.shipping {
  padding-bottom: 12px;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary .summary-container .totals.store-credit {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-left: auto;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary .summary-container .totals.store-credit .mark span,
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary .summary-container .totals.store-credit .amount span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  display: flex;
  align-items: center;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .sidebar-summary .summary-container .totals.store-credit .amount span {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .secure-purchase {
  max-width: 315px;
  margin: 0 auto;
  justify-content: space-between;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .secure-purchase img {
  margin-right: 20px;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .secure-purchase .red-helper {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: justify;
  color: #93002a;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .secure-purchase .red-helper span:first-child {
  font-size: 22px;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_alternative_wrapper .onepage-steps-container .secure-purchase .red-helper span:last-child {
  font-size: 18px;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column_name {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_small_button_container #iwd_opc_login_button {
  text-transform: uppercase;
  float: none;
  height: 40px;
  border: solid 1px #93002a;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #93002a;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 0;
  transition: 0.5s;
  margin-bottom: 20px;
  width: 100%;
  background: #000000;
  color: #fff;
  border: 1px solid #000000;
}
@media screen and (min-width: 979px) {
  .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_small_button_container #iwd_opc_login_button {
    height: 45px;
    font-size: 22px;
  }
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_small_button_container #iwd_opc_login_button:hover {
  background: #93002a;
  color: #fff;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column {
  display: flex;
  float: none;
  padding: 0 24px;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column {
  max-width: 100%;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .payments .iwd_opc_button, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .payments .iwd_opc_button {
  border: none;
  display: flex;
  padding: 10px 0;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .payments .iwd_opc_button.active, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .payments .iwd_opc_button.active {
  border: none;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .payments .iwd_opc_button.with_angle:after, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .payments .iwd_opc_button.with_angle:after {
  content: url(../images/icons/icon-plus.svg) !important;
  right: 0;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .payments .fieldset #iwd_opc_discount, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .payments .fieldset #iwd_opc_discount {
  padding: 0 20px;
  margin-bottom: 20px;
  border: solid 1px #4a4a4a;
  background: #fff;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .payments .fieldset #iwd_opc_discount .iwd_opc_universal_wrapper, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .payments .fieldset #iwd_opc_discount .iwd_opc_universal_wrapper {
  margin-bottom: 0;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .payments .fieldset #iwd_opc_discount .iwd_opc_button, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .payments .fieldset #iwd_opc_discount .iwd_opc_button {
  display: flex;
  align-items: center;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .payments .fieldset #iwd_opc_discount .form-discount .iwd_opc_small_button_container, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .payments .fieldset #iwd_opc_discount .form-discount .iwd_opc_small_button_container {
  display: flex;
  flex-direction: column;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .payments .fieldset #iwd_opc_discount .form-discount .iwd_opc_small_button_container .iwd_opc_small_button, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .payments .fieldset #iwd_opc_discount .form-discount .iwd_opc_small_button_container .iwd_opc_small_button {
  text-transform: uppercase;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .payments .fieldset #iwd_opc_discount .form-discount .iwd_opc_small_button_container .iwd_opc_small_button.active, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .payments .fieldset #iwd_opc_discount .form-discount .iwd_opc_small_button_container .iwd_opc_small_button.active {
  height: 40px;
  border: solid 1px #93002a;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #93002a;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 0;
  transition: 0.5s;
  margin-bottom: 20px;
  width: 100%;
  background: #000000;
  color: #fff;
  border: 1px solid #000000;
}
@media screen and (min-width: 979px) {
  .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .payments .fieldset #iwd_opc_discount .form-discount .iwd_opc_small_button_container .iwd_opc_small_button.active, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .payments .fieldset #iwd_opc_discount .form-discount .iwd_opc_small_button_container .iwd_opc_small_button.active {
    height: 45px;
    font-size: 22px;
  }
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .payments .fieldset #iwd_opc_discount .form-discount .iwd_opc_small_button_container .iwd_opc_small_button.active:hover, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .payments .fieldset #iwd_opc_discount .form-discount .iwd_opc_small_button_container .iwd_opc_small_button.active:hover {
  background: #93002a;
  color: #fff;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .payments .fieldset #iwd_opc_discount.iwd_opc_collapsible_opened .with_angle:after, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .payments .fieldset #iwd_opc_discount.iwd_opc_collapsible_opened .with_angle:after {
  content: url(../images/icons/icon-minus.svg) !important;
  right: 0;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .payments .fieldset .payment-option.store-credit, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .payments .fieldset .payment-option.store-credit {
  min-height: 45px;
  border: 1px solid #4a4a4a;
  margin-bottom: 20px;
  padding: 10px 20px;
  position: relative;
  background: white;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .payments .fieldset .payment-option.store-credit .payment-option-title, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .payments .fieldset .payment-option.store-credit .payment-option-title {
  margin: 0;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .payments .fieldset .payment-option.store-credit .payment-option-title .action span, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .payments .fieldset .payment-option.store-credit .payment-option-title .action span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #4a4a4a;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .payments .fieldset .payment-option.store-credit .payment-option-title:before, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .payments .fieldset .payment-option.store-credit .payment-option-title:before {
  content: url(../images/icons/icon-plus.svg);
  right: 0;
  position: absolute;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .payments .fieldset .payment-option.store-credit .payment-option-content .form .actions-toolbar, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .payments .fieldset .payment-option.store-credit .payment-option-content .form .actions-toolbar {
  margin: 15px 0 0 0;
  display: flex;
  flex-direction: column;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .payments .fieldset .payment-option.store-credit .payment-option-content .form .actions-toolbar .label, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .payments .fieldset .payment-option.store-credit .payment-option-content .form .actions-toolbar .label {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  margin-bottom: 10px;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .payments .fieldset .payment-option.store-credit .payment-option-content .form .actions-toolbar .primary .action, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .payments .fieldset .payment-option.store-credit .payment-option-content .form .actions-toolbar .primary .action {
  margin: 0;
  height: 40px;
  border: solid 1px #93002a;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #93002a;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 0;
  transition: 0.5s;
}
@media screen and (min-width: 979px) {
  .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .payments .fieldset .payment-option.store-credit .payment-option-content .form .actions-toolbar .primary .action, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .payments .fieldset .payment-option.store-credit .payment-option-content .form .actions-toolbar .primary .action {
    height: 45px;
    font-size: 22px;
  }
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .payments .fieldset .payment-option.store-credit .payment-option-content .form .actions-toolbar .primary .action:hover, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .payments .fieldset .payment-option.store-credit .payment-option-content .form .actions-toolbar .primary .action:hover {
  background: #93002a;
  color: #fff;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .payments .fieldset .payment-option.store-credit._active .payment-option-title::before, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .payments .fieldset .payment-option.store-credit._active .payment-option-title::before {
  content: "";
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .payments .fieldset .payment-option.store-credit._active .payment-option-title:after, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .payments .fieldset .payment-option.store-credit._active .payment-option-title:after {
  content: url(../images/icons/icon-minus.svg);
  right: 0;
  position: absolute;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .fieldset .field.choice label, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .fieldset .field.choice label {
  width: 100%;
  display: flex;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .iwd_opc_universal_wrapper .iwd_opc_place_order_button, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .iwd_opc_universal_wrapper .iwd_opc_place_order_button {
  text-transform: uppercase;
  margin-bottom: 20px;
  width: 100%;
  background: #93002a;
  color: #fff;
  border: 1px solid #93002a;
  font-size: 20px;
  height: 45px;
  border-radius: 0;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column .iwd_opc_universal_wrapper .iwd_opc_place_order_button span, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column .iwd_opc_universal_wrapper .iwd_opc_place_order_button span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_address_column .iwd_opc_alternative_column, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column {
  padding: 20px;
  max-width: 100%;
}
.onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_address_column .iwd_opc_alternative_column:focus-within, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_shipping_column .iwd_opc_alternative_column:focus-within, .onepage-index-index .page-main .iwd_main_wrapper .iwd_opc_column.iwd_opc_payment_column .iwd_opc_alternative_column:focus-within {
  background: #f2f2f2;
}
.onepage-index-index .page-main .iwd_main_wrapper .fieldset .field.choice input[type=checkbox] ~ label:before {
  width: 16px;
  height: 16px;
  border: 2px solid #4a4a4a;
  border-radius: 0;
}
.onepage-index-index .page-main .iwd_main_wrapper .fieldset .field.choice input[type=checkbox]:checked ~ label:before {
  background: url(../images/checkout/checked.svg);
  content: "";
  background-repeat: no-repeat;
  background-position: center;
}
.onepage-index-index .fieldset > .field:not(.choice) > .control {
  width: 100%;
}
.onepage-index-index .field.street {
  margin: 0 !important;
}
.onepage-index-index .iwd_main_wrapper .iwd_opc_field.iwd_opc_input,
.onepage-index-index .iwd_main_wrapper .field.iwd_opc_input,
.onepage-index-index .iwd_main_wrapper .iwd_opc_field .input-text,
.onepage-index-index .iwd_main_wrapper .field .input-text,
.onepage-index-index .iwd_main_wrapper .iwd_opc_field textarea,
.onepage-index-index .iwd_main_wrapper .field textarea,
.onepage-index-index .iwd_main_wrapper .iwd_opc_field .iwd_opc_hosted_field,
.onepage-index-index .iwd_main_wrapper .field .iwd_opc_hosted_field,
.onepage-index-index .iwd_main_wrapper .iwd_opc_field.iwd_opc_textarea,
.onepage-index-index .iwd_main_wrapper .field.iwd_opc_textarea,
.onepage-index-index .iwd_main_wrapper .field .iwd_opc_select_container .iwd_opc_select_option,
.onepage-index-index .iwd_main_wrapper .field .iwd_opc_select_container.selected .iwd_opc_select_option.selected,
.onepage-index-index .selectize-control.single .selectize-input,
.onepage-index-index .selectize-dropdown.single {
  background-color: #fff !important;
  border: solid 1px #4a4a4a;
  border-radius: 0;
  min-height: 45px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}
.onepage-index-index .iwd_main_wrapper .iwd_opc_field.iwd_opc_input ::placeholder,
.onepage-index-index .iwd_main_wrapper .field.iwd_opc_input ::placeholder,
.onepage-index-index .iwd_main_wrapper .iwd_opc_field .input-text ::placeholder,
.onepage-index-index .iwd_main_wrapper .field .input-text ::placeholder,
.onepage-index-index .iwd_main_wrapper .iwd_opc_field textarea ::placeholder,
.onepage-index-index .iwd_main_wrapper .field textarea ::placeholder,
.onepage-index-index .iwd_main_wrapper .iwd_opc_field .iwd_opc_hosted_field ::placeholder,
.onepage-index-index .iwd_main_wrapper .field .iwd_opc_hosted_field ::placeholder,
.onepage-index-index .iwd_main_wrapper .iwd_opc_field.iwd_opc_textarea ::placeholder,
.onepage-index-index .iwd_main_wrapper .field.iwd_opc_textarea ::placeholder,
.onepage-index-index .iwd_main_wrapper .field .iwd_opc_select_container .iwd_opc_select_option ::placeholder,
.onepage-index-index .iwd_main_wrapper .field .iwd_opc_select_container.selected .iwd_opc_select_option.selected ::placeholder,
.onepage-index-index .selectize-control.single .selectize-input ::placeholder,
.onepage-index-index .selectize-dropdown.single ::placeholder {
  color: #4a4a4a;
}
.onepage-index-index .iwd_main_wrapper .iwd_opc_field.iwd_opc_input:focus,
.onepage-index-index .iwd_main_wrapper .field.iwd_opc_input:focus,
.onepage-index-index .iwd_main_wrapper .iwd_opc_field .input-text:focus,
.onepage-index-index .iwd_main_wrapper .field .input-text:focus,
.onepage-index-index .iwd_main_wrapper .iwd_opc_field textarea:focus,
.onepage-index-index .iwd_main_wrapper .field textarea:focus,
.onepage-index-index .iwd_main_wrapper .iwd_opc_field .iwd_opc_hosted_field:focus,
.onepage-index-index .iwd_main_wrapper .field .iwd_opc_hosted_field:focus,
.onepage-index-index .iwd_main_wrapper .iwd_opc_field.iwd_opc_textarea:focus,
.onepage-index-index .iwd_main_wrapper .field.iwd_opc_textarea:focus,
.onepage-index-index .iwd_main_wrapper .field .iwd_opc_select_container .iwd_opc_select_option:focus,
.onepage-index-index .iwd_main_wrapper .field .iwd_opc_select_container.selected .iwd_opc_select_option.selected:focus,
.onepage-index-index .selectize-control.single .selectize-input:focus,
.onepage-index-index .selectize-dropdown.single:focus {
  box-shadow: none;
  outline: none;
}
.onepage-index-index .selectize-dropdown.single {
  border: 0;
}
.onepage-index-index .selectize-dropdown.single .selectize-dropdown-content {
  border: solid 1px #4a4a4a;
}
.onepage-index-index .iwd_main_wrapper .field .input-text,
.onepage-index-index .iwd_main_wrapper .field .select {
  height: 45px;
  border-radius: 0;
  border: solid 1px #4a4a4a;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}
.onepage-index-index .iwd_main_wrapper .field .input-text ::placeholder,
.onepage-index-index .iwd_main_wrapper .field .select ::placeholder {
  color: #4a4a4a;
}
.onepage-index-index .iwd_main_wrapper .field .input-text:focus,
.onepage-index-index .iwd_main_wrapper .field .select:focus {
  box-shadow: none;
  outline: none;
}
.onepage-index-index .selectize-dropdown-content {
  background: #fff !important;
}

#shipping-new-address-form div:nth-child(8) .control:after,
#shipping-new-address-form div:nth-child(10) .control:after,
#shipping-new-address-form div:nth-child(12) .control:after {
  font-family: "FontAwesome";
  content: "\f107";
  position: absolute;
  color: #343434;
  font-size: 14px;
  right: 16px;
  line-height: 1;
  top: calc(50% - 7px);
}
#shipping-new-address-form div:nth-child(8) .control .select,
#shipping-new-address-form div:nth-child(10) .control .select,
#shipping-new-address-form div:nth-child(12) .control .select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

#checkout-payment-method-load .items .payment-method .payment-method-content .payment-method-billing-address .fieldset .field-select-billing .control:after {
  font-family: "FontAwesome";
  content: "\f107";
  position: absolute;
  color: #343434;
  font-size: 14px;
  right: 16px;
  line-height: 1;
  top: calc(50% - 7px);
}
#checkout-payment-method-load .items .payment-method .payment-method-content .payment-method-billing-address .fieldset .field-select-billing .control .select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #4a4a4a;
}
#checkout-payment-method-load .items .payment-method .payment-method-content .payment-method-billing-address .fieldset .form #billing-new-address-form div:nth-child(8) .control:after,
#checkout-payment-method-load .items .payment-method .payment-method-content .payment-method-billing-address .fieldset .form #billing-new-address-form div:nth-child(11) .control:after {
  font-family: "FontAwesome";
  content: "\f107";
  position: absolute;
  color: #343434;
  font-size: 14px;
  right: 16px;
  line-height: 1;
  top: calc(50% - 7px);
}
#checkout-payment-method-load .items .payment-method .payment-method-content .payment-method-billing-address .fieldset .form #billing-new-address-form div:nth-child(8) .control .select,
#checkout-payment-method-load .items .payment-method .payment-method-content .payment-method-billing-address .fieldset .form #billing-new-address-form div:nth-child(11) .control .select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #4a4a4a;
}
#checkout-payment-method-load .items .payment-method .payment-method-content #openpay_cards-form .fieldset .field label.label {
  width: auto !important;
  text-align: left !important;
  display: block !important;
}
#checkout-payment-method-load .items .payment-method .payment-method-content #openpay_cards-form .fieldset .field.number {
  width: 100%;
}
#checkout-payment-method-load .items .payment-method .payment-method-content #openpay_cards-form .fieldset .field.number .control .payment-method__select-card {
  width: 100% !important;
  max-width: 100%;
}
#checkout-payment-method-load .items .payment-method .payment-method-content #openpay_cards-form .fieldset .field.cvv {
  float: none;
  padding: 0;
  width: 100%;
}
#checkout-payment-method-load .items .payment-method-conekta-payments .payment-method-content .conekta-message {
  width: 100%;
}
#checkout-payment-method-load .items .payment-method-conekta-payments .payment-method-content .conekta-message .conekta-message-header .conekta-payment-logo {
  display: none;
}

#conekta_cc-form #payment_form_conekta_cc .field.number .control {
  list-style: none;
  display: flex;
  justify-content: space-between;
}
#conekta_cc-form #payment_form_conekta_cc .field.number .control:after {
  font-family: "FontAwesome";
  content: "\f107";
  position: absolute;
  color: #343434;
  font-size: 14px;
  right: 16px;
  line-height: 1;
  top: calc(50% - 7px);
}
#conekta_cc-form #payment_form_conekta_cc .field.number .control .select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
#conekta_cc-form #payment_form_conekta_cc .field.date .control .fields .field .control:after {
  font-family: "FontAwesome";
  content: "\f107";
  position: absolute;
  color: #343434;
  font-size: 14px;
  right: 16px;
  line-height: 1;
  top: calc(50% - 7px);
}
#conekta_cc-form #payment_form_conekta_cc .field.date .control .fields .field .control .select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
#conekta_cc-form #payment_form_conekta_cc .field.type .control {
  display: flex;
  justify-content: space-between;
}
#conekta_cc-form #payment_form_conekta_cc .field.type .control .credit-card-types {
  display: flex;
  list-style: none;
  margin: 0;
}
#conekta_cc-form #payment_form_conekta_cc .field.type .control .credit-card-types .icons:nth-child(1) {
  order: 2;
}
#conekta_cc-form #payment_form_conekta_cc .field.type .control .title-container p {
  color: #000000;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  font-weight: bold;
}

.field.date.required .control .fields {
  display: flex;
  justify-content: space-between;
}
.field.date.required .control .fields .field.month {
  margin-right: 10px;
}

#conekta_cc_cc_type_exp_div .control:after {
  display: none;
}
#conekta_cc_cc_type_exp_div .control .fields {
  width: 100%;
}

#conekta_cc_cc_type_cvv_div .control::after {
  display: none;
}
#conekta_cc_cc_type_cvv_div .control .input-text.cvv {
  width: 15%;
}

#payment_form_bottomconekta_cc .field .control:after {
  font-family: "FontAwesome";
  content: "\f107";
  position: absolute;
  color: #343434;
  font-size: 14px;
  right: 16px;
  line-height: 1;
  top: calc(50% - 7px);
}
#payment_form_bottomconekta_cc .field .control .select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.page-wrapper .page-header .miniquote-wrapper .action .counter {
  position: absolute;
  right: -12px;
  top: -8px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: #93002a;
  border: 1px solid #fff;
  padding: 0;
  margin: 0;
  line-height: 15px;
}
.page-wrapper .page-header .miniquote-wrapper .action .counter .counter-number {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #fff;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container {
  background-color: #fff;
  padding: 0;
  width: 95%;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container::-webkit-scrollbar-thumb {
  background: #9b9b9b;
  border-radius: 5px;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container::-webkit-scrollbar {
  width: 10px;
}
@media screen and (min-width: 768px) {
  .page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container {
    width: 55%;
  }
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container {
    min-width: 400px;
    max-width: 400px;
  }
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-title {
  text-align: center;
  padding: 25px 0;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-title #btn-miniquote-close {
  left: 20px;
  top: 20px;
  position: absolute;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-title #btn-miniquote-close::before {
  content: "";
  font-size: 10px;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-title .text {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 22px;
  color: #93002a;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-title button::before {
  color: #333333;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content {
  padding: 0 20px;
  text-align: center;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .minicart-items-wrapper {
  max-height: 450px;
  height: auto !important;
  border: none;
  padding: 0;
  width: 100%;
  margin: auto;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .minicart-items-wrapper #mini-quote .item {
  padding: 20px 0;
  margin: 0;
  border-bottom: 1px solid #93002a;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .minicart-items-wrapper #mini-quote .item .product {
  display: flex;
  color: #000000;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .minicart-items-wrapper #mini-quote .item .product .content-img .product-item-photo .photo {
  width: 77px !important;
  height: 77px !important;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .minicart-items-wrapper #mini-quote .item .product .product-item-details {
  padding-left: 10px;
  width: 100%;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .minicart-items-wrapper #mini-quote .item .product .product-item-details .product-item-content {
  display: flex;
  justify-content: space-between;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .minicart-items-wrapper #mini-quote .item .product .product-item-details .product-item-content .product-item-name {
  display: flex;
  text-align: left;
  margin-right: 5px;
  height: 45px;
  overflow: hidden;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .minicart-items-wrapper #mini-quote .item .product .product-item-details .product-item-content .product-item-name {
    margin-right: 25px;
  }
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .minicart-items-wrapper #mini-quote .item .product .product-item-details .product-item-content .product-item-name a {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .minicart-items-wrapper #mini-quote .item .product .product-item-details .product-item-content .actions {
  margin: 0;
  display: flex;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .minicart-items-wrapper #mini-quote .item .product .product-item-details .product-item-content .actions .primary {
  display: none;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .minicart-items-wrapper #mini-quote .item .product .product-item-details .product-item-content .actions .secondary {
  display: flex;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .minicart-items-wrapper #mini-quote .item .product .product-item-details .product-item-content .actions .secondary .action::before {
  font-size: 11px;
  content: "";
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .minicart-items-wrapper #mini-quote .item .product .product-item-details .product-item-pricing {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .minicart-items-wrapper #mini-quote .item .product .product-item-details .product-item-pricing .price-container .price-wrapper .price-excluding-tax {
  font-size: 0;
  margin: 0;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .minicart-items-wrapper #mini-quote .item .product .product-item-details .product-item-pricing .price-container .price-wrapper .price-excluding-tax .minicart-price .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 20px;
  color: #000000;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .minicart-items-wrapper #mini-quote .item .product .product-item-details .product-item-pricing .price-container .price-wrapper .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 20px;
  color: #000000;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .minicart-items-wrapper #mini-quote .item .product .product-item-details .product-item-pricing .details-qty .qty-content {
  margin-top: 0;
  padding: 0 5px;
  border: 1px solid #4a4a4a;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .minicart-items-wrapper #mini-quote .item .product .product-item-details .product-item-pricing .details-qty .update-quote-item {
  background-color: #93002a;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #fff;
  border-radius: 0;
  border: none;
  margin-top: 10px;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .minicart-items-wrapper #mini-quote .item .product .product-item-details .product-item-pricing .details-qty .item-qty {
  margin: 0;
  padding: 0;
  border: none;
  width: 35px;
  height: 30px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .content {
  margin-top: 20px;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .content #discount-coupon-form .fieldset {
  display: flex;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .content #discount-coupon-form .fieldset .field {
  width: 100%;
  margin-right: 10px;
  margin-bottom: 0;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .content #discount-coupon-form .fieldset .field .control {
  width: 100%;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .content #discount-coupon-form .fieldset .field .control .input-text {
  height: 45px;
  padding: 12px 20px;
  border: 1px solid #4a4a4a;
}
@media screen and (min-width: 979px) {
  .page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .content #discount-coupon-form .fieldset .field .control .input-text {
    padding: 12px 20px;
  }
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .content #discount-coupon-form .fieldset .field .control .input-text::placeholder {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #4a4a4a;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .content #discount-coupon-form .fieldset .actions-toolbar .primary .action {
  width: 100%;
  height: 45px;
  border-radius: 0;
  margin: 0;
  background-color: #000000;
  padding: 10px 20px;
  border: none;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .content #discount-coupon-form .fieldset .actions-toolbar .primary .action span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .subtotal {
  display: flex;
  justify-content: space-between;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .subtotal .label span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  color: #4a4a4a;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .subtotal .amount .price-wrapper .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  color: #4a4a4a;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .total {
  display: flex;
  justify-content: space-between;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .total .label span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  color: #4a4a4a;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .total .total .price-wrapper .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 24px;
  color: #4a4a4a;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .discount,
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .items-total {
  display: flex;
  justify-content: space-between;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .discount .text,
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .items-total .text {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  color: #4a4a4a;
  margin: 0;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .discount .amount,
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .items-total .amount {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  color: #4a4a4a;
  margin: 0;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content div.discount:nth-child(2) {
  justify-content: center;
  margin: 20px 0;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content div.discount:nth-child(2) .payment-option {
  width: 100%;
  cursor: pointer;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content div.discount:nth-child(2) .payment-option .payment-option-title {
  height: 45px;
  display: flex;
  align-items: center;
  background: #000000;
  justify-content: center;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content div.discount:nth-child(2) .payment-option .payment-option-title .action span {
  color: #fff;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  text-transform: uppercase;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content div.discount:nth-child(2) .payment-option .payment-option-content .form {
  display: flex;
  margin-top: 20px;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content div.discount:nth-child(2) .payment-option .payment-option-content .form .payment-option-inner {
  width: 100%;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content div.discount:nth-child(2) .payment-option .payment-option-content .form .payment-option-inner .field .label span {
  display: none;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content div.discount:nth-child(2) .payment-option .payment-option-content .form .payment-option-inner .field .control .input-text {
  height: 45px;
  border: 1px solid #4a4a4a !important;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content div.discount:nth-child(2) .payment-option .payment-option-content .form .payment-option-inner .field .control .input-text::placeholder {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #4a4a4a;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content div.discount:nth-child(2) .payment-option .payment-option-content .form .payment-option-inner .field .control .input-text:focus {
  box-shadow: none;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content div.discount:nth-child(2) .payment-option .payment-option-content .form .payment-option-inner .field .control .mage-error {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: 1px dashed red;
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  text-align: center;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content div.discount:nth-child(2) .payment-option .payment-option-content .form .actions-toolbar .primary .action {
  height: 45px;
  border-radius: 0;
  width: auto;
  margin: 0 0 0 10px;
  background-color: #000000;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content div.discount:nth-child(2) .payment-option .payment-option-content .form .actions-toolbar .primary .action span span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content div.discount:nth-child(4) .label span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #4a4a4a;
  line-height: 26px;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content div.discount:nth-child(4) .discount.price-container {
  margin: 0;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content div.discount:nth-child(4) .discount.price-container .price-wrapper .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #4a4a4a;
  line-height: 26px;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .items-total .text {
  font-size: 24px;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .items-total .amount {
  font-size: 24px;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .actions {
  margin-top: 20px;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .actions .primary .action {
  background-color: #93002a;
  width: 100%;
  border: none;
  border-radius: 0;
  height: 45px;
  font-size: 20px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .actions .secondary {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .actions .secondary .action span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #93002a;
  text-transform: uppercase;
  text-decoration: underline;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .actions .secondary .quotation {
  width: 100%;
  background-color: #93002a;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  flex: none;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .actions .secondary .quotation:hover {
  text-decoration: none;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .actions .secondary .quotation span {
  text-decoration: none;
  color: #fff;
  font-size: 20px;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .actions .secondary .quotation-resume {
  margin-top: 20px;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .help {
  padding-bottom: 50px;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .help .contact-us {
  margin: 20px 0 10px;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .help .contact-us .text {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  margin: 0;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .help .whatssap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .help .whatssap .text {
  margin: 0;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #4a4a4a;
  margin-left: 10px;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .block-empty {
  padding: 30% 0;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .block-empty .subtitle {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: #000000;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .block-empty .empty-img {
  margin: 20px 0;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .block-empty .btn {
  background-color: #93002a;
  border: none;
  border-radius: 0;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
}
.page-wrapper .page-header .miniquote-wrapper #miniquote-content-toggle .off-canvas-quote-container .block-content .block-empty .btn span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: #fff;
  padding: 10px 0;
}

.quotation-index-resume .page-wrapper .page-main .page-title-wrapper .block-title {
  text-align: center;
  margin: 20px 0;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .page-title-wrapper .block-title {
    margin: 20px 0 0;
  }
}
.quotation-index-resume .page-wrapper .page-main .page-title-wrapper .block-title .page-title {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: #000000;
  margin: 0;
}
@media screen and (min-width: 979px) {
  .quotation-index-resume .page-wrapper .page-main .page-title-wrapper .block-title .page-title {
    font-size: 30px;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column {
  width: 100%;
  padding: 0 10px;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container {
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container {
    display: flex;
    flex-direction: row-reverse;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary {
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  background-color: #f2f2f2;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary {
    width: auto;
    top: 0 !important;
  }
}
@media screen and (min-width: 979px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary {
    width: 25%;
    height: 100%;
    padding-bottom: 20px;
    margin-left: 40px;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .summary {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .summary {
    margin-top: 20px;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary #block-shipping {
  display: none;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals {
  border-top: none;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .totals .mark {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #4a4a4a;
  line-height: 1.44;
  padding: 0;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .totals .amount {
  padding: 0;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .totals .amount .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #4a4a4a;
  line-height: 1.44;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .grand {
  border-top: 1px solid #93002a;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .grand .mark {
  font-size: 24px;
  padding-top: 20px;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .grand .mark {
    font-size: 18px;
  }
}
@media screen and (min-width: 979px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .grand .mark {
    font-size: 24px;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .grand .amount {
  padding-top: 20px;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .grand .amount {
    padding-bottom: 0;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .grand .amount .price {
  font-size: 24px;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .grand .amount .price {
    font-size: 20px;
  }
}
@media screen and (min-width: 979px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .cart-totals .table-wrapper .data tbody .grand .amount .price {
    font-size: 24px;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .checkout {
  margin: 20px 0;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .checkout .item .quotation:hover {
  text-decoration: none;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .checkout .item .quotation .action {
  height: 45px;
  background-color: #93002a;
  border-radius: 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .checkout .item .quotation .action {
    height: 100%;
    line-height: 16px;
  }
}
@media screen and (min-width: 979px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .checkout .item .quotation .action {
    height: 45px;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .checkout .item .quotation .action span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .checkout .item .quotation .action span {
    font-size: 16px;
  }
}
@media screen and (min-width: 979px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .checkout .item .quotation .action span {
    font-size: 20px;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .quotation-comments {
  /* checkbox aspect */
  /* checked mark aspect */
  /* checked mark aspect changes */
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .quotation-comments label {
  display: flex;
  margin-bottom: 30px;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .quotation-comments label strong {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  margin-left: 20px;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .quotation-comments [type=checkbox]:not(:checked),
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .quotation-comments [type=checkbox]:checked {
  left: -9999px;
  position: absolute;
  top: 20px;
  visibility: hidden;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .quotation-comments [type=checkbox]:not(:checked) + label,
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .quotation-comments [type=checkbox]:checked + label {
  position: relative;
  padding-left: 0;
  cursor: pointer;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .quotation-comments [type=checkbox]:not(:checked) + label:before,
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .quotation-comments [type=checkbox]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  width: 12px;
  height: 12px;
  border: 2px solid #4a4a4a;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 0;
}
@media screen and (min-width: 979px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .quotation-comments [type=checkbox]:not(:checked) + label:before,
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .quotation-comments [type=checkbox]:checked + label:before {
    top: 5px;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .quotation-comments [type=checkbox]:checked + label:before {
  background: #4a4a4a;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .quotation-comments [type=checkbox]:not(:checked) + label:after,
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .quotation-comments [type=checkbox]:checked + label:after {
  content: "";
  left: 3px;
  top: 7px;
  border: 2px solid #fff;
  background: transparent;
  opacity: 0;
  transform: rotate(-45deg);
  position: absolute;
  width: 9px;
  height: 5px;
  font-size: 1.4em;
  line-height: 0.8;
  transition: all 0.2s;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .quotation-comments [type=checkbox]:not(:checked) + label:after,
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .quotation-comments [type=checkbox]:checked + label:after {
    top: 10px;
    height: 3px;
  }
}
@media screen and (min-width: 979px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .quotation-comments [type=checkbox]:not(:checked) + label:after,
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .quotation-comments [type=checkbox]:checked + label:after {
    border: 2px solid #fff;
    top: 10px;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .quotation-comments [type=checkbox]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
  transform: rotate(-45deg);
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .quotation-comments [type=checkbox]:checked + label:after {
  opacity: 1;
  border-top: none;
  border-right: none;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .quotation-comments #quotation-comments {
  border: 1px solid #4a4a4a;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .cart-summary .quotation-comments #quotation-comments::placeholder {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #4a4a4a;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form {
    width: auto;
    margin-right: 5px;
  }
}
@media screen and (min-width: 979px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form {
    width: 72%;
    margin-right: 0;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items {
    display: table;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items thead tr .item {
  display: none;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items thead tr .item {
    display: table-cell;
  }
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items thead tr .col {
    font-size: 18px;
    font-family: "Playfair Display";
    font-weight: 800;
    font-style: normal;
    color: #000000;
    text-align: center;
  }
}
@media screen and (min-width: 979px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items thead tr .col {
    font-size: 24px;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart {
  border-top: 1px solid #4a4a4a;
  border-left: 1px solid #4a4a4a;
  border-right: 1px solid #4a4a4a;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart {
    border-top: 1px solid #93002a;
    border-bottom: 1px solid #93002a;
    border-left: none;
    border-right: none;
  }
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info {
    padding: 20px;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .price .price-excluding-tax {
  font-size: 0;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .price .price-excluding-tax .cart-price p {
  margin: 0 0 5px;
  color: #000000 !important;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .price .price-excluding-tax .cart-price p .price {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item {
  display: grid;
  grid-template-columns: 40% 58%;
  padding: 10px;
  align-items: flex-start;
  grid-column-gap: 10px;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item {
    margin: 20px;
    padding: 0;
    display: flex;
    width: 100%;
  }
}
@media screen and (min-width: 979px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item {
    align-items: center;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-photo {
  position: relative;
  top: 0;
  max-width: 100%;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-photo {
    width: 120px;
    max-width: 120px;
    max-height: 120px;
  }
}
@media screen and (min-width: 979px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-photo {
    width: 174px;
    max-width: 174px;
    max-height: 174px;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-photo .product-image-container {
  width: 100% !important;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-photo .product-image-container {
    width: 120px !important;
  }
}
@media screen and (min-width: 979px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-photo .product-image-container {
    width: 174px !important;
  }
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-photo .product-image-container .product-image-wrapper .product-image-photo {
    width: 120px;
    height: 120px;
    object-fit: cover;
  }
}
@media screen and (min-width: 979px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-photo .product-image-container .product-image-wrapper .product-image-photo {
    width: 174px;
    height: 174px;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-details {
  line-height: 1.6rem;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-details {
    display: flex;
    justify-content: flex-end;
    position: relative;
    left: -10px;
    flex-direction: column;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item .product-item-details .product-item-name a {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #000000;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .price,
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty,
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal {
  padding-top: 0;
  padding-bottom: 0;
  width: 26%;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .price,
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty,
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal {
    position: relative;
    vertical-align: bottom;
    padding-bottom: 20px;
    text-align: center;
  }
}
@media screen and (min-width: 979px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .price,
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty,
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal {
    width: 16%;
    vertical-align: middle;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .price::before,
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty::before,
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal::before {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  text-transform: uppercase;
  padding-bottom: 20px;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .price .price-excluding-tax .cart-price .price,
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal .price-excluding-tax .cart-price .price {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #93002a;
}
@media screen and (min-width: 979px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .price .price-excluding-tax .cart-price .price,
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal .price-excluding-tax .cart-price .price {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 979px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty {
    width: 12%;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty .field {
  border: 1px solid #4a4a4a;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty .field {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 979px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty .field {
    margin: auto;
    width: 70%;
  }
}
@media screen and (min-width: 1920px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty .field {
    width: 50%;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty .field .control {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty .field .control {
    padding-bottom: 0;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty .field .control button {
  border: none;
  background-color: transparent;
  padding: 0;
  display: flex;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .qty .field .control .input-text {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  border: none;
  padding: 0;
  width: 26%;
  margin: 0 2px;
  min-width: 25px;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal .flex-actions {
  display: none;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal .flex-actions {
    display: block;
  }
}
@media screen and (min-width: 979px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal .flex-actions {
    display: none;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal .flex-actions .actions-toolbar {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin-left: 0;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal .flex-actions .actions-toolbar .action {
  border: none;
  background: transparent;
  padding-top: 0;
  padding: 0;
  margin: 0;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal .price-excluding-tax .cart-price .price {
  color: #000000;
}
@media screen and (min-width: 979px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .subtotal .price-excluding-tax .cart-price .price {
    padding-bottom: 0;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item-actions {
  box-sizing: border-box;
  display: block;
  float: left;
  text-align: center;
  width: 21%;
  padding-top: 0;
  padding-bottom: 0;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item-actions {
    display: none;
  }
}
@media screen and (min-width: 979px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item-actions {
    display: table-cell;
    float: none;
    vertical-align: middle;
    width: 12%;
    padding-bottom: 20px;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item-actions .actions-toolbar {
  display: flex;
  flex-direction: column-reverse;
}
@media screen and (min-width: 979px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item-actions .actions-toolbar {
    flex-direction: row-reverse;
    justify-content: center;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item-actions .actions-toolbar .action {
  border: none;
  background: transparent;
  padding-top: 0;
}
@media screen and (min-width: 979px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart .item-info .item-actions .actions-toolbar .action {
    margin: 0;
    padding: 0 10px;
    display: flex;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .cart .cart.items .cart:last-child {
  border-bottom: 1px solid #4a4a4a;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .main {
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .main {
    justify-content: flex-end;
  }
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .main .continue {
  display: flex;
  background: #fff;
  border: 1px solid #000000;
  border-radius: 0;
  padding: 10px 20px;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .main .continue::before {
  display: none;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .main .continue span {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  text-transform: uppercase;
  color: #000000;
}
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .main .clear,
.quotation-index-resume .page-wrapper .page-main .columns .column .cart-container .form .main .update {
  display: none;
}

.quotation-index-index .page-wrapper .page-main .columns .column div .title h1 {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: #000000;
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .quotation-index-index .page-wrapper .page-main .columns .column div .data thead {
    border-top: 1px solid #979797;
  }
}
.quotation-index-index .page-wrapper .page-main .columns .column div .data thead tr th {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  border-bottom: 1px solid #979797;
}
.quotation-index-index .page-wrapper .page-main .columns .column div .data tbody tr {
  border-bottom: 1px solid #979797;
}
.quotation-index-index .page-wrapper .page-main .columns .column div .data tbody tr td {
  display: block;
  color: #000000;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
}
@media screen and (min-width: 768px) {
  .quotation-index-index .page-wrapper .page-main .columns .column div .data tbody tr td {
    display: table-cell;
  }
}
.quotation-index-index .page-wrapper .page-main .columns .column div .data tbody tr td::before {
  padding-right: 10px;
  content: attr(data-th) ": ";
  display: inline-block;
  color: #000000;
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
}
@media screen and (min-width: 768px) {
  .quotation-index-index .page-wrapper .page-main .columns .column div .data tbody tr td::before {
    display: none;
  }
}
.quotation-index-index .page-wrapper .page-main .columns .column div .data tbody tr .actions a {
  color: #93002a;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  text-decoration: underline;
}
.quotation-index-index .page-wrapper .page-main .columns .column div .data tbody tr .actions .action.delete::before, .quotation-index-index .page-wrapper .page-main .columns .column div .data tbody tr .actions .action.delete::after {
  content: "|";
  color: #000000;
  margin: 0 5px;
}
@media screen and (max-width: 599px) {
  .quotation-index-index .page-wrapper .page-main .columns .column div .table:not(.cart):not(.totals):not(.table-comparison) > thead > tr > th {
    display: none;
  }
}
@media screen and (max-width: 599px) {
  .quotation-index-index .page-wrapper .page-main .columns .column div .table:not(.cart):not(.totals):not(.table-comparison) > tbody {
    display: block;
  }
}
@media screen and (max-width: 599px) {
  .quotation-index-index .page-wrapper .page-main .columns .column div .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr {
    display: block;
  }
}

.quotation-index-detail .page-wrapper .page-main {
  padding: 0 15px;
}
.quotation-index-detail .page-wrapper .page-main .page.messages .messages .message {
  padding: 20px;
  display: flex;
  justify-content: center;
  border: solid 1px #f5a623;
  background-color: rgba(245, 166, 35, 0.2);
}
.quotation-index-detail .page-wrapper .page-main .page.messages .messages .message div {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  display: flex;
  align-items: center;
}
.quotation-index-detail .page-wrapper .page-main .page.messages .messages .message div::before {
  position: relative;
  text-align: center;
  width: 70px;
  height: 35px;
  margin-right: 0;
  background-image: url("../images/customer/dashboard/alert.svg");
  background-size: 38px 35px;
  content: "";
  background-repeat: no-repeat;
  top: 5px;
}
@media screen and (min-width: 768px) {
  .quotation-index-detail .page-wrapper .page-main .page.messages .messages .message div::before {
    width: 38px;
    margin-right: 30px;
  }
}
@media screen and (min-width: 768px) {
  .quotation-index-detail .page-wrapper .page-main .columns {
    padding-top: 25px;
  }
}
.quotation-index-detail .page-wrapper .page-main .columns .column {
  display: block;
  margin: auto;
}
.quotation-index-detail .page-wrapper .page-main .columns .column .page-title-wrapper {
  display: block;
}
.quotation-index-detail .page-wrapper .page-main .columns .column .page-title-wrapper .block-title .page-title {
  text-align: left;
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .page-title-wrapper .block-title .page-title {
    margin-top: 0;
    margin-bottom: 20px;
    display: flex;
  }
}
.quotation-index-detail .page-wrapper .page-main .columns .column .page-title-wrapper .block-title .page-title span {
  font-family: "Playfair Display";
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: #000000;
}
.quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation {
  border-collapse: collapse;
}
@media screen and (max-width: 767px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation thead {
    display: none;
  }
}
.quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation thead tr {
  border: none;
  border-bottom: 1px solid #93002a;
}
.quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation thead tr th {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 20px;
  color: #000000;
  border: none;
  text-align: center;
}
.quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation thead tr th:nth-child(1),
.quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation thead tr th:nth-child(2),
.quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation thead tr th:nth-child(4),
.quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation thead tr th:nth-child(5) {
  width: 2%;
}
.quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation thead tr th:nth-child(3) {
  text-align: left;
  width: 30%;
}
@media screen and (max-width: 767px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody {
    display: flex;
    flex-direction: column;
  }
}
.quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container {
  border: none;
  border-bottom: 1px solid #93002a;
}
@media screen and (max-width: 767px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 0px 0px;
    padding: 20px 0;
  }
}
@media screen and (max-width: 767px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container .img {
    grid-area: 1/1/5/2;
    justify-content: center;
    align-items: center;
    display: flex;
    width: auto;
  }
}
@media screen and (max-width: 767px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container .qty {
    grid-area: 1/2/2/3;
  }
}
@media screen and (max-width: 767px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container .product-name {
    grid-area: 2/2/3/3;
  }
}
@media screen and (max-width: 767px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container .price {
    grid-area: 3/2/4/3;
  }
}
@media screen and (max-width: 767px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container .subtotal {
    grid-area: 4/2/5/3;
  }
}
@media screen and (max-width: 767px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container .qty,
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container .product-name,
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container .price,
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container .subtotal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
  }
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container .qty::before,
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container .product-name::before,
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container .price::before,
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container .subtotal::before {
    padding-right: 10px;
    content: attr(data-th) ": ";
    display: inline-block;
    color: #000000;
    font-family: "Source Sans Pro";
    font-weight: bold;
    font-style: normal;
    font-size: 16px;
  }
}
.quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container td {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  text-align: center !important;
  border: none !important;
}
@media screen and (min-width: 768px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container td {
    font-size: 18px;
  }
}
.quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container td img {
  height: 80px;
  width: 80px;
  min-width: 80px;
}
.quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container td a {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container td a {
    font-size: 18px;
  }
}
.quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container td:nth-child(1) {
  text-align: left !important;
}
.quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container td:nth-child(3) {
  text-align: left !important;
}
.quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container td:nth-child(4),
.quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container td:nth-child(5) {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
}
.quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tbody .details-container td:nth-child(4) {
  color: #93002a;
}
@media screen and (max-width: 767px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tfoot .borderless .space {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tfoot .borderless .remove-padding .remove-padding {
    padding: 10px;
  }
}
@media screen and (min-width: 768px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tfoot .borderless .remove-padding .remove-padding {
    border-collapse: collapse;
  }
}
@media screen and (max-width: 767px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tfoot .borderless .remove-padding .remove-padding tbody tr {
    justify-content: space-between;
    width: 100%;
    display: flex;
    min-width: 100%;
    border: none;
  }
}
@media screen and (min-width: 768px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tfoot .borderless .remove-padding .remove-padding tbody tr {
    justify-content: center;
    border: none;
    border-bottom: 1px solid #93002a;
  }
}
.quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tfoot .borderless .remove-padding .remove-padding tbody tr td {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  padding: 20px 0;
  border: none !important;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tfoot .borderless .remove-padding .remove-padding tbody tr td {
    width: 100%;
    padding: 10px 0 0;
    justify-content: flex-end;
  }
}
.quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tfoot .borderless .remove-padding .remove-padding tbody tr td span {
  margin: 0 !important;
}
@media screen and (max-width: 767px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tfoot .borderless .remove-padding .remove-padding tbody tr td:nth-child(1) {
    text-align: left !important;
  }
}
@media screen and (min-width: 979px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tfoot .borderless .remove-padding .remove-padding tbody tr .td-space {
    padding-left: 25px;
  }
}
@media screen and (min-width: 1920px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tfoot .borderless .remove-padding .remove-padding tbody tr .td-space {
    padding-left: 35px;
  }
}
@media screen and (min-width: 979px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tfoot .borderless .remove-padding .remove-padding tbody tr .space-td {
    padding-left: 5px;
    justify-content: end;
  }
}
@media screen and (min-width: 1920px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tfoot .borderless .remove-padding .remove-padding tbody tr .space-td {
    padding-left: 25px;
  }
}
.quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tfoot .borderless .remove-padding .remove-padding tbody tr:nth-child(4) td {
  justify-content: center;
  color: #93002a;
}
@media screen and (max-width: 767px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tfoot .borderless .remove-padding .remove-padding tbody tr:nth-child(4) td {
    justify-content: flex-end;
  }
}
.quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tfoot .borderless .remove-padding .remove-padding tbody tr:nth-child(4) td span {
  color: #93002a;
}
@media screen and (min-width: 979px) {
  .quotation-index-detail .page-wrapper .page-main .columns .column .table-quotation tfoot .borderless .remove-padding .remove-padding tbody tr:nth-child(4) .space-td {
    justify-content: flex-start;
  }
}
.quotation-index-detail .page-wrapper .page-main .columns .column .back-btn-quote {
  left: 0;
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: #fff;
  display: flex;
  border: 1px solid #000000;
  border-radius: 0;
  padding: 10px 20px;
  width: 100px;
  justify-content: center;
  margin-left: auto;
  text-decoration: none;
  background-color: #000000;
}
.quotation-index-detail .page-wrapper .page-main .columns .column .back-btn-quote:hover {
  background-color: #fff;
  color: #000000;
}

body.cms-terminos-y-condiciones .message.global.demo,
body.cms-aviso-de-privacidad .message.global.demo,
body.cms-privacidad .message.global.demo {
  display: none;
}
body.cms-terminos-y-condiciones .page-wrapper,
body.cms-aviso-de-privacidad .page-wrapper,
body.cms-privacidad .page-wrapper {
  font-family: Source Sans Pro;
}
body.cms-terminos-y-condiciones .page-wrapper .breadcrumbs,
body.cms-aviso-de-privacidad .page-wrapper .breadcrumbs,
body.cms-privacidad .page-wrapper .breadcrumbs {
  display: none;
}
body.cms-terminos-y-condiciones .page-wrapper .page-title,
body.cms-aviso-de-privacidad .page-wrapper .page-title,
body.cms-privacidad .page-wrapper .page-title {
  font-family: Playfair Display;
  font-weight: 900;
  text-align: center;
  text-transform: none;
  font-size: 30px;
  line-height: 34px;
  margin: 55px 40px;
  width: auto;
  display: block;
  color: black;
}
body.cms-terminos-y-condiciones .page-wrapper .footer .block.newsletter,
body.cms-aviso-de-privacidad .page-wrapper .footer .block.newsletter,
body.cms-privacidad .page-wrapper .footer .block.newsletter {
  display: none;
}
body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page,
body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page,
body.cms-privacidad .page-wrapper .page-main .cont-page {
  max-width: 1000px;
  margin: 0 auto 50px;
}
body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page.large,
body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page.large,
body.cms-privacidad .page-wrapper .page-main .cont-page.large {
  max-width: 1140px;
}
body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page p,
body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page p,
body.cms-privacidad .page-wrapper .page-main .cont-page p {
  font-family: Source Sans Pro;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  width: 100%;
  display: table;
}
body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page strong,
body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page strong,
body.cms-privacidad .page-wrapper .page-main .cont-page strong {
  font-weight: 900;
  color: black;
}
body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page a,
body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page a,
body.cms-privacidad .page-wrapper .page-main .cont-page a {
  color: #93002a;
  text-decoration: none;
}
body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page a.mail,
body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page a.mail,
body.cms-privacidad .page-wrapper .page-main .cont-page a.mail {
  color: black;
}
body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page h3,
body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page h3,
body.cms-privacidad .page-wrapper .page-main .cont-page h3 {
  font-family: Playfair Display;
  font-weight: 900;
  text-align: center;
  font-size: 25px;
  width: 100%;
  margin-bottom: 40px;
  color: black;
}
body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page label,
body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page label,
body.cms-privacidad .page-wrapper .page-main .cont-page label {
  font-size: 16px;
}
body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page .back-gray,
body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page .back-gray,
body.cms-privacidad .page-wrapper .page-main .cont-page .back-gray {
  border-radius: 10px;
  background: #f3f3f3;
  padding: 30px 10%;
  width: auto;
  margin: 0 auto;
  display: block;
}
body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page .back-gray p,
body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page .back-gray p,
body.cms-privacidad .page-wrapper .page-main .cont-page .back-gray p {
  margin: 0;
  text-align: left;
}
body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page .back-gray.small,
body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page .back-gray.small,
body.cms-privacidad .page-wrapper .page-main .cont-page .back-gray.small {
  float: left;
  width: 15%;
  margin: 0 0 30px 0;
}
body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page .back-gray.medium,
body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page .back-gray.medium,
body.cms-privacidad .page-wrapper .page-main .cont-page .back-gray.medium {
  width: 40%;
  margin: 0 auto 30px;
}
body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page .list-icos,
body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page .list-icos,
body.cms-privacidad .page-wrapper .page-main .cont-page .list-icos {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 60px 0 50px 0;
}
body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page .list-icos li,
body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page .list-icos li,
body.cms-privacidad .page-wrapper .page-main .cont-page .list-icos li {
  list-style: none;
  margin-right: 39px;
  flex: 1;
  text-align: center;
  max-width: 130px;
  margin: 0 auto;
}
body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page .list-icos li:nth-child(1), body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page .list-icos li:nth-child(2), body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page .list-icos li:nth-child(3), body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page .list-icos li:nth-child(8),
body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page .list-icos li:nth-child(1),
body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page .list-icos li:nth-child(2),
body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page .list-icos li:nth-child(3),
body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page .list-icos li:nth-child(8),
body.cms-privacidad .page-wrapper .page-main .cont-page .list-icos li:nth-child(1),
body.cms-privacidad .page-wrapper .page-main .cont-page .list-icos li:nth-child(2),
body.cms-privacidad .page-wrapper .page-main .cont-page .list-icos li:nth-child(3),
body.cms-privacidad .page-wrapper .page-main .cont-page .list-icos li:nth-child(8) {
  max-width: 80px;
}
body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page .list-icos li:nth-child(4), body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page .list-icos li:nth-child(5),
body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page .list-icos li:nth-child(4),
body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page .list-icos li:nth-child(5),
body.cms-privacidad .page-wrapper .page-main .cont-page .list-icos li:nth-child(4),
body.cms-privacidad .page-wrapper .page-main .cont-page .list-icos li:nth-child(5) {
  max-width: 105px;
}
body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page .list-icos li:nth-child(6),
body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page .list-icos li:nth-child(6),
body.cms-privacidad .page-wrapper .page-main .cont-page .list-icos li:nth-child(6) {
  max-width: 135px;
}
body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page .list-icos li h4,
body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page .list-icos li h4,
body.cms-privacidad .page-wrapper .page-main .cont-page .list-icos li h4 {
  text-transform: uppercase;
  font-size: 12px;
  text-align: left;
  margin: 15px 0 0;
  font-weight: 900;
}
body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page .list-icos li p,
body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page .list-icos li p,
body.cms-privacidad .page-wrapper .page-main .cont-page .list-icos li p {
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  margin: 0;
}
body.cms-terminos-y-condiciones .page-wrapper .styled-checkbox,
body.cms-aviso-de-privacidad .page-wrapper .styled-checkbox,
body.cms-privacidad .page-wrapper .styled-checkbox {
  position: absolute;
  opacity: 0;
}
body.cms-terminos-y-condiciones .page-wrapper .styled-checkbox + label,
body.cms-aviso-de-privacidad .page-wrapper .styled-checkbox + label,
body.cms-privacidad .page-wrapper .styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
body.cms-terminos-y-condiciones .page-wrapper .styled-checkbox + label:before,
body.cms-aviso-de-privacidad .page-wrapper .styled-checkbox + label:before,
body.cms-privacidad .page-wrapper .styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 15px;
  height: 15px;
  background: white;
  border: 2px solid #4a4a4a;
}
body.cms-terminos-y-condiciones .page-wrapper .styled-checkbox:hover + label:before,
body.cms-aviso-de-privacidad .page-wrapper .styled-checkbox:hover + label:before,
body.cms-privacidad .page-wrapper .styled-checkbox:hover + label:before {
  background: white;
}
body.cms-terminos-y-condiciones .page-wrapper .styled-checkbox:focus + label:before,
body.cms-aviso-de-privacidad .page-wrapper .styled-checkbox:focus + label:before,
body.cms-privacidad .page-wrapper .styled-checkbox:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
body.cms-terminos-y-condiciones .page-wrapper .styled-checkbox:checked + label:before,
body.cms-aviso-de-privacidad .page-wrapper .styled-checkbox:checked + label:before,
body.cms-privacidad .page-wrapper .styled-checkbox:checked + label:before {
  background: white;
  border-color: #93002a;
}
body.cms-terminos-y-condiciones .page-wrapper .styled-checkbox:disabled + label,
body.cms-aviso-de-privacidad .page-wrapper .styled-checkbox:disabled + label,
body.cms-privacidad .page-wrapper .styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
body.cms-terminos-y-condiciones .page-wrapper .styled-checkbox:disabled + label:before,
body.cms-aviso-de-privacidad .page-wrapper .styled-checkbox:disabled + label:before,
body.cms-privacidad .page-wrapper .styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
body.cms-terminos-y-condiciones .page-wrapper .styled-checkbox:checked + label:after,
body.cms-aviso-de-privacidad .page-wrapper .styled-checkbox:checked + label:after,
body.cms-privacidad .page-wrapper .styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 #93002a, 4px 0 0 #93002a, 4px -2px 0 #93002a, 4px -4px 0 #93002a, 4px -6px 0 #93002a, 4px -8px 0 #93002a;
  transform: rotate(45deg);
}

@media only screen and (max-width: 1199px) {
  body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page,
  body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page,
  body.cms-privacidad .page-wrapper .page-main .cont-page {
    padding: 0 25px;
  }
}
@media only screen and (max-width: 1023px) {
  body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page .list-icos li,
  body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page .list-icos li,
  body.cms-privacidad .page-wrapper .page-main .cont-page .list-icos li {
    text-align: center;
    max-width: 130px !important;
    flex: 1 130px;
    margin: 15px auto;
  }
  body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page .list-icos li h4,
  body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page .list-icos li h4,
  body.cms-privacidad .page-wrapper .page-main .cont-page .list-icos li h4 {
    text-align: center;
  }
  body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page .list-icos li p,
  body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page .list-icos li p,
  body.cms-privacidad .page-wrapper .page-main .cont-page .list-icos li p {
    text-align: center;
  }
  body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page .back-gray.small,
  body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page .back-gray.small,
  body.cms-privacidad .page-wrapper .page-main .cont-page .back-gray.small {
    width: 25%;
  }
}
@media only screen and (max-width: 767px) {
  body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page .list-icos li,
  body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page .list-icos li,
  body.cms-privacidad .page-wrapper .page-main .cont-page .list-icos li {
    text-align: center;
    max-width: 150px !important;
    flex: 1 130px;
    margin: 15px auto;
  }
  body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page .list-icos li h4,
  body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page .list-icos li h4,
  body.cms-privacidad .page-wrapper .page-main .cont-page .list-icos li h4 {
    text-align: center;
  }
  body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page .list-icos li p,
  body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page .list-icos li p,
  body.cms-privacidad .page-wrapper .page-main .cont-page .list-icos li p {
    text-align: center;
  }
  body.cms-terminos-y-condiciones .page-wrapper .page-main .cont-page .back-gray.small,
  body.cms-aviso-de-privacidad .page-wrapper .page-main .cont-page .back-gray.small,
  body.cms-privacidad .page-wrapper .page-main .cont-page .back-gray.small {
    width: 50%;
  }
}
body.cms-preguntas-frecuentes .message.global.demo {
  display: none;
}
body.cms-preguntas-frecuentes .page-wrapper {
  font-family: Source Sans Pro;
}
body.cms-preguntas-frecuentes .page-wrapper .page-header {
  background: transparent;
}
body.cms-preguntas-frecuentes .page-wrapper .page-header .header {
  background-color: rgba(0, 0, 0, 0.45);
}
body.cms-preguntas-frecuentes .page-wrapper .breadcrumbs {
  display: none;
}
body.cms-preguntas-frecuentes .page-wrapper .rootmenu {
  background-color: rgba(0, 0, 0, 0.45) !important;
}
body.cms-preguntas-frecuentes .page-wrapper .footer .block.newsletter {
  display: none;
}
body.cms-preguntas-frecuentes .page-wrapper .page-title-wrapper {
  position: absolute;
  text-align: center;
  top: 45%;
  width: 100%;
}
body.cms-preguntas-frecuentes .page-wrapper .page-title-wrapper .page-title {
  color: white;
  font-family: Playfair Display;
  font-size: 50px;
  font-weight: 800;
  font-style: normal;
  letter-spacing: 1px;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.75);
}
body.cms-preguntas-frecuentes .page-wrapper .page-title-wrapper .page-title:before, body.cms-preguntas-frecuentes .page-wrapper .page-title-wrapper .page-title:after {
  content: "~";
  font-size: 50px;
  display: inline;
  margin: 0 15px;
}
body.cms-preguntas-frecuentes .page-wrapper .column.main {
  margin-top: 25%;
}
body.cms-preguntas-frecuentes .page-wrapper .banner-top {
  position: absolute;
  top: 0;
  width: 100%;
}
body.cms-preguntas-frecuentes .page-wrapper .banner-top img {
  width: 100%;
}
body.cms-preguntas-frecuentes .page-wrapper .banner-top img.only-desk {
  display: block;
}
body.cms-preguntas-frecuentes .page-wrapper .banner-top img.only-tab {
  display: none;
}
body.cms-preguntas-frecuentes .page-wrapper .banner-top img.only-mob {
  display: none;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page {
  max-width: 1140px;
  margin: 100px auto 0;
  display: table;
  width: 100%;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page p {
  font-size: 14px;
  text-align: justify;
  font-family: Source Sans Pro;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page .col {
  width: 20%;
  float: left;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page .col.right {
  width: 70%;
  float: right;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page #faqs {
  margin-top: 5px;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page #faqs .submenu {
  display: none;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page #faqs .menu-li {
  position: relative;
  font-size: 20px;
  font-weight: bold;
  margin: 25px 0;
  z-index: 2;
  cursor: pointer;
  color: black;
  letter-spacing: 0.56px;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page #faqs .menu-li .trig {
  width: 100%;
  display: table;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page #faqs .menu-li:first-child {
  margin-top: 0;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page #faqs .submenu ul {
  padding-left: 0;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page #faqs .submenu ul li {
  list-style: none;
  font-size: 18px;
  font-family: Source Sans Pro;
  padding-left: 20px;
  cursor: pointer;
  letter-spacing: 0.56px;
  color: black;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page #faqs .submenu ul li.active {
  font-weight: bold;
  border-left: 5px solid black;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page #faqs .ico {
  position: absolute;
  right: 0;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page #faqs .ico:before {
  content: "";
  background: url("../images/catalog/more.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  width: 15px;
  height: 15px;
  font-weight: normal;
  font-size: 25px;
  display: inline-block;
  position: relative;
  top: 0;
  z-index: 1;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page #faqs .ico.active {
  position: absolute;
  right: 0;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page #faqs .ico.active:before {
  content: "";
  background: url("../images/catalog/less.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  width: 15px;
  height: 15px;
  font-weight: normal;
  font-size: 25px;
  display: inline-block;
  position: relative;
  top: 0;
  z-index: 1;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page .cont-faq.hide {
  display: none;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page .cont-faq h4 {
  font-family: Playfair Display;
  font-size: 30px;
  font-weight: 800;
  color: black;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page .cont-faq p {
  font-size: 16px;
  line-height: 28px;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page .info-block {
  border-top: 1px solid black;
  margin: 30px 0 0 0;
  padding: 30px 0 0;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page .info-block .legend {
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  color: #000000;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page .info-block .legend a {
  width: 100%;
  float: left;
  color: #555555;
  text-decoration: underline;
  font-size: 20px;
  color: #93002a;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page .black-gray {
  border-radius: 10px;
  background: #f3f3f3;
  padding: 40px;
  text-align: center;
  margin: 70px 0 0;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page .black-gray p {
  font-weight: normal;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  margin: 0;
  color: #000000;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page .black-gray p.large {
  font-size: 30px;
}
body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page .black-gray p a {
  color: black;
  font-weight: 900;
  text-decoration: none;
}

@media only screen and (max-width: 1199px) {
  body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page {
    padding: 0 25px;
    margin: 60px auto 0;
    width: auto;
    max-width: 100%;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  body.cms-preguntas-frecuentes .page-wrapper .column.main {
    margin-top: 24%;
  }
}
@media only screen and (max-width: 1025px) {
  body.cms-preguntas-frecuentes .page-wrapper .column.main {
    margin-top: 45%;
  }
  body.cms-preguntas-frecuentes .page-wrapper .page-title-wrapper .page-title {
    font-size: 32px;
  }
  body.cms-preguntas-frecuentes .page-wrapper .banner-top img.only-desk {
    display: none;
  }
  body.cms-preguntas-frecuentes .page-wrapper .banner-top img.only-tab {
    display: block;
  }
  body.cms-preguntas-frecuentes .page-wrapper .banner-top img.only-mob {
    display: none;
  }
  body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page .cont-faq h4 {
    font-size: 25px;
  }
}
/* Landscape */
@media (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  body.cms-preguntas-frecuentes .page-wrapper .banner-top img.only-desk {
    display: block !important;
  }
  body.cms-preguntas-frecuentes .page-wrapper .banner-top img.only-tab {
    display: none !important;
  }
  body.cms-preguntas-frecuentes .page-wrapper .banner-top img.only-mob {
    display: none !important;
  }
}
@media only screen and (max-width: 767px) {
  body.cms-preguntas-frecuentes .page-wrapper .page-title-wrapper {
    top: 35%;
  }
  body.cms-preguntas-frecuentes .page-wrapper .page-title-wrapper .page-title {
    font-size: 23px;
  }
  body.cms-preguntas-frecuentes .page-wrapper .page-title-wrapper .page-title span {
    margin: 0 auto;
    display: inline;
  }
  body.cms-preguntas-frecuentes .page-wrapper .page-title-wrapper .page-title:before, body.cms-preguntas-frecuentes .page-wrapper .page-title-wrapper .page-title:after {
    font-size: 20px;
  }
  body.cms-preguntas-frecuentes .page-wrapper .column.main {
    margin-top: 45%;
  }
  body.cms-preguntas-frecuentes .page-wrapper .banner-top img.only-desk {
    display: none;
  }
  body.cms-preguntas-frecuentes .page-wrapper .banner-top img.only-tab {
    display: none;
  }
  body.cms-preguntas-frecuentes .page-wrapper .banner-top img.only-mob {
    display: block;
  }
  body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page {
    margin: 30px auto 30px;
    width: auto;
    display: block;
  }
  body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page .col {
    width: auto;
    width: auto;
    display: block;
    float: none;
  }
  body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page .col.right {
    width: auto;
    float: none;
  }
  body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page .black-gray {
    padding: 30px 20px;
  }
  body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page .black-gray p {
    font-size: 20px;
    line-height: 40px;
  }
  body.cms-preguntas-frecuentes .page-wrapper .page-main .cont-page .black-gray p.large {
    font-size: 18px;
  }
}
@media only screen and (max-width: 599px) {
  body.contact-index-index .page-wrapper .page-title-wrapper {
    top: 35%;
  }
  body.contact-index-index .page-wrapper .page-title-wrapper .page-title {
    font-size: 22px;
  }
  body.contact-index-index .page-wrapper .page-title-wrapper .page-title:before, body.contact-index-index .page-wrapper .page-title-wrapper .page-title:after {
    font-size: 18px;
  }
  body.contact-index-index .page-wrapper .page-main .cont-page .black-gray {
    font-size: 18px;
  }
  body.contact-index-index .page-wrapper .page-main .cont-page .black-gray p.large {
    font-size: 16px;
  }
}
body.cms-nosotros .message.global.demo {
  display: none;
}
body.cms-nosotros .page-wrapper {
  font-family: Source Sans Pro;
}
body.cms-nosotros .page-wrapper .breadcrumbs {
  display: none;
}
body.cms-nosotros .page-wrapper .page-header {
  background: transparent;
}
body.cms-nosotros .page-wrapper .page-header .header {
  background-color: rgba(0, 0, 0, 0.65);
}
body.cms-nosotros .page-wrapper .rootmenu {
  background-color: rgba(0, 0, 0, 0.65) !important;
}
body.cms-nosotros .page-wrapper .footer .block.newsletter {
  display: none;
}
body.cms-nosotros .page-wrapper .page-title-wrapper {
  position: absolute;
  text-align: center;
  top: 45%;
  width: 100%;
}
body.cms-nosotros .page-wrapper .page-title-wrapper .page-title {
  color: white;
  font-family: Playfair Display;
  font-size: 50px;
  font-weight: 800;
  font-style: normal;
  letter-spacing: 1px;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.75);
}
body.cms-nosotros .page-wrapper .page-title-wrapper .page-title:before, body.cms-nosotros .page-wrapper .page-title-wrapper .page-title:after {
  content: "~";
  font-size: 50px;
  display: inline;
  margin: 0 15px;
}
body.cms-nosotros .page-wrapper .column.main {
  margin-top: 25%;
}
body.cms-nosotros .page-wrapper .banner-top {
  position: absolute;
  top: 0;
  width: 100%;
}
body.cms-nosotros .page-wrapper .banner-top img {
  width: 100%;
}
body.cms-nosotros .page-wrapper .banner-top img.only-desk {
  display: block;
}
body.cms-nosotros .page-wrapper .banner-top img.only-tab {
  display: none;
}
body.cms-nosotros .page-wrapper .banner-top img.only-mob {
  display: none;
}
body.cms-nosotros .page-wrapper .page-main .cont-page {
  max-width: 1140px;
  margin: 100px auto 0;
  display: table;
}
body.cms-nosotros .page-wrapper .page-main .cont-page p {
  font-size: 16px;
  text-align: justify;
}
body.cms-nosotros .page-wrapper .page-main .cont-page .col {
  width: 100%;
}
body.cms-nosotros .page-wrapper .page-main .cont-page .col.left .img-container {
  display: none;
}
body.cms-nosotros .page-wrapper .page-main .cont-page .col.right {
  text-align: center;
}

@media only screen and (max-width: 1199px) {
  body.cms-nosotros .page-wrapper .page-main .cont-page {
    padding: 0 25px;
    margin: 60px auto 0;
    max-width: 100%;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  body.cms-nosotros .page-wrapper .column.main {
    margin-top: 21%;
  }
}
@media only screen and (max-width: 1025px) {
  body.cms-nosotros .page-wrapper .column.main {
    margin-top: 45%;
  }
  body.cms-nosotros .page-wrapper .page-title-wrapper .page-title {
    font-size: 40px;
  }
  body.cms-nosotros .page-wrapper .banner-top img.only-desk {
    display: none;
  }
  body.cms-nosotros .page-wrapper .banner-top img.only-tab {
    display: block;
  }
  body.cms-nosotros .page-wrapper .banner-top img.only-mob {
    display: none;
  }
}
/* Landscape */
@media (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  body.cms-nosotros .page-wrapper .banner-top img.only-desk {
    display: block !important;
  }
  body.cms-nosotros .page-wrapper .banner-top img.only-tab {
    display: none !important;
  }
  body.cms-nosotros .page-wrapper .banner-top img.only-mob {
    display: none !important;
  }
}
@media only screen and (max-width: 767px) {
  body.cms-nosotros .page-wrapper .page-title-wrapper {
    top: 35%;
  }
  body.cms-nosotros .page-wrapper .page-title-wrapper .page-title {
    font-size: 23px;
  }
  body.cms-nosotros .page-wrapper .page-title-wrapper .page-title span {
    margin: 0 auto;
    display: inline;
  }
  body.cms-nosotros .page-wrapper .page-title-wrapper .page-title:before, body.cms-nosotros .page-wrapper .page-title-wrapper .page-title:after {
    font-size: 20px;
  }
  body.cms-nosotros .page-wrapper .column.main {
    margin-top: 50%;
  }
  body.cms-nosotros .page-wrapper .banner-top img.only-desk {
    display: none;
  }
  body.cms-nosotros .page-wrapper .banner-top img.only-tab {
    display: none;
  }
  body.cms-nosotros .page-wrapper .banner-top img.only-mob {
    display: block;
  }
  body.cms-nosotros .page-wrapper .page-main .cont-page {
    margin: 30px auto 0;
  }
  body.cms-nosotros .page-wrapper .page-main .cont-page .col {
    width: 100%;
    margin-bottom: 50px;
  }
}
@media screen and (min-width: 979px) {
  .cms-nosotros .page-wrapper .page-main .cont-page {
    margin-top: 30px;
  }
  .cms-nosotros .page-wrapper .page-main .cont-page .col.left {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  .cms-nosotros .page-wrapper .page-main .cont-page .col.left .text-container {
    width: 48%;
  }
  .cms-nosotros .page-wrapper .page-main .cont-page .col.left .img-container {
    width: 48%;
    text-align: center;
    display: block !important;
  }
  .cms-nosotros .page-wrapper .page-main .cont-page .col.right img {
    height: 100%;
  }
  .cms-nosotros .page-wrapper .page-main .cont-page .col.right .img-mobile {
    display: none;
  }
}
body.contact-index-index .message.global.demo {
  display: none;
}
body.contact-index-index .page-wrapper {
  font-family: Source Sans Pro;
}
body.contact-index-index .page-wrapper .page-header {
  background: transparent;
}
body.contact-index-index .page-wrapper .page-header .header {
  background-color: rgba(0, 0, 0, 0.75);
}
body.contact-index-index .page-wrapper .breadcrumbs {
  display: none;
}
body.contact-index-index .page-wrapper .rootmenu {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
body.contact-index-index .page-wrapper .footer .block.newsletter {
  display: none;
}
body.contact-index-index .page-wrapper .page-title-wrapper {
  position: absolute;
  text-align: center;
  top: 45%;
  width: 100%;
}
body.contact-index-index .page-wrapper .page-title-wrapper .page-title {
  color: white;
  font-family: Playfair Display;
  font-size: 50px;
  font-weight: 800;
  font-style: normal;
  letter-spacing: 1px;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.75);
}
body.contact-index-index .page-wrapper .page-title-wrapper .page-title:before, body.contact-index-index .page-wrapper .page-title-wrapper .page-title:after {
  content: "~";
  font-size: 50px;
  display: inline;
  margin: 0 15px;
}
body.contact-index-index .page-wrapper .columns .column.main {
  margin-top: 21%;
  padding-bottom: 0;
}
body.contact-index-index .page-wrapper .top-banner-contact {
  position: absolute;
  top: 0;
  width: 100%;
}
body.contact-index-index .page-wrapper .top-banner-contact img {
  width: 100%;
}
body.contact-index-index .page-wrapper .top-banner-contact img.only-desk {
  display: block;
}
body.contact-index-index .page-wrapper .top-banner-contact img.only-tab {
  display: none;
}
body.contact-index-index .page-wrapper .top-banner-contact img.only-mob {
  display: none;
}
body.contact-index-index .page-wrapper .banner-form {
  max-width: 1140px;
  margin: 0 auto;
  display: table;
  width: 100%;
}
body.contact-index-index .page-wrapper .banner-form .form .fieldset .legend span {
  color: #000000;
}
body.contact-index-index .page-wrapper .banner-form .form .fieldset .field.note {
  color: #000000;
}
body.contact-index-index .page-wrapper #contact-form {
  float: left;
  width: 45%;
  min-width: 45%;
}
body.contact-index-index .page-wrapper #contact-form input[type=text],
body.contact-index-index .page-wrapper #contact-form input[type=email],
body.contact-index-index .page-wrapper #contact-form textarea {
  background: white;
  border-radius: 0;
  border: 1px solid black;
}
body.contact-index-index .page-wrapper #contact-form input[type=text],
body.contact-index-index .page-wrapper #contact-form input[type=email] {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 45px;
  height: 45px;
}
body.contact-index-index .page-wrapper #contact-form textarea {
  min-height: 150px;
}
body.contact-index-index .page-wrapper #contact-form label {
  display: none;
}
body.contact-index-index .page-wrapper #contact-form .fieldset {
  margin-bottom: 0;
}
body.contact-index-index .page-wrapper #contact-form .fieldset .legend {
  margin-left: 0;
  width: 100%;
  font-family: Playfair Display;
  font-weight: bold;
  font-size: 30px;
}
body.contact-index-index .page-wrapper #contact-form .fieldset .field {
  margin-bottom: 20px;
}
body.contact-index-index .page-wrapper #contact-form .fieldset .field .control {
  width: 100%;
}
body.contact-index-index .page-wrapper #contact-form .fieldset .field.note {
  font-size: 16px;
}
body.contact-index-index .page-wrapper #contact-form .field.note:before {
  display: none;
}
body.contact-index-index .page-wrapper #contact-form .actions-toolbar .primary {
  width: 100%;
}
body.contact-index-index .page-wrapper #contact-form .actions-toolbar button {
  background: #93002a;
  text-transform: uppercase;
  font-family: Source Sans Pro;
  font-size: 20px;
  font-weight: normal;
  color: white;
  float: right;
  letter-spacing: 1px;
  height: 47px;
  line-height: 47px;
  padding: 0;
  max-width: 250px;
  text-align: center;
  border: none;
  margin: 0;
}
body.contact-index-index .page-wrapper .right-banner-contact {
  float: right;
  min-width: 45%;
  width: 45%;
}
body.contact-index-index .page-wrapper .right-banner-contact .black-gray {
  border-radius: 10px;
  background: #f3f3f3;
  padding: 55px 40px;
  text-align: center;
  margin: 100px 0 0;
}
body.contact-index-index .page-wrapper .right-banner-contact .black-gray p {
  font-weight: normal;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  margin: 0;
  letter-spacing: 0.67px;
}
body.contact-index-index .page-wrapper .right-banner-contact .black-gray p a {
  color: black;
  font-weight: 900;
  text-decoration: none;
}
body.contact-index-index .page-wrapper .right-banner-contact .black-gray p a img {
  max-width: 40px;
}
body.contact-index-index .page-wrapper .right-banner-contact .black-gray p.bld {
  font-weight: bold;
  color: black;
}
body.contact-index-index .page-wrapper .right-banner-contact .black-gray p.bld span {
  font-size: 24px;
}
body.contact-index-index .page-wrapper .contact-bottom-stores {
  background: #f3f3f3;
  display: table;
  width: 100%;
  text-align: left;
  margin: 100px auto 0;
  max-width: 100%;
  padding: 50px 0 0;
}
body.contact-index-index .page-wrapper .contact-bottom-stores .content-center {
  display: table;
  max-width: 1140px;
  margin: 0 auto;
}
body.contact-index-index .page-wrapper .contact-bottom-stores .cont_stores {
  display: flex;
  flex-wrap: wrap;
}
body.contact-index-index .page-wrapper .contact-bottom-stores .cont_stores .subtitle {
  font-size: 30px;
  line-height: 60px;
  text-align: left;
  margin: 0 0 30px 0;
  width: 100%;
  font-family: Playfair Display;
  font-weight: 900;
}
body.contact-index-index .page-wrapper .contact-bottom-stores .cont_stores .row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
body.contact-index-index .page-wrapper .contact-bottom-stores .cont_stores .row .col {
  flex: 0 43%;
  margin: 0 5% 50px 0;
  display: inline-block;
  padding: 20px;
  background: white;
  border-radius: 10px;
}
body.contact-index-index .page-wrapper .contact-bottom-stores .cont_stores .row .col::nth-of-type(even) {
  margin-right: 0;
}
body.contact-index-index .page-wrapper .contact-bottom-stores .cont_stores .row .col .map {
  margin-top: 20px;
}
body.contact-index-index .page-wrapper .contact-bottom-stores .cont_stores .row .col h4 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: bold;
}
body.contact-index-index .page-wrapper .contact-bottom-stores .cont_stores .row .col p {
  font-size: 20px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
}
body.contact-index-index .page-wrapper .contact-bottom-stores .cont_stores .row .col p.phone {
  font-weight: bold;
}
body.contact-index-index .page-wrapper .contact-bottom-stores .cont_stores .row .col iframe {
  max-width: 100%;
  width: 100%;
}
body.contact-index-index .page-wrapper .contact-bottom-stores .cont_stores .row div.col:nth-of-type(even) {
  margin-right: 0;
}

@media (min-width: 2001px) and (max-width: 3000px) {
  body.contact-index-index .page-wrapper .columns .column.main {
    margin-top: 800px;
    padding-bottom: 0;
  }
}
@media (min-width: 1701px) and (max-width: 2000px) {
  body.contact-index-index .page-wrapper .columns .column.main {
    margin-top: 24%;
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 1199px) {
  body.contact-index-index .page-wrapper .banner-form {
    width: 95%;
    padding: 0 2.5%;
    display: inline-block;
    max-width: 100%;
  }
  body.contact-index-index .page-wrapper .contact-bottom-stores .content-center {
    max-width: 100%;
    padding: 0 20px;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  body.contact-index-index .page-wrapper .columns .column.main {
    margin-top: 21%;
  }
}
@media only screen and (max-width: 1025px) {
  body.contact-index-index .page-wrapper .columns .column.main {
    margin-top: 43% !important;
  }
  body.contact-index-index .page-wrapper .page-title-wrapper .page-title {
    font-size: 32px;
  }
  body.contact-index-index .page-wrapper .top-banner-contact img.only-desk {
    display: none;
  }
  body.contact-index-index .page-wrapper .top-banner-contact img.only-tab {
    display: block;
  }
  body.contact-index-index .page-wrapper .top-banner-contact img.only-mob {
    display: none;
  }
  body.contact-index-index .page-wrapper #contact-form {
    min-width: 45% !important;
    width: 45%;
  }
  body.contact-index-index .page-wrapper #contact-form .field.note {
    font-size: 20px;
  }
  body.contact-index-index .page-wrapper .right-banner-contact {
    max-width: 45%;
    width: 45%;
  }
  body.contact-index-index .page-wrapper .right-banner-contact .black-gray {
    padding: 35px;
  }
  body.contact-index-index .page-wrapper .right-banner-contact .black-gray p {
    font-size: 16px;
    line-height: 40px;
  }
  body.contact-index-index .page-wrapper .right-banner-contact .black-gray p span {
    font-size: 18px;
  }
  body.contact-index-index .page-wrapper .contact-bottom-stores .content-center {
    max-width: 100%;
    padding: 0 20px;
  }
  body.contact-index-index .page-wrapper .contact-bottom-stores .cont_stores .row .col {
    padding: 20px 15px;
    margin: 0 20px 50px;
    flex: 0 42%;
  }
  body.contact-index-index .page-wrapper .contact-bottom-stores .cont_stores .row .col p {
    font-size: 13px;
  }
}
/* Landscape */
@media (min-width: 1024px) and (max-width: 1366px) and (orientation: landscape) {
  body.contact-index-index .page-wrapper .banner-form {
    width: 95%;
    padding: 0 2.5%;
    display: inline-block;
    max-width: 100%;
  }
  body.contact-index-index .page-wrapper .contact-bottom-stores .content-center {
    max-width: 100%;
    padding: 0 20px;
  }
}
@media (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  body.contact-index-index .page-wrapper .top-banner-contact img.only-desk {
    display: block !important;
  }
  body.contact-index-index .page-wrapper .top-banner-contact img.only-tab {
    display: none !important;
  }
  body.contact-index-index .page-wrapper .top-banner-contact img.only-mob {
    display: none !important;
  }
}
@media only screen and (max-width: 768px) {
  body.contact-index-index .page-wrapper .right-banner-contact .black-gray {
    margin-top: 100px;
  }
  body.contact-index-index .page-wrapper .right-banner-contact .black-gray p {
    font-size: 15px;
    line-height: 30px;
  }
  body.contact-index-index .page-wrapper #contact-form .field.note {
    font-size: 16px;
  }
  body.contact-index-index .page-wrapper .contact-bottom-stores .cont_stores .row .col {
    max-width: 35%;
    padding: 20px 15px;
    margin: 0 20px 50px;
  }
  body.contact-index-index .page-wrapper .contact-bottom-stores .cont_stores .row .col p {
    font-size: 12px;
  }
}
@media only screen and (max-width: 767px) {
  body.contact-index-index .page-wrapper .page-title-wrapper {
    top: 34%;
  }
  body.contact-index-index .page-wrapper .page-title-wrapper .page-title {
    font-size: 30px;
  }
  body.contact-index-index .page-wrapper .page-title-wrapper .page-title span {
    margin: 0 auto;
    display: inline;
  }
  body.contact-index-index .page-wrapper .page-title-wrapper .page-title:before, body.contact-index-index .page-wrapper .page-title-wrapper .page-title:after {
    font-size: 25px;
  }
  body.contact-index-index .page-wrapper .columns .column.main {
    margin-top: 50% !important;
  }
  body.contact-index-index .page-wrapper .top-banner-contact img.only-desk {
    display: none;
  }
  body.contact-index-index .page-wrapper .top-banner-contact img.only-tab {
    display: none;
  }
  body.contact-index-index .page-wrapper .top-banner-contact img.only-mob {
    display: block;
  }
  body.contact-index-index .page-wrapper #contact-form,
  body.contact-index-index .page-wrapper .right-banner-contact {
    width: 90%;
    float: none;
    display: table;
    margin: 0 auto;
  }
  body.contact-index-index .page-wrapper .right-banner-contact {
    max-width: 100%;
  }
  body.contact-index-index .page-wrapper .right-banner-contact .black-gray {
    margin-top: 50px;
    padding-left: 15px;
    padding-right: 15px;
  }
  body.contact-index-index .page-wrapper .right-banner-contact .black-gray p {
    font-size: 13px;
  }
  body.contact-index-index .page-wrapper .right-banner-contact .black-gray p.bld span {
    font-size: 20px;
  }
  body.contact-index-index .page-wrapper .right-banner-contact .black-gray p.bld.social a img {
    max-width: 20px;
    margin: 0 5px;
  }
  body.contact-index-index .page-wrapper #contact-form .actions-toolbar .primary {
    float: none;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 599px) {
  body.contact-index-index .page-wrapper .page-title-wrapper .page-title {
    font-size: 25px;
  }
  body.contact-index-index .page-wrapper .page-title-wrapper .page-title:before, body.contact-index-index .page-wrapper .page-title-wrapper .page-title:after {
    font-size: 20px;
  }
  body.contact-index-index .page-wrapper .contact-bottom-stores .cont_stores .subtitle {
    font-size: 25px;
  }
  body.contact-index-index .page-wrapper .contact-bottom-stores .cont_stores .row .col {
    max-width: 100%;
    margin: 0 0 50px;
    flex: 0 100%;
  }
  body.contact-index-index .page-wrapper .contact-bottom-stores .cont_stores .row .col p {
    font-size: 13px;
  }
}
@media screen and (min-width: 979px) {
  body.contact-index-index .page-wrapper .banner-form {
    width: 100%;
    padding: 0 10%;
    display: inline-block;
    max-width: 100%;
  }
  body.contact-index-index .page-wrapper .contact-bottom-stores {
    margin-top: 40px;
    background-color: #fff;
  }
  body.contact-index-index .page-wrapper .contact-bottom-stores .content-center {
    padding: 0 10%;
  }
  body.contact-index-index .page-wrapper .contact-bottom-stores .content-center .cont_stores .subtitle {
    color: #000000;
  }
  body.contact-index-index .page-wrapper .contact-bottom-stores .content-center .cont_stores .row {
    padding-bottom: 20%;
  }
  body.contact-index-index .page-wrapper .contact-bottom-stores .content-center .cont_stores .row .col {
    flex: 0 47%;
    padding: 0;
  }
  body.contact-index-index .page-wrapper .contact-bottom-stores .content-center .cont_stores .row .col h4,
  body.contact-index-index .page-wrapper .contact-bottom-stores .content-center .cont_stores .row .col p {
    columns: #000000;
  }
}