body {
    .page-wrapper {
        .page-header {
            background-color: $p-black;
            .header {
                .header-right {
                    .minicart-wrapper,
                    .miniquote-wrapper {
                        #minicart-content-toggle,
                        #miniquote-content-toggle {
                            &:before {
                                background: rgba(0, 0, 0, 0.75) !important;
                            }
                        }
                    }
                }
            }
        }
        .sections {
            z-index: 14;
            @media screen and (max-width: $bp-large) {
                background: $p-black !important;
            }
            @media screen and (min-width: $bp-large) {
                z-index: 3;
            }
            .section-items {
                .section-item-content {
                    margin-top: 0;
                    padding-top: 0;
                    .page-main {
                        margin-top: 0;
                        padding: 0;
                        .rootmenu {
                            @media screen and (min-width: $bp-large) {
                                background-color: $p-black;
                            }
                            @media screen and (max-width: $bp-large) {
                                width: 100%;
                                display: flex;
                            }
                            #rw-menutop {
                                @media screen and (max-width: $bp-large) {
                                    display: block;
                                }
                            }
                            .mobile-sub {
                                .nav-10 {
                                    a {
                                        color: $hover;
                                        @media screen and (max-width: $bp-large) {
                                            background-color: $p-black !important;
                                        }
                                    }
                                    .active {
                                        @media screen and (max-width: $bp-large) {
                                            background-color: $p-blackred !important;
                                        }
                                    }
                                }
                                .bullet-level3 {
                                    .level3-popup {
                                        .category-item {
                                            a {
                                                &:hover {
                                                    @media screen and (min-width: $bp-large) {
                                                        color: $white;
                                                        background-color: $p-red;
                                                        .sub-cat-name {
                                                            &::before {
                                                                background: url(../images/media/arrow-menu-hover.png);
                                                                content: "";
                                                                margin: 0 10px 0
                                                                    0;
                                                                display: inline-block;
                                                                width: 10px;
                                                                height: 8px;
                                                                background-repeat: no-repeat;
                                                                background-position: center;
                                                            }
                                                        }
                                                    }
                                                }
                                                .sub-cat-name {
                                                    @media screen and (min-width: $bp-large) {
                                                        font-weight: bold;
                                                        &::before {
                                                            background: url(../images/media/arrow-menu.png);
                                                            content: "";
                                                            margin: 0 10px 0 0;
                                                            display: inline-block;
                                                            width: 10px;
                                                            height: 8px;
                                                            background-repeat: no-repeat;
                                                            background-position: center;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .category-item {
                                    border: none !important;
                                    &.hover {
                                        .megamenu {
                                            display: block;
                                        }
                                    }
                                    a {
                                        border: none !important;
                                        text-transform: capitalize;
                                        @include SourceSansRegular;
                                        padding-bottom: 20px;
                                    }
                                    a::first-letter {
                                        text-transform: capitalize;
                                    }
                                    .rootmenu-click {
                                        .rootmenu-arrow {
                                            &::before {
                                                top: 8px;
                                                @media screen and (min-width: $bp-medium) {
                                                    top: 5px;
                                                }
                                            }
                                        }
                                        .rootmenu-arrow.rootmenu-rotate {
                                            &::before {
                                                top: 8px;
                                                @media screen and (min-width: $bp-medium) {
                                                    top: 5px;
                                                }
                                            }
                                        }
                                    }
                                    @media screen and (max-width: $bp-large) {
                                        a {
                                            color: $white !important;
                                            background-color: $p-black;
                                            padding: 10px 20px !important;
                                            font-size: 18px;
                                        }
                                        .rootmenu-click {
                                            background-color: $p-black;
                                            height: auto;
                                            .rootmenu-arrow {
                                                background: $p-black !important;
                                                &::after {
                                                    display: none;
                                                }
                                                &::before {
                                                    background: url(../images/icons/icon-menu.svg);
                                                    content: "";
                                                    width: 16px;
                                                    height: 16px;
                                                    background-repeat: no-repeat;
                                                    padding: 0;
                                                    margin: 0;
                                                    background-position: 50%;
                                                    border-radius: 0;
                                                    transform: none;
                                                    border: none;
                                                }
                                            }
                                            .rootmenu-rotate {
                                                margin-top: 10px;
                                                &::before {
                                                    background: url(../images/icons/menu-menos-info.svg);
                                                    content: "";
                                                    width: 16px;
                                                    height: 16px;
                                                    background-repeat: no-repeat;
                                                    padding: 0;
                                                    margin: 0;
                                                    background-position: 50%;
                                                    border-radius: 0;
                                                    top: 5px;
                                                    transform: none;
                                                    border: none;
                                                }
                                            }
                                        }
                                        .has-sub-cat {
                                            border: none !important;
                                            background-color: black !important;
                                            color: $white !important;
                                            padding: 15px 20px !important;
                                            font-size: 18px;
                                        }
                                        .megamenu {
                                            background-color: $p-blackred;
                                            border-top: none;
                                            .clearfix {
                                                padding: 0;
                                                .clearfix {
                                                    .clearfix {
                                                        padding: 0;
                                                        .category-item {
                                                            color: $white;
                                                            a {
                                                                background: transparent;
                                                                padding: 10px
                                                                    20px;
                                                                font-size: 18px;
                                                            }
                                                            .rootmenu-click {
                                                                background-color: $p-blackred;
                                                                padding-right: 12px;
                                                                .rootmenu-arrow {
                                                                    background-color: $p-blackred !important;
                                                                }
                                                            }
                                                            .level3-popup {
                                                                .category-item {
                                                                    a {
                                                                        padding: 10px;
                                                                        font-size: 18px;
                                                                    }
                                                                    .rootmenu-click {
                                                                        padding-right: 12px;
                                                                    }
                                                                    .level4-popup {
                                                                        .category-item {
                                                                            a {
                                                                                padding: 10px;
                                                                                font-size: 18px;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .megamenu {
                                        border-top: none;
                                        @media screen and (min-width: $bp-large) {
                                            display: none;
                                            padding: 10px 10px 25px;
                                            transition: none;
                                        }
                                        .clearfix {
                                            .clearfix {
                                                .clearfix {
                                                    .category-item {
                                                        a {
                                                            @media screen and (min-width: $bp-large) {
                                                                padding: 10px;
                                                            }
                                                            &:hover {
                                                                @media screen and (min-width: $bp-large) {
                                                                    color: $white;
                                                                    background-color: $p-red;
                                                                }
                                                            }
                                                            span {
                                                                text-transform: capitalize;
                                                            }
                                                            .cat-arrow {
                                                                @media screen and (min-width: $bp-large) {
                                                                    display: none;
                                                                }
                                                            }
                                                            span::first-letter {
                                                                text-transform: capitalize;
                                                            }
                                                            .sub-cat-name {
                                                                text-decoration: none;
                                                                @media screen and (max-width: $bp-xxlarge) and (min-width: $bp-xlarge) {
                                                                    font-size: 15px;
                                                                }
                                                                @media screen and (min-width: $bp-xxlarge) {
                                                                    font-size: 18px;
                                                                }
                                                            }
                                                            .level2-name {
                                                                &:hover {
                                                                    @media screen and (min-width: $bp-large) {
                                                                        color: $white;
                                                                    }
                                                                }
                                                                @media screen and (min-width: $bp-large) {
                                                                    @include SourceSansBold;
                                                                }
                                                            }
                                                        }
                                                        .level3-popup {
                                                            border: none;
                                                            @media screen and (min-width: $bp-large) {
                                                                background-color: $white;
                                                                top: 0;
                                                                display: block;
                                                                position: relative;
                                                                box-shadow: none;
                                                                left: 0;
                                                                min-width: 100%;
                                                            }
                                                            .bullet-level4 {
                                                                a {
                                                                    .sub-cat-name {
                                                                        @media screen and (min-width: $bp-large) {
                                                                            font-weight: normal;
                                                                        }
                                                                        &::before {
                                                                            @media screen and (min-width: $bp-large) {
                                                                                display: none;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            .category-item {
                                                                @media screen and (min-width: $bp-large) {
                                                                    margin-left: 20px;
                                                                }
                                                                &:hover {
                                                                    text-decoration: none;
                                                                    @media screen and (min-width: $bp-large) {
                                                                    }
                                                                }
                                                                a {
                                                                    @media screen and (min-width: $bp-large) {
                                                                        padding: 10px;
                                                                    }
                                                                    span {
                                                                        text-transform: capitalize;
                                                                    }
                                                                    span::first-letter {
                                                                        text-transform: capitalize;
                                                                    }
                                                                    .sub-cat-name {
                                                                        text-decoration: none;
                                                                    }
                                                                }
                                                                .level4-popup {
                                                                    border-bottom: none;
                                                                    @media screen and (min-width: $bp-large) {
                                                                        position: relative;
                                                                        left: 0;
                                                                        box-shadow: none;
                                                                        border: none;
                                                                        top: 0;
                                                                        display: block;
                                                                        background-color: $white;
                                                                        min-width: 100%;
                                                                    }
                                                                    .category-item {
                                                                        @media screen and (min-width: $bp-large) {
                                                                            margin-left: 0;
                                                                            padding-left: 20px;
                                                                        }
                                                                        &:hover {
                                                                            @media screen and (min-width: $bp-large) {
                                                                                background-color: $p-red;
                                                                            }
                                                                        }
                                                                        a {
                                                                            &:hover {
                                                                                @media screen and (min-width: $bp-large) {
                                                                                    color: $white;
                                                                                }
                                                                            }
                                                                            span {
                                                                                text-decoration: none;
                                                                                @media screen and (max-width: $bp-xxlarge) and (min-width: $bp-xlarge) {
                                                                                    font-size: 15px;
                                                                                }
                                                                                @media screen and (min-width: $bp-xxlarge) {
                                                                                    font-size: 18px;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .root-sub-col-6 {
                                                @media screen and (min-width: $bp-large) {
                                                    display: flex;
                                                    justify-content: flex-end;
                                                }
                                                .level2-popup {
                                                    @media screen and (min-width: $bp-large) {
                                                        display: flex;
                                                        flex-direction: column;
                                                        width: 25%;
                                                    }
                                                }
                                            }
                                            .rootmegamenu_block {
                                                width: 100%;
                                                @media screen and (min-width: $bp-large) {
                                                    width: 50%;
                                                }
                                                div[data-appearance="contained"] {
                                                    width: 100%;
                                                    margin: 0;
                                                    .slick-list {
                                                        width: 100% !important;
                                                        .slick-track {
                                                            .block-title {
                                                                position: initial !important;
                                                                text-align: center;
                                                                border-bottom: none;
                                                                border-top: 1px
                                                                    solid $white;
                                                                margin: 0;
                                                                padding: 20px 0;
                                                                @media screen and (min-width: $bp-large) {
                                                                    padding: 0;
                                                                    text-align: left;
                                                                    margin-bottom: 15px;
                                                                }
                                                                strong {
                                                                    @include SourceSansBold;
                                                                    font-size: 18px;
                                                                    color: $white;
                                                                    margin: 0;
                                                                    text-transform: uppercase;
                                                                    @media screen and (min-width: $bp-large) {
                                                                        color: $p-black;
                                                                    }
                                                                }
                                                            }
                                                            .product-items {
                                                                margin: 0;
                                                                flex-wrap: wrap;
                                                                display: flex;
                                                                justify-content: space-between;
                                                                height: auto !important;
                                                                @media screen and (min-width: $bp-large) {
                                                                    justify-content: flex-start;
                                                                    height: 100% !important;
                                                                }
                                                                .product-item {
                                                                    padding: 20px
                                                                        0;
                                                                    background: $white;
                                                                    .discount-flag {
                                                                        width: 45px;
                                                                        height: 45px;
                                                                        margin: 10px;
                                                                        border-radius: 3em;
                                                                        overflow: hidden;
                                                                        position: absolute;
                                                                        z-index: 2;
                                                                        display: flex;
                                                                        justify-content: center;
                                                                        align-items: center;
                                                                        background: $hover;
                                                                        transform: rotate(
                                                                            -25deg
                                                                        );
                                                                        font-size: 18px;
                                                                        color: $white;
                                                                        @include PlayfairDisplayBold;
                                                                        top: -15px;
                                                                        left: -18px;
                                                                        @media screen and (min-width: $bp-medium) {
                                                                            width: 50px;
                                                                            height: 50px;
                                                                        }
                                                                        @media screen and (min-width: $bp-large) {
                                                                            left: -10px;
                                                                        }
                                                                        .discount {
                                                                            margin-top: -5px;
                                                                            @media screen and (min-width: $bp-medium) {
                                                                                margin-top: -6px;
                                                                            }
                                                                        }
                                                                        .percent {
                                                                            font-size: 10.5px;
                                                                            padding-top: 5px;
                                                                            @media screen and (min-width: $bp-medium) {
                                                                                font-size: 14px;
                                                                                padding-top: 0;
                                                                            }
                                                                        }
                                                                    }
                                                                    .product-item-info {
                                                                        .product-item-photo {
                                                                            width: 100%;
                                                                            display: block;
                                                                            text-align: center;
                                                                            background-color: transparent !important;
                                                                            .product-image-container {
                                                                            }
                                                                        }
                                                                        .product-item-details {
                                                                            .product-item-name {
                                                                                .product-item-link {
                                                                                    background-color: transparent !important;
                                                                                    padding: 0 !important;
                                                                                    font-size: 16px;
                                                                                    @include SourceSansRegular;
                                                                                    color: $p-black !important;
                                                                                    padding: 0
                                                                                        10px !important;
                                                                                }
                                                                            }
                                                                            .price-box {
                                                                                display: none;
                                                                            }
                                                                            .product-item-inner {
                                                                                display: none;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                .carousel-container {
                                                    padding: 20px 12px;
                                                    margin: 0;
                                                    width: 100%;
                                                    display: block;
                                                    @media screen and (min-width: $bp-large) {
                                                        padding: 0 10px;
                                                    }
                                                    .block-title {
                                                    }
                                                    .products {
                                                        .owl-stage-outer {
                                                            @media screen and (min-width: $bp-medium) {
                                                                position: relative !important;
                                                            }
                                                            .owl-stage {
                                                                width: 100% !important;
                                                                display: flex;
                                                                justify-content: center;
                                                                transform: none !important;
                                                                .owl-item {
                                                                    display: none;
                                                                    &:nth-child(
                                                                            -n
                                                                                + 2
                                                                        ) {
                                                                        display: block;
                                                                        padding: 0
                                                                            5px;
                                                                        width: calc(
                                                                            (
                                                                                    100% -
                                                                                        5%
                                                                                ) /
                                                                                2
                                                                        ) !important;
                                                                    }
                                                                    &:nth-child(
                                                                            -n
                                                                                + 3
                                                                        ) {
                                                                        @media screen and (min-width: $bp-large) {
                                                                            width: 150px !important;
                                                                            display: block;
                                                                        }
                                                                    }
                                                                    &:nth-child(
                                                                            2
                                                                        ),
                                                                    &:nth-child(
                                                                            3
                                                                        ) {
                                                                        @media screen and (min-width: $bp-large) {
                                                                            margin: 0
                                                                                30px;
                                                                            margin-top: 10px;
                                                                        }
                                                                    }
                                                                    @media screen and (min-width: $bp-large) {
                                                                        //display: block;
                                                                        position: relative !important;
                                                                        left: 0 !important;
                                                                        top: 0 !important;
                                                                        //width: calc((100% - 60%) / 2);
                                                                    }
                                                                    ol {
                                                                        padding: 0;
                                                                        margin: 0;
                                                                        position: relative !important;
                                                                        left: 0 !important;
                                                                        top: 0 !important;
                                                                        .item {
                                                                            width: 100%;
                                                                            margin-bottom: 0;

                                                                            .cd-gallery {
                                                                                li {
                                                                                    .product {
                                                                                        padding: 0 !important;
                                                                                        background: transparent;
                                                                                        img {
                                                                                            @media screen and (min-width: $bp-large) {
                                                                                                width: 140px;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                    .cd-item-info {
                                                                                        padding-top: 10px;
                                                                                        text-align: center;
                                                                                        height: 70px;
                                                                                        overflow: hidden;
                                                                                        font-size: 0;
                                                                                        line-height: normal;
                                                                                        .product-item-link {
                                                                                            background-color: transparent;
                                                                                            padding: 0 !important;
                                                                                            font-size: 16px;
                                                                                            @include SourceSansRegular;
                                                                                            @media screen and (min-width: $bp-large) {
                                                                                                color: $p-black;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                @media screen and (max-width: $bp-large) {
                                    .nav-11 {
                                        &:hover {
                                            a {
                                                background-color: $p-black !important;
                                                color: $hover !important;
                                            }
                                        }
                                        .active {
                                            background-color: $p-black !important;
                                            color: $hover !important;
                                        }
                                        a {
                                            background-color: $p-black !important;
                                            color: $hover !important;
                                            font-size: 18px;
                                        }
                                    }
                                }
                                .nav-2 {
                                    .megamenu {
                                        .root-col-4 {
                                            @media screen and (min-width: $bp-large) {
                                                width: 100% !important;
                                            }
                                            .nav-2-1 {
                                                a {
                                                    @media screen and (min-width: $bp-large) {
                                                        width: 20%;
                                                    }
                                                }
                                                .level3-popup {
                                                    @media screen and (min-width: $bp-large) {
                                                        display: flex !important;
                                                        flex-wrap: wrap;
                                                    }
                                                    .bullet-level4 {
                                                        a {
                                                            @media screen and (min-width: $bp-large) {
                                                                width: 100%;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .nav-11 {
                                    a {
                                        @media screen and (min-width: $bp-large) {
                                            color: $hover !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .page-main {
            padding-left: 0;
            padding-right: 0;
            max-width: 100%;
        }
        .page-footer {
            .footer {
                .block {
                    .content {
                        .form {
                            .field {
                                .control {
                                    label {
                                        .mage-error {
                                            display: block;
                                            font-size: 14px;
                                            text-align: center;
                                            width: auto;
                                            padding: 5px 20px;
                                            border: 1px dashed red;
                                            box-sizing: border-box;
                                            background: $white;
                                            @include SourceSansRegular;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.cms-home {
    .page-header {
        background-color: transparent !important;

        .header {
            .header-right {
                .mobile-right-menu {
                    #right-menu {
                        background-color: rgba(0, 0, 0, 0.75);
                    }
                }
            }
        }
    }
    .sections {
        .section-items {
            .section-item-content {
                .page-main {
                    .rootmenu {
                        background-color: rgba(0, 0, 0, 0.75) !important;
                    }
                }
            }
        }
    }

    .page-main {
        margin: 0;
        padding: 0;
        max-width: 100%;
        margin-top: -71px;
        @media screen and (min-width: $bp-large) {
            margin-top: -120px;
        }
        @media screen and (min-width: $bp-xlarge) {
            margin-top: -169px;
        }
        .page.messages {
            position: absolute;
            width: 100%;
            .messages {
                .message {
                    z-index: 9;
                    top: 70px;
                    @media screen and (min-width: $bp-large) {
                        top: 120px;
                    }
                    @media screen and (min-width: $bp-xlarge) {
                        top: 169px;
                    }
                }
            }
        }
        .columns {
            .column {
                padding-bottom: 10px;
                overflow: hidden;

                .owl-stage-outer {
                    img {
                        @media screen and (min-width: $bp-large) {
                            object-fit: cover;
                        }
                    }
                }
                .owl-controls {
                    top: 50%;
                    height: 50%;
                    .owl-nav {
                        .owl-prev,
                        .owl-next {
                            background: url(../images/icons/icon-prev.svg);
                            content: "";
                            width: 50px;
                            height: 55px;
                            background-repeat: no-repeat;
                            padding: 0;
                            margin: 0;
                            background-position: center;
                            background-color: rgba(0, 0, 0, 0.25);
                            border-radius: 0;

                            &::after {
                                display: none;
                            }

                            &:hover {
                                background: url(../images/icons/icon-next.svg);
                                content: "";
                                width: 50px;
                                height: 55px;
                                background-repeat: no-repeat;
                                padding: 0;
                                margin: 0;
                                background-position: center;
                                background-color: $p-black;
                                border-radius: 0;
                            }
                        }

                        .owl-next {
                            transform: rotate(-180deg);

                            &:hover {
                                transform: rotate(0deg);
                            }
                        }

                        .owl-prev {
                            &:hover {
                                transform: rotate(-180deg);
                            }
                        }
                    }
                }

                .owl-ext-banner {
                    .top-slider {
                        .owl-stage-outer * {
                            @media screen and (min-width: $bp-large) {
                                height: 100vh;
                            }
                        }
                        .owl-stage-outer {
                            .owl-stage {
                                .owl-item {
                                    .item {
                                        img {
                                            object-fit: cover;
                                            height: 100vh;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .owl-carousel {
                        .owl-controls {
                            .owl-dots {
                                left: 0;
                                right: 0;
                                top: auto;
                                bottom: 0;

                                .owl-dot.active {
                                    span {
                                        background: $hover;
                                    }
                                }
                            }
                        }
                    }
                }

                .widget {
                    margin-bottom: 0;

                    .main-container {
                        @media screen and (min-width: $bp-medium) {
                            display: flex;
                            justify-content: center;
                        }

                        .img-container {
                            margin-top: 10px;
                            @media screen and (min-width: $bp-medium) {
                                display: none;
                                &:nth-child(-n + 2) {
                                    display: block;
                                    width: 100%;
                                }
                                &:nth-child(1) {
                                    margin-right: 10px;
                                }
                            }
                            @media screen and (min-width: $bp-large) {
                                &:nth-child(-n + 3) {
                                    display: block;
                                    width: 100%;
                                }
                                &:nth-child(3) {
                                    margin-left: 10px;
                                }
                            }
                        }
                    }

                    .steps {
                        background-color: $p-red;
                        padding: 30px 20px;
                        @media screen and (min-width: $bp-medium) {
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            grid-template-rows: 1fr 1fr;
                        }
                        @media screen and (min-width: $bp-large) {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr 1fr;
                            grid-template-rows: 1fr;
                            grid-column-gap: 35px;
                            padding: 40px 30px;
                        }

                        .container {
                            display: grid;
                            grid-template-columns: 25% 75%;
                            align-items: center;
                            margin-bottom: 20px;

                            &::after,
                            &::before {
                                display: none;
                            }

                            @media screen and (min-width: $bp-medium) {
                                margin-bottom: 30px;
                            }
                            @media screen and (min-width: $bp-large) {
                                margin-bottom: 0;
                                grid-template-columns: 25% 82%;
                            }

                            .img {
                                margin-right: 20px;
                                text-align: center;
                            }

                            .text-container {
                                .text {
                                    margin: 0;
                                    color: $white;
                                    @include SourceSansRegular;
                                }

                                .title {
                                    font-size: 22px;
                                    text-transform: uppercase;
                                    @media screen and (min-width: $bp-medium) {
                                        text-transform: initial;
                                    }
                                }

                                .subtitle {
                                    font-size: 18px;
                                }
                            }
                        }
                    }
                }

                .carousel-container {
                    margin-left: auto;
                    margin-right: auto;
                    width: 100%;

                    .owl-stage-outer * {
                        @media screen and (min-width: $bp-large) {
                            height: auto;
                        }
                    }

                    .block-title {
                        h2 {
                            text-align: center;
                            font-size: 24px;
                            @include PlayfairDisplayBlack;
                            color: $p-black;
                            @media screen and (min-width: $bp-medium) {
                                font-size: 30px;
                            }
                        }
                    }

                    .products {
                        margin-top: 0;
                        margin-bottom: 0;
                        margin-left: 0;

                        .owl-stage-outer {
                            .owl-stage {
                                .owl-item {
                                    ol {
                                        padding-left: 0;
                                        .item {
                                            .discount-flag {
                                                width: 45px;
                                                height: 45px;
                                                margin: 10px;
                                                border-radius: 3em;
                                                overflow: hidden;
                                                position: absolute;
                                                z-index: 2;
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                background: $hover;
                                                transform: rotate(-25deg);
                                                font-size: 18px;
                                                color: $white;
                                                @include PlayfairDisplayBold;
                                                top: -15px;
                                                @media screen and (min-width: $bp-medium) {
                                                    font-size: 24px;
                                                    width: 60px;
                                                    height: 60px;
                                                }
                                                .discount {
                                                    margin-top: -5px;
                                                    @media screen and (min-width: $bp-medium) {
                                                        margin-top: -8px;
                                                    }
                                                }
                                                .percent {
                                                    font-size: 10.5px;
                                                    padding-top: 10px;
                                                    padding-top: 5px;
                                                    @media screen and (min-width: $bp-medium) {
                                                        font-size: 14px;
                                                    }
                                                }
                                            }

                                            .cd-gallery {
                                                margin-top: 0;
                                                padding: 0;
                                                margin: 0 15px;

                                                li {
                                                    .cd-item-info {
                                                        .product-item-link {
                                                            margin-top: 10px;
                                                            font-size: 16px;
                                                            @include SourceSansRegular;
                                                            color: $p-black;
                                                            height: 45px;
                                                            overflow: hidden;
                                                            display: block;
                                                            text-align: center;
                                                            @media screen and (min-width: $bp-medium) {
                                                                margin-top: 30px;
                                                                font-size: 20px;
                                                                height: 55px;
                                                            }
                                                            @media screen and (min-width: $bp-large) {
                                                                margin-top: 20px;
                                                            }
                                                        }

                                                        .cd-price {
                                                            color: $p-black;

                                                            .price-box {
                                                                height: 45px;
                                                                line-height: 20px;
                                                                text-align: center;
                                                                margin-bottom: 10px;
                                                                display: flex;
                                                                flex-direction: column;
                                                                justify-content: center;
                                                                @media screen and (min-width: $bp-medium) {
                                                                    line-height: 26px;
                                                                }

                                                                .text-old-price {
                                                                    display: none;
                                                                }

                                                                .price-container {
                                                                    .price-wrapper {
                                                                        .price {
                                                                            @include SourceSansBold;
                                                                            font-size: 18px;
                                                                            color: $p-black;
                                                                            @media screen and (min-width: $bp-medium) {
                                                                                font-size: 24px;
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                .old-price {
                                                                    .text-old-price {
                                                                        display: none;
                                                                    }

                                                                    .text {
                                                                        display: none;
                                                                    }

                                                                    .price-container {
                                                                        .price-wrapper {
                                                                            .price {
                                                                                @include SourceSansRegular;
                                                                                font-size: 18px;
                                                                                color: $p-black;
                                                                                @media screen and (min-width: $bp-medium) {
                                                                                    font-size: 24px;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                .special-price {
                                                                    .text-special-price {
                                                                        display: none;
                                                                    }

                                                                    .text {
                                                                        display: none;
                                                                    }
                                                                }

                                                                .normal-price {
                                                                    .text-special-price {
                                                                        display: none;
                                                                    }

                                                                    .price-container {
                                                                        .price-label {
                                                                            display: none; //se oculta ya que no va en boceto
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        .product {
                                                            height: 88px;
                                                            display: flex;
                                                            flex-direction: column;
                                                            justify-content: flex-end;

                                                            .product-reviews-summary {
                                                                display: flex;
                                                                justify-content: center;
                                                                margin: 0;

                                                                .rating-summary {
                                                                    margin: 0;
                                                                    height: 30px;
                                                                    left: 0;

                                                                    .rating-result {
                                                                        width: 125px;
                                                                        margin: 0;

                                                                        &::before {
                                                                            content: "";
                                                                            width: 100%;
                                                                            background-image: url("../images/empty-stars-small.svg");
                                                                            background-repeat: no-repeat;
                                                                        }

                                                                        span {
                                                                            &::before {
                                                                                position: sticky;
                                                                                content: "";
                                                                                background-image: url("../images/filled-stars-small.svg");
                                                                                background-repeat: no-repeat;
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                .reviews-actions {
                                                                    display: none;
                                                                }
                                                            }

                                                            .product {
                                                                text-align: center;
                                                                margin-bottom: 0;

                                                                .actions-primary {
                                                                    width: 100%;
                                                                    .stock.unavailable {
                                                                        height: 45px;
                                                                        display: flex;
                                                                        justify-content: center;
                                                                        align-items: center;
                                                                        padding: 7px
                                                                            10px;
                                                                        font-size: 16px;
                                                                        @include SourceSansRegular;
                                                                        border: 1px
                                                                            solid
                                                                            $p-red;
                                                                        @media screen and (min-width: $bp-medium) {
                                                                            font-size: 20px;
                                                                        }
                                                                        @media screen and (min-width: $bp-large) {
                                                                            height: 48px;
                                                                        }
                                                                        span {
                                                                            text-transform: uppercase;
                                                                            color: $p-red;
                                                                        }
                                                                    }

                                                                    form {
                                                                        .action {
                                                                            @include btn-red;
                                                                            font-size: 16px;
                                                                            height: 45px;
                                                                            padding: 7px
                                                                                10px;
                                                                            width: 100%;
                                                                            @media screen and (min-width: $bp-medium) {
                                                                                font-size: 20px;
                                                                            }

                                                                            span {
                                                                                text-transform: uppercase;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .owl-controls {
                            left: 0;
                            top: 26%;
                            width: 100%;
                            @media screen and (min-width: 424px) {
                                top: 33%;
                            }
                            @media screen and (min-width: $bp-medium) {
                                left: 0;
                                top: 38%;
                                width: 100%;
                            }
                            @media screen and (min-width: $bp-large) {
                                top: 42%;
                            }

                            .owl-nav {
                                .owl-prev,
                                .owl-next {
                                    background: url(../images/icons/icon-arrow-izq-oscura.svg);
                                    content: "";
                                    width: 50px;
                                    height: 55px;
                                    background-repeat: no-repeat;
                                    padding: 0;
                                    margin: 0;
                                    background-position: center;
                                    background-color: rgba(255, 255, 255, 0.25);
                                    border-radius: 0;

                                    &::after {
                                        display: none;
                                    }

                                    &:hover {
                                        background: url(../images/icons/icon-arrow.svg);
                                        content: "";
                                        width: 50px;
                                        height: 55px;
                                        background-repeat: no-repeat;
                                        padding: 0;
                                        margin: 0;
                                        background-position: center;
                                        background-color: $white;
                                        border-radius: 0;
                                    }
                                }

                                .owl-next {
                                    transform: rotate(-180deg);

                                    &:hover {
                                        transform: rotate(0deg);
                                    }
                                }

                                .owl-prev {
                                    &:hover {
                                        transform: rotate(-180deg);
                                    }
                                }
                            }
                        }
                    }
                }

                .blog__main-recent {
                    .block-title {
                        text-align: center;
                        margin: 20px 0;

                        h2 {
                            @include PlayfairDisplayBlack;
                            font-size: 24px;
                            color: $p-black;
                            @media screen and (min-width: $bp-medium) {
                                font-size: 30px;
                            }
                        }
                    }
                }
            }
        }

        .brands {
            padding: 20px 10px;
            @media screen and (min-width: $bp-large) {
                padding: 20px 40px;
            }
            .title {
                p {
                    @include PlayfairDisplayBlack;
                    font-size: 24px;
                    color: $p-black;
                    text-align: center;
                    margin-bottom: 20px;
                    @media screen and (min-width: $bp-medium) {
                        font-size: 30px;
                    }
                }
            }

            .brands-img {
                display: flex;
                justify-content: center;
                align-items: center;
                .uk-slider-items {
                    width: 100%;
                    align-items: center;
                    .uk-text-center {
                        img {
                            width: 100%;
                            height: 100%;
                            max-width: 110px;
                            max-height: 60px;
                            object-fit: contain;
                            @media screen and (min-width: $bp-medium) {
                                max-width: 182px;
                                max-height: 86px;
                            }
                            @media screen and (min-width: $bp-large) {
                                max-height: 100px;
                                max-width: 200px;
                            }
                        }
                    }
                }
                .uk-slidenav {
                    margin: 15px 0;
                    background-color: hsla(0, 0%, 100%, 0.25);
                    &:hover {
                        background-color: $white;
                    }
                }
                .uk-slidenav:focus,
                .uk-slidenav:hover,
                .uk-slidenav:visited {
                    color: $p-black !important;
                }

                .img {
                    text-align: center;
                    margin: 0 10px;
                    @media screen and (min-width: $bp-medium) {
                        margin: 0 20px;
                    }
                }

                .img:nth-child(n + 4) {
                    display: none;
                }

                @media screen and (min-width: $bp-medium) {
                    .img:nth-child(4) {
                        display: block;
                    }
                }
                @media screen and (min-width: $bp-large) {
                    .img:nth-child(5) {
                        display: block;
                    }
                    .img:nth-child(6) {
                        display: block;
                    }
                }
            }
        }
    }

    .page-bottom {
        @media screen and (min-width: $bp-large) {
            max-width: 100%;
            margin-left: inherit;
            margin-right: inherit;
        }
    }
}

//Logout page

.customer-account-logoutsuccess {
    .page-main {
        .page-title-wrapper {
            margin-top: 25px;
            .block-title {
                h1 {
                    color: $p-black;
                    font-size: 40px;
                    @include SourceSansRegular;
                    margin: 0 15px;
                }
            }
        }
        .columns {
            .column {
                p {
                    font-size: 16px;
                    color: $p-black;
                    @include SourceSansRegular;
                    margin: 0 15px;
                }
            }
        }
    }
}
