.blog__post-list {
  .grid-gallery {
    display: grid;
    grid-auto-rows: 200px;
    grid-gap: 0;
    grid-auto-flow: row dense;
    @media all and (min-width: $bp-xxsmall) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media all and (min-width: $bp-medium) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media all and (min-width: $bp-large) {
      grid-template-columns: repeat(5, 1fr);
    }

    .grid-gallery__item {
      .blog__post-item {
        position: relative;
        height: 100%;
        overflow: hidden;

        .blog__post-header {
          display: none;
          position: absolute;
          height: 90%;
          width: 100%;
          z-index: 1;

          h3 {
            border: 1px solid white;
            height: 100%;
            margin: 0 20px;

            a {
              display: flex;
              justify-content: center;
              align-items: center;
              text-shadow: 0 0 3px $p-black;
              @include PlayfairDisplayBold;
              font-size: 20px;
              font-weight: bold;
              font-stretch: normal;
              font-style: italic;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              color: $white;
              height: 100%;
              width: 100%;
            }
          }
        }

        .img-container {
          height: 100%;
          width: 100%;
          .grid-gallery__image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: .5s display;
          }
        }

        &:hover {
          .blog__post-header {
            display: block;
          }
          .img-container {
            filter: blur(2px);
            transform: scale(1.1);
            .grid-gallery__image {
              filter: grayscale(1);
            }
          }
        }
      }

      &:nth-child(11n+1) {
        grid-column: span 2;
        grid-row: span 1;
        @media all and (min-width: $bp-medium) {
          grid-column: span 1;
          grid-row: span 2;
        }
        @media all and (min-width: $bp-large) {
          grid-column: span 1;
          grid-row: span 2;
        }
      }

      &:nth-child(11n+2) {
        grid-column: span 1;
        grid-row: span 2;
        @media all and (min-width: $bp-medium) {
          grid-column: span 2;
          grid-row: span 1;
        }
        @media all and (min-width: $bp-large) {
          grid-column: span 2;
          grid-row: span 1;
        }
      }

      &:nth-child(11n+3) {
        grid-column: span 1;
        grid-row: span 1;
        @media all and (min-width: $bp-medium) {
          grid-column: span 1;
          grid-row: span 1;
        }
      }

      &:nth-child(11n+4) {
        grid-column: span 1;
        grid-row: span 1;
        @media all and (min-width: $bp-medium) {
          grid-column: span 1;
          grid-row: span 1;
        }
      }

      &:nth-child(11n+5) {
        display: none;
        @media all and (min-width: $bp-medium) {
          display: block;
          grid-column: span 2;
          grid-row: span 1;
        }
        @media all and (min-width: $bp-large) {
          grid-column: span 1;
          grid-row: span 1;
        }
      }

      &:nth-child(11n+6) {
        display: none;
        @media all and (min-width: $bp-medium) {
          display: block;
          grid-column: span 1;
          grid-row: span 1;
        }
      }

      &:nth-child(11n+7) {
        display: none;
        @media all and (min-width: $bp-large) {
          grid-column: span 2;
          grid-row: span 2;
          display: block;
        }
      }

      &:nth-child(11n+8) {
        display: none;
        @media all and (min-width: $bp-large) {
          grid-column: span 2;
          grid-row: span 1;
          display: block;
        }
      }

      &:nth-child(11n+9) {
        display: none;
        @media all and (min-width: $bp-large) {
          display: block;
          grid-column: span 1;
          grid-row: span 1;
        }
      }
    }
  }
}