.product-carousel {
    .row-full-width-inner {
        max-width: 100%;
    }
    h1,
    h2,
    h3.h4.h5,
    h6 {
        margin-top: 40px;
        text-align: center;
        font-size: 24px;
        @include PlayfairDisplayBlack;
        color: $p-black;
        @media screen and (min-width: $bp-medium) {
            font-size: 30px;
        }
    }
    .product-items {
        .slick-arrow {
            background: none !important;
            &.slick-prev {
                left: 0;
            }
            &.slick-next {
                right: 0;
            }
        }
        .slick-list {
            .slick-slide {
                .product-item {
                    padding: 0 20px;
                    .product-item-info {
                        .product-item-photo {
                            display: block;
                            text-align: center;
                            .product-image-container {
                                .product-image-wrapper {
                                    .product-image-photo {
                                        height: auto;
                                    }
                                }
                            }
                        }
                        .product-item-details {
                            .product-item-name {
                                .product-item-link {
                                    margin-top: 10px;
                                    font-size: 16px;
                                    @include SourceSansRegular;
                                    color: $p-black;
                                    height: 45px;
                                    overflow: hidden;
                                    display: block;
                                    text-align: center;
                                    @media screen and (min-width: $bp-medium) {
                                        margin-top: 30px;
                                        font-size: 20px;
                                        height: 55px;
                                    }
                                    @media screen and (min-width: $bp-large) {
                                        margin-top: 20px;
                                    }
                                }
                            }
                            .price-box {
                                height: 45px;
                                line-height: 20px;
                                text-align: center;
                                margin-bottom: 10px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                @media screen and (min-width: $bp-medium) {
                                    line-height: 26px;
                                }

                                .text-old-price {
                                    display: none;
                                }

                                .price-container {
                                    .price-wrapper {
                                        .price {
                                            @include SourceSansBold;
                                            font-size: 18px;
                                            color: $p-black;
                                            @media screen and (min-width: $bp-medium) {
                                                font-size: 24px;
                                            }
                                        }
                                    }
                                }

                                .old-price {
                                    .text-old-price {
                                        display: none;
                                    }

                                    .text {
                                        display: none;
                                    }

                                    .price-container {
                                        .price-wrapper {
                                            .price {
                                                @include SourceSansRegular;
                                                font-size: 18px;
                                                color: $p-black;
                                                @media screen and (min-width: $bp-medium) {
                                                    font-size: 24px;
                                                }
                                            }
                                        }
                                    }
                                }

                                .special-price {
                                    .text-special-price {
                                        display: none;
                                    }

                                    .text {
                                        display: none;
                                    }
                                }

                                .normal-price {
                                    .text-special-price {
                                        display: none;
                                    }

                                    .price-container {
                                        .price-label {
                                            display: none; //se oculta ya que no va en boceto
                                        }
                                    }
                                }
                            }
                            .product-item-inner {
                                .product-item-actions {
                                    .actions-primary {
                                        width: 100%;
                                        .stock.unavailable {
                                            height: 45px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            padding: 7px 10px;
                                            font-size: 16px;
                                            @include SourceSansRegular;
                                            border: 1px solid $p-red;
                                            @media screen and (min-width: $bp-medium) {
                                                font-size: 20px;
                                            }
                                            @media screen and (min-width: $bp-large) {
                                                height: 48px;
                                            }
                                            span {
                                                text-transform: uppercase;
                                                color: $p-red;
                                            }
                                        }

                                        form {
                                            .action {
                                                @include btn-red;
                                                font-size: 16px;
                                                height: 45px;
                                                padding: 7px 10px;
                                                width: 100%;
                                                @media screen and (min-width: $bp-medium) {
                                                    font-size: 20px;
                                                }

                                                span {
                                                    text-transform: uppercase;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
