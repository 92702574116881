//Menu mobile-tablet override

@media screen and (min-width: $bp-medium) and (max-width: $bp-large) {
    .nav-sections {
        -webkit-overflow-scrolling: touch;
        transition: left 0.3s;
        height: 100%;
        left: -80%;
        left: calc(-1 * (100%));
        overflow: auto;
        position: fixed;
        top: 0;
        width: 47% !important;

        .section-items {
            .section-item-title {
                display: block;
                float: left;
                text-align: center;
            }
        }
        .section-item-content {
            .page-main {
                .rootmenu {
                    .mobile-sub {
                        display: block;
                        .category-item {
                            position: relative;
                            display: block;
                            text-align: left;
                            a {
                                padding-left: 25px;
                            }
                            .rootmenu-click {
                                display: block;
                            }
                            .has-sub-cat {
                                border-top: none;
                                text-align: left;
                                padding-left: 25px;
                                &::after {
                                    display: none;
                                }
                            }
                            .halfmenu,
                            .rootmenu-submenu,
                            .megamenu {
                                position: relative;
                                width: 100%;
                                opacity: 1;
                                visibility: visible;
                                transform: translateY(0);
                                display: none;
                                .clearfix {
                                    .clearfix {
                                        width: 100%;
                                        .clearfix {
                                            width: 100%;
                                            .category-item {
                                                display: flex;
                                                flex-direction: column;
                                                a {
                                                    .cat-arrow {
                                                        display: none;
                                                    }
                                                }
                                                .level3-popup {
                                                    position: relative;
                                                    left: 0;
                                                    top: 0;
                                                    display: none;
                                                    background-color: transparent;
                                                    border: none;
                                                    box-shadow: none;
                                                    padding-left: 20px;
                                                    .category-item {
                                                        a {
                                                            float: none;
                                                        }
                                                    }
                                                }
                                                .level4-popup {
                                                    position: static;
                                                    background-color: transparent;
                                                    border: none;
                                                    box-shadow: none;
                                                    display: none;
                                                    padding-left: 20px;
                                                }
                                            }
                                        }
                                    }
                                    .rootmegamenu_block {
                                        .carousel-container {
                                            .products {
                                                height: 100% !important;
                                                ol {
                                                    position: relative !important;
                                                    left: 0 !important;
                                                }
                                            }
                                        }
                                    }
                                }
                                .category-item {
                                    a {
                                        .cat-arrow {
                                            display: none;
                                        }
                                    }
                                    .rootmenu-submenu-sub {
                                        position: relative;
                                        left: 0;
                                        display: none;
                                        .category-item {
                                            .rootmenu-submenu-sub-sub {
                                                display: none;
                                                position: static;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .nav-open .nav-sections {
        box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
        left: 0;
        z-index: 99;
    }
    html.nav-before-open {
        overflow: hidden;
    }
    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;
    }
    .nav-before-open body {
        height: 100%;
        overflow: hidden;
        position: relative;
        width: 100%;
    }
    .nav-open .page-wrapper {
        left: calc(100% - 55px);
        overflow: visible !important;
        @media screen and (min-width: $bp-medium) {
            left: calc(100% - 408px);
        }
    }
    .nav-before-open .page-wrapper {
        transition: left 0.3s;
        height: 100%;
        //overflow: hidden;
        position: relative;
    }
}

html.nav-before-open {
    @media screen and (min-width: $bp-large) {
        overflow: initial;
    }
}

//styles menu

.ves-megamenu li.level0 > a {
    text-transform: capitalize !important;
}

.sections {
    margin-bottom: 0 !important;
    @media screen and (max-width: $bp-medium) {
        background-color: black;
    }
    @media screen and (min-width: $bp-large) {
        background: transparent;
    }

    .section-items {
        .section-item-title {
            /*border-width: 0;
      background: transparent;
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 20px;
      @media screen and (min-width: $bp-medium) {
        float: none;
        padding: 20px;
      }
      @media screen and (min-width: $bp-large) {
        display: none;
      }
      .nav-sections-item-switch {
        color: $white;
        padding-left: 10px;
        font-size: 18px;
      }*/
            display: none;
        }

        .section-item-content {
            .page-main {
                margin-top: 0;
            }
            /*padding: 0;
      @media screen and (max-width: $bp-medium) {
        margin-top: 10px;
      }

      .block {
        padding-top: 0;
        position: static;
        top: 70px;
        width: 100%;

        .navigation {
          @media screen and (min-width: $bp-large) {
            max-width: 100%;
          }

          .ves-megamenu {
            background-color: $p-black;
            @media screen and (min-width: $bp-medium) {
              display: flex;
              flex-direction: column;
            }
            @media screen and (min-width: $bp-large) {
              display: flex;
              flex-direction: row;
              justify-content: center;
              background: rgba(0, 0, 0, 0.75);
              position: initial;
            }

            .nav-item {
              border-bottom: none;
              border-top: none;
              @media screen and (min-width: $bp-medium) {
                margin: 0;
              }
              @media screen and (min-width: $bp-large) {
                position: static;
              }

              .nav-anchor {
                @include SourceSansRegular;
                font-size: 18px;
                color: $white;
                @media screen and (min-width: $bp-medium) {
                  width: 100%;
                }
                @media screen and (min-width: $bp-large) {
                  color: $white;
                }

                &:hover {
                  @media screen and (min-width: $bp-large) {
                    background: $white;
                    color: $p-black !important;
                  }
                }

                .opener {
                  padding: 15px 20px;
                  @media screen and (min-width: $bp-medium) {
                    display: block;
                  }
                  @media screen and (min-width: $bp-large) {
                    display: none;
                  }
                }
              }

              &.active > a {
                background-color: $p-blackred;
                border-color: transparent;
                @media screen and (min-width: $bp-large) {
                  background-color: $white;
                  color: $p-black !important;
                }
              }

              .ui-state-focus {
                background-color: $p-blackred;
                @media screen and (min-width: $bp-large) {
                  background-color: $white;
                  color: $p-black;
                }
              }

              .submenu {
                background-color: $p-blackred;
                @media screen and (min-width: $bp-medium) {
                  top: auto !important;
                  position: relative;
                  overflow-x: hidden;
                  width: 100% !important;
                  border: 0;
                  padding: 0;
                }
                @media screen and (min-width: $bp-large) {
                  position: absolute;
                  top: 45px !important;
                  width: auto;
                  background-color: $white;
                  overflow: visible;
                }

                .content-wrap {
                  @media screen and (min-width: $bp-large) {
                    display: flex;
                  }

                  .megamenu-content {
                    @media screen and (max-width: $bp-medium) {
                      padding-bottom: 20px;
                    }
                    @media screen and (min-width: $bp-large) {
                      width: 50%;
                      display: flex;
                    }

                    .level1 {
                      @media screen and (min-width: $bp-medium) {
                        display: flex;
                        flex-direction: column;
                      }
                      @media screen and (min-width: $bp-medium) {
                        width: 100%;
                      }

                      .row {
                        padding: 0;
                        @media screen and (min-width: $bp-large) {
                          width: 100%;
                          display: flex;
                          justify-content: flex-end;
                        }

                        .mega-col {
                          @media screen and (min-width: $bp-medium) {
                            width: 100%;
                          }
                          @media screen and (min-width: $bp-large) {
                            width: 34%;
                          }

                          .nav-item {
                            padding: 0;
                            @media screen and (min-width: $bp-large) {
                              line-height: 50px;
                            }

                            .nav-anchor {
                              padding-left: 40px;
                              background-color: $p-blackred;
                              @media screen and (min-width: $bp-large) {
                                background-color: $white;
                                color: $p-black;
                                padding: 0;
                                font-weight: bold;
                              }

                              .opener {
                                @media screen and (min-width: $bp-medium) {
                                  right: 0;
                                }
                              }
                            }

                            .submenu {
                              @media screen and (min-width: $bp-medium) {
                                left: 0 !important;
                                box-shadow: none;
                              }
                              @media screen and (min-width: $bp-large) {
                                overflow-x: visible;
                                left: 10% !important;
                                box-shadow: none;
                                top: auto !important;
                                position: initial;
                              }

                              .content-wrap {
                                .megamenu-content {
                                  .level2 {
                                    .item-content2,
                                    .item-content1 {
                                      padding-left: 0;

                                      .nav-item {
                                        .nav-anchor {
                                          @media screen and (min-width: $bp-medium) {
                                            padding-left: 60px;
                                          }
                                          @media screen and (min-width: $bp-large) {
                                            font-weight: normal;
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }

                      .item-content1,
                      .item-content2 {
                        padding-left: 40px;
                        @media screen and (min-width: $bp-large) {
                          padding-left: 20px;
                        }

                        .nav-item {
                          padding-left: 0;
                          @media screen and (min-width: $bp-medium) {
                            margin: 0;
                          }
                          @media screen and (min-width: $bp-large) {
                            padding-left: 0;
                          }

                          .nav-anchor {
                            @media screen and (min-width: $bp-medium) {
                              padding-left: 0;
                            }
                            @media screen and (min-width: $bp-large) {
                              color: $p-black;
                            }
                          }
                        }
                      }
                    }
                  }

                  .megamenu-sidebar {
                    display: block;
                    @media screen and (min-width: $bp-large) {
                      display: flex;
                      margin-right: 25px;
                    }

                    .block {
                      margin-bottom: 20px;

                      .block-title {
                        @media screen and (max-width: $bp-medium) {
                          display: block;
                          text-align: center;
                          width: 90%;
                          margin: 0 auto 20px;
                          border-top: 1px solid white;
                          padding-top: 20px;
                        }
                        @media screen and (min-width: $bp-large) {
                          margin-bottom: 0;
                          margin-top: 8px;
                        }

                        strong {
                          @include SourceSansBold;
                          font-size: 18px;
                          color: $white;
                          text-transform: uppercase;
                          @media screen and (min-width: $bp-large) {
                            color: $p-black;
                            line-height: 50px;
                          }
                        }
                      }

                      .block-content {
                        .products-grid {
                          .product-items {
                            @media screen and (max-width: $bp-medium) {
                              display: flex;
                              justify-content: space-between;
                              padding: 0 20px;
                            }
                            @media screen and (min-width: $bp-large) {
                              display: flex;
                              justify-content: flex-start;
                            }

                            .product-item {
                              @media screen and (max-width: $bp-medium) {
                                box-sizing: content-box;
                                display: flex;
                                width: calc((100% / 2) - 5px);
                                border-bottom: none;
                              }
                              @media screen and (min-width: $bp-large) {
                                width: 30%;
                              }

                              &:nth-child(2n + 1) {
                                @media screen and (max-width: $bp-medium) {
                                  margin-left: 0;
                                }
                              }

                              &:nth-child(3) {
                                @media screen and (max-width: $bp-medium) {
                                  display: none;
                                }
                              }

                              .product-item-info {
                                .product-item-photo {
                                  .product-image-container {
                                    @media screen and (max-width: $bp-medium) {
                                      max-width: 100%;
                                      width: 100% !important;
                                    }

                                    .product-image-wrapper {
                                      padding-bottom: 100% !important;
                                    }
                                  }
                                }

                                .product-item-details {
                                  .product-item-name {
                                    @media screen and (min-width: $bp-large) {
                                      text-align: center;
                                    }

                                    .product-item-link {
                                      @include SourceSansRegular;
                                      font-size: 16px;
                                      color: $white;
                                      text-align: center;
                                      @media screen and (min-width: $bp-large) {
                                        color: $p-black;
                                      }
                                    }
                                  }

                                  .price-final_price {
                                    display: none;
                                  }

                                  .product-item-inner {
                                    display: none;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              &.level-0 {
                > a {
                  text-transform: capitalize;
                }
              }
            }

            .ofertas {
              .nav-anchor {
                span {
                  color: $hover;
                }
              }
            }
          }
        }
      }*/

            .header {
                background-color: $p-blackred;

                li {
                    a {
                        @include SourceSansRegular;
                        font-size: 18px;
                        color: $white;
                        padding-left: 20px;
                        border-top: none;
                    }
                }
            }
        }
    }
}

.messages-container {
    z-index: 2;
    position: absolute;
    top: 145px;
    width: 100%;
    @media screen and (min-width: $bp-medium) {
        top: 115px;
    }
    @media screen and (min-width: $bp-large) {
        top: 165px;
    }
    @media screen and (min-width: $bp-xlarge) {
        top: 215px;
    }
    // @media screen and (min-width: 1920px) {
    //   top: 215px;
    // }
}
