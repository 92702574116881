.blog,
.blog-search-result {
    .page-wrapper {
        .page-header {
            background-color: transparent;
        }

        .sections {
            .section-items {
                .section-item-content {
                    .page-main {
                        .rootmenu {
                            background-color: rgba(0, 0, 0, 0.75);
                        }
                    }
                }
            }
        }

        .breadcrumbs {
            display: none;
        }

        .page-main {
            .image-view {
                .image-container {
                    position: relative;
                    height: calc(100vh - 43.75vh);
                    overflow: hidden;
                    margin-top: -111px;
                    height: 100%;
                    width: 100%;
                    @media screen and (min-width: $bp-medium) {
                        margin-top: -70px;
                    }
                    @media screen and (min-width: $bp-large) {
                        margin-top: -169px;
                    }

                    h1 {
                        position: absolute;
                        left: 0;
                        top: 50px;
                        color: white;
                        right: 0;
                        text-align: center;
                        font-size: 40px;
                        @include PlayfairDisplayBold;
                        margin: 0;
                        bottom: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
                        @media screen and (min-width: $bp-large) {
                            font-size: 60px;
                            top: 15%;
                        }
                    }

                    .img {
                        object-fit: contain;
                        height: 100%;
                        max-width: none;
                        width: auto;
                        @media screen and (min-width: $bp-medium) {
                            object-fit: cover;
                            width: 100%;
                        }
                    }
                }
            }

            .page-title-wrapper {
                display: none;

                .block-title {
                    .page-title {
                        margin: 0 0 15px;

                        span {
                            @include PlayfairDisplayBlack;
                            font-size: 24px;
                            color: $p-black;
                        }
                    }
                }
            }

            .blog__sidebar-search {
                padding: 40px 20px;
                margin-bottom: 0;
                background: #ebebeb;
                @media screen and (min-width: $bp-large) {
                    padding: 50px 18% 60px;
                }

                form {
                    .input-container {
                        border-bottom: 3px solid;

                        .input-text {
                            border: none;
                            background: #ebebeb;
                            text-align: center;
                            padding-bottom: 15px;
                            @media screen and (min-width: $bp-large) {
                                height: 50px;
                                padding-left: 0;
                                padding-right: 0;
                            }

                            &::placeholder {
                                color: $p-black;
                                @include SourceSansRegular;
                                font-size: 18px;
                                @media screen and (min-width: $bp-large) {
                                    font-size: 30px;
                                }
                            }
                        }

                        .action {
                            border: none;
                            background: #ebebeb;
                            top: -10px;
                        }
                    }
                }
            }

            .columns {
                padding: 20px 20px 0;
                @media screen and (min-width: $bp-large) {
                    margin-top: 60px;
                }

                .column {
                    padding-bottom: 0;
                    @media screen and (min-width: $bp-medium) {
                        width: 70%;
                    }
                    @media screen and (min-width: $bp-large) {
                        width: 80%;
                    }

                    .toolbar {
                        display: flex;
                        align-items: flex-end;
                        margin-bottom: 20px;

                        .toolbar-amount {
                            color: $p-black;
                            font-size: 14px;
                            @include SourceSansRegular;
                            line-height: normal;
                        }

                        .pages {
                            display: none;
                        }

                        .limiter {
                            margin-left: 15px;
                            display: flex;
                            align-items: flex-end;

                            #limiter {
                                background: #ebebeb;
                                border: none;
                                width: 60px;
                                height: 24px;
                                padding: 0 10px;
                            }

                            .limiter-label,
                            .limiter-text {
                                font-size: 14px;
                                color: $p-black;
                                @include SourceSansRegular;
                            }
                        }
                    }

                    .blog__post-list-wrapper {
                        .blog__post-list {
                            .blog__post-item {
                                @media screen and (min-width: $bp-large) {
                                    border: none;
                                    padding-bottom: 60px;
                                }

                                .blog__post-excerpt {
                                    display: flex;
                                    flex-direction: column;
                                    @include SourceSansRegular;
                                    font-size: 16px;
                                    color: $p-black;
                                    @media screen and (min-width: $bp-large) {
                                        flex-direction: row;
                                    }

                                    .img-container {
                                        img {
                                            max-width: 100%;
                                            padding: 0;
                                        }
                                    }

                                    p,
                                    h5 {
                                        @include SourceSansRegular;
                                        font-size: 16px;
                                        color: $p-black;
                                    }

                                    .read-more {
                                        @include SourceSansRegular;
                                        font-size: 14px;
                                        color: #d20615;
                                        text-decoration: underline;
                                    }

                                    div {
                                        iframe {
                                            width: 100%;
                                            height: 100%;
                                            @media screen and (min-width: $bp-medium) {
                                                height: 415px;
                                            }
                                        }
                                    }

                                    .blog__post-header {
                                        @media screen and (min-width: $bp-large) {
                                            margin-left: 15px;
                                            margin-right: 70px;
                                            width: 100%;
                                            border-bottom: 1px solid #a1a1a1;
                                            margin-bottom: 00px;
                                            font-size: 14px;
                                        }

                                        .blog__post-title {
                                            margin-top: 15px;
                                            @media screen and (min-width: $bp-large) {
                                                margin-top: 0;
                                                margin-bottom: 25px;
                                            }

                                            a {
                                                @include PlayfairDisplayBold;
                                                font-size: 26px;
                                                color: $p-black;
                                            }
                                        }

                                        .blog__post-meta {
                                            display: grid;
                                            grid-template-columns: 1fr;
                                            grid-template-rows: 1fr auto;
                                            margin: 0 0 20px;
                                            @media screen and (min-width: $bp-large) {
                                                grid-template-columns: 1fr auto;
                                                grid-template-rows: 1fr;
                                            }

                                            .meta-container {
                                                display: grid;
                                                grid-template-columns: auto 1fr;
                                                grid-template-rows: auto auto;
                                                grid-area: 1 / 1 / 2 / 2;

                                                .blog__post-meta-time,
                                                .blog__post-meta-difficult,
                                                .blog__post-meta-comments,
                                                .blog__post-meta-author {
                                                    display: flex;
                                                    align-items: center;
                                                    @include SourceSansRegular;
                                                    font-size: 12px;
                                                    color: $p-black;
                                                    margin-right: 45px;

                                                    .icon,
                                                    .fa-user {
                                                        margin-right: 5px;
                                                    }
                                                }

                                                .blog__post-meta-comments {
                                                    display: block;
                                                }

                                                .blog__post-meta-difficult {
                                                    align-items: end;
                                                }

                                                .blog__post-meta-author {
                                                    margin-top: 20px;

                                                    span {
                                                        a {
                                                            color: $p-black;
                                                        }
                                                    }
                                                }
                                            }

                                            .socials {
                                                grid-area: 2 / 1 / 3 / 2;
                                                margin-top: 20px;
                                                @media screen and (min-width: $bp-large) {
                                                    grid-area: 1 / 2 / 2 / 3;
                                                    margin-top: 0;
                                                    text-align: right;
                                                }

                                                .addthis_sharing_toolbox {
                                                    margin-left: 0;
                                                    @media screen and (min-width: $bp-large) {
                                                        margin: 0;
                                                    }
                                                }
                                            }
                                        }

                                        .blog__content {
                                            .content {
                                                font-size: 14px;
                                                line-height: 24px;
                                                @media screen and (min-width: $bp-large) {
                                                    overflow: hidden;
                                                    height: 70px;
                                                }
                                            }
                                        }
                                    }
                                }

                                .blog__post-footer {
                                    display: flex;
                                    @media screen and (min-width: $bp-large) {
                                        margin-bottom: 0;
                                    }

                                    .blog__post-footer-categories {
                                        @include SourceSansRegular;
                                        font-size: 14px;
                                        color: #a3a3a3;
                                        margin-left: 0;

                                        a {
                                            @include SourceSansRegular;
                                            font-size: 14px;
                                            color: #a3a3a3;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .blog__post-view {
                        .blog__post-meta {
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            margin: 0 0 20px;

                            .blog__post-meta-time,
                            .blog__post-meta-author,
                            .blog__post-meta-comments {
                                @include SourceSansRegular;
                                font-size: 16px;
                                color: $p-black;
                            }

                            .blog__post-meta-author {
                                span {
                                    a {
                                        color: $p-black;
                                    }
                                }
                            }
                        }

                        p {
                            @include SourceSansRegular;
                            font-size: 16px;
                            color: $p-black;
                        }

                        .blog__post-featured-image {
                            width: 100%;
                        }

                        .blog__post-footer {
                            .blog__post-footer-categories {
                                @include SourceSansRegular;
                                font-size: 16px;
                                color: $p-black;

                                a {
                                    @include SourceSansRegular;
                                    font-size: 16px;
                                    color: $p-black;
                                }
                            }
                        }
                    }

                    .blog__copyright-wrapper {
                        display: none;
                    }
                }

                .sidebar {
                    @media screen and (min-width: $bp-medium) {
                        width: 30%;
                    }
                    @media screen and (min-width: $bp-large) {
                        width: 20%;
                    }

                    .blog__sidebar-main {
                        .block.block-collapsible-nav {
                            margin-bottom: 40px;
                            position: initial;

                            .title {
                                margin-bottom: 15px;

                                strong {
                                    @include PlayfairDisplayBold;
                                    font-size: 25px;
                                }
                            }

                            .block-collapsible-content {
                                background-color: #ebebeb;
                                padding: 20px 20px 20px 0;

                                .nav {
                                    li {
                                        display: flex;
                                        align-items: center;
                                        padding-left: 20px;
                                        height: 50px;

                                        a {
                                            @include SourceSansRegular;
                                            font-size: 18px;
                                            color: $p-black;
                                        }
                                    }

                                    li.current {
                                        border-left: 8px solid #ad8334;
                                        padding-left: 12px;

                                        a {
                                            font-weight: 800;
                                        }
                                    }
                                }
                            }
                        }

                        .blog__sidebar-recent {
                            .block-title {
                                margin-bottom: 25px;

                                strong {
                                    @include PlayfairDisplayBold;
                                    color: $p-black;
                                    font-size: 22px;
                                }
                            }

                            .block-content {
                                .items {
                                    .item {
                                        display: flex;
                                        flex-direction: column;
                                        margin-bottom: 25px;

                                        a {
                                            .blog__post-featured-image {
                                                max-width: 100%;
                                                padding: 0;
                                            }
                                        }

                                        a:nth-child(2) {
                                            @include SourceSansBold;
                                            font-size: 16px;
                                            color: $p-black;
                                            line-height: 16px;
                                            padding: 15px;
                                            text-align: center;
                                            background-color: #ebebeb;
                                        }
                                    }
                                }
                            }
                        }

                        .blog__sidebar-tag-cloud {
                            display: none;
                        }

                        .blog__sidebar-search {
                            form {
                                display: flex;

                                .input-text {
                                    height: 45px;
                                    border-radius: 0;
                                    border: 1px solid $p-gray;
                                    box-shadow: none;
                                    font-size: 16px;

                                    &::placeholder {
                                        @include SourceSansRegular;
                                        font-size: 16px;
                                        color: $p-black;
                                    }
                                }

                                .action {
                                    position: relative;
                                    height: 45px;
                                    border-radius: 0;
                                    background: $p-red;
                                    border: 1px solid $p-red;
                                }
                            }
                        }

                        .block-collapsible-nav {
                            .title {
                                strong {
                                    @include SourceSansBold;
                                    font-size: 26px;
                                    color: $p-black;
                                }
                            }
                        }

                        .blog__sidebar-rss-link {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
