.cms-no-route {
    .page-wrapper {
        .page-main {
            padding: 0 15px;
            .page-title-wrapper {
                text-align: center;
                .block-title {
                    .page-title {
                        @media screen and (min-width: $bp-medium) {
                            margin-bottom: 25px;
                        }
                        .base {
                            @include PlayfairDisplayBlack;
                            font-size: 26px;
                            color: $p-black;
                            @media screen and (min-width: $bp-medium) {
                                font-size: 30px;
                            }
                        }
                    }
                }
            }
            .columns {
                @media screen and (min-width: $bp-medium) {
                    width: 85%;
                    margin: auto;
                }
                @media screen and (min-width: $bp-large) {
                    width: 45%;
                }
                .column {
                    @media screen and (min-width: $bp-medium) {
                        width: 100%;
                    }
                    .error-page {
                        .steps {
                            @media screen and (min-width: $bp-medium) {
                                margin-bottom: 40px;
                            }
                            @media screen and (min-width: $bp-large) {
                                padding-left: 10%;
                            }
                            li {
                                @include SourceSansRegular;
                                font-size: 18px;
                                color: $p-black;
                                margin-bottom: 0;
                                @media screen and (min-width: $bp-large) {
                                    padding-left: 10%;
                                    font-size: 16px;
                                }
                                a {
                                    color: $p-red;
                                    text-decoration: underline;
                                }
                            }
                        }
                        .btn {
                            text-align: center;
                            .button {
                                border-radius: 0;
                                background-color: $p-red;
                                border: 1px solid $p-red;
                                .action {
                                    color: $white;
                                    @include SourceSansRegular;
                                    text-transform: uppercase;
                                }
                            }
                        }
                        .img-container {
                            margin-top: 20px;
                            @media screen and (min-width: $bp-medium) {
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
}
