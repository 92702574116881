.multishipping-checkout-addresses {
    .page-wrapper {
        .page-header {
            .header {
                .nav-toggle {
                    display: none;
                }
            }
        }
        .page-main {
            padding: 0 20px;
            .page-title-wrapper {
                .block-title {
                    .page-title {
                        @media screen and (min-width: $bp-medium) {
                            margin: 20px 0;
                        }
                        span {
                            @include PlayfairDisplayBold;
                            font-size: 36px;
                            color: $p-black;
                        }
                    }
                }
            }
            .columns {
                .column {
                    .multicheckout {
                        .title {
                            margin: 0;
                            line-height: normal;
                            @media screen and (min-width: $bp-medium) {
                                margin-bottom: 20px;
                            }
                            strong {
                                @include SourceSansRegular;
                                font-size: 16px;
                                color: $p-black;
                            }
                        }
                        .table-wrapper {
                            .items {
                                thead {
                                    tr {
                                        th {
                                            @include PlayfairDisplayBold;
                                            font-size: 16px;
                                            color: $p-black;
                                            @media screen and (min-width: $bp-large) {
                                                border-bottom: 1px solid #4a4a4a;
                                            }
                                        }
                                    }
                                }
                                tbody {
                                    tr {
                                        border-bottom: 1px solid #4a4a4a;
                                        td {
                                            @media screen and (min-width: $bp-medium) {
                                                padding: 15px 10px;
                                            }
                                            strong {
                                                a {
                                                    @include SourceSansRegular;
                                                    font-size: 18px;
                                                    color: $p-black;
                                                }
                                            }
                                            &::before {
                                                @include PlayfairDisplayBold;
                                                font-size: 16px;
                                                color: $p-black;
                                            }
                                        }
                                        .col.qty {
                                            .field.qty {
                                                .control {
                                                    .input-text {
                                                        border: 1px solid
                                                            #4a4a4a;
                                                        &::placeholder {
                                                            @include SourceSansRegular;
                                                            font-size: 18px;
                                                            color: $p-black;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .col.address {
                                            .field {
                                                .control {
                                                    position: relative;
                                                    &::after {
                                                        font-family: FontAwesome;
                                                        content: "\f107";
                                                        position: absolute;
                                                        color: #343434;
                                                        font-size: 14px;
                                                        right: 16px;
                                                        line-height: 1;
                                                        top: calc(50% - 7px);
                                                    }
                                                    select {
                                                        border: 1px solid
                                                            #4a4a4a;
                                                        border-radius: 0;
                                                        min-height: 45px;
                                                        color: #4a4a4a;
                                                        font-size: 16px;
                                                        @include SourceSansRegular;
                                                        appearance: none;
                                                        padding: 0 30px 0 10px;
                                                    }
                                                }
                                            }
                                        }
                                        .col.actions {
                                            .delete {
                                                span {
                                                    color: $p-red;
                                                    font-size: 16px;
                                                    @include SourceSansRegular;
                                                    text-decoration: underline;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .actions-toolbar {
                            @media screen and (min-width: $bp-medium) {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                            }
                            @media screen and (min-width: $bp-large) {
                                flex-direction: row;
                                justify-content: flex-end;
                                align-items: flex-start;
                            }
                            .primary {
                                @media screen and (min-width: $bp-medium) {
                                    margin-right: 0;
                                }
                                .action {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    background-color: $p-red;
                                    border-radius: 0;
                                    border: none;
                                    height: 45px;
                                    line-height: 24px;
                                    padding: 0;
                                    color: $white;
                                    @media screen and (min-width: $bp-medium) {
                                        margin-right: 0;
                                        padding: 15px;
                                    }
                                    @media screen and (min-width: $bp-large) {
                                        transition: 0.5s;
                                    }
                                    &:hover {
                                        @media screen and (min-width: $bp-large) {
                                            background-color: $white;
                                            color: $p-red;
                                            border: 1px solid $p-red;
                                        }
                                    }
                                    span {
                                        @include SourceSansRegular;
                                        font-size: 18px;
                                        text-transform: uppercase;
                                    }
                                }
                            }
                            .secondary {
                                @media screen and (min-width: $bp-medium) {
                                    margin-top: 25px;
                                }
                                @media screen and (min-width: $bp-large) {
                                    margin-top: 0;
                                    display: grid;
                                    grid-template-columns: 1fr 1fr;
                                    grid-template-rows: 1fr auto;
                                    gap: 0px 0px;
                                }
                                .action {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    background-color: $white;
                                    border-radius: 0;
                                    border: 1px solid $p-black;
                                    height: 45px;
                                    line-height: 24px;
                                    padding: 0;
                                    color: $p-black;
                                    @media screen and (min-width: $bp-medium) {
                                        margin-left: 0;
                                        margin-right: 0;
                                        padding: 15px;
                                        width: 100%;
                                    }
                                    @media screen and (min-width: $bp-large) {
                                        width: auto;
                                        margin-bottom: 0;
                                        transition: 0.5s;
                                    }
                                    &:hover {
                                        @media screen and (min-width: $bp-large) {
                                            background-color: $p-black;
                                            color: $white;
                                        }
                                    }
                                    span {
                                        @include SourceSansRegular;
                                        font-size: 18px;
                                        text-transform: uppercase;
                                    }
                                }
                                .action.update {
                                    @media screen and (min-width: $bp-large) {
                                        margin-bottom: 0;
                                        margin: 0 10px;
                                    }
                                }
                                .action.back {
                                    border: none;
                                    height: 100%;
                                    @media screen and (min-width: $bp-large) {
                                        margin-top: 0;
                                        grid-area: 2 / 1 / 3 / 3;
                                        justify-content: flex-end;
                                        height: auto;
                                        background-color: transparent;
                                    }
                                    span {
                                        font-size: 16px;
                                        color: $p-red;
                                        text-decoration: underline;
                                        text-transform: initial;
                                        @media screen and (min-width: $bp-medium) {
                                            font-size: 18px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
