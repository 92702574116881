.shipping-tracking-popup {
    .page-wrapper {
        .page-main {
            text-align: center;
            .page-title-wrapper {
                .page-title {
                    @include SourceSansBold;
                    font-size: 28px;
                    color: $p-black;
                }
            }
            .columns {
                .page {
                    .order {
                        @include SourceSansRegular;
                        font-size: 22px;
                        color: $p-black;
                    }
                    .table-wrapper {
                        .data {
                            tbody {
                                display: inline-block;
                                th {
                                    @include SourceSansBold;
                                    font-size: 22px;
                                    color: $p-black;
                                }
                                td {
                                    @include SourceSansRegular;
                                    font-size: 22px;
                                    color: $p-black;
                                }
                            }
                        }
                    }
                    .actions {
                        .action {
                            background-color: #93002a;
                            border-radius: 0;
                            border: none;
                            padding: 10px 30px;
                            height: 45px;
                            span {
                                color: $white;
                                @include SourceSansRegular;
                                font-size: 22px;
                            }
                        }
                    }
                }
            }
        }
    }
}