body.cms-preguntas-frecuentes {
  .message.global.demo {
    display:none
  }
  .page-wrapper {
    font-family: Source Sans Pro;
    .page-header {
      background: transparent;
      .header {
        background-color: rgba(0,0,0,.45);
      }
    }
    .breadcrumbs {
      display: none;
    }
    .rootmenu {
      background-color: rgba(0,0,0,.45) !important;
    }
    .footer {
      .block.newsletter {
        display: none;
      }
    }
    .page-title-wrapper {
      position: absolute;
      text-align: center;
      top: 45%;
      width: 100%;
      .page-title {
        color: white;
        font-family: Playfair Display;
        font-size: 50px;
          font-weight: 800;
          font-style: normal;
          letter-spacing: 1px;
          text-shadow: 2px 3px 5px rgba(0,0,0,.75);
          &:before,
          &:after {
            content: "~";
            font-size: 50px;
            display: inline;
            margin: 0 15px;
          }
      }
    }
    .column.main {
      margin-top: 25%;
    }
    .banner-top {
      position: absolute;
      top: 0;
      width: 100%;
      img {
        width: 100%;
        &.only-desk {
          display: block;
        }
        &.only-tab {
          display: none;
        }
        &.only-mob {
          display: none;
        }
      }
    }
    .page-main {
      .cont-page {
        max-width: 1140px;
        margin: 100px auto 0;
        display: table;
        width: 100%;
        p {
          font-size: 14px;
          text-align: justify;
          font-family: Source Sans Pro;
        }
        .col {
          width: 20%;
          float: left;
          &.right {
            width: 70%;
            float: right;
          }
        }
        #faqs {
          margin-top: 5px;
          .submenu {
            display: none;
          }
          .menu-li {
            position: relative;
            font-size: 20px;
            font-weight: bold;
            margin: 25px 0;
            z-index: 2;
            cursor: pointer;
            color: black;
            letter-spacing: 0.56px;
            .trig {
              width: 100%;
              display: table;
            }
            &:first-child {
              margin-top: 0;
            }
          }
          .submenu {
            ul {
              padding-left: 0;
              li {
                list-style: none;
                font-size: 18px;
                font-family: Source Sans Pro;
                padding-left: 20px;
                cursor: pointer;
                letter-spacing: 0.56px;
                color: black;
                &.active {
                  font-weight: bold;
                  border-left: 5px solid black;
                }
              }
            }
          }
          .ico {
              position: absolute;
              right: 0;
              &:before {
                content: '';
                background: url('../images/catalog/more.svg');
                background-repeat: no-repeat;
                background-position: 50%;
                width: 15px;
                height: 15px;
                font-weight: normal;
                font-size: 25px;
                display: inline-block;
                position: relative;
                top: 0;
                z-index: 1;
              }
          }
          .ico.active {
              position: absolute;
              right: 0;
              &:before {
                content: '';
                background: url('../images/catalog/less.svg');
                background-repeat: no-repeat;
                background-position: 50%;
                width: 15px;
                height: 15px;
                font-weight: normal;
                font-size: 25px;
                display: inline-block;
                position: relative;
                top: 0;
                z-index: 1;
              }
          }
        }
        .cont-faq {
          &.hide {
            display: none;
          }
          h4 {
            font-family: Playfair Display;
            font-size: 30px;
            font-weight: 800;
            color: black;
          }
          p {
            font-size: 16px;
            line-height: 28px;
          }
        }
        .info-block {
          border-top: 1px solid black;
          margin: 30px 0 0 0;
          padding: 30px 0 0; 
          .legend {
            font-size: 20px;
            text-align: center;
            line-height: 30px;
            font-weight: bold;
            color: $p-black;
            a {
              width: 100%;
              float: left;
              color: #555555;
              text-decoration: underline;
              font-size: 20px;
              color: #93002a;
            }
          }
        }
        .black-gray {
          border-radius: 10px;
          background: #f3f3f3;
          padding: 40px;
          text-align: center;
          margin: 70px 0 0;
          p {
            font-weight: normal;
            font-size: 24px;
            line-height: 40px;
            text-align: center;
            margin: 0;
            color: $p-black;
            &.large {
              font-size: 30px;
            }
            a {
              color: black;
              font-weight: 900;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  body.cms-preguntas-frecuentes {
    .page-wrapper {
      .page-main {
        .cont-page {
          padding: 0 25px;
          margin: 60px auto 0;
          width: auto;
          max-width: 100%;
        }
      }
    }
  }
}

@media only screen 
    and (min-device-width: 1024px) 
    and (max-device-width: 1366px)
    and (-webkit-min-device-pixel-ratio: 2) {
    body.cms-preguntas-frecuentes {
    .page-wrapper {
      .column.main {
        margin-top: 24%;
      }
    }
  }
}

@media only screen and (max-width: 1025px) {
  body.cms-preguntas-frecuentes {
    .page-wrapper {
      .column.main {
        margin-top: 45%;
      }
      .page-title-wrapper {
        .page-title {
          font-size: 32px;
        }
      }
      .banner-top {
        img {
          &.only-desk {
            display: none;
          }
          &.only-tab {
            display: block;
          }
          &.only-mob {
            display: none;
          }
        }
      }
      .page-main {
        .cont-page {
          .cont-faq {
            h4 {
              font-size: 25px;
            }
          }
        }
      }
    }
  }
}

/* Landscape */

@media (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  body.cms-preguntas-frecuentes {
    .page-wrapper {
      .banner-top {
        img {
          &.only-desk {
            display: block !important;
          }
          &.only-tab {
              display: none !important;
          }
          &.only-mob {
            display:  none !important;
          }
        }
      }
    }
  }  
}

@media only screen and (max-width: 767px) {
  body.cms-preguntas-frecuentes {
    .page-wrapper {
      .page-title-wrapper {
        top: 35%;
        .page-title {
          font-size: 23px;
          span {
              margin: 0 auto;
              display: inline;
          }
          &:before,
            &:after {
              font-size: 20px;
            }
        }
      }
      .column.main {
        margin-top: 45%;
      }
      .banner-top {
        img {
          &.only-desk {
            display: none;
          }
          &.only-tab {
            display: none;
          }
          &.only-mob {
            display: block;
          }
        }
      }
      .page-main {
        .cont-page {
          margin: 30px auto 30px;
          width: auto;
          display: block;
          .col {
            width: auto;
            width: auto;
            display: block;
            float: none;
            &.right {
              width: auto;
              float: none;
            }
          }
          .black-gray {
            padding: 30px 20px;
            p {
              font-size: 20px;
              line-height: 40px;
              &.large {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  body.contact-index-index {
    .page-wrapper {
      .page-title-wrapper {
        top: 35%;
        .page-title {
          font-size: 22px;
          
          &:before,
            &:after {
              font-size: 18px;
            }
        }
      }
      .page-main {
        .cont-page {
          .black-gray {
            font-size: 18px;
            p.large {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}