.account.sales-order-history {
    .page-wrapper {
        .page-main {
            .columns {
                .column {
                    .table-wrapper {
                        .data {
                            thead {
                                tr {
                                    th {
                                        @include SourceSansBold;
                                        font-size: 18px;
                                        color: $p-black;
                                    }
                                }
                            }
                            tbody {
                                tr {
                                    .col {
                                        @include SourceSansRegular;
                                        font-size: 16px;
                                        color: $p-black;
                                        &::before {
                                            @include SourceSansBold;
                                            font-size: 16px;
                                        }
                                    }
                                    .actions {
                                        .action {
                                            span {
                                                color: $p-red;
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .order-products-toolbar {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        @media screen and (min-width: $bp-medium) {
                            flex-direction: row;
                            justify-content: flex-end;
                        }
                        .pager {
                            @media screen and (min-width: $bp-medium) {
                                display: flex;
                                align-items: center;
                            }
                            .toolbar-amount {
                                @media screen and (min-width: $bp-medium) {
                                    margin: 0;
                                    padding: 0;
                                    margin-right: 25px;
                                }
                            }
                            .pages {
                                float: none;
                                @media screen and (min-width: $bp-medium) {
                                    position: relative;
                                    width: auto;
                                }
                                .items {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    .item {
                                        display: flex;

                                        .page {
                                            @include SourceSansRegular;
                                            font-size: 16px;
                                            color: $p-black;
                                        }
                                    }

                                    .current {
                                        .page {
                                            background-color: $p-red;
                                            padding: 0;
                                            width: 25px;
                                            height: 25px;
                                            line-height: 25px;
                                            color: $white;
                                        }
                                    }

                                    .pages-item-next,
                                    .pages-item-previous {
                                        .action {
                                            background: url(../images/icons/icon-right.svg);
                                            content: "";
                                            width: 25px;
                                            height: 25px;
                                            background-repeat: no-repeat;
                                            padding: 0;
                                            margin: 0;
                                            background-position: center;
                                            border: none;

                                            &::before {
                                                display: none;
                                            }
                                        }
                                    }

                                    .pages-item-previous {
                                        transform: rotate(-180deg);
                                    }
                                }
                            }
                            .limiter {
                                @media screen and (min-width: $bp-medium) {
                                    display: flex;
                                    align-items: center;
                                    margin-left: 25px;
                                }
                            }
                        }
                    }
                    .actions-toolbar {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}
