.account.review-customer-index {
  .page-wrapper {
    .page-main {
      .columns {
        .column {
          .table-wrapper {
            .data {
              thead {
                tr {
                  th {
                    @include SourceSansBold;
                    font-size: 18px;
                    color: $p-black;
                  }
                }
              }
              tbody {
                tr {
                  .col {
                    @include SourceSansRegular;
                    font-size: 16px;
                    color: $p-black;
                    &::before {
                      @include SourceSansBold;
                      font-size: 16px;
                    }
                  }
                  .item {
                    .product-name {
                      a {
                        @include SourceSansRegular;
                        font-size: 16px;
                        color: $p-black !important;
                      }
                    }
                  }
                  .actions {
                    .action {
                      span {
                        color: $p-red;
                        text-decoration: underline;
                      }
                    }
                  }
                }
              }
            }
          }
          .actions-toolbar {
            max-width: 100%;
          }
        }
      }
    }
  }
}