.uk-active {
    div {
        #offcanvas-login {
            .background-login {
                .custom-login {
                    .close-arrow {
                        display: none;
                    }

                    .block-title {
                        p {
                            @include PlayfairDisplayBlack;
                            font-size: 22px;
                            text-align: center;
                            color: $white;
                            @media screen and (min-width: $bp-large) {
                                color: $p-black;
                            }
                        }

                        .helper {
                            display: none;
                        }
                    }

                    .block-content {
                        .fieldset {
                            .field {
                                margin: 0;

                                .control {
                                    @media screen and (min-width: $bp-medium) {
                                        width: 100%;
                                    }

                                    .input-text {
                                        height: 45px;
                                        border: 1px solid $white !important;
                                        background: rgba(255, 255, 255, 0.15);
                                        border-radius: 0;
                                        margin: 5px 0;
                                        padding-left: 15px !important;
                                        color: $white;
                                        text-align: left !important;
                                        @media screen and (min-width: $bp-large) {
                                            background: $white;
                                            border: 1px solid $p-black !important;
                                            color: $p-gray;
                                        }

                                        &::placeholder {
                                            font-size: 16px;
                                            @include SourceSansRegular;
                                            color: $white;
                                            padding-left: 0;
                                            @media screen and (min-width: $bp-large) {
                                                color: $p-gray;
                                            }
                                        }
                                        &:focus {
                                            color: $p-gray;
                                        }
                                    }
                                    input:not([disabled]):focus {
                                        background-color: $white;
                                        &::placeholder {
                                            color: $p-gray;
                                        }
                                    }
                                    .mage-error {
                                        display: block;
                                        font-size: 14px;
                                        text-align: center;
                                        font-weight: normal;
                                        width: 100%;
                                        padding: 5px;
                                        border: 1px dashed red;
                                        @include SourceSansRegular;
                                        box-sizing: border-box;
                                    }
                                }
                            }

                            .password {
                                .secondary {
                                    text-align: center;

                                    .action {
                                        @media screen and (min-width: $bp-medium) {
                                            display: inline-block;
                                        }
                                        span {
                                            @include SourceSansRegular;
                                            font-size: 16px;
                                            color: $white;
                                            @media screen and (min-width: $bp-large) {
                                                color: $p-black;
                                            }
                                        }
                                    }
                                    .text {
                                        color: $p-red;
                                        text-decoration: underline;
                                        @include SourceSansRegular;
                                        font-size: 16px;
                                    }
                                }
                            }

                            .actions-toolbar {
                                .messages {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin-top: 10px;
                                    .error {
                                        font-size: 16px;
                                        color: red;
                                        text-align: center;
                                        border: 1px dashed red;
                                        padding: 5px;
                                        width: 100%;
                                        @include SourceSansRegular;
                                    }
                                }
                            }

                            .rememberme {
                                .control {
                                    text-align: center;

                                    .label {
                                        span {
                                            @include SourceSansRegular;
                                            font-size: 16px;
                                            color: $white;
                                            @media screen and (min-width: $bp-large) {
                                                color: $p-black;
                                            }
                                        }
                                    }

                                    [type="checkbox"]:not(:checked),
                                    [type="checkbox"]:checked {
                                        left: -9999px;
                                        position: absolute;
                                        top: +20px;
                                        visibility: hidden;
                                    }

                                    [type="checkbox"]:not(:checked) + label,
                                    [type="checkbox"]:checked + label {
                                        position: relative;
                                        padding-left: 1.2em;
                                        cursor: pointer;
                                    }

                                    /* checkbox aspect */
                                    [type="checkbox"]:not(:checked)
                                        + label:before,
                                    [type="checkbox"]:checked + label:before {
                                        content: "";
                                        position: absolute;
                                        left: 0;
                                        top: 8px;
                                        width: 12px;
                                        height: 12px;
                                        border: 2px solid $white;
                                        background: rgba(255, 255, 255, 0.15);
                                        @media screen and (min-width: $bp-large) {
                                            border: 2px solid $p-gray;
                                            top: 10px;
                                        }
                                    }

                                    [type="checkbox"]:checked + label:before {
                                        background: $p-red;
                                    }

                                    /* checked mark aspect */
                                    [type="checkbox"]:not(:checked)
                                        + label:after,
                                    [type="checkbox"]:checked + label:after {
                                        content: "";
                                        left: 3px;
                                        top: 10px;
                                        border: 2px solid $white;
                                        background: transparent;
                                        opacity: 0;
                                        transform: rotate(-45deg);
                                        position: absolute;
                                        width: 9px;
                                        height: 5px;
                                        font-size: 1.4em;
                                        line-height: 0.8;
                                        transition: all 0.2s;
                                        @media screen and (min-width: $bp-medium) {
                                            top: 12px;
                                            height: 3px;
                                        }
                                        @media screen and (min-width: $bp-large) {
                                            border: 2px solid $white;
                                            top: 14px;
                                        }
                                    }

                                    /* checked mark aspect changes */
                                    [type="checkbox"]:not(:checked)
                                        + label:after {
                                        opacity: 0;
                                        transform: scale(0);
                                        transform: rotate(-45deg);
                                    }

                                    [type="checkbox"]:checked + label:after {
                                        opacity: 1;
                                        border-top: none;
                                        border-right: none;
                                    }
                                }
                            }

                            .actions-toolbar {
                                .primary {
                                    @media screen and (min-width: $bp-medium) {
                                        width: 100%;
                                    }

                                    .action {
                                        background-color: $p-red;
                                        color: $white;
                                        @include SourceSansRegular;
                                        font-size: 20px;
                                        border: none;
                                        border-radius: 0;
                                        padding: 10px 0;
                                        text-transform: uppercase;
                                        margin-top: 20px;
                                        @media screen and (min-width: $bp-medium) {
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                            .social-login {
                                .title {
                                    .text {
                                        @include PlayfairDisplayBlack;
                                        font-size: 22px;
                                        color: $white;
                                        text-align: center;
                                        margin: 20px 0 0;
                                        @media screen and (min-width: $bp-large) {
                                            color: $p-black;
                                        }
                                    }
                                }
                                .pslogin-block {
                                    .pslogin-buttons {
                                        width: 100%;
                                        ul {
                                            .pslogin-button {
                                                width: 100%;
                                                .pslogin-button-link {
                                                    .pslogin-button-auto {
                                                        display: flex;
                                                        justify-content: center;
                                                        align-items: center;
                                                        padding: 10px;
                                                        height: 45px;
                                                        margin-top: 20px;
                                                        background-color: #1d71b8;
                                                        .pslogin-button-icon {
                                                            left: 45px;
                                                            top: auto;
                                                        }
                                                        .pslogin-button-text {
                                                            @include SourceSansRegular;
                                                            font-size: 20px;
                                                            min-width: auto;
                                                            margin-left: 10px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .logined {
                                    padding-top: 20px;
                                    text-align: center;
                                    span {
                                        @include SourceSansRegular;
                                        font-size: 16px;
                                        color: $white;
                                        @media screen and (min-width: $bp-large) {
                                            color: $p-black;
                                        }
                                    }
                                    a {
                                        color: $p-red;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
