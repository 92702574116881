.checkout-onepage-success {
    .page-wrapper {
        .page-main {
            .columns {
                .column {
                    padding-bottom: 0;
                    .iwd_success_page_wrapper {
                        margin-top: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @media screen and (min-width: $bp-xlarge) {
                            height: 80vh;
                        }
                        .background {
                            @media screen and (min-width: $bp-xlarge) {
                                width: 100%;
                                height: 100%;
                            }
                            .img {
                                min-height: 125vh;
                                object-fit: cover;
                                @media screen and (min-width: $bp-xlarge) {
                                    width: 100%;
                                    min-height: auto;
                                }
                            }
                        }
                        .iwd_opc_alternative_wrapper {
                            position: absolute;
                            top: auto;
                            bottom: auto;
                            left: auto;
                            right: auto;
                            .iwd_opc_column {
                                .iwd_opc_alternative_column {
                                    padding: 10px;
                                    background-color: rgba(255, 255, 255, 0.75);
                                    border-radius: 5px;
                                    margin: 5px;
                                    max-width: 350px;
                                    @media screen and (min-width: $bp-medium) {
                                        max-width: 640px;
                                        margin: 5px auto;
                                        padding: 25px;
                                    }
                                    @media screen and (min-width: $bp-large) {
                                        margin: 0;
                                        padding: 30px;
                                        max-width: 670px;
                                    }
                                    .iwd_opc_universal_wrapper {
                                        @include SourceSansRegular;
                                        color: $p-black;
                                        font-size: 26px;
                                        @media screen and (min-width: $bp-medium) {
                                            font-size: 36px;
                                        }
                                    }
                                    .iwd_opc_success_page_create_account_info {
                                        font-size: 16px;
                                    }
                                    .iwd_opc_column_content {
                                        .iwd_success_page_info {
                                            @include SourceSansRegular;
                                            color: $p-black;
                                            margin-bottom: 0;
                                            font-size: 16px;
                                            padding: 0;
                                            a {
                                                color: $p-red;
                                                text-decoration: underline;
                                            }
                                            .checkout-success {
                                                h5 {
                                                    display: none;
                                                }
                                            }
                                            .text {
                                                margin: 0;
                                            }
                                        }
                                        .iwd_opc_universal_wrapper {
                                            .iwd_opc_button {
                                                margin-top: 20px;
                                                background-color: $p-red;
                                                border: $p-red;
                                                color: $white;
                                                text-transform: uppercase;
                                                border-radius: 0;
                                                @media screen and (min-width: $bp-medium) {
                                                    width: 50%;
                                                    margin: 20px auto 0;
                                                    height: 45px;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    font-size: 20px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .page-footer {
            .footer {
                .newsletter {
                    display: none;
                }
            }
        }
    }
}
