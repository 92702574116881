.owl-ext-banner .owl-prev:after {
  content: url('../images/icons/icon-prev.svg')!important;
  font-size: initial!important;
}

.owl-ext-banner .owl-next:after {
  content: url('../images/icons/icon-next.svg') !important;
  font-size: initial!important;
}

.owl-theme .owl-nav [class*="owl-"] {
  visibility: visible!important;
  opacity: 1!important;
  transition: opacity .3s linear!important;
}