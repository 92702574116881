.page-wrapper {
    .page-header {
        .minicart-wrapper {
            .action {
                .counter {
                    position: absolute;
                    right: -12px;
                    top: -8px;
                    border-radius: 50%;
                    width: 25px;
                    height: 25px;
                    background-color: $p-red;
                    border: 1px solid $white;
                    padding: 0;
                    margin: 0;
                    line-height: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .counter-number {
                        @include SourceSansRegular;
                        font-size: 14px;
                        color: $white;
                        line-height: 0;
                    }
                }
            }
            #minicart-content-toggle {
                .off-canvas-cart-container {
                    background-color: $white;
                    padding: 0;
                    width: 95%;
                    &::-webkit-scrollbar-thumb {
                        background: #9b9b9b;
                        border-radius: 5px;
                    }
                    &::-webkit-scrollbar {
                        width: 10px;
                    }
                    @media screen and (min-width: $bp-medium) {
                        width: 55%;
                    }
                    @media screen and (min-width: $bp-large) {
                        min-width: 400px;
                        max-width: 400px;
                    }
                    .block-title {
                        text-align: center;
                        padding: 25px 0;
                        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
                        #btn-minicart-close {
                            left: 20px;
                            top: 20px;
                            position: absolute;
                            &::before {
                                content: "";
                                font-size: 10px;
                            }
                        }
                        .text {
                            @include PlayfairDisplayBlack;
                            font-size: 22px;
                            color: $p-red;
                        }
                        button {
                            &::before {
                                color: #333333;
                            }
                        }
                    }
                    .block-content {
                        padding: 0 20px;
                        text-align: center;
                        .minicart-items-wrapper {
                            max-height: 450px;
                            height: auto !important;
                            border: none;
                            padding: 0;
                            width: 100%;
                            margin: auto;
                            #mini-cart {
                                .item {
                                    padding: 20px 0;
                                    margin: 0;
                                    border-bottom: 1px solid $p-red;
                                    .product {
                                        display: flex;
                                        .product-item-details {
                                            padding-left: 10px;
                                            width: 100%;
                                            .product-item-content {
                                                display: flex;
                                                justify-content: space-between;
                                                .product-item-name {
                                                    display: flex;
                                                    text-align: left;
                                                    margin-right: 5px;
                                                    height: 45px;
                                                    overflow: hidden;
                                                    @media screen and (min-width: $bp-large) {
                                                        margin-right: 25px;
                                                    }
                                                    a {
                                                        @include SourceSansRegular;
                                                        font-size: 16px;
                                                        color: $p-black;
                                                    }
                                                }
                                                .actions {
                                                    margin: 0;
                                                    display: flex;
                                                    .primary {
                                                        display: none;
                                                    }
                                                    .secondary {
                                                        display: flex;
                                                        .action {
                                                            &::before {
                                                                font-size: 11px;
                                                                content: "";
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .product-item-pricing {
                                                display: flex;
                                                justify-content: space-between;
                                                align-items: flex-end;
                                                .price-container {
                                                    .price-wrapper {
                                                        .old-price {
                                                            color: $p-black;
                                                            .price {
                                                                color: $p-black;
                                                                @include SourceSansRegular;
                                                                font-size: 20px;
                                                            }
                                                        }
                                                        .price-excluding-tax {
                                                            font-size: 0;
                                                            margin: 0;
                                                            .minicart-price {
                                                                .price {
                                                                    @include SourceSansBold;
                                                                    font-size: 20px;
                                                                    color: $p-red;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                .details-qty {
                                                    margin-top: 0;
                                                    .qty-content {
                                                        padding: 0 5px;
                                                        border: 1px solid
                                                            $p-gray;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        .item-qty {
                                                            margin: 0;
                                                            padding: 0;
                                                            border: none;
                                                            width: 35px;
                                                            height: 30px;
                                                            @include SourceSansRegular;
                                                            font-size: 16px;
                                                            color: $p-black;
                                                        }
                                                    }
                                                    .update-cart-item {
                                                        background-color: $p-red;
                                                        @include SourceSansRegular;
                                                        font-size: 16px;
                                                        color: $white;
                                                        border-radius: 0;
                                                        border: none;
                                                        margin-top: 10px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .content {
                            margin-top: 20px;
                            #discount-coupon-form {
                                .fieldset {
                                    display: flex;
                                    .field {
                                        width: 100%;
                                        margin-right: 10px;
                                        margin-bottom: 0;
                                        .control {
                                            width: 100%;
                                            .input-text {
                                                height: 45px;
                                                padding: 12px 20px;
                                                border: 1px solid $p-gray;
                                                @media screen and (min-width: $bp-large) {
                                                    padding: 12px 20px;
                                                }
                                                &::placeholder {
                                                    @include SourceSansRegular;
                                                    font-size: 16px;
                                                    color: $p-gray;
                                                }
                                            }
                                        }
                                    }
                                    .actions-toolbar {
                                        .primary {
                                            .action {
                                                width: 100%;
                                                height: 45px;
                                                border-radius: 0;
                                                margin: 0;
                                                background-color: $p-black;
                                                padding: 10px 20px;
                                                border: none;
                                                span {
                                                    @include SourceSansRegular;
                                                    font-size: 20px;
                                                    color: $white;
                                                    text-transform: uppercase;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .subtotal {
                            display: flex;
                            justify-content: space-between;
                            .label {
                                span {
                                    @include SourceSansRegular;
                                    font-size: 18px;
                                    line-height: 26px;
                                    color: $p-gray;
                                }
                            }
                            .amount {
                                .price-wrapper {
                                    .price {
                                        @include SourceSansBold;
                                        font-size: 18px;
                                        line-height: 26px;
                                        color: $p-gray;
                                    }
                                }
                            }
                        }
                        .total {
                            display: flex;
                            justify-content: space-between;
                            .label {
                                span {
                                    @include SourceSansRegular;
                                    font-size: 24px;
                                    color: $p-gray;
                                }
                            }
                            .total {
                                .price-wrapper {
                                    .price {
                                        @include SourceSansBold;
                                        font-size: 24px;
                                        color: $p-gray;
                                    }
                                }
                            }
                        }
                        .discount,
                        .items-total {
                            display: flex;
                            justify-content: space-between;
                            .text {
                                @include SourceSansRegular;
                                font-size: 18px;
                                line-height: 26px;
                                color: $p-gray;
                                margin: 0;
                            }
                            .amount {
                                @include SourceSansBold;
                                font-size: 18px;
                                line-height: 26px;
                                color: $p-gray;
                                margin: 0;
                            }
                        }
                        div.discount:nth-child(2) {
                            justify-content: center;
                            margin: 20px 0;
                            .payment-option {
                                width: 100%;
                                cursor: pointer;
                                .payment-option-title {
                                    height: 45px;
                                    display: flex;
                                    align-items: center;
                                    background: $p-black;
                                    justify-content: center;
                                    .action {
                                        span {
                                            color: $white;
                                            @include SourceSansRegular;
                                            font-size: 16px;
                                            text-transform: uppercase;
                                        }
                                    }
                                }
                                .payment-option-content {
                                    .form {
                                        display: flex;
                                        margin-top: 20px;
                                        .payment-option-inner {
                                            width: 100%;
                                            .field {
                                                .label {
                                                    span {
                                                        display: none;
                                                    }
                                                }
                                                .control {
                                                    .input-text {
                                                        height: 45px;
                                                        border: 1px solid
                                                            $p-gray !important;
                                                        &::placeholder {
                                                            @include SourceSansRegular;
                                                            font-size: 16px;
                                                            color: $p-gray;
                                                        }
                                                        &:focus {
                                                            box-shadow: none;
                                                        }
                                                    }
                                                    .mage-error {
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        padding: 5px;
                                                        border: 1px dashed red;
                                                        font-size: 16px;
                                                        @include SourceSansRegular;
                                                        text-align: center;
                                                    }
                                                }
                                            }
                                        }
                                        .actions-toolbar {
                                            .primary {
                                                .action {
                                                    height: 45px;
                                                    border-radius: 0;
                                                    width: auto;
                                                    margin: 0 0 0 10px;
                                                    background-color: $p-black;
                                                    span {
                                                        span {
                                                            @include SourceSansRegular;
                                                            font-size: 20px;
                                                            color: $white;
                                                            text-transform: uppercase;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        div.discount:nth-child(4) {
                            .label {
                                span {
                                    @include SourceSansRegular;
                                    font-size: 18px;
                                    color: $p-gray;
                                    line-height: 26px;
                                }
                            }
                            .discount.price-container {
                                margin: 0;
                                .price-wrapper {
                                    .price {
                                        @include SourceSansBold;
                                        font-size: 18px;
                                        color: $p-gray;
                                        line-height: 26px;
                                    }
                                }
                            }
                        }
                        .items-total {
                            .text {
                                font-size: 24px;
                            }
                            .amount {
                                font-size: 24px;
                            }
                        }
                        .actions {
                            margin-top: 20px;
                            .primary {
                                .action {
                                    background-color: $p-red;
                                    width: 100%;
                                    border: none;
                                    border-radius: 0;
                                    height: 45px;
                                    font-size: 20px;
                                    @include SourceSansRegular;
                                    text-transform: uppercase;
                                }
                            }
                            .secondary {
                                .action {
                                    span {
                                        @include SourceSansRegular;
                                        font-size: 16px;
                                        color: $p-red;
                                        text-transform: uppercase;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                        .payment-methods {
                            margin: 30px 0;
                            .logos {
                                .list {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    .icon {
                                        padding: 0 2px;
                                    }
                                }
                                .second {
                                    width: 60%;
                                    margin: 10px auto 0;
                                }
                            }
                            .help {
                                .contact-us {
                                    margin: 20px 0 10px;
                                    .text {
                                        @include SourceSansRegular;
                                        font-size: 16px;
                                        color: $p-black;
                                        margin: 0;
                                    }
                                }
                                .whatssap {
                                    .link {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        .text {
                                            margin: 0;
                                            @include SourceSansRegular;
                                            font-size: 18px;
                                            color: $p-gray;
                                            margin-left: 10px;
                                        }
                                    }
                                }
                            }
                        }
                        .block-empty {
                            padding: 30% 0;
                            .subtitle {
                                @include SourceSansRegular;
                                font-size: 20px;
                                color: $p-black;
                            }
                            .empty-img {
                                margin: 20px 0;
                            }
                            .btn {
                                background-color: $p-red;
                                border: none;
                                border-radius: 0;
                                width: 100%;
                                height: 45px;
                                display: flex;
                                justify-content: center;
                                span {
                                    @include SourceSansRegular;
                                    font-size: 20px;
                                    color: $white;
                                    padding: 10px 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modals-wrapper {
    .modal-popup {
        .modal-inner-wrap {
            .modal-content {
                padding: 0 50px;
                div {
                    @include SourceSansRegular;
                    font-size: 16px;
                    color: $p-black;
                    text-align: center;
                }
            }
            .modal-footer {
                text-align: center;
                button {
                    background-color: $p-black;
                    border: none;
                    border-radius: 0;
                    span {
                        @include SourceSansRegular;
                        font-size: 16px;
                        color: $white;
                    }
                }
            }
        }
    }
}
