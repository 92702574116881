.uk-active {
    div {
        #offcanvas-register {
            .background-uikit {
                .form {
                    @media screen and (min-width: $bp-medium) {
                        min-width: auto;
                        width: 100%;
                    }

                    .block-title {
                        p {
                            @include PlayfairDisplayBlack;
                            font-size: 22px;
                            text-align: center;
                            color: $white;
                            @media screen and (min-width: $bp-large) {
                                color: $p-black;
                            }
                        }

                        .helper {
                            display: none;
                        }
                    }

                    .field {
                        .label {
                            display: none;
                        }

                        .control {
                            @media screen and (min-width: $bp-medium) {
                                width: 100%;
                            }

                            .input-text {
                                height: 45px;
                                border: 1px solid $white !important;
                                background: rgba(255, 255, 255, 0.15);
                                border-radius: 0;
                                margin: 5px 0;
                                padding: 0;
                                padding-left: 15px;
                                color: $white;
                                @media screen and (min-width: $bp-large) {
                                    color: $p-gray;
                                    background: $white;
                                    border: 1px solid $p-black !important;
                                }

                                &::placeholder {
                                    font-size: 16px;
                                    color: $white;
                                    @include SourceSansRegular;
                                    padding-left: 0;
                                    @media screen and (min-width: $bp-large) {
                                        color: $p-gray;
                                    }
                                }

                                &:focus {
                                    color: $p-gray;
                                }
                            }

                            input:not([disabled]):focus {
                                background-color: $white;

                                &::placeholder {
                                    color: $p-gray;
                                }
                            }

                            .mage-error {
                                display: block;
                                font-size: 14px;
                                text-align: center;
                                font-weight: normal;
                                width: 100%;
                                padding: 5px;
                                border: 1px dashed red;
                                @include SourceSansRegular;
                                box-sizing: border-box;
                                margin: 10px 0 0 !important;
                            }

                            #password-error {
                                @media screen and (min-width: $bp-large) {
                                    margin-top: 2px;
                                }
                            }
                        }
                    }

                    .fieldset {
                        margin: 0;

                        .field {
                            margin: 0;

                            .label {
                                display: none;
                            }

                            .control {
                                #password-strength-meter-container {
                                    margin: 5px 0;
                                    @include SourceSansRegular;
                                    font-size: 18px;
                                    color: $p-black;
                                }
                            }
                        }
                    }

                    .password-container {
                        display: flex;
                        justify-content: center;

                        .password {
                            width: 100%;

                            .control {
                                width: 97%;
                                margin-right: auto;

                                #password-strength-meter-container {
                                    display: none;
                                }
                            }

                            .input-text {
                                padding-left: 5px;

                                &::placeholder {
                                    padding-left: 0;
                                }
                            }
                        }

                        .confirmation {
                            width: 100%;

                            .control {
                                width: 97%;
                                margin-left: auto;
                            }

                            .input-text {
                                padding-left: 5px;

                                &::placeholder {
                                    padding-left: 0;
                                }
                            }
                        }
                    }

                    .business {
                        .control {
                            text-align: center;

                            .label {
                                display: block;

                                span {
                                    @include SourceSansRegular;
                                    font-size: 16px;
                                    color: $white;
                                    @media screen and (min-width: $bp-large) {
                                        color: $p-black;
                                    }
                                }
                            }

                            [type="checkbox"]:not(:checked),
                            [type="checkbox"]:checked {
                                left: -9999px;
                                position: absolute;
                                top: +20px;
                                visibility: hidden;
                            }

                            [type="checkbox"]:not(:checked) + label,
                            [type="checkbox"]:checked + label {
                                position: relative;
                                padding-left: 1.2em;
                                cursor: pointer;
                            }

                            /* checkbox aspect */
                            [type="checkbox"]:not(:checked) + label:before,
                            [type="checkbox"]:checked + label:before {
                                content: "";
                                position: absolute;
                                left: 20px;
                                top: 10px;
                                width: 12px;
                                height: 12px;
                                border: 2px solid $white;
                                background: rgba(255, 255, 255, 0.15);
                                @media screen and (min-width: 424px) {
                                    left: 55px;
                                }
                                @media screen and (min-width: $bp-medium) {
                                    left: 35px;
                                }
                                @media screen and (min-width: $bp-large) {
                                    border: 2px solid $p-gray;
                                    top: 10px;
                                }
                                @media screen and (min-width: $bp-xlarge) {
                                    left: 20px;
                                }
                                @media screen and (min-width: $bp-xxlarge) {
                                    left: 35px;
                                }
                                @media screen and (min-width: 1600px) {
                                    left: 65px;
                                }
                                @media screen and (min-width: 1920px) {
                                    left: 115px;
                                }
                            }

                            [type="checkbox"]:checked + label:before {
                                background: $p-red;
                            }

                            /* checked mark aspect */
                            [type="checkbox"]:not(:checked) + label:after,
                            [type="checkbox"]:checked + label:after {
                                content: "";
                                left: 23px;
                                top: 12px;
                                border: 2px solid $white;
                                background: transparent;
                                opacity: 0;
                                transform: rotate(-45deg);
                                position: absolute;
                                width: 9px;
                                height: 5px;
                                font-size: 1.4em;
                                line-height: 0.8;
                                transition: all 0.2s;
                                @media screen and (min-width: 424px) {
                                    left: 58px;
                                    top: 12px;
                                }
                                @media screen and (min-width: $bp-medium) {
                                    top: 14px;
                                    height: 3px;
                                    left: 38px;
                                }
                                @media screen and (min-width: $bp-large) {
                                    border: 2px solid $white;
                                }
                                @media screen and (min-width: $bp-xlarge) {
                                    left: 23px;
                                }
                                @media screen and (min-width: $bp-xxlarge) {
                                    left: 38px;
                                }
                                @media screen and (min-width: 1600px) {
                                    left: 68px;
                                }
                                @media screen and (min-width: 1920px) {
                                    left: 118px;
                                }
                            }

                            /* checked mark aspect changes */
                            [type="checkbox"]:not(:checked) + label:after {
                                opacity: 0;
                                transform: scale(0);
                                transform: rotate(-45deg);
                            }

                            [type="checkbox"]:checked + label:after {
                                opacity: 1;
                                border-top: none;
                                border-right: none;
                            }
                        }
                    }

                    .actions-toolbar {
                        margin-top: 20px;

                        .primary {
                            @media screen and (min-width: $bp-medium) {
                                width: 100%;
                            }

                            .action {
                                background-color: $p-red;
                                color: $white;
                                @include SourceSansRegular;
                                font-size: 20px;
                                border: none;
                                border-radius: 0;
                                padding: 10px 0;
                                text-transform: uppercase;
                                @media screen and (min-width: $bp-medium) {
                                    width: 100%;
                                }
                            }

                            .messages {
                                line-height: 1;
                                margin-bottom: 20px;

                                .error {
                                    @include SourceSansRegular;
                                    font-size: 16px;
                                    color: $white;
                                    @media screen and (min-width: $bp-large) {
                                        color: $p-black;
                                    }

                                    a {
                                        color: $hover;
                                        text-decoration: underline;
                                        @media screen and (min-width: $bp-large) {
                                            color: $p-red;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .social-login {
                        .title {
                            .text {
                                @include PlayfairDisplayBlack;
                                font-size: 22px;
                                color: $white;
                                text-align: center;
                                margin: 20px 0 0;
                                @media screen and (min-width: $bp-large) {
                                    color: $p-black;
                                }
                            }
                        }

                        .pslogin-block {
                            .pslogin-buttons {
                                width: 100%;

                                ul {
                                    .pslogin-button {
                                        width: 100%;

                                        .pslogin-button-link {
                                            .pslogin-button-auto {
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                padding: 10px;
                                                height: 45px;
                                                margin-top: 20px;
                                                background-color: #1d71b8;

                                                .pslogin-button-icon {
                                                    left: 35px;
                                                    top: auto;
                                                }

                                                .pslogin-button-text {
                                                    @include SourceSansRegular;
                                                    font-size: 20px;
                                                    min-width: auto;
                                                    margin-left: 10px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .legals {
                            padding-top: 20px;

                            .control {
                                text-align: center;

                                .label {
                                    display: block;
                                    width: 85%;
                                    margin: auto;
                                    line-height: 16px;

                                    span {
                                        @include SourceSansRegular;
                                        font-size: 16px;
                                        color: $white;
                                        @media screen and (min-width: $bp-large) {
                                            color: $p-black;
                                        }

                                        a {
                                            color: $hover;
                                            text-decoration: underline;
                                            @media screen and (min-width: $bp-large) {
                                                color: $p-red;
                                            }
                                        }
                                    }
                                }

                                [type="checkbox"]:not(:checked),
                                [type="checkbox"]:checked {
                                    left: -9999px;
                                    position: absolute;
                                    top: +20px;
                                    visibility: hidden;
                                }

                                [type="checkbox"]:not(:checked) + label,
                                [type="checkbox"]:checked + label {
                                    position: relative;
                                    padding-left: 1.2em;
                                    cursor: pointer;
                                }

                                /* checkbox aspect */
                                [type="checkbox"]:not(:checked) + label:before,
                                [type="checkbox"]:checked + label:before {
                                    content: "";
                                    position: absolute;
                                    left: 0;
                                    top: 8px;
                                    width: 12px;
                                    height: 12px;
                                    border: 2px solid $white;
                                    background: rgba(255, 255, 255, 0.15);
                                    @media screen and (min-width: $bp-large) {
                                        border: 2px solid $p-gray;
                                        top: 10px;
                                    }
                                }

                                [type="checkbox"]:checked + label:before {
                                    background: $p-red;
                                }

                                /* checked mark aspect */
                                [type="checkbox"]:not(:checked) + label:after,
                                [type="checkbox"]:checked + label:after {
                                    content: "";
                                    left: 3px;
                                    top: 10px;
                                    border: 2px solid $white;
                                    background: transparent;
                                    opacity: 0;
                                    transform: rotate(-45deg);
                                    position: absolute;
                                    width: 9px;
                                    height: 5px;
                                    font-size: 1.4em;
                                    line-height: 0.8;
                                    transition: all 0.2s;
                                    @media screen and (min-width: $bp-medium) {
                                        top: 12px;
                                        height: 3px;
                                    }
                                    @media screen and (min-width: $bp-large) {
                                        border: 2px solid $white;
                                        top: 14px;
                                    }
                                }

                                /* checked mark aspect changes */
                                [type="checkbox"]:not(:checked) + label:after {
                                    opacity: 0;
                                    transform: scale(0);
                                    transform: rotate(-45deg);
                                }

                                [type="checkbox"]:checked + label:after {
                                    opacity: 1;
                                    border-top: none;
                                    border-right: none;
                                }
                            }
                        }

                        .registered {
                            margin-top: 20px;
                            text-align: center;

                            span {
                                @include SourceSansRegular;
                                font-size: 16px;
                                color: $white;
                                @media screen and (min-width: $bp-large) {
                                    color: $p-black;
                                }
                            }

                            a {
                                color: $hover;
                                text-decoration: underline;
                                @media screen and (min-width: $bp-large) {
                                    color: $p-red;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//Create account after reset password request
.customer-account-create {
    .page-wrapper {
        .page-main {
            padding: 0 15px;

            .page-title-wrapper {
                @media screen and (min-width: $bp-medium) {
                    text-align: center;
                }

                .block-title {
                    .page-title {
                        @include PlayfairDisplayBlack;
                        font-size: 22px;
                        color: $p-black;
                    }
                }
            }

            .columns {
                .column {
                    .customer-form-before {
                        @media screen and (min-width: $bp-medium) {
                            min-width: 600px;
                            width: 50%;
                            margin: auto;
                        }

                        .pslogin_register_container {
                            .pslogin-block {
                                margin-bottom: 0;
                                @media screen and (min-width: $bp-medium) {
                                    text-align: center;
                                }

                                .pslogin-buttons {
                                    ul {
                                        margin: 0;
                                        .pslogin-button {
                                            margin: 0;
                                            .pslogin-button-link {
                                                .pslogin-button-auto {
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    padding: 10px 25px;
                                                    height: 45px;
                                                    background-color: #1d71b8;

                                                    .pslogin-button-icon {
                                                        left: 35px;
                                                        top: auto;
                                                    }

                                                    .pslogin-button-text {
                                                        @include SourceSansRegular;
                                                        font-size: 20px;
                                                        min-width: auto;
                                                        margin-left: 10px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .pslogin-spacer {
                                table {
                                    tbody {
                                        td {
                                            @include SourceSansRegular;
                                            color: $p-black;

                                            .pslogin-border {
                                                border-top: 1px solid $p-black;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .form {
                        @media screen and (min-width: $bp-medium) {
                            margin: auto;
                        }

                        .info {
                            @media screen and (min-width: $bp-medium) {
                                display: flex;
                                margin-bottom: 30px;
                                flex-direction: column;
                            }

                            .legend {
                                @media screen and (min-width: $bp-medium) {
                                    margin: 0 auto;
                                }

                                span {
                                    @include SourceSansBold;
                                    font-size: 20px;
                                    color: $p-black;
                                }
                            }

                            .field-name-firstname,
                            .field-name-lastname {
                                @media screen and (min-width: $bp-medium) {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    margin-bottom: 20px;
                                }

                                .label {
                                    display: none;
                                }

                                .control {
                                    .input-text {
                                        height: 45px;
                                        border: 1px solid $p-gray;

                                        &::placeholder {
                                            @include SourceSansRegular;
                                            font-size: 14px;
                                            color: $p-black;
                                        }

                                        &:focus {
                                            box-shadow: none;
                                        }
                                    }
                                }
                            }

                            .choice {
                                @media screen and (min-width: $bp-medium) {
                                    text-align: center;
                                }
                                @media screen and (min-width: $bp-medium) {
                                    display: flex;
                                }

                                &::before {
                                    @media screen and (min-width: $bp-medium) {
                                        width: 0;
                                    }
                                }

                                label {
                                    @include SourceSansRegular;
                                    font-size: 16px;
                                    color: $p-black;
                                    span {
                                        padding-left: 10px;
                                        @media screen and (min-width: $bp-medium) {
                                            padding-left: 70px;
                                        }
                                        @media screen and (min-width: $bp-xxlarge) {
                                            padding-left: 100px;
                                        }
                                        @media screen and (min-width: 1920px) {
                                            padding-left: 110px;
                                        }
                                    }
                                }
                            }
                            .choice {
                                .label {
                                    display: block;
                                }

                                [type="checkbox"]:not(:checked),
                                [type="checkbox"]:checked {
                                    left: -9999px;
                                    position: absolute;
                                    top: +20px;
                                    visibility: hidden;
                                }

                                [type="checkbox"]:not(:checked) + label,
                                [type="checkbox"]:checked + label {
                                    position: relative;
                                    padding-left: 1.2em;
                                    cursor: pointer;
                                }

                                /* checkbox aspect */
                                [type="checkbox"]:not(:checked) + label:before,
                                [type="checkbox"]:checked + label:before {
                                    content: "";
                                    position: absolute;
                                    left: 0px;
                                    top: 4px;
                                    width: 12px;
                                    height: 12px;
                                    border: 2px solid #4a4a4a;
                                    background: rgba(255, 255, 255, 0.15);
                                    @media screen and (min-width: $bp-medium) {
                                        left: 65px;
                                    }
                                    @media screen and (min-width: $bp-large) {
                                        border: 2px solid $p-gray;
                                    }
                                    @media screen and (min-width: $bp-xxlarge) {
                                        left: 70px;
                                    }
                                    @media screen and (min-width: 1600px) {
                                        left: 90px;
                                    }
                                    @media screen and (min-width: 1920px) {
                                        left: 108px;
                                    }
                                }

                                [type="checkbox"]:checked + label:before {
                                    background: $p-red;
                                }

                                /* checked mark aspect */
                                [type="checkbox"]:not(:checked) + label:after,
                                [type="checkbox"]:checked + label:after {
                                    content: "";
                                    left: 3px;
                                    top: 7px;
                                    border: 2px solid $white;
                                    background: transparent;
                                    opacity: 0;
                                    transform: rotate(-45deg);
                                    position: absolute;
                                    width: 9px;
                                    height: 5px;
                                    font-size: 1.4em;
                                    line-height: 0.8;
                                    transition: all 0.2s;
                                    @media screen and (min-width: $bp-medium) {
                                        top: 8px;
                                        height: 3px;
                                        left: 68px;
                                    }
                                    @media screen and (min-width: $bp-large) {
                                        border: 2px solid $white;
                                    }
                                    @media screen and (min-width: $bp-xxlarge) {
                                        left: 73px;
                                    }
                                    @media screen and (min-width: 1600px) {
                                        left: 93px;
                                    }
                                    @media screen and (min-width: 1920px) {
                                        left: 110px;
                                    }
                                }

                                /* checked mark aspect changes */
                                [type="checkbox"]:not(:checked) + label:after {
                                    opacity: 0;
                                    transform: scale(0);
                                    transform: rotate(-45deg);
                                }

                                [type="checkbox"]:checked + label:after {
                                    opacity: 1;
                                    border-top: none;
                                    border-right: none;
                                }
                            }
                        }

                        .account {
                            margin-bottom: 20px;

                            .legend {
                                @media screen and (min-width: $bp-medium) {
                                    width: 100%;
                                    margin-left: 0;
                                }

                                span {
                                    @include SourceSansBold;
                                    font-size: 20px;
                                    color: $p-black;
                                    @media screen and (min-width: $bp-medium) {
                                        display: flex;
                                        justify-content: center;
                                    }
                                }
                            }

                            .field {
                                margin-bottom: 10px;
                                @media screen and (min-width: $bp-medium) {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                }

                                .label {
                                    @include SourceSansBold;
                                    font-size: 16px;
                                    color: $p-black;
                                    @media screen and (min-width: $bp-medium) {
                                        width: auto;
                                        padding: 0;
                                    }
                                }

                                .control {
                                    .input-text {
                                        height: 45px;
                                        border: 1px solid $p-gray;

                                        &::placeholder {
                                            @include SourceSansRegular;
                                            font-size: 14px;
                                            color: $p-black;
                                        }

                                        &:focus {
                                            box-shadow: none;
                                        }
                                    }

                                    #password-strength-meter-container {
                                        .password-strength-meter {
                                            @include SourceSansRegular;
                                            font-size: 16px;
                                            color: $p-black;
                                        }
                                    }
                                }
                            }
                        }

                        .actions-toolbar {
                            @media screen and (min-width: $bp-medium) {
                                margin-left: 0;
                                display: flex;
                                justify-content: space-around;
                            }

                            .primary {
                                .action {
                                    background: $p-black;
                                    border-radius: 0;
                                    height: 45px;
                                    border: none;

                                    span {
                                        @include SourceSansRegular;
                                        font-size: 20px;
                                        text-transform: uppercase;
                                    }
                                }
                            }

                            .secondary {
                                .action {
                                    span {
                                        @include SourceSansRegular;
                                        font-size: 16px;
                                        color: $p-red;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
