.customer-account-forgotpassword {
    .page-wrapper {
        .page-main {
            padding: 0 15px;
            @media screen and (min-width: $bp-medium) {
                padding: 0 25px;
            }
            .page-title-wrapper {
                .block-title {
                    @media screen and (min-width: $bp-medium) {
                        text-align: center;
                    }
                    .page-title {
                        @include PlayfairDisplayBlack;
                        font-size: 22px;
                        color: $p-black;
                    }
                }
            }
            .columns {
                .column {
                    .form {
                        @media screen and (min-width: $bp-medium) {
                            margin: 0 auto;
                        }
                        .fieldset {
                            .note {
                                @include SourceSansRegular;
                                font-size: 16px;
                                color: $p-black;
                                @media screen and (min-width: $bp-medium) {
                                    text-align: center;
                                }
                            }
                            .email,
                            .captcha {
                                @media screen and (min-width: $bp-medium) {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                }
                                .label {
                                    @include SourceSansBold;
                                    font-size: 20px;
                                    color: $p-black;
                                    @media screen and (min-width: $bp-medium) {
                                        width: auto;
                                        padding: 0;
                                        font-size: 18px;
                                    }
                                }
                                .control {
                                    .input-text {
                                        height: 45px;
                                        border: solid 1px #4a4a4a;
                                        background-color: #ffffff;
                                    }
                                }
                            }
                            .captcha {
                                .captcha {
                                    .nested {
                                        .field {
                                            .control {
                                                .action {
                                                    background: $p-red;
                                                    border-radius: 0;
                                                    span {
                                                        font-size: 20px;
                                                        @include SourceSansRegular;
                                                        color: $white;
                                                        text-transform: uppercase;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .actions-toolbar {
                            @media screen and (min-width: $bp-medium) {
                                display: flex;
                                justify-content: space-around;
                                margin-left: 0;
                            }
                            .primary {
                                .action {
                                    background: $p-black;
                                    border-radius: 0;
                                    height: 45px;
                                    span {
                                        font-size: 20px;
                                        @include SourceSansRegular;
                                        color: $white;
                                        text-transform: uppercase;
                                    }
                                }
                            }
                            .secondary {
                                .action {
                                    span {
                                        font-size: 16px;
                                        @include SourceSansRegular;
                                        color: $p-red;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//layout login after reset password

.customer-account-login {
    .page-wrapper {
        .messages-container {
            position: static;
        }
        .page-main {
            padding: 0 15px;
            .page-title-wrapper {
                .block-title {
                    .page-title {
                        span {
                            @include PlayfairDisplayBlack;
                            font-size: 22px;
                            color: $p-black;
                        }
                    }
                }
            }
            .columns {
                .column {
                    .login-container {
                        .block-customer-login {
                            .block-title {
                                strong {
                                    @include SourceSansBold;
                                    font-size: 22px;
                                    color: $p-black;
                                }
                            }
                            .block-content {
                                .form {
                                    .fieldset {
                                        &::after {
                                            display: none;
                                        }
                                        .note {
                                            @include SourceSansRegular;
                                            font-size: 16px;
                                            color: $p-black;
                                        }
                                        .email,
                                        .password {
                                            label {
                                                @include SourceSansBold;
                                                font-size: 20px;
                                                color: $p-black;
                                                @media screen and (min-width: $bp-medium) {
                                                    width: auto;
                                                }
                                                @media screen and (min-width: $bp-large) {
                                                    width: 30%;
                                                    text-align: left;
                                                }
                                            }
                                            .control {
                                                .input-text {
                                                    height: 45px;
                                                    border: 1px solid $p-gray;
                                                    &:focus {
                                                        box-shadow: none;
                                                    }
                                                }
                                            }
                                        }
                                        .actions-toolbar {
                                            @media screen and (min-width: $bp-medium) {
                                                display: flex;
                                                justify-content: space-between;
                                                margin-left: 0;
                                            }
                                            @media screen and (min-width: $bp-large) {
                                                justify-content: flex-start;
                                            }
                                            .primary {
                                                @media screen and (min-width: $bp-large) {
                                                    margin-right: 10px;
                                                }
                                                .action {
                                                    height: 45px;
                                                    border-radius: 0;
                                                    background-color: $p-black;
                                                    border: none;
                                                    span {
                                                        @include SourceSansRegular;
                                                        font-size: 20px;
                                                        text-transform: uppercase;
                                                        color: $white;
                                                    }
                                                }
                                            }
                                            .secondary {
                                                .action {
                                                    span {
                                                        @include SourceSansRegular;
                                                        font-size: 16px;
                                                        color: $p-red;
                                                        text-decoration: underline;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .block-new-customer {
                            .block-title {
                                strong {
                                    @include SourceSansBold;
                                    font-size: 22px;
                                    color: $p-black;
                                }
                            }
                            .block-content {
                                p {
                                    @include SourceSansRegular;
                                    font-size: 16px;
                                    color: $p-black;
                                }
                                .actions-toolbar {
                                    .primary {
                                        .action {
                                            height: 45px;
                                            border-radius: 0;
                                            background-color: $p-black;
                                            border: none;
                                            span {
                                                @include SourceSansRegular;
                                                font-size: 20px;
                                                text-transform: uppercase;
                                                color: $white;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//layout create new password after receive email

.customer-account-createpassword {
    .page-wrapper {
        .messages-container {
            position: relative;
            top: 0;
        }
        .page-main {
            padding-left: 25px;
            padding-right: 25px;
            .page-title-wrapper {
                .block-title {
                    .page-title {
                        margin: 20px 0;
                        line-height: 0.8;
                        @media screen and (min-width: $bp-medium) {
                            text-align: center;
                            margin: 35px 0;
                            width: 100%;
                        }
                        @media screen and (min-width: $bp-large) {
                            margin: 35px 0 45px;
                        }
                        .base {
                            font-size: 22px;
                            @include SourceSansBold;
                            color: $p-black;
                            @media screen and (min-width: $bp-xlarge) {
                                font-size: 30px;
                            }
                        }
                    }
                }
            }
            .columns {
                .column {
                    .password {
                        @media screen and (min-width: $bp-medium) {
                            margin: 0 auto;
                            min-width: 730px;
                        }
                        @media screen and (min-width: $bp-large) {
                            width: 65%;
                        }
                        .fieldset {
                            .password {
                                @media screen and (min-width: $bp-medium) {
                                    display: flex;
                                    justify-content: center;
                                    width: 97%;
                                }
                                @media screen and (min-width: $bp-xlarge) {
                                    width: 100%;
                                }
                                .label {
                                    @include SourceSansRegular;
                                    @media screen and (min-width: $bp-medium) {
                                        text-align: left;
                                    }
                                    span {
                                        color: $p-black;
                                        font-weight: 500;
                                        font-size: 18px;
                                        &::after {
                                            color: $p-black;
                                        }
                                    }
                                }
                                .control {
                                    @media screen and (min-width: $bp-medium) {
                                        width: 50%;
                                    }
                                    .input-text {
                                        height: 40px;
                                        border: 1px solid $p-black;
                                    }
                                    #password-strength-meter-container {
                                        .password-strength-meter {
                                            color: $p-black;
                                            font-size: 14px;
                                            @include SourceSansRegular;
                                        }
                                    }
                                }
                            }
                            .confirmation {
                                margin-top: 20px;
                                @media screen and (min-width: $bp-medium) {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                                @media screen and (min-width: $bp-large) {
                                    width: 100%;
                                    margin-top: 0;
                                }
                                .label {
                                    @media screen and (min-width: $bp-medium) {
                                        text-align: left;
                                        font-size: 14px;
                                    }
                                    @include SourceSansRegular;
                                    span {
                                        color: $p-black;
                                        font-weight: 500;
                                        font-size: 18px;
                                        &::after {
                                            color: $p-black;
                                        }
                                    }
                                }
                                .control {
                                    @media screen and (min-width: $bp-medium) {
                                        width: 50%;
                                    }
                                    .input-text {
                                        height: 40px;
                                        border: 1px solid $p-black;
                                    }
                                }
                            }
                        }
                        .actions-toolbar {
                            @media screen and (min-width: $bp-medium) {
                                margin-left: 11.5%;
                            }
                            .primary {
                                .action {
                                    background: $p-black;
                                    border: 1px solid $p-black;
                                    border-radius: 0;
                                    font-size: 16px;
                                    height: 40px;
                                    padding: 0;
                                    font-weight: 500;
                                    @include SourceSansRegular;
                                    @media screen and (min-width: $bp-medium) {
                                        width: 100%;
                                        padding: 0 15px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
