.catalogsearch-result-index {
    .page-wrapper {
        .sections {
            @media screen and (min-width: $bp-large) {
                background-color: transparent;
            }
        }
        .page-title-wrapper {
            position: relative;
            .block-title {
                display: flex;
                position: absolute;
                justify-content: center;
                align-items: center;
                text-align: center;
                margin-top: 60px;
                top: auto;
                left: 0;
                right: 0;
                @media screen and (min-width: $bp-medium) {
                    margin-top: 40px;
                }
                @media screen and (min-width: $bp-xxlarge) {
                    margin-top: 60px;
                }
                @media screen and (min-width: 1600px) {
                    margin-top: 90px;
                }
                @media screen and (min-width: 1920px) {
                    margin-top: 8%;
                }
                .page-title {
                    @include PlayfairDisplayBlack;
                    font-size: 20px;
                    color: $white;
                    text-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
                    margin: 0;
                    @media screen and (min-width: $bp-medium) {
                        font-size: 36px;
                    }
                    @media screen and (min-width: $bp-large) {
                        font-size: 50px;
                    }
                    span {
                        &::before {
                            content: "~ ";
                        }
                        &::after {
                            content: " ~";
                        }
                    }
                }
            }
        }
        .banner-container {
            display: flex;
            justify-content: center;
            margin-top: -70px;
            height: 223px;
            overflow: hidden;
            @media screen and (min-width: $bp-large) {
                margin-top: -120px;
                height: auto;
            }
            @media screen and (min-width: $bp-xlarge) {
                margin-top: -170px;
            }
            @media screen and (min-width: $bp-xxlarge) {
                margin-top: -170px;
            }
            img {
                width: auto;
                object-fit: contain;
                height: 100%;
                max-width: none;
                @media screen and (min-width: $bp-large) {
                    width: 100%;
                }
            }
        }
        .breadcrumbs {
            display: none;
        }
        .page-header {
            background-color: transparent !important;
            .header {
                .header-right {
                    .mobile-right-menu {
                        #right-menu {
                            background-color: rgba(0, 0, 0, 0.75) !important;
                        }
                    }
                }
            }
        }
        .page-main {
            margin-top: 0 !important;
            .columns {
                margin-top: 20px;
                .column {
                    .search {
                        .toolbar {
                            margin-top: 20px;
                            @media screen and (min-width: $bp-medium) {
                                padding: 0;
                                justify-content: space-between;
                            }
                            .toolbar-amount {
                                @media screen and (min-width: $bp-medium) {
                                    padding: 0;
                                }
                            }
                            .limiter {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin: 10px 0;
                                @media screen and (min-width: $bp-medium) {
                                    margin: 0;
                                }
                            }
                            .toolbar-sorter {
                                @media screen and (min-width: $bp-medium) {
                                    padding: 0;
                                    text-align: right;
                                    width: 100%;
                                    justify-content: flex-end;
                                }
                            }
                        }
                        .block {
                            display: flex;
                            flex-wrap: wrap;
                            margin-bottom: 0px;
                            .title {
                                @include SourceSansRegular;
                                font-size: 16px;
                                color: $p-black;
                                margin-right: 10px;
                                width: 100%;
                                @media screen and (min-width: $bp-medium) {
                                    width: auto;
                                    margin-bottom: 0;
                                    margin-right: 5px;
                                }
                                @media screen and (min-width: $bp-large) {
                                    font-size: 20px;
                                }
                            }
                            .item {
                                display: flex;
                                margin: 0 5px;
                                a {
                                    @include SourceSansRegular;
                                    font-size: 12px;
                                    color: $white;
                                    padding: 5px 10px;
                                    background-color: $hover;
                                    @media screen and (min-width: $bp-large) {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                        div:nth-child(1) {
                            margin-top: 0;
                            @media screen and (min-width: $bp-medium) {
                                justify-content: space-between;
                            }
                            .limiter {
                                display: none;
                            }
                            .toolbar-amount {
                                @media screen and (min-width: $bp-medium) {
                                    width: 45%;
                                    text-align: left;
                                }
                                @media screen and (min-width: 1600px) {
                                    width: 74%;
                                }
                            }
                        }
                        div:nth-child(3) {
                            @media screen and (min-width: $bp-medium) {
                                justify-content: flex-end;
                            }
                            .pages {
                                display: block !important;
                            }
                            .toolbar-sorter {
                                display: none;
                            }
                        }
                        div:nth-child(4) {
                            @media screen and (min-width: $bp-medium) {
                                justify-content: flex-end;
                            }
                            .pages {
                                display: block !important;
                            }
                            .toolbar-sorter {
                                display: none;
                            }
                        }
                        .products {
                            .products {
                                .item {
                                    .product-item-info {
                                        .product {
                                            .product-item-inner {
                                                .product {
                                                    .actions-secondary {
                                                        display: none !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
