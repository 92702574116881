body.contact-index-index {
    .message.global.demo {
        display: none;
    }
    .page-wrapper {
        font-family: Source Sans Pro;
        .page-header {
            background: transparent;
            .header {
                background-color: rgba(0, 0, 0, 0.75);
            }
        }
        .breadcrumbs {
            display: none;
        }
        .rootmenu {
            background-color: rgba(0, 0, 0, 0.75) !important;
        }
        .footer {
            .block.newsletter {
                display: none;
            }
        }
        .page-title-wrapper {
            position: absolute;
            text-align: center;
            top: 45%;
            width: 100%;
            .page-title {
                color: white;
                font-family: Playfair Display;
                font-size: 50px;
                font-weight: 800;
                font-style: normal;
                letter-spacing: 1px;
                text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.75);
                &:before,
                &:after {
                    content: "~";
                    font-size: 50px;
                    display: inline;
                    margin: 0 15px;
                }
            }
        }
        .columns .column.main {
            margin-top: 21%;
            padding-bottom: 0;
        }
        .top-banner-contact {
            position: absolute;
            top: 0;
            width: 100%;
            img {
                width: 100%;
                &.only-desk {
                    display: block;
                }
                &.only-tab {
                    display: none;
                }
                &.only-mob {
                    display: none;
                }
            }
        }
        .banner-form {
            max-width: 1140px;
            margin: 0 auto;
            display: table;
            width: 100%;
            .form {
                .fieldset {
                    .legend {
                        span {
                            color: $p-black;
                        }
                    }
                    .field.note {
                        color: $p-black;
                    }
                }
            }
        }
        #contact-form {
            float: left;
            width: 45%;
            min-width: 45%;

            input[type="text"],
            input[type="email"],
            textarea {
                background: white;
                border-radius: 0;
                border: 1px solid black;
            }
            input[type="text"],
            input[type="email"] {
                padding-top: 0;
                padding-bottom: 0;
                line-height: 45px;
                height: 45px;
            }
            textarea {
                min-height: 150px;
            }
            label {
                display: none;
            }
            .fieldset {
                margin-bottom: 0;
                .legend {
                    margin-left: 0;
                    width: 100%;
                    font-family: Playfair Display;
                    font-weight: bold;
                    font-size: 30px;
                }
                .field {
                    margin-bottom: 20px;
                    .control {
                        width: 100%;
                    }
                    &.note {
                        font-size: 16px;
                    }
                }
            }
            .field.note {
                &:before {
                    display: none;
                }
            }
            .actions-toolbar {
                .primary {
                    width: 100%;
                }
                button {
                    background: #93002a;
                    text-transform: uppercase;
                    font-family: Source Sans Pro;
                    font-size: 20px;
                    font-weight: normal;
                    color: white;
                    float: right;
                    letter-spacing: 1px;
                    height: 47px;
                    line-height: 47px;
                    padding: 0;
                    max-width: 250px;
                    text-align: center;
                    border: none;
                    margin: 0;
                    &:hover {
                    }
                }
            }
        }
        .right-banner-contact {
            float: right;
            min-width: 45%;
            width: 45%;
            .black-gray {
                border-radius: 10px;
                background: #f3f3f3;
                padding: 55px 40px;
                text-align: center;
                margin: 100px 0 0;
                p {
                    font-weight: normal;
                    font-size: 20px;
                    line-height: 40px;
                    text-align: center;
                    margin: 0;
                    letter-spacing: 0.67px;
                    a {
                        color: black;
                        font-weight: 900;
                        text-decoration: none;
                        img {
                            max-width: 40px;
                        }
                    }
                    &.bld {
                        font-weight: bold;
                        color: black;
                        span {
                            font-size: 24px;
                        }
                    }
                }
            }
        }
        .contact-bottom-stores {
            background: #f3f3f3;
            display: table;
            width: 100%;
            text-align: left;
            margin: 100px auto 0;
            max-width: 100%;
            padding: 50px 0 0;
            .content-center {
                display: table;
                max-width: 1140px;
                margin: 0 auto;
            }
            .cont_stores {
                display: flex;
                flex-wrap: wrap;
                .subtitle {
                    font-size: 30px;
                    line-height: 60px;
                    text-align: left;
                    margin: 0 0 30px 0;
                    width: 100%;
                    font-family: Playfair Display;
                    font-weight: 900;
                }
                .row {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    .col {
                        flex: 0 43%;
                        margin: 0 5% 50px 0;
                        display: inline-block;
                        padding: 20px;
                        background: white;
                        border-radius: 10px;
                        &::nth-of-type(even) {
                            margin-right: 0;
                        }
                        .map {
                            margin-top: 20px;
                        }
                        h4 {
                            margin-bottom: 0;
                            font-size: 20px;
                            font-weight: bold;
                        }
                        p {
                            font-size: 20px;
                            text-align: left;
                            margin-top: 0;
                            margin-bottom: 0;
                            &.phone {
                                font-weight: bold;
                            }
                        }

                        iframe {
                            max-width: 100%;
                            width: 100%;
                        }
                    }
                    div.col:nth-of-type(even) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@media (min-width: 2001px) and (max-width: 3000px) {
    body.contact-index-index {
        .page-wrapper {
            .columns .column.main {
                margin-top: 800px;
                padding-bottom: 0;
            }
        }
    }
}

@media (min-width: 1701px) and (max-width: 2000px) {
    body.contact-index-index {
        .page-wrapper {
            .columns .column.main {
                margin-top: 24%;
                padding-bottom: 0;
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    body.contact-index-index {
        .page-wrapper {
            .banner-form {
                width: 95%;
                padding: 0 2.5%;
                display: inline-block;
                max-width: 100%;
            }
            .contact-bottom-stores {
                .content-center {
                    max-width: 100%;
                    padding: 0 20px;
                }
            }
        }
    }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    body.contact-index-index {
        .page-wrapper {
            .columns .column.main {
                margin-top: 21%;
            }
        }
    }
}

@media only screen and (max-width: 1025px) {
    body.contact-index-index {
        .page-wrapper {
            .columns .column.main {
                margin-top: 43% !important;
            }
            .page-title-wrapper {
                .page-title {
                    font-size: 32px;
                }
            }
            .top-banner-contact {
                img {
                    &.only-desk {
                        display: none;
                    }
                    &.only-tab {
                        display: block;
                    }
                    &.only-mob {
                        display: none;
                    }
                }
            }
            #contact-form {
                min-width: 45% !important;
                width: 45%;
                .field.note {
                    font-size: 20px;
                }
            }
            .right-banner-contact {
                max-width: 45%;
                width: 45%;
                .black-gray {
                    padding: 35px;
                    p {
                        font-size: 16px;
                        line-height: 40px;
                        span {
                            font-size: 18px;
                        }
                    }
                }
            }
            .contact-bottom-stores {
                .content-center {
                    max-width: 100%;
                    padding: 0 20px;
                }
                .cont_stores {
                    .row {
                        .col {
                            padding: 20px 15px;
                            margin: 0 20px 50px;
                            flex: 0 42%;
                            p {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Landscape */

@media (min-width: 1024px) and (max-width: 1366px) and (orientation: landscape) {
    body.contact-index-index {
        .page-wrapper {
            .banner-form {
                width: 95%;
                padding: 0 2.5%;
                display: inline-block;
                max-width: 100%;
            }
            .contact-bottom-stores {
                .content-center {
                    max-width: 100%;
                    padding: 0 20px;
                }
            }
        }
    }
}

@media (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    body.contact-index-index {
        .page-wrapper {
            .top-banner-contact {
                img {
                    &.only-desk {
                        display: block !important;
                    }
                    &.only-tab {
                        display: none !important;
                    }
                    &.only-mob {
                        display: none !important;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    body.contact-index-index {
        .page-wrapper {
            .right-banner-contact {
                .black-gray {
                    margin-top: 100px;
                    p {
                        font-size: 15px;
                        line-height: 30px;
                    }
                }
            }
            #contact-form {
                .field.note {
                    font-size: 16px;
                }
            }
            .contact-bottom-stores {
                .cont_stores {
                    .row {
                        .col {
                            max-width: 35%;
                            padding: 20px 15px;
                            margin: 0 20px 50px;
                            p {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    body.contact-index-index {
        .page-wrapper {
            .page-title-wrapper {
                top: 34%;
                .page-title {
                    font-size: 30px;
                    span {
                        margin: 0 auto;
                        display: inline;
                    }
                    &:before,
                    &:after {
                        font-size: 25px;
                    }
                }
            }
            .columns .column.main {
                margin-top: 50% !important;
            }
            .top-banner-contact {
                img {
                    &.only-desk {
                        display: none;
                    }
                    &.only-tab {
                        display: none;
                    }
                    &.only-mob {
                        display: block;
                    }
                }
            }
            #contact-form,
            .right-banner-contact {
                width: 90%;
                float: none;
                display: table;
                margin: 0 auto;
            }
            .right-banner-contact {
                max-width: 100%;
                .black-gray {
                    margin-top: 50px;
                    padding-left: 15px;
                    padding-right: 15px;
                    p {
                        font-size: 13px;
                        &.bld {
                            span {
                                font-size: 20px;
                            }
                            &.social {
                                a {
                                    img {
                                        max-width: 20px;
                                        margin: 0 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            #contact-form {
                .actions-toolbar {
                    .primary {
                        float: none;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 599px) {
    body.contact-index-index {
        .page-wrapper {
            .page-title-wrapper {
                .page-title {
                    font-size: 25px;
                    &:before,
                    &:after {
                        font-size: 20px;
                    }
                }
            }
            .contact-bottom-stores {
                .cont_stores {
                    .subtitle {
                        font-size: 25px;
                    }
                    .row {
                        .col {
                            max-width: 100%;
                            margin: 0 0 50px;
                            flex: 0 100%;
                            p {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $bp-large) {
    body.contact-index-index {
        .page-wrapper {
            .banner-form {
                width: 100%;
                padding: 0 10%;
                display: inline-block;
                max-width: 100%;
            }
            .contact-bottom-stores {
                margin-top: 40px;
                background-color: $white;
                .content-center {
                    padding: 0 10%;
                    .cont_stores {
                        .subtitle {
                            color: $p-black;
                        }
                        .row {
                            padding-bottom: 20%;
                            .col {
                                flex: 0 47%;
                                padding: 0;
                                h4,
                                p {
                                    columns: $p-black;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
