.onepage-index-index {
    .page-wrapper {
        .page-header {
            .header {
                @media screen and (min-width: $bp-large) {
                    min-height: 85px;
                }

                .logo {
                    margin-left: 30px;
                    padding-bottom: 10px;
                    width: 145px;

                    img {
                        max-height: 64px;
                    }
                }

                .block.block-search {
                    display: none;
                }

                .header-right {
                    top: 0;

                    .help-container {
                        margin-right: 55px;

                        a {
                            @include SourceSansRegular;
                            color: $white;
                            font-size: 18px;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }

                    .store-info {
                        .whatssap {
                            .icon-icon-whatsapp-relleno {
                                top: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .page-title {
        margin-bottom: 0;
        display: block !important;
        text-align: center;

        span {
            @include PlayfairDisplayBlack;
            font-size: 30px;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $p-black;
        }
    }

    .columns {
        @media screen and (max-width: $bp-medium) {
            padding: 0 20px;
        }
        .kueski-widget {
            display: none;
        }
    }

    .field-error {
        font-size: 1rem !important;
    }

    .iwd_main_wrapper .field .scroll-wrapper .iwd_opc_select_option:last-child {
        border-bottom: initial;
    }

    .page-main {
        max-width: 1366px !important;

        .iwd_main_wrapper {
            margin: 0;

            div.mage-error[generated],
            .iwd_opc_universal_wrapper.iwd_opc_message .iwd_opc_message_error {
                font-size: 16px !important;
            }

            #iwd_opc_top {
                margin-top: 0;
                margin-bottom: 0;

                .iwd_opc_column {
                    float: left;
                    @media screen and (min-width: $bp-xlarge) {
                        padding-left: 20px !important;
                        padding-right: 15px;
                    }

                    .iwd_opc_alternative_column {
                        #iwd_opc_top_login_button,
                        .iwd_opc_button.active {
                            background-color: $white !important;
                            border: solid 1px $p-gray;
                            border-radius: 0;
                            height: 45px;
                            @include input-styles;
                            @media screen and (min-width: $bp-xlarge) {
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .iwd_opc_alternative_wrapper {
                @media screen and (min-width: $bp-large) {
                    margin: 0 10px;
                }

                .onepage-steps-container {
                    @media screen and (min-width: $bp-xlarge) {
                        display: flex;
                    }

                    .steps-container {
                        width: 100%;
                        display: block;
                        @media screen and (min-width: $bp-large) {
                            width: 70%;
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            grid-template-rows: max-content;
                        }

                        .iwd_opc_address_column {
                            @media screen and (min-width: $bp-large) {
                                grid-area: 1 / 1 / 3 / 2;
                            }

                            .iwd_opc_alternative_column {
                                .iwd_opc_column_content {
                                    .fieldset {
                                        .field {
                                            .control {
                                                .scroll-wrapper {
                                                    .iwd_opc_select_container {
                                                        .iwd_opc_select_option:last-child {
                                                            border-bottom: 1px
                                                                solid #4a4a4a;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .iwd_opc_shipping_column {
                            @media screen and (min-width: $bp-large) {
                                grid-area: 1 / 2 / 2 / 3;
                            }

                            .iwd_opc_alternative_column {
                                .iwd_opc_column_content {
                                    #iwd_opc_shipping_method {
                                        .fieldset {
                                            .form {
                                                .mage-error {
                                                    color: #e02b27;
                                                    font-size: 1rem !important;
                                                    margin-bottom: 10px;
                                                }
                                                #checkout-shipping-method-load {
                                                    .field {
                                                        .control {
                                                            .iwd_opc_select_container {
                                                                border-radius: 0;
                                                                border: 0;

                                                                .iwd_opc_select_option {
                                                                    border-bottom: 1px
                                                                        solid
                                                                        #4a4a4a;

                                                                    &.selected {
                                                                        border: 2px
                                                                            solid
                                                                            $hover;
                                                                    }
                                                                }
                                                            }

                                                            [data-element-id="iwd_opc_shipping_method_group"] {
                                                                .iwd_opc_select_option {
                                                                    &:last-child {
                                                                        margin-top: 20px;
                                                                    }
                                                                    &.selected {
                                                                        &:last-child {
                                                                            margin-top: 0;
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            [data-element-id="iwd_opc_shipping_method_rates"] {
                                                                .iwd_opc_select_option {
                                                                    &:last-child {
                                                                        margin-top: 20px;
                                                                    }
                                                                    &.selected {
                                                                        &:last-child {
                                                                            margin-top: 0;
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            .iwd_opc_select_container:focus {
                                                                box-shadow: none;
                                                            }

                                                            .scroll-wrapper {
                                                                border-radius: 0;

                                                                .iwd_opc_select_container {
                                                                    border-radius: 0;
                                                                }

                                                                .iwd_opc_select_container:focus {
                                                                    box-shadow: none;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .iwd_opc_payment_column {
                            @media screen and (min-width: $bp-large) {
                                grid-area: 2 / 2 / 3 / 3;
                            }

                            #conektaIframeContainer {
                                width: 100% !important;
                            }

                            .iwd_opc_alternative_column {
                                .iwd_opc_column_content {
                                    #co-payment-form {
                                        .fieldset {
                                            #checkout-payment-method-load {
                                                .items {
                                                    .field {
                                                        .control {
                                                            .iwd_opc_select_container {
                                                                border-radius: 0;

                                                                .iwd_opc_select_option {
                                                                    border-bottom: 1px
                                                                        solid
                                                                        #4a4a4a;
                                                                }
                                                            }

                                                            .iwd_opc_select_container:focus {
                                                                box-shadow: none;
                                                            }

                                                            .scroll-wrapper {
                                                                border-radius: 0;

                                                                .iwd_opc_select_container {
                                                                    border-radius: 0;
                                                                }

                                                                .iwd_opc_select_container:focus {
                                                                    box-shadow: none;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .factura-container {
                                                margin-bottom: 15px;
                                                .fieldset {
                                                    position: relative;
                                                    label {
                                                        span {
                                                            padding-left: 26px;
                                                        }
                                                    }
                                                    input[type="checkbox"] {
                                                        position: absolute;
                                                        top: 0;
                                                        left: 0;
                                                        opacity: 0;
                                                        z-index: 1;
                                                    }
                                                    input[type="checkbox"]
                                                        ~ label {
                                                        cursor: pointer;
                                                        font-size: 14px;
                                                        line-height: 19px;
                                                        position: relative;
                                                        display: block;
                                                    }
                                                    input[type="checkbox"]
                                                        ~ label:before {
                                                        width: 16px;
                                                        height: 16px;
                                                        border: 2px solid
                                                            $p-gray;
                                                        border-radius: 0;
                                                        position: absolute;
                                                        content: "";
                                                        left: 0;
                                                    }
                                                    input[type="checkbox"]:checked
                                                        ~ label:before {
                                                        background: url(../images/checkout/checked.svg);
                                                        content: "";
                                                        background-repeat: no-repeat;
                                                        background-position: center;
                                                    }
                                                    .requires-invoice-msg {
                                                        margin: 15px 0;
                                                    }
                                                    .invoice-details {
                                                        .required-entry {
                                                            height: 45px;
                                                            font-size: 16px;
                                                            border: 1px solid
                                                                #4a4a4a;
                                                            margin-bottom: 20px;
                                                            &::placeholder {
                                                                color: #4a4a4a;
                                                            }
                                                        }
                                                        .control {
                                                            position: relative;
                                                            #use_of_cfdi {
                                                                height: 45px;
                                                                font-size: 16px;
                                                                border: 1px
                                                                    solid
                                                                    #4a4a4a;
                                                                color: #4a4a4a;
                                                                appearance: none;
                                                            }
                                                            &:after {
                                                                font-family: FontAwesome;
                                                                content: "\f107";
                                                                position: absolute;
                                                                color: #343434;
                                                                font-size: 14px;
                                                                right: 16px;
                                                                line-height: 1;
                                                                top: calc(
                                                                    50% - 7px
                                                                );
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .iwd_opc_column {
                            width: 100%;
                            margin: 0 0 20px;
                            @media screen and (min-width: $bp-medium) {
                                margin: 0 auto 20px;
                                max-width: 70% !important;
                            }
                            @media screen and (min-width: $bp-xlarge) {
                                margin: 0;
                                max-width: 410px !important;
                                padding: 0;
                            }
                            @media screen and (min-width: $bp-xxlarge) {
                                max-width: 442px !important;
                            }

                            .iwd_opc_alternative_column {
                                .iwd_opc_column_content {
                                    .fieldset {
                                        .field {
                                            .control {
                                                .scroll-wrapper {
                                                    border-radius: 0;

                                                    .iwd_opc_select_container {
                                                        border-radius: 0;
                                                    }

                                                    .iwd_opc_select_container:focus {
                                                        box-shadow: none;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    #opc-new-shipping-address {
                                        .form {
                                            .fieldset {
                                                .field {
                                                    .control {
                                                        .select {
                                                            border: 1px solid
                                                                $p-gray;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .sidebar-summary {
                        width: 100%;
                        height: fit-content;
                        @media screen and (min-width: $bp-xlarge) {
                            padding: 0 20px;
                            max-width: 442px;
                        }

                        .summary-container {
                            padding: 20px;
                            border: 1px solid $p-gray;

                            .scroll-wrapper {
                                #iwd_opc_review_items {
                                    .iwd_opc_review_item {
                                        display: flex;
                                        justify-content: space-between;
                                        padding: 20px 0;
                                        border-bottom: 2px solid $p-red;

                                        div:nth-child(2) {
                                            width: 100%;
                                            padding: 0 10px;
                                        }

                                        .iwd_opc_review_item_cell {
                                            .product-image-container {
                                                .product-image-wrapper {
                                                    height: initial;
                                                }
                                            }

                                            .iwd_opc_review_item_name {
                                                @include SourceSansRegular;
                                                font-size: 16px;
                                                font-stretch: normal;
                                                line-height: normal;
                                                letter-spacing: normal;
                                                color: $p-black;
                                                max-width: 200px;
                                            }

                                            .iwd_opc_review_item_options {
                                                padding: 0;

                                                .iwd_opc_review_item_option {
                                                    .iwd_opc_review_item_option_label {
                                                        color: $p-black;
                                                        font-weight: bold;
                                                    }

                                                    .iwd_opc_review_item_option_value {
                                                        color: $p-red;
                                                        font-weight: bold;
                                                    }
                                                }
                                            }

                                            .iwd_opc_review_item_subtotal.old-price {
                                                color: $p-black;
                                            }

                                            .iwd_opc_review_item_subtotal {
                                                @include SourceSansBold;
                                                font-size: 20px;
                                                font-stretch: normal;
                                                line-height: normal;
                                                letter-spacing: normal;
                                                text-align: center;
                                                color: $p-red;
                                            }
                                        }

                                        &:last-child {
                                            margin-bottom: 12px;
                                        }
                                    }
                                }
                            }

                            .separator {
                                width: 100%;
                                height: 2px;
                                background: $p-red;
                            }

                            .iwd_opc_review_total {
                                display: flex;
                                justify-content: space-between;
                                width: 60%;
                                margin-left: auto;

                                .iwd_opc_review_total_cell {
                                    @include SourceSansRegular;
                                    font-size: 20px;
                                    font-stretch: normal;
                                    line-height: 1.3;
                                    letter-spacing: normal;
                                    text-align: right;
                                    color: $p-black;
                                    width: inherit;
                                    display: flex;
                                    align-items: center;

                                    &:last-child {
                                        @include SourceSansBold;
                                        display: flex;
                                        justify-content: flex-end;
                                        align-items: center;
                                    }
                                }

                                &.iwd_opc_grand_total {
                                    padding-top: 12px;
                                    position: relative;

                                    .iwd_opc_review_total_cell {
                                        &:last-child {
                                            font-size: 24px;
                                        }
                                    }
                                }

                                &.shipping {
                                    padding-bottom: 12px;
                                }
                            }

                            .totals.store-credit {
                                display: flex;
                                justify-content: space-between;
                                width: 60%;
                                margin-left: auto;

                                .mark,
                                .amount {
                                    span {
                                        @include SourceSansRegular;
                                        font-size: 20px;
                                        line-height: 1.3;
                                        letter-spacing: normal;
                                        text-align: right;
                                        color: #000;
                                        display: flex;
                                        align-items: center;
                                    }
                                }

                                .amount {
                                    span {
                                        @include SourceSansBold;
                                    }
                                }
                            }
                        }
                    }

                    .secure-purchase {
                        max-width: 315px;
                        margin: 0 auto;
                        justify-content: space-between;

                        img {
                            margin-right: 20px;
                        }

                        .red-helper {
                            @include SourceSansRegular;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.44;
                            letter-spacing: normal;
                            text-align: justify;
                            color: $p-red;

                            span {
                                &:first-child {
                                    font-size: 22px;
                                }

                                &:last-child {
                                    font-size: 18px;
                                }
                            }
                        }
                    }
                }
            }

            .iwd_opc_column_name {
                @include PlayfairDisplayBlack;
                font-size: 24px;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $p-black;
            }

            .iwd_opc_small_button_container {
                #iwd_opc_login_button {
                    text-transform: uppercase;
                    float: none;
                    @include btn-red;
                    margin-bottom: 20px;
                    width: 100%;
                    background: $p-black;
                    color: $white;
                    border: 1px solid $p-black;
                }
            }

            .iwd_opc_column {
                &.iwd_opc_payment_column,
                &.iwd_opc_shipping_column {
                    display: flex;
                    float: none;
                    padding: 0 24px;

                    .iwd_opc_alternative_column {
                        max-width: 100%;

                        .payments {
                            .iwd_opc_button {
                                border: none;
                                display: flex;
                                padding: 10px 0;

                                &.active {
                                    border: none;
                                    @include SourceSansRegular;
                                    font-size: 16px;
                                    font-stretch: normal;
                                    line-height: normal;
                                    letter-spacing: normal;
                                    color: $p-gray;
                                }

                                &.with_angle {
                                    &:after {
                                        content: url(../images/icons/icon-plus.svg) !important;
                                        right: 0;
                                    }
                                }
                            }

                            .fieldset {
                                #iwd_opc_discount {
                                    padding: 0 20px;
                                    margin-bottom: 20px;
                                    border: solid 1px $p-gray;
                                    background: $white;

                                    .iwd_opc_universal_wrapper {
                                        margin-bottom: 0;
                                    }

                                    .iwd_opc_button {
                                        display: flex;
                                        align-items: center;
                                    }

                                    .form-discount {
                                        .iwd_opc_small_button_container {
                                            display: flex;
                                            flex-direction: column;

                                            .iwd_opc_small_button {
                                                text-transform: uppercase;

                                                &.active {
                                                    @include btn-red;
                                                    margin-bottom: 20px;
                                                    width: 100%;
                                                    background: $p-black;
                                                    color: $white;
                                                    border: 1px solid $p-black;
                                                }
                                            }
                                        }
                                    }

                                    &.iwd_opc_collapsible_opened {
                                        .with_angle {
                                            &:after {
                                                content: url(../images/icons/icon-minus.svg) !important;
                                                right: 0;
                                            }
                                        }
                                    }
                                }

                                .payment-option.store-credit {
                                    min-height: 45px;
                                    border: 1px solid #4a4a4a;
                                    margin-bottom: 20px;
                                    padding: 10px 20px;
                                    position: relative;
                                    background: white;

                                    .payment-option-title {
                                        margin: 0;

                                        .action {
                                            span {
                                                @include SourceSansRegular;
                                                font-size: 16px;
                                                color: #4a4a4a;
                                            }
                                        }

                                        &:before {
                                            content: url(../images/icons/icon-plus.svg);
                                            right: 0;
                                            position: absolute;
                                        }
                                    }

                                    .payment-option-content {
                                        .form {
                                            .actions-toolbar {
                                                margin: 15px 0 0 0;
                                                display: flex;
                                                flex-direction: column;

                                                .label {
                                                    @include SourceSansRegular;
                                                    font-size: 16px;
                                                    color: $p-black;
                                                    margin-bottom: 10px;
                                                }

                                                .primary {
                                                    .action {
                                                        margin: 0;
                                                        @include btn-red;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .payment-option.store-credit._active {
                                    .payment-option-title {
                                        &::before {
                                            content: "";
                                        }

                                        &:after {
                                            content: url(../images/icons/icon-minus.svg);
                                            right: 0;
                                            position: absolute;
                                        }
                                    }
                                }
                            }
                        }

                        .fieldset {
                            .field.choice {
                                label {
                                    width: 100%;
                                    display: flex;
                                }
                            }
                        }

                        .iwd_opc_universal_wrapper {
                            .iwd_opc_place_order_button {
                                text-transform: uppercase;
                                margin-bottom: 20px;
                                width: 100%;
                                background: $p-red;
                                color: $white;
                                border: 1px solid $p-red;
                                font-size: 20px;
                                height: 45px;
                                border-radius: 0;

                                span {
                                    @include SourceSansRegular;
                                }
                            }
                        }
                    }
                }

                &.iwd_opc_address_column,
                &.iwd_opc_shipping_column,
                &.iwd_opc_payment_column {
                    .iwd_opc_alternative_column {
                        padding: 20px;
                        max-width: 100%;

                        &:focus-within {
                            background: $p-checkout;
                        }
                    }
                }
            }

            .fieldset {
                .field {
                    &.choice {
                        input[type="checkbox"] ~ label:before {
                            width: 16px;
                            height: 16px;
                            border: 2px solid $p-gray;
                            border-radius: 0;
                        }

                        input[type="checkbox"]:checked ~ label:before {
                            background: url(../images/checkout/checked.svg);
                            content: "";
                            background-repeat: no-repeat;
                            background-position: center;
                        }
                    }
                }
            }
        }
    }

    .fieldset > .field:not(.choice) > .control {
        width: 100%;
    }

    .field.street {
        margin: 0 !important;
    }

    .iwd_main_wrapper .iwd_opc_field.iwd_opc_input,
    .iwd_main_wrapper .field.iwd_opc_input,
    .iwd_main_wrapper .iwd_opc_field .input-text,
    .iwd_main_wrapper .field .input-text,
    .iwd_main_wrapper .iwd_opc_field textarea,
    .iwd_main_wrapper .field textarea,
    .iwd_main_wrapper .iwd_opc_field .iwd_opc_hosted_field,
    .iwd_main_wrapper .field .iwd_opc_hosted_field,
    .iwd_main_wrapper .iwd_opc_field.iwd_opc_textarea,
    .iwd_main_wrapper .field.iwd_opc_textarea,
    .iwd_main_wrapper .field .iwd_opc_select_container .iwd_opc_select_option,
    .iwd_main_wrapper
        .field
        .iwd_opc_select_container.selected
        .iwd_opc_select_option.selected,
    .selectize-control.single .selectize-input,
    .selectize-dropdown.single {
        background-color: $white !important;
        border: solid 1px $p-gray;
        border-radius: 0;
        min-height: 45px;
        @include input-styles;
    }

    .selectize-dropdown.single {
        border: 0;

        .selectize-dropdown-content {
            border: solid 1px $p-gray;
        }
    }

    .iwd_main_wrapper .field .input-text,
    .iwd_main_wrapper .field .select {
        height: 45px;
        border-radius: 0;
        border: solid 1px $p-gray;
        @include input-styles;
    }

    .selectize-dropdown-content {
        background: $white !important;
    }
}

#shipping-new-address-form {
    div:nth-child(8),
    div:nth-child(10),
    div:nth-child(12) {
        .control {
            &:after {
                font-family: "FontAwesome";
                content: "\f107";
                position: absolute;
                color: #343434;
                font-size: 14px;
                right: 16px;
                line-height: 1;
                top: calc(50% - 7px);
            }

            .select {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }
        }
    }
}

#checkout-payment-method-load {
    .items {
        .payment-method {
            .payment-method-content {
                .payment-method-billing-address {
                    .fieldset {
                        .field-select-billing {
                            .control {
                                &:after {
                                    font-family: "FontAwesome";
                                    content: "\f107";
                                    position: absolute;
                                    color: #343434;
                                    font-size: 14px;
                                    right: 16px;
                                    line-height: 1;
                                    top: calc(50% - 7px);
                                }

                                .select {
                                    -webkit-appearance: none;
                                    -moz-appearance: none;
                                    appearance: none;
                                    border: 1px solid #4a4a4a;
                                }
                            }
                        }

                        .form {
                            #billing-new-address-form {
                                div:nth-child(8),
                                div:nth-child(11) {
                                    .control {
                                        &:after {
                                            font-family: "FontAwesome";
                                            content: "\f107";
                                            position: absolute;
                                            color: #343434;
                                            font-size: 14px;
                                            right: 16px;
                                            line-height: 1;
                                            top: calc(50% - 7px);
                                        }

                                        .select {
                                            -webkit-appearance: none;
                                            -moz-appearance: none;
                                            appearance: none;
                                            border: 1px solid #4a4a4a;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                #openpay_cards-form {
                    .fieldset {
                        .field {
                            label.label {
                                width: auto !important;
                                text-align: left !important;
                                display: block !important;
                            }
                            &.number {
                                width: 100%;
                                .control {
                                    .payment-method__select-card {
                                        width: 100% !important;
                                        max-width: 100%;
                                    }
                                }
                            }
                            &.cvv {
                                float: none;
                                padding: 0;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        .payment-method-conekta-payments {
            .payment-method-content {
                .conekta-message {
                    width: 100%;
                    .conekta-message-header {
                        .conekta-payment-logo {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

#conekta_cc-form {
    #payment_form_conekta_cc {
        .field.number {
            .control {
                list-style: none;
                display: flex;
                justify-content: space-between;

                &:after {
                    font-family: "FontAwesome";
                    content: "\f107";
                    position: absolute;
                    color: #343434;
                    font-size: 14px;
                    right: 16px;
                    line-height: 1;
                    top: calc(50% - 7px);
                }

                .select {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                }
            }
        }

        .field.date {
            .control {
                .fields {
                    .field {
                        .control {
                            &:after {
                                font-family: "FontAwesome";
                                content: "\f107";
                                position: absolute;
                                color: #343434;
                                font-size: 14px;
                                right: 16px;
                                line-height: 1;
                                top: calc(50% - 7px);
                            }

                            .select {
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                            }
                        }
                    }
                }
            }
        }

        .field.type {
            .control {
                display: flex;
                justify-content: space-between;

                .credit-card-types {
                    display: flex;
                    list-style: none;
                    margin: 0;

                    .icons:nth-child(1) {
                        order: 2;
                    }
                }

                .title-container {
                    p {
                        color: $p-black;
                        @include SourceSansRegular;
                        font-size: 16px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

.field.date.required {
    .control {
        .fields {
            display: flex;
            justify-content: space-between;

            .field.month {
                margin-right: 10px;
            }
        }
    }
}

#conekta_cc_cc_type_exp_div {
    .control {
        &:after {
            display: none;
        }

        .fields {
            width: 100%;
        }
    }
}

#conekta_cc_cc_type_cvv_div {
    .control {
        &::after {
            display: none;
        }

        .input-text.cvv {
            width: 15%;
        }
    }
}

#payment_form_bottomconekta_cc {
    .field {
        .control {
            &:after {
                font-family: "FontAwesome";
                content: "\f107";
                position: absolute;
                color: #343434;
                font-size: 14px;
                right: 16px;
                line-height: 1;
                top: calc(50% - 7px);
            }

            .select {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }
        }
    }
}
